import RefreshIcon from '@mui/icons-material/Refresh';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import { styled, CustomTheme } from '@mui/material/styles';

export const StyledIconButton = styled(IconButton)({
  padding: 0,
});

export const StyledFailedIcon = styled(RefreshIcon)(({ theme }: { theme?: CustomTheme }) => ({
  backgroundColor: theme?.palette.error.main,
  color: theme?.palette.primary.contrast,
  borderRadius: '100px',
  marginLeft: '8px',
  padding: '4px 4px 4px 4px',
  height: '24px',
  width: '24px',
}));

export const StyledPendingIcon = styled(CircularProgress)(({ theme }: { theme?: CustomTheme }) => ({
  backgroundColor: theme?.palette.action.selected,
  color: '#b0bcc4',
  borderRadius: '100px',
  marginLeft: '8px',
  padding: '4px 4px 4px 4px',
  display: 'inline-flex',
}));
