import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import { CustomTheme, styled } from '@mui/material/styles';

export const RowContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  margin: theme.spacing(1),
  padding: theme.spacing(0, 2),
}));

export const StyledBackdrop = styled(Backdrop)(({ theme }: { theme?: CustomTheme }) => ({
  color: theme?.palette.primary.contrast,
  zIndex: 9999,
}));
