import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';

export const OfferButtons = styled('div')({
  display: 'flex',
  marginLeft: 'auto',
  alignItems: 'center',
});

export const IconButtonContainer = styled('div')(({ theme }) => ({
  '& > *': {
    textAlign: 'right',
    marginRight: theme.spacing(2),
  },
  display: 'flex',
}));

export const MetadataGrid = styled(Grid)(({ theme }) => ({
  marginLeft: theme.spacing(0.75),
  maxWidth: 'none!important',
  display: 'grid',
  gridTemplateColumns: `repeat(auto-fill, max(${theme.spacing(20)}))`,
}));

export const OfferSelectContainer = styled('div')(({ theme }) => ({
  fontSize: theme.spacing(1.5),
}));

export const OfferMenuContainer = styled('div')({
  position: 'relative',
});
