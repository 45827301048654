import React from 'react';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { ExcelIconFilled } from '@components';

interface ExcelButtonProps {
  launchExcel: () => Promise<void>;
  loading: boolean;
  label: string;
}

export const ExcelButton: React.FC<ExcelButtonProps> = ({ launchExcel, loading, label }) => (
  <Button
    variant="outlined"
    startIcon={
      loading ? (
        <CircularProgress size={24} color="inherit" data-testid="progress-icon" />
      ) : (
        <ExcelIconFilled data-testid="excel-icon" />
      )
    }
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    onClick={async () => launchExcel()}
    data-testid="excel-button"
    disabled={loading}
  >
    {label}
  </Button>
);
