import Chip from '@mui/material/Chip';
import { styled } from '@mui/material/styles';

export const StyledChip = styled(Chip)(({ theme }) => ({
  backgroundColor: theme?.palette?.action?.active,
}));

export const StyledContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: theme?.spacing(7.5),
  height: '100%',
}));
