import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import ListItem from '@mui/material/ListItem';
import { CustomTheme, styled } from '@mui/material/styles';

export const ConfirmationModalContainer = styled('div')(({ theme }: { theme?: CustomTheme }) => ({
  padding: theme?.spacing(1),
}));

export const ListContainer = styled('div')(() => ({
  listStyleType: 'disc',
}));

export const ListItemStyled = styled(ListItem)(({ theme }: { theme?: CustomTheme }) => ({
  display: 'list-item',
  fontSize: theme?.spacing(1.75),
  '& a': {
    textDecoration: 'underline',
  },
}));

export const AlertContainer = styled(Box)(({ theme }: { theme?: CustomTheme }) => ({
  padding: theme?.spacing(2),
}));

export const ButtonContainer = styled(Box)(({ theme }: { theme?: CustomTheme }) => ({
  display: 'flex',
  gap: theme?.spacing(2),
  paddingLeft: theme?.spacing(2),
  marginTop: theme?.spacing(1),
  marginBottom: theme?.spacing(1),
}));

export const ButtonStyled = styled(Button)({
  textTransform: 'none',
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '&:disabled > div': {
    color: 'transparent',
  },
});

export const LoadingIconStyled = styled(CircularProgress)({
  color: 'inherit',
  position: 'absolute',
});

export const DivStyled = styled('div')(({ theme }: { theme?: CustomTheme }) => ({
  padding: theme?.spacing(2),
  color: theme?.palette.primary.main,
  fontWeight: 500,
  fontSize: '14px',
}));
