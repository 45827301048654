import { GRID_DETAIL_PANEL_TOGGLE_COL_DEF, GridColDef } from '@mui/x-data-grid-pro';
import {
  LeftPinnedCell,
  ExpandButtonCell,
  TextWrapPrettyCell,
  VenueNameCell,
  ShowsCountCell,
  EventOfferActionsCell,
  TotalsCell,
} from '@components';
import { leftPinnedColumn } from '@utils/datagridHelpers';
import { FinancesAdjustments, FinancesAdjustmentsEventOffer } from '@types';
import { formatNumberToCurrency, formatTotalsToCurrencyOrDash } from '@utils/numberHelpers';

const budgetColumn = {
  field: 'budget',
  headerName: 'Budget',
  flex: 0.09,
  minWidth: 88,
};

const internalColumn = {
  field: 'internal',
  headerName: 'Internal',
  flex: 0.09,
  minWidth: 89,
};

const typeColumn: GridColDef = {
  field: 'type',
  headerName: 'Type',
  flex: 0.15,
  minWidth: 143,
};

const notesColumn: GridColDef = {
  field: 'notes',
  headerName: 'Notes',
  flex: 0.25,
  minWidth: 170,
};

type FixedCostsColumns = GridColDef<FinancesAdjustments>[];

export const adjustmentsColumns: FixedCostsColumns = [
  {
    ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
    renderCell: ExpandButtonCell,
  },
  {
    field: 'name',
    ...leftPinnedColumn,
    renderCell: ({ row }) => (
      <LeftPinnedCell>
        <TextWrapPrettyCell>{row.name}</TextWrapPrettyCell>
        <ShowsCountCell count={row.eventOffers.length} total={row.showsCount} />
      </LeftPinnedCell>
    ),
  },
  {
    ...budgetColumn,
    valueGetter: ({ row }) => formatTotalsToCurrencyOrDash(row.budget),
    renderCell: ({ formattedValue }) => (
        <TotalsCell {...formattedValue} name='Budget' />
    ),
  },
  {
    ...internalColumn,
    valueGetter: ({ row }) => formatTotalsToCurrencyOrDash(row.internal),
    renderCell: ({ formattedValue }) => (
        <TotalsCell {...formattedValue} name='Internal' />
    ),
  },
  typeColumn,
  notesColumn,
];

type FixedCostsEventOffer = GridColDef<FinancesAdjustmentsEventOffer>[];

export const adjustmentsEventOfferColumns: FixedCostsEventOffer = [
  {
    ...leftPinnedColumn,
    field: 'venueName',
    colSpan: 2,
    renderCell: ({ row }) => (
      <LeftPinnedCell>
        <VenueNameCell venue={row.venue} />
        <EventOfferActionsCell
          updatedDate={row.updatedDate}
          offerId={row.offerId}
        />
      </LeftPinnedCell>
    ),
  },
  {
    field: 'whiteSpace',
    width: 10,
  },
  {
    ...budgetColumn,
    valueFormatter: ({ value }) => formatNumberToCurrency(value as number),
  },
  {
    ...internalColumn,
    valueFormatter: ({ value }) => formatNumberToCurrency(value as number),
  },
  typeColumn,
  notesColumn,
];
