import ChevronLeftSharp from '@mui/icons-material/ChevronLeftSharp';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { SvgImage } from '../../components/shared/ImageComponents/SvgImage';

export const PageContainer = styled(Box)({
  width: '100%',
  height: '100vh',
  display: 'flex',
  flexDirection: 'column',
});

export const Header = styled(Box)(({ theme }) => ({
  width: '100%',
  padding: theme.spacing(2.5, 0),
  display: 'flex',
  justifyContent: 'center',
  backgroundColor: theme.palette.primary.dark,
}));

export const ContentContainer = styled(Box)({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
});

export const ActionButton = styled(Button)(({ theme }) => ({
  marginTop: '1rem',
  padding: theme.spacing(0.5, 1),
  color: '#FFFFFF',
  backgroundColor: theme.palette.primary.main,
  display: 'flex',
  justifyContent: 'center',
  borderRadius: theme.spacing(0.5),
  fontSize: theme.spacing(1.5),
  textTransform: 'capitalize',
}));

export const BackButtonChevron = styled(ChevronLeftSharp)(({ theme }) => ({
  fontSize: theme.spacing(2),
  marginRight: theme.spacing(0.5),
}));

export const ErrorMessage = styled(Typography)(({ theme }) => ({
  color: theme.palette.secondary.main,
  fontSize: theme.spacing(3.5),
  textAlign: 'center',
  margin: theme.spacing(0, 2),
}));

export const GenericMessage = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontWeight: 500,
  maxWidth: theme.spacing(92),
  textAlign: 'center',
  margin: '.75rem 1rem',
}));

export const ErrorImage = styled(SvgImage)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  maxHeight: theme.spacing(20),
}));
