import Typography from '@mui/material/Typography';
import { DivisionCellContainer } from './DivisionCell.styled';

interface DivisionCellProps {
  dividend: string;
  divisor: string;
}

export function DivisionCell({ dividend, divisor }: DivisionCellProps) {
  return (
    <DivisionCellContainer>
      <Typography variant="body2" fontWeight={500}>{dividend}</Typography>
      <Typography variant="body2" fontWeight={500}>/</Typography>
      <Typography variant="body2" fontWeight={500} color="action.disabled">{divisor}</Typography>
    </DivisionCellContainer>
  );
}
