/* eslint-disable max-lines-per-function */
import { useMutation } from '@apollo/client';
import { MODIFY_BUNDLE_TEAM } from '@gql/mutations/bundles';
import {
  BundleTeamInformationInput, Bundle, TeamInformation,
} from '@gql/types/graphql';
import { useContext, useState } from 'react';
import Typography from '@mui/material/Typography';
import { WebUserContext } from '@providers';
import { FormErrorComponent } from '@components';
import { ModifyTeamMembersFormUserRoles } from './ModifyTeamMembersForm.models';
import { SubHeader } from './ModifyTeamMembersForm.styled';
import { ModifyTeamMembersForm, ModifyTeamMembersFormInput } from './ModifyTeamMembersForm';

const MODIFY_BUNDLE_TEAM_TEXT = {
  title: 'Manage Team',
  submitText: 'Save',
  subTitle: 'Operators and Buyers will have immediate access and edit rights to this bundle.',
};

interface ModifyTeamProps {
  closeDrawer: () => void;
  bundleDetails: Bundle;
}
export function ModifyBundleTeamMembers({ closeDrawer, bundleDetails }: ModifyTeamProps) {
  const [isFormErrorOpen, setIsFormErrorOpen] = useState(false);
  const user = useContext(WebUserContext);

  const [modifyTeam, { loading: isMutationLoading, error: mutationError }] = useMutation(MODIFY_BUNDLE_TEAM, {
    errorPolicy: 'all',
    onCompleted() {
      closeDrawer();
    },
    onError() {
      setIsFormErrorOpen(true);
    },
  });

  const {
    operators,
    buyers,
    viewOnlyUsers,
  } = bundleDetails.team as TeamInformation;

  const userRole: ModifyTeamMembersFormUserRoles = buyers?.some((buyer) => buyer.id === user?.id)
    ? ModifyTeamMembersFormUserRoles.buyer
    : ModifyTeamMembersFormUserRoles.operator;

  const defaultValues = {
    userRole,
    operators: operators ?? [],
    buyers: buyers ?? [],
    viewOnly: viewOnlyUsers ?? [],
  };

  const handleSubmitModifyTeam = async (teamData: ModifyTeamMembersFormInput) => {
    if (!teamData) return;

    const bundleId = bundleDetails.id;

    // Combine primary and additional buyer IDs
    const buyerIds: string[] = (
      [teamData.primaryBuyerId]
        .concat(teamData.additionalBuyerIds ?? [])
        .filter((id): id is string => id !== undefined && id !== null)
    );

    // Combine primary and additional operator IDs
    const operatorIds: string[] = (
      [teamData.primaryOperatorId]
        .concat(teamData.additionalOperatorIds ?? [])
        .filter((id): id is string => id !== undefined && id !== null)
    );

    const viewOnlyIds = teamData.viewOnlyIds ?? [];

    const bundleTeam: BundleTeamInformationInput = {
      buyerIds,
      operatorIds,
      viewOnlyIds,
    };

    await modifyTeam({
      variables: {
        bundleId,
        bundleTeam,
      },
    });
  };

  return (
    <>
      <FormErrorComponent
        isDismissable={true}
        error={mutationError}
        isOpen={isFormErrorOpen}
        setIsOpen={setIsFormErrorOpen}
      />
      <Typography variant="h5" data-testid="modify-bundle-team-form-header-text">
        {MODIFY_BUNDLE_TEAM_TEXT.title}
      </Typography>
      <SubHeader
        variant="body2"
        data-testid="modify-bundle-team-form-sub-header-text"
      >
        {MODIFY_BUNDLE_TEAM_TEXT.subTitle}
      </SubHeader>
      <ModifyTeamMembersForm
        isFormSaving={isMutationLoading}
        defaultValues={defaultValues}
        onFormSubmit={handleSubmitModifyTeam}
      />
    </>
  );
}
