/* eslint-disable max-lines-per-function */
import Alert, { AlertColor } from '@mui/material/Alert';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import { DialogDispatchContext } from '@providers';
import { ReactNode, useContext } from 'react';
import { kebabCase } from '@utils/stringHelpers';
import { CloseIconGrey } from '@components';
import {
  AlertList,
  ButtonStyled,
  LoadingIconStyled,
  OptionalCloseButtonContainer,
  DialogActionsContainer,
  DialogDescription,
} from './AegDialog.styled';

export type AegDialogProps = {
  titles?: {
    dialogTitle: string;
    alertTitle?: string;
    alertList?: ReactNode[];
  },
  description?: React.ReactNode;
  submit?: {
    text: string;
    action: () => void;
  },
  cancel?: {
    text: string;
    action: () => void;
  },
  severity?: AlertColor,
  buttonsPosition?: 'left' | 'right',
  includeCloseButton?: boolean;
  isLoading?: boolean;
  rightSubmit?: boolean;
  onClose?: () => void;
} & Omit<DialogProps, 'open'>;

export function AegDialog({
  titles,
  description,
  submit,
  cancel,
  severity = 'warning',
  buttonsPosition = 'left',
  includeCloseButton = false,
  isLoading = false,
  rightSubmit,
  onClose,
  ...rest
}: AegDialogProps) {
  const setDialog = useContext(DialogDispatchContext);

  const onCloseDialog = () => {
    setDialog(null);
    onClose?.();
  };

  const parseAndBoldText = (text: string): string => text.replace(/<strong>(.*?)<\/strong>/g, '<strong>$1</strong>');

  return (
    <Dialog
      onClose={onCloseDialog}
      {...rest}
      open={submit !== undefined}
    >
      {includeCloseButton ? <OptionalCloseButtonContainer>
        <CloseIconGrey onClick={onClose ? onCloseDialog : cancel?.action} />
      </OptionalCloseButtonContainer> : null}
      <DialogTitle variant="h6" data-testid="dialog-title">
        {titles?.dialogTitle}
      </DialogTitle>
      {description && (
        <DialogDescription data-testid="dialog-description">{description}</DialogDescription>
      )}
      {(titles?.alertTitle || titles?.alertList) ? (
        <DialogContent data-testid="dialog-content">
          <Alert
            severity={severity}
            data-testid="alert-message"
          >
            <Typography>
              {titles?.alertTitle && parseAndBoldText(titles.alertTitle).split(' ').map((word, index) => (
                <span key={`${kebabCase(titles.alertTitle)}-${index}`}>
                  {word.includes('<strong>') ? <strong>{word.replace(/<\/?strong>/g, '')}</strong> : word}{' '}
                </span>
              ))}
            </Typography>
            {titles?.alertList && (
              <AlertList data-testid="alert-list">
                {titles?.alertList.map((item, index) => (
                  <li key={`alert-list-item-${index}`}>{item}</li>
                ))}
              </AlertList>
            )}
          </Alert>
        </DialogContent>
      ) : <br />}
      <DialogActionsContainer data-testid="dialog-actions"
       style={{ justifyContent: buttonsPosition === 'right' ? 'flex-end' : 'flex-start' }}>
        {rightSubmit ? (
          <>
            {cancel && <ButtonStyled
              variant="outlined"
              data-testid="cancel-dialog-button"
              type="submit"
              onClick={cancel?.action}
              disabled={isLoading}
            >
              {cancel?.text}
            </ButtonStyled>}
            <ButtonStyled
              variant="contained"
              data-testid="submit-dialog-button"
              type="submit"
              onClick={submit?.action}
              disabled={isLoading}
            >
              <div>{submit?.text}</div>
              {isLoading && <LoadingIconStyled size={24} />}
            </ButtonStyled>
          </>
        ) : (
          <>
            <ButtonStyled
              variant="contained"
              data-testid="submit-dialog-button"
              type="submit"
              onClick={submit?.action}
              disabled={isLoading}
            >
              <div>{submit?.text}</div>
              {isLoading && <LoadingIconStyled size={24} />}
            </ButtonStyled>
            {cancel && <ButtonStyled
              variant="outlined"
              data-testid="cancel-dialog-button"
              type="submit"
              onClick={cancel?.action}
              disabled={isLoading}
            >
              {cancel?.text}
            </ButtonStyled>}
          </>
        )}
      </DialogActionsContainer>
    </Dialog>
  );
}
