import {
  ArrayPath,
  Control,
  FieldValues,
} from 'react-hook-form';
import { UserModel } from '@types';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { kebabCase } from '@utils/stringHelpers';
import { AutocompleteInput } from '../../shared/Form/AutocompleteInput';
import { InputLabel } from '../../shared/Form/Form.styled';
import {
  ColoredTableRow,
  InputCell,
} from './AutocompleteChipsArray.styled';
import { ModifyTeamMembersFormFields } from '../ModifyTeamMembersForm.models';

type AutocompleteChipsArrayProps<
  TFieldValues extends FieldValues = ModifyTeamMembersFormFields,
  TContext = any,
> = {
  control: Control<TFieldValues, TContext>;
  fieldName: ArrayPath<ModifyTeamMembersFormFields>;
  subjectName: string;
  options: Array<UserModel>;
  onInputChange: React.Dispatch<React.SetStateAction<string>>;
  filterOut?: Array<string>;
  areOptionsLoading?: boolean;
};

export const AutocompleteChipsArray = ({
  control,
  fieldName,
  subjectName,
  options,
  onInputChange,
  filterOut,
  areOptionsLoading,
}: AutocompleteChipsArrayProps) => (
  <div>
    <Table>
      <TableHead>
        <ColoredTableRow>
          <TableCell>
            <InputLabel
              required={false}
              data-testid={`${kebabCase(fieldName)}-role-label`}
            >
              {subjectName}
            </InputLabel>
          </TableCell>
        </ColoredTableRow>
      </TableHead>
      <TableBody>
          <TableRow>
            <InputCell>
              <AutocompleteInput
                  chipOption={{ labelProperty: 'name', includeAvatar: true }}
                  disableClearable={true}
                  areOptionsLoading={areOptionsLoading}
                  data-testid={`autocomplete-${kebabCase(subjectName)}`}
                  fieldName={`${fieldName}`}
                  control={control}
                  options={options}
                  dataDisplayField={{ primary: 'name', secondary: 'email' }}
                  dataFilterField={'id'}
                  filterOut={filterOut}
                  onInputChange={onInputChange}
                  isRequired={false}
                />
            </InputCell>
          </TableRow>
      </TableBody>
    </Table>
  </div>
);
