import Button, { ButtonProps } from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

export const SubHeader = styled(Typography)(({ theme }) => ({
  paddingTop: theme.spacing(3),
}));

export const ButtonContainer = styled('div')({
  display: 'flex',
  justifyContent: 'flex-end',
});

export const AddHeadlinerButton = styled(Button)<ButtonProps>(({ theme }) => ({
  marginTop: theme.spacing(3),
  padding: theme.spacing(1, 2.75),
  display: 'inline-flex',
  width: 'fit-content',
}));
