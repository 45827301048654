import { useMutation, useQuery } from '@apollo/client';
import { SnackbarType } from '@components';
import { GET_PDF_URL } from '@gql/queries/pdf';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import Download from '@mui/icons-material/Download';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { NotificationDispatchContext } from '@providers';
import { logError } from '@services/telemetry-service';
import { MutationWithInputArgument } from '@types';
import { useContext, useEffect } from 'react';

interface PDFDownloadLinkProps<Input, Output, Mutation extends MutationWithInputArgument<Input, Output>> {
  field: keyof Output;
  mutation: Mutation;
  mutationInput: Input;
  title: string;
}

export function PDFDownloadLink<I, O, M extends MutationWithInputArgument<I, O>>({
  field,
  mutation,
  mutationInput,
  title,
} : Readonly<PDFDownloadLinkProps<I, O, M>>) {
  const appInsights = useAppInsightsContext();
  const setNotification = useContext(NotificationDispatchContext);

  const [generatePdf, {
    loading: mutationLoading,
    data: mutationData,
    reset: resetMutation,
  }] = useMutation(mutation, {
    errorPolicy: 'all',
    onError: (error) => {
      logError(appInsights, `Print bundle ${title} error`, error);
      setNotification({
        text: `Error creating PDF: ${error.message}`,
        type: SnackbarType.ERROR,
        duration: 6000,
      });
    },
  });

  const messageId: string = mutationData?.[field]?.id ?? '';
  const { data: queryData } = useQuery(GET_PDF_URL, {
    variables: { messageId },
    pollInterval: 500,
    skip: messageId.length === 0,
  });

  useEffect(() => {
    if (queryData?.pdfUrl?.url) {
      window.open(queryData.pdfUrl.url);
      resetMutation();
    } else if (queryData?.pdfUrl?.failReason) {
      setNotification({
        text: `Error creating PDF: ${queryData.pdfUrl.failReason}`,
        type: SnackbarType.ERROR,
        duration: 6000,
      });
      resetMutation();
    }
  }, [queryData]);

  const handleDownloadClick = () => {
    void generatePdf({ variables: { input: mutationInput } });
  };

  const loading = mutationLoading || Boolean(mutationData);

  return (
    <Button
      variant="text"
      size="large"
      endIcon={loading ? <CircularProgress size={24} /> : <Download />}
      onClick={handleDownloadClick}
      disabled={loading}
    >
      {title}
    </Button>
  );
}
