import Box from '@mui/material/Box';
import { CustomTheme, styled } from '@mui/material/styles';

export const ModalLayoutTitle = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: theme.spacing(1),
}));

export const ModalLayoutContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'fullWidth',
})<{ theme?: CustomTheme; fullWidth?: boolean; }>(({ theme, fullWidth }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90%',
  backgroundColor: theme?.palette.primary.contrast,
  borderRadius: theme?.spacing(0.5),
  boxShadow: fullWidth ? 'none' : theme?.spacing(3),
  padding: fullWidth ? 0 : theme?.spacing(3),
  maxHeight: '95%',
  overflow: 'auto',
  maxWidth: 1160,
}));

export const CloseButtonContainer = styled('div')((
  { theme, fullWidth }: { theme?: CustomTheme, fullWidth?: boolean },
) => ({
  // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
  padding: fullWidth ? `${theme?.spacing(3)} ${theme?.spacing(3)} 0px 0px` : '0px',
}));
