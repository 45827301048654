import { DataGridPro } from '@mui/x-data-grid-pro';
import Box from '@mui/material/Box';
import { styled, CustomTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

export const StyledDataGrid = styled(DataGridPro)(
  ({
    theme,
    checkboxSelection,
    heightSubtract = 256,
  }: { theme?: CustomTheme, checkboxSelection?: boolean, heightSubtract?: number }) => ({
    height: `calc(100vh - ${heightSubtract}px)`,
    borderRadius: theme?.spacing(0),
    border: 'none',
    // eslint-disable-next-line max-len
    '& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus, & .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-columnHeader:focus':
      {
        outline: 'none',
      },
    '& .MuiDataGrid-row:hover, & .MuiDataGrid-row.Mui-hovered': {
      backgroundColor: theme?.palette.info.states?.hover,
    },
    '& .MuiDataGrid-columnHeaders, & .MuiDataGrid-pinnedColumnHeaders': {
      backgroundColor: theme?.palette.primary.states?.hover,
      border: 'none',
      borderBottom: 'solid',
      borderWidth: theme?.spacing(0.125),
      borderColor: theme?.palette.secondary.light,
      boxShadow: 'none',
    },
    '& .MuiDataGrid-pinnedColumns': {
      boxShadow: 'none',
    },
    '& .MuiDataGrid-pinnedColumnHeaders': {
      backgroundColor: theme?.palette.primary.states?.hover,
    },
    '& .MuiDataGrid-columnHeader, & .MuiDataGrid-cell': {
      border: 'none',
      borderBottom: 'solid',
      borderWidth: theme?.spacing(0.125),
      borderColor: theme?.palette.secondary.dark,
      paddingLeft: theme?.spacing(3),
    },
    '& .MuiDataGrid-columnHeader:first-of-type, & .MuiDataGrid-cell:first-of-type': {
      paddingLeft: checkboxSelection ? 0 : theme?.spacing(3),
    },
    '& .MuiDataGrid-columnHeader[data-field="addVenueEvent"], .MuiDataGrid-cell[data-field="addVenueEvent"]':
      {
        paddingLeft: theme?.spacing(1.2),
      },
    // '& .MuiLinearProgress-colorPrimary': {}, // activate to change background color of linear progress bar
    '& .MuiLinearProgress-barColorPrimary': {
      backgroundColor: theme?.palette.info.main,
    },
  }),
) as typeof DataGridPro;

export const TableHeader = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  position: 'relative',
}));

export const ActionsStyled = styled('div')(() => ({
  marginLeft: 'auto',
}));

export const LineElement = styled('div')(
  ({ theme }: { theme?: CustomTheme }) => ({
    border: 0,
    height: 0,
    borderTop: '1px solid',
    borderTopColor: theme?.palette.primary.states?.outlineBorder,
  }),
);

export const GridInfoText = styled(Typography)(
  ({ theme }: { theme?: CustomTheme }) => ({
    fontWeight: 400,
    fontSize: theme?.spacing(2),
    display: 'flex',
    justifyContent: 'center',
    paddingTop: theme?.spacing(7.25),
    color: theme?.palette.primary.states?.outlineBorder,
    height: '100%',
  }),
);

export const NoRowsContainer = styled(Box)({
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

export const ListViewHeaderContainerStyled = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'fullWidth',
})((
  { theme, fullWidth }: { theme?: CustomTheme, fullWidth?: boolean },
) => ({
  marginTop: theme?.spacing(1),
  marginRight: fullWidth ? '0px' : theme?.spacing(3),
  marginBottom: fullWidth ? theme?.spacing(3) : '0px',
  marginLeft: fullWidth ? '0px' : theme?.spacing(3),
}));
