import { styled } from '@mui/material/styles';

export const PrimaryText = styled('span')(({ theme }) => ({
  fontSize: theme.spacing(2),
  fontWeight: 400,
}));

export const PrimaryTextBold = styled('span')(({ theme }) => ({
  fontSize: theme.spacing(1.75),
  fontWeight: 500,
  color: theme.palette.primary.main,
}));

export const SecondaryText = styled('span')(({ theme }) => ({
  opacity: '87%',
  fontSize: theme.spacing(1.5),
  fontWeight: 400,
  color: theme.palette.primary.light[300],
}));

export const SpaceHyphen = styled('span')(({ theme }) => ({
  margin: theme.spacing(0, 1),
  color: theme.palette.text.secondary,
}));
