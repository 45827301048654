/* eslint-disable max-lines-per-function */
import React from 'react';
import {
  DetailsMetadataItem,
  KebabPopperMenu,
  SelectWithPrimary,
  StatusChip,
} from '@components';
import { BundleOffer } from '@gql/types/graphql';
import Grid from '@mui/material/Grid';
import { PermissionCheck, LineOfBusiness } from '@types';
import {
  IconButtonContainer,
  MetadataGrid,
  OfferButtons,
  OfferMenuContainer,
  OfferSelectContainer,
} from './OfferHeader.styled';

export interface OfferHeaderProps {
  actions: React.ReactElement[];
  buttons: React.ReactElement[];
  offers: BundleOffer[];
  currentOfferIndex: number;
  setCurrentOfferIndex?: (index: number) => void;
  checkPermission?: PermissionCheck;
  primaryOfferId?: string;
  lineOfBusiness?: LineOfBusiness;
}

export const OfferHeader: React.FC<OfferHeaderProps> = ({
  actions,
  buttons,
  offers,
  currentOfferIndex,
  setCurrentOfferIndex,
  checkPermission,
  primaryOfferId,
  lineOfBusiness,
}) => {
  const currentOffer = offers.length > currentOfferIndex ? offers[currentOfferIndex] : null;

  const metadataRowItems = (
    <>
      <DetailsMetadataItem
        key={'offer-status'}
        metadata={{
          title: 'Offer Status',
          element: <StatusChip status={currentOffer?.status ?? 'Draft'}/>,
        }}
      />
      <DetailsMetadataItem
        key={'offer-crossed'}
        metadata={{
          title: 'Crossed',
          value: currentOffer?.crossed ? 'Yes' : 'No',
        }}
      />
    </>
  );

  const handleOfferChange = (offerId: string) => {
    const index = offers?.findIndex((offer) => offer?.id === offerId);
    if (index >= 0) {
      setCurrentOfferIndex?.(index);
    }
  };

  const isPrimaryOffer = (offerId: string, numberOfOffers: number) => {
    if (numberOfOffers === 1) {
      return true;
    }
    return primaryOfferId === offerId;
  };

  const items = offers.map((offer: BundleOffer) => ({
    value: offer.id,
    label: offer.name,
    primary: isPrimaryOffer(offer.id, offers.length),
  }));

  return (
    <Grid container>
      <Grid item xs={2.5} data-testid="offer-dropdown-container">
        <OfferSelectContainer>
          <SelectWithPrimary
            value={currentOffer?.id ?? undefined}
            items={items}
            label={`Offers (${offers.length})`}
            onChange={handleOfferChange}
            lineOfBusiness={lineOfBusiness}
          ></SelectWithPrimary>
        </OfferSelectContainer>
      </Grid>
      <MetadataGrid
        item
        xs={true}
        container
        spacing={5}
        data-testid="offer-metadata"
      >
        {metadataRowItems}
      </MetadataGrid>
      <OfferButtons>
        <IconButtonContainer>
          {buttons.map((ButtonComponent, index) => (
            <React.Fragment key={index}>
              {ButtonComponent}
            </React.Fragment>
          ))}
        </IconButtonContainer>
        {/* <ProtectedComponent // TODO: Update for Bundles when new AuthZed Bundle permissions implemented (SANE-7188?)
          checkPermission={checkPermission}
        > */}
          <OfferMenuContainer>
            <KebabPopperMenu>
            {actions.map((ActionComponent, index) => (
              <React.Fragment key={index}>
                {ActionComponent}
              </React.Fragment>
            ))}
            </KebabPopperMenu>
          </OfferMenuContainer>
        {/* </ProtectedComponent> */}
      </OfferButtons>
    </Grid>
  );
};
