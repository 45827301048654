import React from 'react';
import { AegDialogProps } from '@components';

const DialogContext = React.createContext<AegDialogProps | null>(null);
const DialogDispatchContext = React.createContext<
React.Dispatch<React.SetStateAction<AegDialogProps | null>>
>(() => {});

type ProviderProps = {
  children: React.ReactNode;
};
function DialogProvider({ children }: ProviderProps) {
  const [dialog, setDialog] = React.useState<AegDialogProps | null>(null);

  return (
    <DialogContext.Provider value={dialog}>
      <DialogDispatchContext.Provider value={setDialog}>
        {children}
      </DialogDispatchContext.Provider>
    </DialogContext.Provider>
  );
}

export {
  DialogProvider,
  DialogContext,
  DialogDispatchContext,
};
