// TODO: Remove lint exceptions when mock data manipulation removed
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */

/* eslint-disable max-lines-per-function */
import React, { useContext, useMemo } from 'react';
import {
  GridColDef,
  GRID_DETAIL_PANEL_TOGGLE_FIELD,
  GridRowId,
} from '@mui/x-data-grid-pro';
import {
  FinancesSupport,
  FinancesHeadliners,
  FinancesFixedCosts,
  FinancesVariableCosts,
  FinancesBoxOfficeTier,
  FinancesAdjustments,
} from '@types';
import {
  Accordion, NestedDataGrid, DataGridWithTotals,
} from '@components';
import { useGridState, PersistedSortGrids } from '@hooks';
import { BundleContext } from '@providers';
import { disableColumnInteractions } from '@utils/datagridHelpers';
import { kebabCase } from '@utils/stringHelpers';
import { getSortLabel, sortBundleEventRows } from '@utils/bundleHelpers';
import { GET_BUNDLE_OFFER_FINANCES } from '@gql/queries/bundles';
import { useQuery } from '@apollo/client';
import { supportColumns, supportEventOfferColumns } from './columns/Support.columns';
import { boxOfficeColumns, boxOfficeEventOfferColumns } from './columns/BoxOffice.columns';
import { headlinersColumns, headlinersEventOfferColumns } from './columns/Headliners.columns';
import { fixedCostsColumns, fixedCostsEventOfferColumns } from './columns/FixedCosts.columns';
import { variableCostsColumns, variableCostsEventOfferColumns } from './columns/VariableCosts.columns';
import { BUNDLE_FINANCES_HEADERS } from '../../views/BundleDetails/BundleDetails.constants';
import { SortLabel, SortText } from '../../views/BundleDetails/BundleDetails.styled';

// TODO: Update with real data
import {
  mockFinancesSupport,
  mockFinancesHeadliners,
  mockFinancesFixedCosts,
  mockFinancesVariableCosts,
  mockFinancesAdjustments,
} from '../../mocks/mockFinancesData';
import { NestedGridContainer } from './BundleFinancesTab.styled';
import { adjustmentsColumns, adjustmentsEventOfferColumns } from './columns/Adjustments.columns';

type BundleFinancesRow =
  FinancesBoxOfficeTier
  | FinancesHeadliners
  | FinancesFixedCosts
  | FinancesVariableCosts
  | FinancesSupport
  | FinancesAdjustments;

interface BundleFinancesSection {
  header: string;
  rowsGroup?: string;
  rows: BundleFinancesRow[];
  columns: GridColDef[];
  eventOffersColumns: GridColDef[];
  totalRows?: FinancesBoxOfficeTier[];
  selectComponent?: React.ReactNode;
}

interface BundleFinancesTabProps {
  bundleId: string
  bundleOfferId: string
}

export function BundleFinancesTab({ bundleId, bundleOfferId }: BundleFinancesTabProps) {
  // const [show, setShow] = useState(0);

  // TODO: Consider changing BundleDetails to fetch instead of pre-fetch to avoid GTAGG requests on every page load
  // See PR comment: https://github.com/AEG-Presents/aegp-elvis-web-app/pull/1342#discussion_r1898598474
  const { data } = useQuery(GET_BUNDLE_OFFER_FINANCES, {
    variables: { bundleId, bundleOfferId },
  });
  const { sortedEventIds } = useContext(BundleContext);
  const { sortModel } = useGridState(PersistedSortGrids.BUNDLE_DETAILS);

  // TODO: Remove mock data manipulation block when real data implemented
  // ** Begin Mock Data Manipulation **

  const sortedEventOffers = sortBundleEventRows(
    data?.bundleOffer?.boxOffice?.tiers?.[0]?.eventOffers ?? [],
    sortedEventIds,
    BUNDLE_FINANCES_HEADERS.BOX_OFFICE,
  );

  // eslint-disable-next-line max-len
  const mockDataSets = [mockFinancesHeadliners, mockFinancesSupport, mockFinancesFixedCosts, mockFinancesAdjustments, mockFinancesVariableCosts];

  sortedEventOffers?.forEach((eventOffer, i) => {
    mockDataSets.forEach((mockDataSet) => {
      mockDataSet.forEach(((dataSet, j) => {
        const venueData = dataSet.eventOffers[i]?.venue ?? eventOffer.venue;

        // eslint-disable-next-line no-param-reassign
        dataSet.eventOffers[i] = {
          ...dataSet.eventOffers[i],
          id: `mock-guid-${i}-${j}`,
          // eslint-disable-next-line @typescript-eslint/no-unsafe-call
          eventId: eventOffer.id.slice(0, 36), // extract 36-char GUID; box office IDs are appended with suffix
          venue: {
            ...venueData,
            city: eventOffer.venue.city,
            stateCode: eventOffer.venue.stateCode,
            name: eventOffer.venue.name,
          },
        };

        if (mockDataSet === mockFinancesVariableCosts) {
          // additional mock data as these fields need to be present for Variable Costs rows
          // eslint-disable-next-line no-param-reassign
          dataSet.eventOffers[i] = {
            ...dataSet.eventOffers[i],
            feeItem: {
              formula: '% of Net Gross',
              min: null,
              max: 300,
              note: 'Lorem ipsum dolor sit amet',
            },
          };
        }
      }));
    });
  });

  // ** End Mock Data Manipulation **

  const sections: BundleFinancesSection[] = useMemo(() => {
    const bundleOffer = data?.bundleOffer;
    if (!bundleOffer) return [];

    // const showOptions = Array.from(
    //   Array(data?.bundleOffer?.boxOffice?.maxShows ?? 0),
    //   (_, index) => ({
    //     label: `Show #${index + 1}`,
    //     value: index,
    //   }),
    // );

    return [{
      header: BUNDLE_FINANCES_HEADERS.BOX_OFFICE,
      rows: bundleOffer?.boxOffice?.tiers,
      columns: boxOfficeColumns.map(disableColumnInteractions),
      eventOffersColumns: boxOfficeEventOfferColumns,
      // selectComponent: (
      //   <ShowSelectContainer>
      //     <SelectWithPrimary
      //       label=''
      //       onChange={(val) => setShow(Number(val))}
      //       items={showOptions}
      //       value={showOptions?.[0]?.value}
      //     />
      //   </ShowSelectContainer>
      // ),
      totalRows: [
        {
          id: 'box-office-totals-row-id',
          name: 'Avg/Total (All Shows)',
          eventOffers: [],
          localOfferTotal: 0,
          boxOffice: bundleOffer?.boxOffice?.totals,
        },
      ],
    },
    {
      header: BUNDLE_FINANCES_HEADERS.HEADLINERS,
      rows: mockFinancesHeadliners,
      columns: headlinersColumns.map(disableColumnInteractions),
      eventOffersColumns: headlinersEventOfferColumns,
    },
    {
      header: BUNDLE_FINANCES_HEADERS.SUPPORT,
      rows: mockFinancesSupport,
      columns: supportColumns.map(disableColumnInteractions),
      eventOffersColumns: supportEventOfferColumns,
    },
    {
      header: BUNDLE_FINANCES_HEADERS.FIXED_COSTS,
      rowsGroup: 'category',
      rows: mockFinancesFixedCosts,
      columns: fixedCostsColumns.map(disableColumnInteractions),
      eventOffersColumns: fixedCostsEventOfferColumns,
    },
    {
      header: 'Adjustments',
      rows: mockFinancesAdjustments,
      columns: adjustmentsColumns.map(disableColumnInteractions),
      eventOffersColumns: adjustmentsEventOfferColumns,
    },
    {
      header: 'Variable Costs',
      rows: mockFinancesVariableCosts,
      columns: variableCostsColumns.map(disableColumnInteractions),
      eventOffersColumns: variableCostsEventOfferColumns,
    }];
  }, [data]);

  if (sections.length === 0) {
    // The prefetch in BundleDetails should prevent this scenario from happenning,
    // but this is here in case something goes wrong
    return 'Loading...';
  }

  return (
    sections.map(({
      header,
      eventOffersColumns,
      ...props
    }) => (
      <Accordion
        header={header}
        headerLabel={
          sortModel.length
            ? <SortLabel>Local Offers sorted by: <SortText>{getSortLabel(sortModel)}</SortText></SortLabel>
            : null
        }
        key={`one-off-accordion-${header}`}
        showDivider
      >
        {props.selectComponent}
        <DataGridWithTotals
          {...props}
          getRowId={(row) => (row as { id: GridRowId }).id}
          testId={kebabCase(header)}
          isRowSelectable={() => false}
          scrollable={false}
          getDetailPanelContent={({ row }) => (
            <NestedGridContainer>
              <NestedDataGrid
                rows={sortBundleEventRows((row as BundleFinancesRow).eventOffers, sortedEventIds, header)}
                columns={eventOffersColumns}
                hideFooter
                slots={{
                  columnHeaders: React.forwardRef(() => null),
                }}
                isRowSelectable={() => false}
                data-testid={`${kebabCase(header)}-event-offers-data-grid`}
                getRowId={(eventOffer: { id: GridRowId }) => eventOffer.id}
                initialState={{
                  pinnedColumns: {
                    left: ['venueName', 'whiteSpace'],
                  },
                }}
              />
            </NestedGridContainer>
          )}
          getDetailPanelHeight={() => 'auto'}
          initialState={{
            pinnedColumns: {
              left: [GRID_DETAIL_PANEL_TOGGLE_FIELD, 'name'],
            },
          }}
        />
      </Accordion>
    ))
  );
}
