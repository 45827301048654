import { useContext, useMemo, useState } from 'react';
import Typography from '@mui/material/Typography';
import { Venue } from '@gql/types/graphql';
import { GET_VENUES } from '@gql/queries/venues';
import { venueRegionDisplayFieldGetter } from '@utils/venueHelpers';
import { ModelingSearchContext } from '@providers';
import { SearchMarkets } from '../../SearchMarkets/SearchMarkets';
import { ListView, SearchBar } from '../../shared';
import { getVenueGridColumns, processVenue, searchVenueOptions } from './constants';
import {
  DisabledText,
  PlainTextButton,
  RadioOptionContainer,
  VenueSearchOptionsHeader,
  ModalContainer,
  RadioStyled,
} from './VenueSearch.styled';
import { VenueSearchOptions, VenueSearchProps } from './VenueSearch.types';

// eslint-disable-next-line max-lines-per-function
export function VenueSearch({
  onVenueSelect,
  venueSelectLabel,
  onVenueRowClick = () => { },
  showGridInfo = false,
  disableMarketSearch = false,
}: VenueSearchProps) {
  const {
    venueSearch,
    setVenueSearch,
    marketSearch,
    setMarketSearch,
    venueSearchOption,
    setVenueSearchOption,
  } = useContext(ModelingSearchContext);

  const [selectedVenues, setSelectedVenues] = useState<Venue[]>([]);
  const selectedGridData = useMemo(() => processVenue(selectedVenues), [selectedVenues]);

  const searchProps = {
    query: GET_VENUES,
    resultKey: 'venues',
  };

  const searchComponent = venueSearchOption === VenueSearchOptions.market ? (
    <SearchMarkets
      search={{
        ...searchProps,
        displayFields: { primary: 'name' },
        resultKey: 'venues',
        searchTerm: marketSearch,
        onMarketSearch: (marketSearchValue) => {
          if (setMarketSearch) {
            setMarketSearch(marketSearchValue);
          }
        },
        onSelect: (option) => {
          if (option && typeof option !== 'string') {
            setSelectedVenues(option as Venue[]);
          }
        },
      }}
    />
  ) : (
    <SearchBar
      search={{
        ...searchProps,
        displayFields: { primary: 'name', secondary: venueRegionDisplayFieldGetter },
        placeholder: 'Search',
        minimumCharacters: 2,
        shouldFlattenVenues: true,
        freeSolo: false,
        resultKey: 'venues',
        defaultValue: venueSearch ?? undefined,
        onSelect: (option: Venue) => {
          if (option && typeof option !== 'string') {
            setSelectedVenues([option]);
            setVenueSearch(option);
          }
          // input cleared, clear selection
          if (option === null) {
            setSelectedVenues([]);
            setVenueSearch(null);
          }
        },
      }}
    />
  );

  return (
    <ModalContainer data-testid='modal-container'>
      <VenueSearchOptionsHeader data-testid='venue-search-options-header'>
        {searchVenueOptions.map(({ value, label }, index) => {
          const disabled = disableMarketSearch && value === VenueSearchOptions.market;
          const handler = () => setVenueSearchOption && setVenueSearchOption(value);
          const defaultCheckedOption = venueSearchOption ?? VenueSearchOptions.name;
          return (
            <RadioOptionContainer key={`radio-option-${label}`}>
              <RadioStyled
                key={`venue-option-${value}-${index}`}
                data-testid={`search-by-${value}`}
                checked={defaultCheckedOption === value}
                value={value}
                disabled={disabled}
                onChange={handler}
              />
              {disabled ? (
                <DisabledText data-testid="disabled-text">{label}</DisabledText>
              ) : (
                <PlainTextButton variant="text" onClick={handler} value={value} disableRipple>
                  <Typography>{label}</Typography>
                </PlainTextButton>
              )}
            </RadioOptionContainer>
          );
        })}
      </VenueSearchOptionsHeader>
      <ListView
        searchComponent={searchComponent}
        datagrid={{
          columns: getVenueGridColumns(onVenueRowClick, venueSelectLabel, onVenueSelect),
          autoHeight: true,
          hideFooter: true,
          rows: selectedGridData,
          showGridInfo: showGridInfo && selectedVenues.length === 0,
          getRowId: (row: { id: string }) => row.id,
          disableRowSelectionOnClick: true,
          onRowClick: (params) => {
            void onVenueRowClick(params);
          },
          slots: {
            noRowsOverlay: () => <></>,
          },
        }}
      />
    </ModalContainer>
  );
}
