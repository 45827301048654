/* eslint-disable @typescript-eslint/no-unsafe-assignment */
// TODO: Resolve type issues in this view
/* eslint-disable max-lines-per-function */
/* eslint-disable @typescript-eslint/no-misused-promises */

/* eslint-disable-next-line import/no-extraneous-dependencies */
import { DevTool } from '@hookform/devtools';
import Typography from '@mui/material/Typography';
import { useMemo, useState } from 'react';
import { SubmitErrorHandler, SubmitHandler, useForm } from 'react-hook-form';
import { SEARCH_ARTISTS } from '@gql/queries/artists';
import { ApolloError, useQuery } from '@apollo/client';
import {
  AddOfferInput,
  Artist,
  ModifyOfferInput,
  Offer,
} from '@gql/types/graphql';
import { useYupValidationResolver } from '@hooks';
import { getNetworkErrorInfo } from '@utils/errorHelpers';
import { logError } from '@services/telemetry-service';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import {
  AutocompleteFieldArray,
  AutocompleteInput,
  AutocompleteOption,
  ButtonStyled,
  DropdownInput,
  FormErrorComponent,
  FormTextInput,
  RadioInput,
} from '../shared';
import {
  CREATE_OFFER_TEXT,
  DUPLICATE_OFFER_TEXT,
  EDIT_OFFER_TEXT,
  configurationOptions,
  crossedOptions,
} from './OfferForm.options';
import { createOfferValidationSchema } from './OfferForm.schema';
import { ButtonContainer, SubHeader } from './OfferForm.styled';

export interface OfferFormFields {
  name: string;
  notes?: string;
  isCrossed: 'true' | 'false';
  numberOfTrucks?: string;
  configuration: string;
  support?: Artist | null;
  additionalSupports: Artist[];
}

export enum OfferFormType {
  create,
  duplicate,
  edit,
}

const CREATE_OFFER_DEFAULT: OfferFormFields = {
  name: '',
  configuration: 'Other',
  isCrossed: 'true',
  additionalSupports: [],
  support: undefined,
  notes: '',
  numberOfTrucks: undefined,
};

interface OfferFormProps {
  offer?: Offer;
  type: OfferFormType;
  isFormSaving: boolean;
  onFormSubmit: SubmitHandler<AddOfferInput | ModifyOfferInput>;
  mutationError?: ApolloError;
}

export function OfferForm({
  offer,
  type,
  onFormSubmit,
  isFormSaving,
  mutationError,
}: OfferFormProps) {
  const appInsights = useAppInsightsContext();
  const [artistSearchVal, setArtistSearchVal] = useState('');
  const [isFormErrorOpen, setIsFormErrorOpen] = useState(false);

  const validationSchema = useMemo(createOfferValidationSchema, []);

  const resolver = useYupValidationResolver(validationSchema);

  const offerNameInput = (name?: string | null) => {
    if (type !== OfferFormType.duplicate) {
      return name || '';
    }
    return `${name || ''} (Copy)`;
  };

  const defaultValues = useMemo((): OfferFormFields => {
    if (offer) {
      const {
        name,
        notes,
        isCrossed,
        configuration,
        numberOfTrucks,
        supports,
      } = offer;

      const [support, ...additionalSupports] = supports || [];

      const crossed = isCrossed !== null && isCrossed !== false ? 'true' : 'false';

      return {
        name: offerNameInput(name),
        notes: notes || '',
        isCrossed: crossed,
        configuration: configuration || 'Other',
        numberOfTrucks: numberOfTrucks?.toString() || '',
        support: support || null,
        additionalSupports: (additionalSupports as Artist[] | undefined) || [],
      };
    }

    return CREATE_OFFER_DEFAULT;
  }, []);

  const {
    formState: { isDirty },
    control,
    watch,
    handleSubmit,
    getValues,
  } = useForm<OfferFormFields>({
    resolver,
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues,
  });

  const supportsWatch = watch('additionalSupports');

  const { title, subTitle, submitText } = useMemo(() => {
    if (!offer?.id) {
      return CREATE_OFFER_TEXT;
    }
    switch (type) {
      case OfferFormType.duplicate:
        return DUPLICATE_OFFER_TEXT;
      case OfferFormType.edit:
        return EDIT_OFFER_TEXT;
      default:
        return CREATE_OFFER_TEXT;
    }
  }, []);

  const { loading: artistsLoading, data: artistsData } = useQuery(
    SEARCH_ARTISTS,
    {
      errorPolicy: 'all',
      variables: { searchTerm: artistSearchVal },
    },
  );

  const getSelectedArtists = useMemo(() => {
    const selectedArtists = [getValues('support')?.id || ''];
    if (supportsWatch?.length) {
      selectedArtists.push(...supportsWatch.map((a) => a?.id || ''));
    }
    return selectedArtists;
  }, [getValues()]);

  const artistOptions = (): AutocompleteOption[] | null => {
    if (artistsLoading || !artistsData || !artistsData.artists) {
      return null;
    }
    return artistsData.artists
      .map((artist) => {
        if (artist?.id && artist?.name) {
          return {
            id: artist.id,
            name: artist.name,
          };
        }
        return null;
      })
      .filter(
        (artist): artist is { id: string; name: string } => artist !== null,
      );
  };

  const isSaveButtonDisabled = useMemo(() => {
    if (isFormSaving) {
      return true;
    }
    if (type === OfferFormType.duplicate) {
      return false;
    }
    if (!isDirty) {
      return true;
    }
    return false;
  }, [isFormSaving, isDirty]);

  const handleValidSubmit: SubmitHandler<OfferFormFields> = async (
    data,
    events,
  ) => {
    const {
      support,
      additionalSupports,
      isCrossed,
      numberOfTrucks,
      ...offerData
    } = data;

    const additionalSupportIds = additionalSupports
      ?.filter((a) => a !== null && a.id && a.name)
      .map((s) => s.id as string);
    const supportId = support?.id || undefined;
    const supports = [
      ...(supportId ? [supportId] : []),
      ...(additionalSupportIds || []),
    ];

    const trucks = numberOfTrucks && Number(numberOfTrucks);

    let targetOffer: AddOfferInput | ModifyOfferInput;

    if (offer && offer.id) {
      const modifiedOffer: ModifyOfferInput = {
        ...offerData,
        id: offer.id,
      };
      targetOffer = modifiedOffer;
    } else {
      const newOffer: AddOfferInput = {
        ...offerData,
        status: 'Draft',
      };
      targetOffer = newOffer;
    }

    targetOffer = {
      ...targetOffer,
      supports,
      isCrossed: isCrossed === 'true',
      numberOfTrucks: trucks || undefined,
    };

    try {
      await onFormSubmit(targetOffer);
    } catch (error) {
      logError(appInsights, 'submit offerForm error', error);
      setIsFormErrorOpen(true);
    }
  };

  const handleInvalidSubmit: SubmitErrorHandler<OfferFormFields> = (error) => {
    if (isFormErrorOpen) {
      setIsFormErrorOpen(false);
    }
  };

  return (
    <>
      <Typography variant="h5" data-testid="offer-form-header-text">
        {title}
      </Typography>

      {subTitle && (
        <SubHeader variant="body2" data-testid="offer-form-sub-header-text">
          {subTitle}
        </SubHeader>
      )}

      <form onSubmit={handleSubmit(handleValidSubmit, handleInvalidSubmit)}>
        <FormErrorComponent
          isDismissable={false}
          error={mutationError}
          isOpen={isFormErrorOpen}
          setIsOpen={setIsFormErrorOpen}
          errorMessage={getNetworkErrorInfo(mutationError)?.message}
        />

        <FormTextInput
          control={control}
          fieldName="name"
          label="Offer Name"
          isRequired={true}
          placeholderText="Enter Offer Name" // ? what should be the placeholder?
          maxLength={50}
        />

        <FormTextInput
          control={control}
          fieldName="notes"
          label="Offer Description"
          placeholderText="Enter Offer Description"
          minRow={3}
        />

        <RadioInput
          fieldName={'isCrossed'}
          control={control}
          label={'Crossed?'}
          options={crossedOptions}
        />

        <DropdownInput
          fieldName="configuration"
          control={control}
          label={'Configurations'}
          placeholderText={'Select configuration'}
          options={configurationOptions}
        />

        {type !== OfferFormType.edit && <FormTextInput
          control={control}
          fieldName="numberOfTrucks"
          label="Trucks"
          placeholderText="Enter Trucks" // ? what should be the placeholder?
        />}

        <AutocompleteInput
          fieldName="support"
          control={control}
          label="Support Artist"
          options={artistOptions() || []}
          dataDisplayField={{ primary: 'name' }}
          dataFilterField="id"
          filterOut={getSelectedArtists}
          onInputChange={setArtistSearchVal}
          placeholderText="Enter Supporting Artist Name"
          defaultValue={defaultValues.support}
        />

        <AutocompleteFieldArray
          subjectName="Supporting Artist"
          fieldName="additionalSupports"
          placeholderText="Enter Supporting Artist Name"
          control={control}
          options={artistOptions() || []}
          filterOut={getSelectedArtists}
          onInputChange={setArtistSearchVal}
          showHeader={false}
          defaultValue={defaultValues.additionalSupports}
        />
        <ButtonContainer>
          <ButtonStyled
            variant="contained"
            data-testid="submit-offer-form"
            type="submit"
            disabled={isSaveButtonDisabled}
          >
            {submitText}
          </ButtonStyled>
        </ButtonContainer>
      </form>
      <DevTool control={control} />
    </>
  );
}
