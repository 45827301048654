/* eslint-disable max-lines-per-function */
import { useState } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { AutocompleteGetTagProps } from '@mui/material/useAutocomplete';
import type { AutocompleteOption } from '@components';

export interface FormAutocompleteProps<Entity> {
  defaultValue?: string;
  options: any[];
  value?: any;
  getOptionLabel: (entity: Entity) => string;
  onInputChange?: React.Dispatch<React.SetStateAction<string>>;
  onSelect?: (
    selectedItem: (AutocompleteOption | Entity)[],
    event: React.SyntheticEvent<Element, Event>,
  ) => void;
  minimumCharacters?: number; // define min length of input value to open autocomplete component
  placeholder?: string;
  testId?: string;
  error?: boolean;
  helperText?: string;
  renderTags?: (value: Entity[], getTagProps: AutocompleteGetTagProps) => JSX.Element[];
  renderOption?: (
    props: React.HTMLAttributes<HTMLLIElement>,
    option: AutocompleteOption,
  ) => React.ReactNode;
  dataLoading?: boolean;
  filterOut?: Array<string>;
  dataFilterField?: string;
  freeSolo?: boolean;
  persistentOption?: any;
  forceEmailValidation?: boolean;
}

export function FormAutocomplete<E>({
  defaultValue,
  options,
  value,
  getOptionLabel,
  onInputChange,
  onSelect,
  minimumCharacters = 1,
  placeholder,
  testId,
  error,
  helperText,
  renderTags,
  renderOption,
  dataLoading,
  filterOut,
  dataFilterField = 'id',
  freeSolo,
  persistentOption,
  forceEmailValidation,
}: Readonly<FormAutocompleteProps<E>>) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Autocomplete
      multiple
      freeSolo={freeSolo}
      clearOnBlur={true}
      open={isOpen && !dataLoading}
      data-testid={testId}
      options={options}
      getOptionLabel={getOptionLabel}
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      value={value ?? defaultValue}
      renderInput={(params) => (
        <TextField
          {...params}
          error={error}
          helperText={helperText}
          placeholder={placeholder}
          InputProps={{
            ...params.InputProps,
            endAdornment: null, // Remove the down-pointing caret
          }}
        />
      )}
      renderTags={renderTags}
      onInputChange={(
        event: React.SyntheticEvent<Element, Event>,
        changedValue: string,
      ) => {
        if (!event) {
          return;
        }

        if (changedValue?.length < minimumCharacters) {
          onInputChange?.('');
          setIsOpen(false);
        } else {
          onInputChange?.(changedValue.trim());
          setIsOpen(true);
        }
      }}
      onChange={(event: React.SyntheticEvent<Element, Event>, changeValue: (AutocompleteOption | E)[]) => {
        let mappedValues;
        if (forceEmailValidation) {
          mappedValues = changeValue.map(
            (val: AutocompleteOption | E) => (typeof val === 'string' ? { email: val } : val),
          );
        }
        if (event) {
          onSelect?.(mappedValues ?? changeValue, event);
          setIsOpen(false);
        }
      }}
      isOptionEqualToValue={(option, selectedValue) => {
        if (typeof option === 'object' && option !== null) {
          return option[dataFilterField] === selectedValue[dataFilterField];
        }
        return false;
      }}
      filterSelectedOptions
      filterOptions={(_options: (AutocompleteOption | E)[]) => {
        if (persistentOption) {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-return
          return [..._options, persistentOption];
        }
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        return filterOut && dataFilterField
          ? _options.filter(
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            (option: AutocompleteOption | E) => !filterOut.includes((option)[dataFilterField]),
          )
          : options;
      }}
      renderOption={renderOption}
    />
  );
}
