import { styled } from '@mui/material/styles';
import { DatePicker } from '@mui/x-date-pickers';
import { Dayjs } from 'dayjs';

export const BorderlessDateInput = styled(DatePicker)(({ theme }) => ({
  '& .MuiInputBase-input': {
    padding: theme.spacing(0.5, 1.5, 0.5, 0),
  },

  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
    borderBottom: 'solid',
    borderWidth: '1px',
    borderRadius: '0px',
    borderColor: 'rgba(0, 0, 0, 0.45)',
  },

  '&:hover .MuiOutlinedInput-notchedOutline': {
    borderWidth: '2px',
  },
})) as typeof DatePicker<Dayjs>;
