import { GridSortModel, GridValidRowModel } from '@mui/x-data-grid-pro';
import { BUNDLE_FINANCES_HEADERS, BUNDLE_PROJECTION_HEADERS } from 'src/views/BundleDetails/BundleDetails.constants';

enum SortType {
  Alphabetical = 'alph',
  Numerical = 'num',
}

export const tableEventReferenceMap: Record<string, string> = {
  [BUNDLE_FINANCES_HEADERS.BOX_OFFICE]: 'id', // eventId = boxOffice.id
  [BUNDLE_FINANCES_HEADERS.HEADLINERS]: 'eventId',
  [BUNDLE_FINANCES_HEADERS.SUPPORT]: 'eventId',
  [BUNDLE_FINANCES_HEADERS.FIXED_COSTS]: 'eventId',
  [BUNDLE_FINANCES_HEADERS.VARIABLE_COSTS]: 'eventId',
  [BUNDLE_PROJECTION_HEADERS.PROJECTION]: 'id', // eventId = projection.id
  [BUNDLE_PROJECTION_HEADERS.SCENARIO_BUILDER]: 'id', // eventId = ScenarioBuilder.id
  [BUNDLE_PROJECTION_HEADERS.BONUS_ALLOCATION]: 'eventId',
};

export const sortBundleEventRows = (
  rows: GridValidRowModel[],
  sortedEventIds: string[],
  header: string,
) => {
  if (!sortedEventIds?.length || !rows) return rows;

  // Create a mapping of eventId to its index in sortedEventIds
  const eventIdToIndexMap = sortedEventIds.reduce((acc, eventId, index) => {
    acc[eventId] = index;
    return acc;
  }, {} as Record<string, number>);

  // Get the field where the eventId can be found in the row data
  const eventIdField = tableEventReferenceMap[header];

  // Sort the rows based on the index of their eventId in sortedEventIds
  const sortedRows = [...rows].sort((a, b) => {
    let aEventId = a[eventIdField as keyof GridValidRowModel] as string;
    let bEventId = b[eventIdField as keyof GridValidRowModel] as string;

    // this next block is necessary because boxOffice eventIds look like 'e6ad3425-1d84-489b-985c-08fb77a955bd-Price 1'
    // so, extracting first 36 characters, which is length of GUID and will match eventId
    if (header === BUNDLE_FINANCES_HEADERS.BOX_OFFICE) {
      aEventId = aEventId.slice(0, 36);
      bEventId = bEventId.slice(0, 36);
    }

    const aIndex = eventIdToIndexMap[aEventId];
    const bIndex = eventIdToIndexMap[bEventId];

    if (aIndex == null) return 1;
    if (bIndex == null) return -1;

    return aIndex - bIndex;
  });
  return sortedRows;
};

export const bundleFieldLabelMap: Record<string, { label: string; sortType: SortType }> = {
  venueName: { label: 'Venue', sortType: SortType.Alphabetical },
  market: { label: 'Market', sortType: SortType.Alphabetical },
  showsCount: { label: 'Shows', sortType: SortType.Numerical },
  firstEventDate: { label: 'Show Date', sortType: SortType.Numerical },
  updatedDate: { label: 'Last Updated', sortType: SortType.Numerical },
  capacity: { label: 'Total Capacity', sortType: SortType.Numerical },
  buyerIds: { label: 'Buyer', sortType: SortType.Alphabetical },
  status: { label: 'Status', sortType: SortType.Alphabetical }, // TODO: Eventually will sort by logical status
  active: { label: 'Market', sortType: SortType.Alphabetical }, // active/inactive offers should be sorted by market
};

export const getSortLabel = (sortModel: GridSortModel) => {
  if (!sortModel || sortModel.length === 0) return null;

  const { field, sort } = sortModel[0];

  if (!field) return null;

  const fieldInfo = bundleFieldLabelMap[field];

  let sortText;
  if (fieldInfo.sortType === SortType.Alphabetical) {
    // active/inactive offers should always be sorted by market (A-Z)
    sortText = (sort === 'asc' || field === 'active') ? '(A-Z)' : '(Z-A)';
  } else {
    sortText = sort === 'asc' ? '(Ascending)' : '(Descending)';
  }

  return `${fieldInfo.label} ${sortText}`;
};
