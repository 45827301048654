/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable max-lines-per-function */
import { useAddInAction } from '@hooks';
import Cloud from '@mui/icons-material/Cloud';
import { SnackBarMessages } from '@types';
import { ButtonStyled } from './SyncButton.styled';

type SyncButtonProps = {
  syncFn: () => Promise<void>;
  outlined?: boolean;
};

export const SyncButton = ({ syncFn, outlined }: SyncButtonProps) => {
  const { handleAddInAction } = useAddInAction();

  return (
  <ButtonStyled
    data-testid="sync-button"
    color={!outlined ? 'primary' : undefined}
    variant={outlined ? 'outlined' : 'contained'}
    startIcon={<Cloud />}
    onClick={() => {
      void handleAddInAction(
        syncFn,
        SnackBarMessages.WorkbookSyncComplete,
      );
    }}
  >
    Sync to Modern Elvis
  </ButtonStyled>
  );
};
