import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Snackbar from '@mui/material/Snackbar';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

export const AlertStyled = styled(Alert)(({ theme }) => ({
  width: theme.spacing(46),
  maxWidth: theme.spacing(60),
}));

export const SnackbarTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 'bold',
  minWidth: 'fit-content',
  textOverflow: 'wrap',
  fontSize: theme.spacing(1.75),
  marginBottom: theme.spacing(2),
}));

export const CloseSnackbarButton = styled(Button)(() => ({
  color: 'white',
  padding: 0,
}));

export const SnackBarListItem = styled(ListItem)(({ theme }) => ({
  padding: 0,
  margin: theme.spacing(0.5, 0),
}));

export const SnackbarList = styled(List)(({ theme }) => ({
  padding: theme.spacing(0, 1),
}));

export const SnackbarStyled = styled(Snackbar)(({ theme }) => ({
  [theme.breakpoints.up('sm')]: {
    width: '100%',
  },
}));
