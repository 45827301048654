import { ApolloError } from '@apollo/client';
import CloseIcon from '@mui/icons-material/Close';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Button from '@mui/material/Button';
import Collapse from '@mui/material/Collapse';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { logError } from '@services/telemetry-service';
import {
  FormErrorContainer,
  AlertContainer,
} from './FormErrorComponent.styled';

type FormErrorComponentProps = {
  isDismissable: boolean;
  error?: ApolloError;
  isOpen: boolean;
  setIsOpen: (isFormErrorOpen: boolean) => void;
  errorMessage?: string;
};

export const FormErrorComponent = ({
  isDismissable,
  error,
  isOpen,
  setIsOpen,
  errorMessage,
}: FormErrorComponentProps) => {
  const appInsights = useAppInsightsContext();
  if (error?.graphQLErrors) {
    error?.graphQLErrors.forEach((graphQLError) => {
      logError(appInsights, `[GraphQL error]: Message: ${graphQLError.message}`, error);
    });
  }
  if (error?.networkError) {
    logError(appInsights, `[Network error]: ${error?.networkError.message}`, error);
  }

  const defaultErrorMessage = 'We’re experiencing an internal server error. Please try again later.';

  return (
    <>
      <FormErrorContainer data-testid="form-error-component">
        <Collapse in={isOpen}>
          <AlertContainer>
            <Alert
              severity="error"
              variant="filled"
              action={
                isDismissable && (
                  <Button
                    onClick={() => {
                      setIsOpen(false);
                    }}
                  >
                    <CloseIcon />
                  </Button>
                )
              }
              data-testid="alert-message"
            >
              <AlertTitle data-testid="alert-title">
                Oops! Something went wrong.
              </AlertTitle>
              <span data-testid="alert-message-body">
                {errorMessage || defaultErrorMessage}
              </span>
            </Alert>
          </AlertContainer>
        </Collapse>
      </FormErrorContainer>
    </>
  );
};
