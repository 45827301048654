import { styled } from '@mui/material/styles';

export const ValueTypeCellContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: theme?.spacing(0.5),
  '& > *': {
    width: `calc((100% / 2) - ${theme?.spacing(0.5) ?? ''})`,
  },
}));
