import { useState } from 'react';
import Typography from '@mui/material/Typography';
import { AddEventForm } from '@components';
import { AegDrawer } from '../shared';
import { EventFormHeaderText } from './CreateEventDrawer.models';

export const CreateEventDrawer = ({
  isDrawerOpen,
  closeDrawer,
} : {
  isDrawerOpen: boolean;
  closeDrawer: () => void;
}) => {
  const [formContainerText, setFormContainerText] = useState<EventFormHeaderText>(EventFormHeaderText.createEvent);

  return (
    <AegDrawer
      anchorValue="right"
      openDrawer={isDrawerOpen}
      onClose={closeDrawer}
    >
      <Typography variant="h5" data-testid="create-event-form-header-text">
        {formContainerText}
      </Typography>
      <AddEventForm
        setFormContainerText={setFormContainerText}
      />
    </AegDrawer>
  );
};
