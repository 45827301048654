import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import Drawer from '@mui/material/Drawer';
import { CloseButton } from './AegDrawer.styled';
import { SIDE_NAV_WIDTH } from '../../SideNav/SideNav.styled';

type Anchor = 'top' | 'left' | 'bottom' | 'right';

type DrawerProps = {
  anchorValue: Anchor;
  openDrawer: boolean;
  onClose: () => void;
  children: React.ReactNode;
};

export function AegDrawer(drawerProps: Readonly<DrawerProps>) {
  const {
    anchorValue, openDrawer, children, onClose,
  } = drawerProps;

  return (
    <React.Fragment key={anchorValue}>
      <Drawer
        sx={{ zIndex: 9 }}
        anchor={anchorValue}
        open={openDrawer}
        onClose={onClose}
        PaperProps={{
          sx: {
            width: 640,
            padding: '0px 34px 0px 30px',
            marginLeft: anchorValue === 'left' ? SIDE_NAV_WIDTH : undefined,
          },
        }}
      >
        <CloseButton onClick={onClose} data-testid="close-button">
          <CloseIcon />
        </CloseButton>
        {children}
      </Drawer>
    </React.Fragment>
  );
}
