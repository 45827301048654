import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import { kebabCase } from '@utils/stringHelpers';
import { NOSSummaryItem } from './NOSSummaryTable.models';
import {
  ColoredTableRow, BorderlessLastTableRow, NOSTableCell,
} from './NOSSummaryTable.styled';

interface NOSSummaryTableProps {
  title: string;
  rows: Array<NOSSummaryItem>;
}

export const NOSSummaryTable = ({ title, rows }: NOSSummaryTableProps) => (
  <Table>
    <TableHead>
      <ColoredTableRow>
        <NOSTableCell component="th" colSpan={2}>
          {title}
        </NOSTableCell>
      </ColoredTableRow>
    </TableHead>
    <TableBody>
      {rows.map((row) => (
        <BorderlessLastTableRow key={row.name} data-testid={`nos-summary-table-${kebabCase(row.name)}`}>
          <NOSTableCell scope="row">{row.name}</NOSTableCell>
          <NOSTableCell align="right">{row.value}</NOSTableCell>
        </BorderlessLastTableRow>
      ))}
    </TableBody>
  </Table>
);
