export enum ManagedSheetType {
  RELEASE_NOTES = 'release_notes',
  SUMMARY = 'summary',
  SHOW_DETAIL = 'show_detail',
  QUICK_SUM = 'quick_sum',
  LATERAL_SUM_POTENTIAL = 'lateral_sum_potential',
  OVERHEAD_COSTS = 'overhead_costs',
  APPROVAL_EMAIL = 'approval_email',
  APPROVAL_REVISED = 'approval_revised',
  VARIABLE_COST_RATES = 'variable_cost_rates',
  PRS_RATES = 'prs_rates',
  MARKET_SHEET_TEMPLATE = 'market_sheet_template',
  PROFIT_LOSS_REFERENCE_SHEET = 'profit_loss_reference_sheet',
  MANAGED_SHEETS = 'managed_sheets',
  INSTRUCTIONS = 'instructions_sheet',
}

export type ManagedSheet = {
  sheetType: ManagedSheetType;
  defaultSheetName: string;
  currentSheetName?: string;
};
