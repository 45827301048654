import {
  Control, Controller, FieldValues,
} from 'react-hook-form';
import { kebabCase } from '@utils/stringHelpers';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Tooltip from '@mui/material/Tooltip';
import {
  FormTextInputContainer,
  InputLabel,
  InputContainer,
  InputLabelContainer,
} from './Form.styled';
import { maxLengthMessage } from './FormHelpers';

type FormTextInputProps<
  TFieldValues extends FieldValues = any,
  TContext = any,
> = {
  fieldName: string;
  control: Control<TFieldValues, TContext>;
  label?: string;
  placeholderText?: string;
  isRequired?: boolean;
  minRow?: number;
  maxRow?: number;
  infoTooltip?: string;
  maxLength?: number;
  isDisabled?: boolean;
};

export const FormTextInput = ({
  fieldName,
  control,
  label,
  placeholderText,
  isRequired = false,
  minRow = 1,
  maxRow,
  infoTooltip,
  maxLength,
  isDisabled = false,
}: FormTextInputProps) => (
  <FormTextInputContainer>
    {label && <InputLabelContainer>
      <InputLabel
        required={isRequired}
        data-testid={`${kebabCase(label)}-label`}
      >
        {label}
      </InputLabel>
      {infoTooltip !== undefined && (
        <Tooltip
          title={infoTooltip}>
          <InfoOutlinedIcon />
        </Tooltip>
      )}
    </InputLabelContainer>}
    {/** Test ID Update */}
    <Controller
      name={fieldName}
      control={control}
      render={({
        field: {
          onChange, value, name,
        },
        formState,
      }) => (
        <InputContainer
          onChange={onChange}
          multiline={minRow !== 1}
          minRows={minRow}
          maxRows={maxRow}
          type="text"
          name={name}
          value={(value as string) ?? ''}
          inputProps={{
            'data-testid': `${kebabCase(name)}-input`,
            maxLength,
          }}
          placeholder={placeholderText}
          error={formState.errors[fieldName] !== undefined}
          helperText={maxLengthMessage(value as string, maxLength) || formState.errors[fieldName]?.message?.toString()}
          disabled={isDisabled}
        />
      )}
    />
  </FormTextInputContainer>
);
