import AccessTime from '@mui/icons-material/AccessTime';
import MoreVert from '@mui/icons-material/MoreVert';
import { styled } from '@mui/material/styles';

export const StyledContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'end',
  width: theme?.spacing(7.5),
  height: '100%',
}));

export const StyledAccessTime = styled(AccessTime)(({ theme }) => ({
  fontSize: theme.spacing(2),
}));

export const StyledMoreVert = styled(MoreVert)(({ theme }) => ({
  fontSize: theme.spacing(2),
}));
