import { styled } from '@mui/material/styles';
import Button, { ButtonProps } from '@mui/material/Button';

export const IndentedContainer = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(3),
  paddingLeft: theme.spacing(2),
  paddingBottom: theme.spacing(1),
  borderLeft: `4px solid ${theme.palette.primary.main}1E`,
}));

export const IndentedInnerContainer = styled('div')(() => ({
  marginTop: '-16px',
}));

export const AddHeadlinerButton = styled(Button)<ButtonProps>(({ theme }) => ({
  marginTop: theme.spacing(3),
  padding: theme.spacing(1, 2.75),
  display: 'inline-flex',
  width: 'fit-content',
}));

export const HeadlinerContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'end',
  gap: theme.spacing(1.75),
  marginTop: theme.spacing(1.5),
  '& .MuiSvgIcon-root': {
    color: theme.palette.action.active,
    cursor: 'pointer',
  },
  '& .MuiFormControl-root': {
    padding: 0,
  },
  '& .MuiAutocomplete-root': {
    width: '94%',
  },
}));
