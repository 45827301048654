import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

export const MetadataTitle = styled(Typography)(({ theme }) => ({
  paddingBottom: theme.spacing(1),
})) as typeof Typography;

export const AdditionalItemsChip = styled(Chip)(({ theme }) => ({
  marginLeft: theme.spacing(1),
})) as typeof Chip;

export const MetadataGridItem = styled(Grid)(({ theme }) => ({
  paddingTop: theme.spacing(1.75),
})) as typeof Grid;

export const TypographyStyled = styled(Typography)({
  textTransform: 'capitalize',
  whiteSpace: 'normal',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: '2',
  WebkitBoxOrient: 'vertical',
});

export const MedadataArrayContainer = styled('div')({
  margin: 0,
  display: 'flex',
  alignItems: 'center',
});
