import { graphql } from '@gql/types';

export const SEARCH_USERS = graphql(`
  query SearchUsers($searchTerm: String!) {
    users(searchTerm: $searchTerm) {
      id
      name
      email
    }
  }
`);

export const GET_USERS_BY_ID = graphql(`
  query GetUsersById($userIds: [ID]!) {
    users(userIds: $userIds) {
      id
      name
      email
    }
  }
`);

export const CHECK_USER_PERMISSION = graphql(`
  query CheckUserPermission($checkPermissionInput: CheckUserPermissionInput!) {
    checkUserPermission(checkPermissionInput: $checkPermissionInput) {
      hasPermission
    }
  }
`);

export const GET_USER_RELATIONSHIPS = graphql(`
  query GetUserRelationships($userId: ID!) {
    getUserRelationships(userId: $userId) {
      user {
        id
        name
        email
      }
      company {
        id
        name
      }
      role {
        actualValue
        displayValue
      }
    }
  }
`);

export const SEARCH_ENTRA_USERS = graphql(`
  query SearchEntraUsers($searchTerm: String!) {
    azureEntraUsers(searchTerm: $searchTerm) {
      id
      name
      email
      jobTitle
      department
      isModernElvisUser
    }
  }
`);
