/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable max-lines-per-function */
import AddIcon from '@mui/icons-material/Add';
import {
  useCallback, useContext, useEffect, useState,
} from 'react';
import {
  AegDrawer,
  ListView,
  AdminForm,
  AdminFormType,
  ProtectedComponent,
  SearchBar,
} from '@components';
import {
  GridActionsCellItem,
  GridCellParams,
  GridColDef,
  GridRowModel,
} from '@mui/x-data-grid-pro';
import { ListViewLayout } from '@layouts/ListViewLayout';
import {
  BreadcrumbDispatchContext,
  NotificationDispatchContext,
  WebUserContext,
} from '@providers';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { GET_USER_RELATIONSHIPS, SEARCH_USERS } from '@gql/queries/users';
import { RemoveUserAccessInput, User, UserRelationship } from '@gql/types/graphql';
import { REMOVE_USER_ACCESS } from '@gql/mutations/users';
import { AegResourceTypes, UserPermissions } from '@types';
import { CreateButton } from '../Tours/Tours.styled';
import { DisabledText } from './AdminPortal.styled';

export const AdminPortal = () => {
  const [whichFormIsOpen, setWhichFormIsOpen] = useState<AdminFormType | ''>(
    '',
  );
  const [selectedUserId, setSelectedUserId] = useState('');

  const user = useContext(WebUserContext);
  const setNotification = useContext(NotificationDispatchContext);

  let selectedUser: UserRelationship[] = [];
  const { loading: selectedUserLoading, data: selectedUserData } = useQuery(
    GET_USER_RELATIONSHIPS,
    {
      errorPolicy: 'all',
      skip: selectedUserId.length === 0,
      variables: {
        userId: selectedUserId,
      },
    },
  );

  if (!selectedUserLoading && selectedUserData?.getUserRelationships) {
    selectedUser = selectedUserData.getUserRelationships as UserRelationship[];
  }

  useEffect(() => {
    document.title = 'Admin Portal - Modern Elvis';
  }, []);

  const [removeUserAccess] = useMutation(REMOVE_USER_ACCESS);

  const handleRemoveUser = useCallback(
    async (userAccess: RemoveUserAccessInput) => {
      const response = await removeUserAccess({
        variables: {
          userAccess,
        },
      });

      if (response) {
        setNotification({
          type: 'success',
          text: 'User permission has been removed successfully',
          duration: 6000,
        });
      }
    },
    [removeUserAccess],
  );

  const setBreadcrumbs = useContext(BreadcrumbDispatchContext);

  useEffect(() => {
    setBreadcrumbs([{ href: '/admin', text: 'Admin Portal' }]);
  }, [setBreadcrumbs]);

  const closeForm = () => setWhichFormIsOpen('');

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Name',
      flex: 0.2,
      renderCell: (params: GridCellParams<GridRowModel>) => {
        const { status } = params.row.user;
        const deactivated = status === 'Deactivated';
        return (
          <Link
            to={deactivated ? '' : `/users/${params.row.user?.id as string}`}
          >
            {deactivated ? (
              <DisabledText>{params.row.user?.name}</DisabledText>
            ) : (
              <Typography variant="body2" color="info.main">
                {params.row.user?.name}
              </Typography>
            )}
          </Link>
        );
      },
    },
    {
      field: 'email',
      headerName: 'Email',
      flex: 0.2,
      renderCell: (params: GridCellParams<GridRowModel>) => (
        <Typography variant="body2">{params.row.user?.email}</Typography>
      ),
    },
    {
      field: 'role',
      headerName: 'Role',
      flex: 0.2,
      renderCell: (params: GridCellParams<GridRowModel>) => (
        <Typography variant="body2">{params.row.role?.displayValue}</Typography>
      ),
    },
    {
      field: 'company',
      headerName: 'Company',
      flex: 0.2,
      renderCell: (params: GridCellParams<GridRowModel>) => (
        <Typography variant="body2">{params.row.company?.name}</Typography>
      ),
    },
    {
      field: 'actions',
      type: 'actions',
      getActions: (params) => {
        if (params.row.user.id === user?.id) {
          return [<></>];
        }

        const userAccess: RemoveUserAccessInput = {
          userId: params.row.user.id,
          companyId: params.row.company?.id || '',
          role: params.row.role?.actualValue || '',
        };
        return [
          <GridActionsCellItem
            label="Remove Permission"
            showInMenu
            onClick={() => {
              void (async () => {
                await handleRemoveUser(userAccess);
              })();
            }}
          />,
        ];
      },
    },
  ];

  return (
    <ProtectedComponent
      checkPermission={{
        permission: UserPermissions.ManageSystem,
        resourceType: AegResourceTypes.System,
      }}
      navigateAway={'/unauthorized'}
    >
      <ListViewLayout headerText="Admin Portal">
        <ListView
          searchComponent={
            <SearchBar search={{
              query: SEARCH_USERS,
              displayFields: { primary: 'name', secondary: 'email' },
              minimumCharacters: 2,
              resultKey: 'users',
              placeholder: 'Search for users by name or email',
              onSelect: (selectedOption: User) => {
                if (typeof selectedOption !== 'string' && selectedOption?.id) {
                  setSelectedUserId(selectedOption?.id);
                }
              },
            }} />
          }
          datagrid={{
            columns,
            rows: selectedUser,
            showGridInfo: !selectedUserId,
            isDataLoading: selectedUserLoading,
            gridInfoText: 'Set, manage, and view user permissions.',
            getRowId: (row) => {
              const companyName = (row.company?.name || '') as string;
              const roleName = (row.role?.actualValue || '') as string;
              return `${
                row.user.email as string
              }${companyName.trim()}${roleName}`;
            },
          }}
          actions={
            <>
              <CreateButton
                data-testid="add-admin-button"
                variant="outlined"
                onClick={() => {
                  setWhichFormIsOpen('Admin');
                }}
                startIcon={<AddIcon />}
              >
                Add Admin
              </CreateButton>
              <CreateButton
                data-testid="add-user-button"
                variant="contained"
                onClick={() => {
                  setWhichFormIsOpen('User');
                }}
                startIcon={<AddIcon />}
              >
                Add User Permissions
              </CreateButton>
            </>
          }
        />
        <AegDrawer
          anchorValue={'right'}
          openDrawer={Boolean(whichFormIsOpen)}
          onClose={closeForm}
        >
          {whichFormIsOpen && (
            <AdminForm onClose={closeForm} type={whichFormIsOpen} />
          )}
        </AegDrawer>
      </ListViewLayout>
    </ProtectedComponent>
  );
};
