export const BUNDLE_FINANCES_HEADERS = {
  BOX_OFFICE: 'Box Office',
  HEADLINERS: 'Headliners',
  SUPPORT: 'Support',
  FIXED_COSTS: 'Fixed Costs',
  VARIABLE_COSTS: 'Variable Costs',
};

export const BUNDLE_PROJECTION_HEADERS = {
  PROJECTION: 'Projection',
  SCENARIO_BUILDER: 'Scenario Builder',
  BONUS_ALLOCATION: 'Bonus Allocation',
};
