/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { CustomTheme, styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

export const AgentFormContainer = styled('div')(({ theme }: { theme?: CustomTheme }) => ({
  padding: `${theme?.spacing(0)} ${theme?.spacing(3)}`,
  '& .MuiButtonBase-root': {
    marginTop: theme?.spacing(5.5),
  },
}));

export const ButtonContainer = styled(Box)(({ theme }: { theme?: CustomTheme }) => ({
  display: 'flex',
  gap: theme?.spacing(2),
}));
