import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { BundleFinancesTotals as BundleFinancesTotalsType } from '@types';
import { StyledTooltip, StyledTotals } from './TotalsCell.styled';

interface BundleFinancesTotalsProps extends BundleFinancesTotalsType {
  name: string;
  bold?: boolean;
}

export function TotalsCell({
  average,
  total,
  name,
  bold,
}: BundleFinancesTotalsProps) {
  return (
    <Tooltip
      title={(
        <StyledTooltip data-testid="bundle-finances-totals-tooltip">
          <span>Average {name}</span>
          <span>Total {name}</span>
        </StyledTooltip>
      )}
      placement='right'
      arrow
    >
      <StyledTotals>
        <Typography variant="body2" fontWeight={bold ? 500 : 'normal'}>
          {average}
        </Typography>
        <Typography variant="caption-small" color="text.secondary">
          {total}
        </Typography>
      </StyledTotals>
    </Tooltip>
  );
}
