import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

export const AutocompleteTableContainer = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(4),
}));

export const ButtonContainer = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
});

export const SubHeader = styled(Typography)(({ theme }) => ({
  paddingTop: theme.spacing(3),
})) as typeof Typography;
