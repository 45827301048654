import { useQuery } from '@apollo/client';
import { GET_COMPANIES } from '@gql/queries/companies';
import { CompanyPermission } from '@gql/types/graphql';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { FormControlStyled } from './CompanySelect.styled';

type CompanySelectProps = {
  selectedCompany: string | null;
  testId: string;
  onChange: (selectedCompany: string) => void;
};
export const CompanySelect = ({
  selectedCompany,
  testId,
  onChange,
}: CompanySelectProps) => {
  const { data: companiesOptions, loading: companiesLoading } = useQuery(GET_COMPANIES, {
    errorPolicy: 'all',
    variables: { permission: CompanyPermission.HasCompanyAccess },
  });

  const dropdownOptions = companiesOptions?.companies?.map((option, index: number) => (
    <MenuItem
      key={`dropdown-option-${index}`}
      value={option?.id}
      data-testid={`${testId}-dropdown-option-${index}`}
    >
      {option?.name}
    </MenuItem>
  ));

  return (
    <FormControlStyled variant="standard" fullWidth>
      <InputLabel shrink variant='standard' id="select-company-label">Value</InputLabel>
      <Select
        notched
        labelId="select-company-label"
        id="select-company"
        variant='standard'
        value={companiesLoading ? '' : selectedCompany ?? ''}
        onChange={(event: SelectChangeEvent) => onChange(event.target.value)}
        label="Company's Name"
        inputProps={{
          'data-testid': `${testId}-child-dropdown`,
        }}
        data-testid={`${testId}-dropdown`}
      >
        {dropdownOptions}
      </Select>
    </FormControlStyled>
  );
};
