import { graphql } from '@gql/types';

export const GET_TOURS = graphql(`
  query GetTours {
    tours {
      id
      name
      primaryOfferId
      headliner {
        name
        id
      }
      company {
        id
        name
      }
      parentTour {
        id
        name
      }
      offers {
        status
        id
        name
        workbookFileId
      }
      startDate
    }
  }
`);

export const TOURS_PAGE_FRAGMENT = graphql(`
  fragment ToursPageFields on Tour {
    id
    name
    primaryOfferId
    startDate
    headliner {
      name
      id
    }
    company {
      id
      name
    }
    primaryBuyer {
      id
      name
    }
    offers {
      status
      id
      name
      workbookFileId
      eventOrder
      events {
        date
        isActive
        shows {
          showDateTime
        }
        venue {
          name
          timezone
        }
      }
    }
    parentTour {
      id
      name
    }
  }
`);

export const GET_TOURS_PAGE = graphql(`
  query GetToursPage($page: Int, $limit: Int, $tourSearch: TourSearch) {
    tours(page: $page, limit: $limit, tourSearch: $tourSearch) {
      ...ToursPageFields
    }
  }
`);

export const GET_TOUR_AND_OFFER_ONLY = graphql(`
  query GetTourAndOffer($tourId: ID!, $offerId: ID) {
    tour(tourId: $tourId) {
      id
      name
      canConfirm {
        result
        requirements
      }
      company {
        id
      }
      hasBeenConfirmed
      jdeBusinessUnit {
        id
      }
      headliner {
        name
        id
      }
      currency
      offers(offerId: $offerId) {
        id
        status
        name
        notes
        configuration
        isCrossed
        numberOfTrucks
        workbookFileId
        eventOrder
        lastSynced
        canSubmitTourSettlement
        populateWorksheet
        cloneMap {
          originalId
          newId
        }
      }
    }
  }
`);

export const GET_TOUR_AND_OFFER_TO_POPULATE_WORKBOOK = graphql(`
  query GetTourAndOfferToPopulateWorkbook($tourId: ID!, $offerId: ID) {
    tour(tourId: $tourId) {
      id
      name
      headliner {
        name
        id
      }
      currency
      offers(offerId: $offerId) {
        id
        numberOfTrucks
        financialSummary {
          id
          totalPoolIncomeTicketingRevenueAverage
          totalPoolIncomeSponsorshipRevenueAverage
          artistEarningsTotalSplitPointFigure
          artistEarningsOverSplitPoint
          totalPoolIncomeManualAdjustments {
            name
            avgTicketPriceTotal
          }
          totalArtistEarningsManualAdjustments {
            name
            avgTicketPriceTotal
          }
          netGrossPromoterIncomeManualAdjustments {
            name
            avgTicketPriceTotal
          }
        }
        events {
          id
          name
          venueStagehandsCost
          status
          baseCurrency
          exchangeRate
          compBreakdown
          merchandiseDeal
          notesAndComments
          preparedBy
          preparedOn
          breakEvenCalculations {
            breakEvenAvgOther
            breakEvenSeqOther
          }
          dealLanguage
          date
          isActive
          sellableCapacityFromConfig
          stagehandsPerTruckFromConfig
          expense {
            id
            phases(id: "modeling") {
              id
              fixedCategoryItems {
                categoryId
                items {
                  id
                  notes
                  budget
                }
              }
              variableCategoryItems {
                categoryId
                items {
                  id
                  rate
                  minimum
                  maximum
                  notes
                }
              }
              ancillaryCategoryItems {
                categoryId
                items {
                  id
                  calcType
                  notes
                  coPro1
                  coPro2
                  amount
                }
              }
              sellOffCalculations {
                guarantee
                versusPercent
                incomePoolPercent
              }
              coProCalculations {
                name
                percentage {
                  potential
                }
              }
            }
          }
          defaultBoxOffice {
            id
            phases {
              id
              percentSold
              fees {
                profitLossCategoryId
                amount
                formula
                notes
              }
              items {
                name
                capacity
                price
                kills
                comps
              }
            }
          }
          venue {
            id
            name
            city
            stateCode
            country
            localCurrencyCode
            timezone
          }
          shows {
            id
          }
          space {
            id
            name
            configurations {
              id
              totalCapacity
            }
          }
          artistOffer {
            artistAdjustment
            artistAdjustmentNotes
            guarantee
            percentNetPoolEarnings
          }
          artistDealSummary {
            useWithholdingTax
            expenseBuyoutNotes
            vsPercentNotes
            withholdingTax
            withholdingTaxNotes
            otherLabel
            otherNotes
            otherValue
            productionContributionNotes
          }
        }
      }
    }
  }
`);

export const GET_TOUR = graphql(`
  query GetTour($tourId: ID!, $offerId: ID, $phaseId: String) {
    tour(tourId: $tourId) {
      id
      name
      primaryOfferId
      hasBeenConfirmed
      subledgerId
      subledgerStatus
      subledgerPayload
      currency
      company {
        id
        name
        jdeCompanyNumber
      }
      parentTour {
        name
        id
      }
      headliner {
        name
        id
      }
      coHeadliners {
        name
        id
      }
      primaryBuyerId
      primaryOperatorId
      primaryBuyer {
        id
        name
        email
      }
      primaryOperator {
        id
        name
        email
      }
      additionalBuyers {
        id
        name
        email
      }
      additionalOperators {
        id
        name
        email
      }
      viewOnlyMembers {
        id
        name
        email
      }
      nosEmailDistributionList {
        id
        name
        email
      }
      jdeBusinessUnit {
        id
      }
      offers(offerId: $offerId) {
        id
        status
        name
        notes
        configuration
        isCrossed
        numberOfTrucks
        workbookFileId
        eventOrder
        lastSynced
        supports {
          id
          name
        }
        events {
          id
          status
          name
          date
          subledgerId
          subledgerStatus
          sellableCapacity
          subledgerPayload
          venue {
            id
            name
            city
            stateCode
            country
            timezone
            spaces {
              name
            }
          }
          space {
            id
            name
            maxCapacity
          }
          shows {
            id
            name
            showDateTime
          }
          isActive
          expense {
            id
            phases(id: $phaseId) {
              id
              fixedCategoryItems {
                items {
                  id
                  notes
                  budget
                }
              }
              variableCategoryItems {
                categoryId
                items {
                  id
                  rate
                  minimum
                  maximum
                }
              }
            }
          }
          defaultBoxOffice {
            id
            phases(id: $phaseId) {
              id
              items {
                name
                capacity
                kills
                comps
                price
              }
            }
          }
          artistOffer {
            artistAdjustment
            artistAdjustmentNotes
            guarantee
            percentNetPoolEarnings
          }
          preparedBy
          preparedOn
          isSettledInOldElvis
          workbookFileId
        }
        previousWorkbookDetails {
          workbookFileId
        }
      }
    }
  }
`);

export const GET_TOUR_OFFER_REFRESH_STATUS = graphql(`
  query GetTourOfferRefreshStatus($tourId: ID!, $offerId: ID) {
    tour(tourId: $tourId) {
      offers(offerId: $offerId) {
        id
        workbookFileId
        workbookRefreshStatus {
          messageId
          status
          reason
          actor
        }
        previousWorkbookDetails {
          dataValidationSnapshot {
              grossTicketSales
              lessShowCosts
              netPoolIncomeBeforeArtistSplit
              netPromoterIncome
              numberOfShows
              totalArtistEarnings
          }
          refreshValidated
          retiredBy
          retiredOn
          workbookFileId
        }
      }
    }
  }
`);

export const GET_WORKBOOK_FILE_ID = graphql(`
  query GetWorkbookFileId($tourId: ID!, $offerId: ID) {
    tour(tourId: $tourId) {
      company {
        id
      }
      offers(offerId: $offerId) {
        id
        workbookFileId
      }
    }
  }
`);

export const SEARCH_TOURS = graphql(`
  query SearchTours($searchTerm: String!) {
    searchTours(searchTerm: $searchTerm) {
      id
      name
    }
  }
`);

export const GET_OFFER_EVENTS = graphql(`
  query GetOfferEvents($tourId: ID!, $offerId: ID!) {
    getOfferEvents(tourId: $tourId, offerId: $offerId) {
      id
      status
      name
      venue {
        id
        name
        city
        stateCode
        spaces {
          name
        }
      }
      space {
        id
        name
      }
      shows {
        id
        name
        showDateTime
      }
      isActive
      date
      canConfirm {
        result
        requirements
      }
      canShareWithMarketing {
        result
        requirements
      }
    }
  }
`);

export const GET_OFFER_AND_EVENTS = graphql(`
  query GetOfferAndEvents($tourId: ID!, $offerId: ID) {
    tour(tourId: $tourId) {
      id
      name
      canConfirm {
        result
        requirements
      }
      jdeBusinessUnit {
        id
      }
      headliner {
        name
        id
      }
      currency
      offers(offerId: $offerId) {
        id
        status
        name
        notes
        configuration
        isCrossed
        numberOfTrucks
        workbookFileId
        eventOrder
        lastSynced
        canTakeActions
        events {
          id
          status
          name
          venue {
            id
            name
            city
            cityCode
            country
            localCurrencyCode
            ownership
            status
            stateCode
            spaces {
              name
            }
            timezone
          }
          space {
            id
            name
          }
          shows {
            id
            name
            showDateTime
          }
          isActive
          date
          canConfirm {
            result
            requirements
          }
          canShareWithMarketing {
            result
            requirements
          }
          canUpdateVenue
        }
      }
    }
  }
`);

export const GET_OFFER_EVENTS_TO_POPULATE_SHOW_DETAIL = graphql(`
  query GetOfferEventsToPopulateShowDetail($tourId: ID!, $offerId: ID!) {
    getOfferEvents(tourId: $tourId, offerId: $offerId, tourSettlementOnly: true) {
      id
      name
      status
      exchangeRate
      date
      venue {
        id
        name
        city
        timezone
      }
      space {
        id
        name
      }
      expense {
        id
        phases {
          id
          fixedCategoryItems {
            categoryId
            totalBudget
            totalBudgetToBase
          }
          ancillaryCategoryItems {
            categoryId
            totalProjected
            totalProjectedToBase
            totalToBase
          }
          seqVariableCostsToBase
          totalBudgetVariableFixedCosts
          totalVariableCosts
          totalVariableCostsToBase
          totalVariableFixedCosts
          totalAncillaryRevenue
        }
      }
      primaryNosSettlement {
        id
        artistSettlement {
          exchangeRate
          actualBoxOfficeOverview {
            totalAvailableCapacity
            totalSellableCapacity
            totalGross
            totalGrossToBase
          }
          actualPostTaxAdjustmentsTotalToBase
          netShowReceipts {
            netShowExpensesTotalToBase
            netShowReceiptsTotalToBase
            netGrossReceiptsTotalToBase
            showAdjustments {
              adjustmentTotalToBase
            }
          }
          artistSettlement {
            guaranteeToBase
            artistNosSettlementTotalToBase
          }
          aegSettlement {
            netShowReceiptsTotalToBase
          }
        }
        internalSettlement {
          exchangeRate
          actualBoxOfficeOverview {
            totalAvailableCapacity
            totalSellableCapacity
            totalGrossToBase
          }
          actualPostTaxAdjustmentsTotalToBase
          netShowReceipts {
            netGrossReceiptsTotalToBase
            netShowExpensesTotalToBase
            netShowReceiptsTotalToBase
            showAdjustments {
              adjustmentTotalToBase
            }
          }
          artistSettlement {
            artistNosSettlementTotalToBase
            guaranteeToBase
          }
          aegSettlement {
            ancillaryIncomeTotalToBase
            artistEarningsTotalToBase
            coPromoterShareTotalToBase
            netProfitOrLossToAegTotalToBase
            netShowReceiptsTotalToBase
          }
        }
      }
      primaryNosVersion
      projectedBoxOfficeOverview {
        totalCapacity
        totalAvailableCapacity
        totalSellableCapacity
        totalGross
        totalGrossToBase
      }
      projectedSequentialTotalShows
      projectedSales
      projectedPostTaxAdjustmentsTotalToBase
      projectedNetShowReceipts {
        budgetedShowExpensesToBase
        netShowExpensesTotalToBase
        netShowReceiptsTotalToBase
        netGrossReceiptsTotalToBase
        sequentialNetToPoolToBase
      }
      projectedAegEarnings {
        aegNetShowEarningsTotal
        aegNetShowEarningsTotalToBase
        coPromoterShareTotal
        coPromoterShareTotalToBase
      }
      projectedArtistOffer {
        guaranteeToBase
        artistEarningsToBase
        artistEarningSeqRoundedToBase
      }
      projectedShowIncome {
        grossShowReceipts
        grossShowReceiptsToBase
        netGrossReceipts
        netGrossReceiptsToBase
      }
      shows {
        id
        name
      }
      artistOffer {
        artistAdjustment
        artistAdjustmentNotes
        guarantee
        percentNetPoolEarnings
      }
      tourInformation {
        tourName
      }
    }
  }
`);

export const GET_TOUR_SETTLEMENT_DATA = graphql(`
  query GetTourSettlementData($tourId: ID!, $offerId: ID) {
    tour(tourId: $tourId) {
      id
      company {
        id
        name
      }
      headliner {
        id
        name
      }
      primaryBuyer {
        id
        name
      }
      offers(offerId: $offerId) {
        id
        status
        eventOrder
        events {
          id
          date
          preparedBy
          preparedOn
          venue {
            id
            timezone
          }
        }
      }
    }
  }
`);

export const GET_TOUR_NAME = graphql(`
  query GetTourName($tourId: ID!) {
    tour(tourId: $tourId) {
      id
      name
    }
  }
`);

export const GET_TOUR_CURRENCY = graphql(`
  query GetTourCurrency($tourId: ID!) {
    tour(tourId: $tourId) {
      id
      currency
    }
  }
`);
