export enum UserPermissions {
  ManageSystem = 'manage_system',
  ManageVenueQD = 'manage_venue_qd',
  UpdateBundle = 'update',
  UpdateTour = 'update',
  DeleteTour = 'delete',
  NosSettle = 'can_nos_settle',
  TourSettle = 'can_tour_settle',
  FinalizeNosSettle = 'can_finalize_nos_settle',
  ViewNos = 'can_view_nos',
  CanRefreshWorkbook = 'can_upgrade_workbook',
}
