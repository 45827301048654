import { graphql } from '@gql/types';

// TODO: Utilize fragments
export const ADD_TOUR = graphql(`
  mutation CreateTour($tour: AddTourInput!) {
    addTour(tour: $tour) {
      id
      name
      notes
      primaryOfferId
      currency
      company {
        id
        name
      }
      parentTour {
        id
        name
      }
      headliner {
        id
        name
      }
      coHeadliners {
        id
        name
      }
      offers {
        id
        status
        name
        notes
        configuration
        isCrossed
        numberOfTrucks
        workbookFileId
        eventOrder
        supports {
          id
          name
        }
        events {
          id
          name
          status
          venue {
            id
            name
          }
          space {
            id
            name
          }
          shows {
            id
            name
            showDateTime
          }
        }
      }
    }
  }
`);

// IMPORTANT: All code locations that use this mutation must
// use useMaestro hook and call the handleShareWithMaestro
// method.
export const UPDATE_TOUR = graphql(`
  mutation ModifyTour($tour: ModifyTourInput!) {
    modifyTour(tour: $tour) {
      id
      name
      notes
      primaryOfferId
      company {
        id
        name
      }
      parentTour {
        id
        name
      }
      headliner {
        id
        name
      }
      coHeadliners {
        id
        name
      }
      offers {
        id
        status
        name
        notes
        configuration
        isCrossed
        numberOfTrucks
        workbookFileId
        eventOrder
        supports {
          id
          name
        }
        events {
          id
          name
          status
          venue {
            id
            name
          }
          space {
            id
            name
          }
          shows {
            id
            name
            showDateTime
          }
        }
      }
      jdeBusinessUnit {
        id
      }
    }
  }
`);

// IMPORTANT: All code locations that use this mutation must
// use useMaestro hook and call the handleShareWithMaestro
// method.
export const MODIFY_TOUR_TEAM = graphql(`
  mutation ModifyTourTeam($tour: ModifyTourTeamInput!) {
    modifyTourTeam(tourMembers: $tour) {
      id
    }
  }
`);

export const DELETE_TOUR = graphql(`
  mutation DeleteTour($tourId: ID!) {
    deleteTour(tourId: $tourId) {
      message
    }
  }
`);

export const ADD_OFFER = graphql(`
  mutation AddOfferToTour($tourId: ID!, $offer: AddOfferInput!) {
    addOfferToTour(tourId: $tourId, offer: $offer) {
      id
      status
      name
      notes
      configuration
      isCrossed
      numberOfTrucks
      workbookFileId
      eventOrder
      supports {
        id
        name
        spotifyId
        isActive
      }
    }
  }
`);

export const CLONE_OFFER = graphql(`
  mutation CloneOffer($tourId: ID!, $offerId: ID!) {
    cloneOffer(tourId: $tourId, offerId: $offerId) {
      id
      status
      name
      notes
      configuration
      isCrossed
      numberOfTrucks
      workbookFileId
      eventOrder
      supports {
        id
        name
        spotifyId
        isActive
      }
      events {
        id
        status
        venue {
          id
          name
          city
          stateCode
          spaces {
            name
          }
        }
        space {
          id
          name
        }
        shows {
          id
          name
          showDateTime
        }
      }
    }
  }
`);

export const UPDATE_OFFER_WORKBOOK_POPULATED = graphql(`
  mutation modifyOfferWorkbookPopulated($tourId: ID!, $offerId: ID!, $offer: ModifyOfferInput!) {
    modifyOffer(tourId: $tourId, offerId: $offerId, offer: $offer) {
      id
      populateWorksheet
      cloneMap {
        originalId
        newId
      }
    }
  }
`);

// IMPORTANT: All code locations that use this mutation must
// use useMaestro hook and call the handleShareWithMaestro
// method.
export const UPDATE_OFFER = graphql(`
  mutation ModifyOffer($tourId: ID!, $offerId: ID!, $offer: ModifyOfferInput!, $eventIdsToUpdateStatus: [ID]) {
    modifyOffer(tourId: $tourId, offerId: $offerId, offer: $offer, eventIdsToUpdateStatus: $eventIdsToUpdateStatus) {
      id
      status
      name
      notes
      configuration
      isCrossed
      numberOfTrucks
      workbookFileId
      eventOrder
      canShareWithMarketing {
        result
        requirements
      }
      supports {
        id
        name
        spotifyId
        isActive
      }
      events {
        id
        status
        venue {
          id
          name
          city
          stateCode
          spaces {
            name
          }
        }
        space {
          id
          name
        }
        shows {
          id
          name
        }
      }
    }
  }
`);

export const LAUNCH_EXCEL = graphql(`
  mutation LaunchExcelWorkbook($launchExcel: LaunchExcelWorkbookInput!) {
    launchExcelWorkbook(launchExcel: $launchExcel) {
      url
    }
  }
`);

export const ADD_OFFER_EVENT = graphql(`
  mutation AddOfferEvent($event: AddEventInput!, $offerId: ID!, $tourId: ID!, $spaceConfigurationId: ID) {
    addOfferEvent(event: $event, tourId: $tourId, offerId: $offerId, spaceConfigurationId: $spaceConfigurationId) {
      id
      status
      name
      venueStagehandsCost
      isActive
      localCurrency
      baseCurrency
      headliner {
        id
        name
      }
      venue {
        id
        name
        city
        stateCode
        country
        localCurrencyCode
        spaces {
          name
        }
      }
      space {
        id
        name
        configurations {
          id
          totalCapacity
        }
      }
      shows {
        id
        name
      }
      defaultBoxOffice {
        id
        phases {
          id
          fees {
            amount
            notes
            formula
            profitLossCategoryId
          }
          items {
            name
            capacity
          }
        }
      }
      expense {
        id
        phases {
          id
          fixedCategoryItems {
            categoryId
            items {
              id
              budget
              notes
            }
          }
          variableCategoryItems {
            categoryId
            items {
              id
              minimum
              maximum
              notes
              rate
            }
          }
          ancillaryCategoryItems {
            categoryId
            items {
              id
              localCurrency
              exchangeRate
              notes
              internalNotes
              amount
              calcType
              totalToBase
              coPro1
              coPro2
              projected
              grossPotential
              description
            }
          }
        }
      }
    }
  }
`);

export const REMOVE_OFFER_EVENT = graphql(`
  mutation RemoveOfferEvent($tourId: ID!, $offerId: ID!, $eventId: ID!) {
    removeOfferEvent(tourId: $tourId, offerId: $offerId, eventId: $eventId) {
      id
      status
      name
      notes
      configuration
      isCrossed
      numberOfTrucks
      workbookFileId
      eventOrder
      supports {
        id
        name
        spotifyId
        isActive
      }
      events {
        id
        status
        venue {
          id
          name
          city
          stateCode
          spaces {
            name
          }
        }
        space {
          id
          name
        }
        shows {
          id
          name
        }
      }
      lastSynced
    }
  }
`);

export const RETRY_JDE_TOUR_SUBLEDGER = graphql(`
  mutation RetryJdeTourSubledger($tourId: ID!) {
    retryJdeTourSubledger(tourId: $tourId) {
      id
      name
      notes
      primaryOfferId
      currency
      subledgerId
      subledgerPayload
      subledgerStatus
      company {
        id
        name
      }
      parentTour {
        id
        name
      }
      headliner {
        id
        name
      }
      coHeadliners {
        id
        name
      }
      offers {
        id
        status
        name
        notes
        configuration
        isCrossed
        numberOfTrucks
        workbookFileId
        eventOrder
        supports {
          id
          name
        }
        events {
          id
          name
          status
          venue {
            id
            name
          }
          space {
            id
            name
          }
          shows {
            id
            name
            showDateTime
          }
        }
      }
      jdeBusinessUnit {
        id
      }
    }
  }
`);

export const SYNC_TOUR_FINANCIAL_SUMMARY = graphql(`
  mutation AddOrUpdateTourFinancialSummary($summary: TourFinancialSummaryInput!) {
    addOrUpdateTourFinancialSummary(summary: $summary) {
      id
      totalPoolIncomeTicketingRevenueAverage
      artistEarningsTotalSplitPointFigure
      artistEarningsOverSplitPoint
      totalPoolIncomeManualAdjustments {
        name
        avgTicketPriceTotal
      }
      totalArtistEarningsManualAdjustments {
        name
        avgTicketPriceTotal
      }
      netGrossPromoterIncomeManualAdjustments {
        name
        avgTicketPriceTotal
      }
    }
  }
`);

export const ACKNOWLEDGE_WORKBOOK_REFRESH = graphql(`
  mutation AcknowledgeWorkbookRefresh($tourId: ID!, $offerId: ID!, $workbookFileId: String!) {
    acknowledgeWorkbookRefresh(tourId: $tourId, offerId: $offerId, workbookFileId: $workbookFileId) {
      id
      workbookFileId
      workbookRefreshStatus {
        messageId
        status
        reason
        actor
      }
      previousWorkbookDetails {
        dataValidationSnapshot {
            grossTicketSales
            lessShowCosts
            netPoolIncomeBeforeArtistSplit
            netPromoterIncome
            numberOfShows
            totalArtistEarnings
        }
        refreshValidated
        retiredBy
        retiredOn
        workbookFileId
      }
    }
  }
`);
