import FormControlLabel from '@mui/material/FormControlLabel';
import { Dispatch, SetStateAction } from 'react';
import { StyledSwitch } from './SwitchInput.styled';

export function SwitchInput({
  checked,
  label,
  onChange,
}: Readonly<{
  checked: boolean;
  label: string;
  onChange: Dispatch<SetStateAction<boolean>>;
}>) {
  return (
    <FormControlLabel
      control={
        <StyledSwitch
          checked={checked}
          size="small"
          onChange={(ev) => onChange(ev.target.checked)}
        />
      }
      label={label}
    />
  );
}
