import { CustomTheme, styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

export const ButtonContainer = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

export const FlexBox = styled('div')(({ theme }) => ({
  marginBottom: theme.spacing(1),
  display: 'flex',
  gap: theme.spacing(1),
}));

export const AutocompleteContainer = styled('div')(({ theme }) => ({
  flexGrow: 2,
}));

export const DropdownContainer = styled('div')(({ theme }) => ({
  maxWidth: theme.spacing(20),
  flexGrow: 1,
}));

export const CloseButtonContainer = styled('div')(({ theme }) => ({
  paddingTop: theme.spacing(2),
}));

export const ColoredLabelContainer = styled('div')(({ theme }: { theme?: CustomTheme }) => ({
  backgroundColor: theme?.palette.primary.states?.hover,
  borderBottom: '1px solid',
  borderBottomColor: theme?.palette.primary.light[50],
  display: 'flex',
  gap: theme?.spacing(2),
  height: theme?.spacing(6.75),
  marginTop: theme?.spacing(3),
  marginBottom: theme?.spacing(1),
  padding: theme?.spacing(2, 0, 0, 2),
}));

export const ColoredLabel = styled('div')(({ theme }: { theme?: CustomTheme }) => ({
  display: 'flex',
  flexGrow: 1,
  fontWeight: '500',
  gap: theme?.spacing(1),
  marginBottom: theme?.spacing(1),
  '&:first-of-type': { flexGrow: 2, maxWidth: theme?.spacing(45) },
  '&:last-of-type': { flexGrow: 1 },
}));

export const RedTypographyWithLineHeight = styled(Typography)(({ theme }: { theme?: CustomTheme }) => ({
  color: theme?.palette.error.main,
  lineHeight: theme?.spacing(3),
}));
