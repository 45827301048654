/* eslint-disable max-lines-per-function */
import React, { MouseEvent, useMemo, useState } from 'react';
import LogoutIcon from '@mui/icons-material/Logout';
import CssBaseline from '@mui/material/CssBaseline';
import { useLocation } from 'react-router-dom';

import { useActiveAccount } from '@hooks';
import {
  AegDrawer, AegLogoBoxCompact, FeatureGate, ProtectedComponent,
} from '@components';

import { SideNavFlyoutProvider } from '@providers';
import {
  Link,
  Header,
  LinkList,
  ListItem,
  ListItemText,
  SideNavDrawer,
  BottomLinkList,
  ListItemButton,
} from './SideNav.styled';
import { menuMap } from './menuMap';

export function SideNav() {
  const location = useLocation();
  const { signOut } = useActiveAccount();
  const [openFlyoutId, setOpenFlyoutId] = useState<string | null>(null);

  const flyouts = useMemo(() => {
    const handleClose = () => setOpenFlyoutId(null);

    return menuMap
      .filter((item) => item.flyoutComponent)
      .map((item) => (
        <AegDrawer
          key={`nav-flyout-${item.label}`}
          anchorValue='left'
          openDrawer={openFlyoutId === item.label}
          onClose={handleClose}
        >
          {item.flyoutComponent}
        </AegDrawer>
      ));
  }, [openFlyoutId]);

  const links = menuMap.map((item) => {
    const handleClick = (ev: MouseEvent) => {
      // If we have a flyout component, we'll handle the click by opening the flyout
      if (item.flyoutComponent) {
        ev.preventDefault();
      }

      // Even if we don't have a flyout, we want to update the id to close any existing flyouts
      setOpenFlyoutId(item.label);
    };

    let link = (
      <ListItem key={`nav-item-${item.label}`} disablePadding data-testid={`sidenav-${item.label}`}>
        <Link
          to={item.path ?? ''}
          target={item.path?.includes('http') ? '_blank' : '_self'}
          onClick={handleClick}
        >
          <ListItemText
            active={item.path === location.pathname ? 'true' : 'false'}
            primary={item.icon}
            secondary={item.label}
          />
        </Link>
      </ListItem>
    );

    if (item.featureFlag) {
      link = (<FeatureGate configFlag={item.featureFlag} key={`nav-item-${item.label}`}>{link}</FeatureGate>);
    }

    if (item.protected) {
      link = (<ProtectedComponent
        checkPermission={{
          permission: item.protected.permissionCheck,
          resourceType: item.protected.resourceType,
        }}
        key={`nav-item-${item.label}`}
      >
        {link}
      </ProtectedComponent>);
    }

    return link;
  });

  return (
    <React.Fragment>
      <CssBaseline />
      <SideNavDrawer
        data-testid="sideNavContainer"
        variant="permanent"
        anchor="left"
      >
        <Header>
          <AegLogoBoxCompact />
        </Header>
        <SideNavFlyoutProvider
          sideNavOpenFlyoutId={openFlyoutId}
          setSideNavOpenFlyoutId={setOpenFlyoutId}
        >
          {flyouts}
        </SideNavFlyoutProvider>
        <LinkList>{links}</LinkList>
        <BottomLinkList>
          <ListItem disablePadding data-testid="sidenav-logout">
            <ListItemButton
              onClick={() => {
                signOut();
              }}
            >
              <ListItemText
                primary={<LogoutIcon />}
                secondary="Logout"
              />
            </ListItemButton>
          </ListItem>
        </BottomLinkList>
      </SideNavDrawer>
    </React.Fragment>
  );
}
