import CircularProgress from '@mui/material/CircularProgress';
import { StyledLoading } from './Loading.styled';

export function Loading() {
  return (
    <StyledLoading>
      <CircularProgress data-testid="circular-loading-icon" />
    </StyledLoading>
  );
}
