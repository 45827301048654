/* eslint-disable max-lines-per-function */
/* eslint-disable-next-line import/no-extraneous-dependencies */
import { DevTool } from '@hookform/devtools';
import {
  ReactNode, useMemo, useState, useEffect,
} from 'react';
import { SubmitErrorHandler, useForm } from 'react-hook-form';
import { AddTourInput, Artist, CompanyPermission } from '@gql/types/graphql';
import { SEARCH_ARTISTS } from '@gql/queries/artists';
import { SEARCH_PARENT_TOURS } from '@gql/queries/parentTours';
import { ApolloError, useQuery } from '@apollo/client';
import { useYupValidationResolver } from '@hooks';
import { configurationTypeOptions } from '@utils/configurationTypeHelpers';
import { GET_COMPANIES } from '@gql/queries/companies';
import { logError } from '@services/telemetry-service';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { MAX_CHARS_TOUR_NAME } from '@utils/excel/shared/constants';
import {
  DropdownInput,
  FormTextInput,
  RadioInput,
  AutocompleteInput,
  FormErrorComponent,
} from '../../shared';
import {
  crossedOptions,
  baseCurrencyOptions,
} from './TourForm.options';
import { createTourValidationSchema } from './TourForm.schema';
import {
  AutocompleteFieldArray,
  ButtonStyled,
  CheckboxInput,
  mapDropdownOptions,
} from '../../shared/Form';
import {
  CREATE_TOUR_DEFAULTS,
  CREATE_TOUR_TEXT,
  CreateTourFormErrors,
  TourFormFields,
} from './TourForm.models';

export interface TourFormSubmit {
  tour: AddTourInput;
  formData: TourFormFields;
}

interface TourFormProps {
  onFormSubmit: (data: TourFormSubmit) => void;
  isFormSaving: boolean; // Push saving state into form
  tourFormData?: TourFormFields;
  mutationError?: ApolloError;
  children?: ReactNode;
}

export function TourForm({
  onFormSubmit,
  isFormSaving,
  tourFormData,
  mutationError,
  children,
}: TourFormProps) {
  const appInsights = useAppInsightsContext();
  const [artistSearchVal, setArtistSearchVal] = useState('');
  const [parentTourSearchVal, setParentTourSearchVal] = useState('');
  const [isFormErrorOpen, setIsFormErrorOpen] = useState(false);

  const { data: companyData } = useQuery(GET_COMPANIES, {
    variables: {
      permission: CompanyPermission.CreateTours,
    },
  });

  const companyOptions = useMemo(
    () => mapDropdownOptions(companyData?.companies),
    [companyData],
  );

  const resolver = useYupValidationResolver(createTourValidationSchema);

  const { loading: artistsLoading, data: artistsData } = useQuery(
    SEARCH_ARTISTS,
    {
      errorPolicy: 'all',
      variables: { searchTerm: artistSearchVal },
      skip: artistSearchVal.length < 1,
    },
  );

  const { loading: parentToursLoading, data: parentToursData } = useQuery(
    SEARCH_PARENT_TOURS,
    {
      errorPolicy: 'all',
      variables: { searchTerm: parentTourSearchVal },
      skip: parentTourSearchVal.length < 1,
    },
  );

  const [isMultiLegDisabled, defaultValues] = useMemo(
    () => [tourFormData?.parentTour !== null, tourFormData || CREATE_TOUR_DEFAULTS],
    [],
  );

  const {
    formState: { isDirty },
    control,
    watch,
    reset,
    handleSubmit,
    getValues,
    setValue,
  } = useForm<TourFormFields>({
    resolver,
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues,
  });

  const isMultiLegWatch = watch('isMultiLeg');
  const coHeadlinersWatch = watch('coHeadliners');

  let artistOptions: any[] = [];
  if (!artistsLoading && artistsData?.artists) {
    artistOptions = artistsData.artists as Artist[];
  }

  const handleInvalidSubmit: SubmitErrorHandler<CreateTourFormErrors> = (
    error,
  ) => {
    if (isFormErrorOpen) {
      setIsFormErrorOpen(false);
    }
  };

  // TODO Pull create/modify mutations into tour form
  const handleValidSubmit = (formData: TourFormFields) => {
    const {
      // visibility,
      // venueType,
      parentTour,
      headliner,
      coHeadliners,
      numberOfTrucks,
      configuration,
      supports,
      name,
      companyId,
      isCrossed,
      currency,
    } = formData;

    const coHeadlinerIds = coHeadliners
      ?.map((a) => a.id)
      .filter((a): a is string => a !== null && a !== undefined);
    const supportIds = supports
      ?.map((a) => a.id)
      .filter((a): a is string => a !== null && a !== undefined);
    const trucks = (numberOfTrucks || numberOfTrucks === 0) && Number(numberOfTrucks);

    const newTour: AddTourInput = {
      name: name.trim(),
      isCrossed: isCrossed === 'yes',
      headliner: headliner?.id || '',
      ...(coHeadlinerIds && coHeadlinerIds.length
        ? { coHeadliners: coHeadlinerIds }
        : {}),
      ...(supportIds && supportIds.length ? { supports: supportIds } : {}),
      ...(trucks ? { numberOfTrucks: trucks } : {}),
      ...(configuration ? { configuration } : {}),
      ...(currency ? { currency } : {}),
      companyId,
      parentTourId: parentTour?.id,
    };

    try {
      onFormSubmit({
        tour: newTour,
        formData,
      });
      reset();
    } catch (error) {
      setIsFormErrorOpen(true);
      logError(appInsights, 'TourForm submit error', error as Error);
    }
  };

  const getSelectedArtists = useMemo(() => {
    const selectedArtists = [getValues('headliner')?.id || ''];
    if (coHeadlinersWatch?.length) {
      selectedArtists.push(...coHeadlinersWatch.map((a) => a?.id || ''));
    }
    return selectedArtists;
  }, [getValues()]);

  const handleDisabledButton = useMemo(() => {
    if (tourFormData) {
      return false;
    }
    if (isFormSaving) {
      return true;
    }
    return false;
  }, [isFormSaving, tourFormData, isDirty]);

  useEffect(() => {
    if (!isMultiLegWatch) {
      setValue('parentTour', null);
    }
  }, [isMultiLegWatch]);

  return (
    <>
      <FormErrorComponent
        isDismissable={true}
        error={mutationError}
        isOpen={isFormErrorOpen}
        setIsOpen={setIsFormErrorOpen}
      />
      {children}
      {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
      <form onSubmit={handleSubmit(handleValidSubmit, handleInvalidSubmit)}>
        <>
          <AutocompleteInput
            fieldName="headliner"
            control={control}
            label="Headliner"
            options={artistOptions}
            dataDisplayField={{ primary: 'name' }}
            dataFilterField="id"
            filterOut={getSelectedArtists}
            onInputChange={setArtistSearchVal}
            placeholderText="Enter Headliner or Artist Name"
            isRequired={true}
            defaultValue={CREATE_TOUR_DEFAULTS.headliner}
          />

          <AutocompleteFieldArray
            subjectName="Co-Headliner"
            fieldName="coHeadliners"
            placeholderText="Enter Co-Headliner or Artist Name"
            control={control}
            options={artistOptions}
            filterOut={getSelectedArtists}
            onInputChange={setArtistSearchVal}
            defaultValue={CREATE_TOUR_DEFAULTS.coHeadliners}
          />

          <AutocompleteFieldArray
            subjectName="Supporting Artist"
            fieldName="supports"
            placeholderText="Enter Supporting Artist Name"
            control={control}
            options={artistOptions}
            filterOut={getSelectedArtists}
            onInputChange={setArtistSearchVal}
            defaultValue={CREATE_TOUR_DEFAULTS.supports}
          />

          <FormTextInput
            control={control}
            fieldName="name"
            label="Tour Name"
            isRequired={true}
            placeholderText="Enter Tour Name"
            maxLength={MAX_CHARS_TOUR_NAME}
          />

          <CheckboxInput
            control={control}
            fieldName="isMultiLeg"
            label="This is part of an existing Multi-Leg Tour"
            isDisabled={isMultiLegDisabled}
          />

          {isMultiLegWatch && (
            <AutocompleteInput
              options={parentToursData?.parentTours || []}
              control={control}
              disabled={isMultiLegDisabled}
              fieldName="parentTour"
              label="Multi-Leg Tour Name"
              dataDisplayField={{ primary: 'name' }}
              onInputChange={setParentTourSearchVal}
              dataFilterField="id"
              isRequired={true}
              placeholderText="Enter Multi-Leg Tour Name"
              defaultValue={CREATE_TOUR_DEFAULTS.parentTour}
              areOptionsLoading={parentToursLoading}
              minimumCharacters={1}
            />
          )}

          <RadioInput
            fieldName={'isCrossed'}
            control={control}
            label={'Crossed?'}
            options={crossedOptions}
          />

          {/* {tourFormData && (
              <DropdownInput
                fieldName={'venueType'}
                control={control}
                label={'Venue Type'}
                placeholderText={'Select a Venue Type'}
                options={venueOptions}
              />
            )}

            {tourFormData && (
              <DropdownInput
                fieldName={'visibility'}
                control={control}
                label={'Visibility'}
                placeholderText={'Select the Visibility'}
                options={visibilityOptions}
              />
            )} */}

          <DropdownInput
            fieldName={'configuration'}
            control={control}
            label={'Configuration'}
            placeholderText={'Select Configuration'}
            options={configurationTypeOptions}
            isRequired={false}
          />

          <FormTextInput
            control={control}
            fieldName={'numberOfTrucks'}
            label={'Trucks'}
            isRequired={false}
            placeholderText="Enter Number of Trucks"
          />

          <DropdownInput
            fieldName={'companyId'}
            control={control}
            label={'Company'}
            placeholderText={'Select Company'}
            options={companyOptions}
            isRequired={true}
          />

          <DropdownInput
            fieldName={'currency'}
            control={control}
            label={'Base Currency'}
            placeholderText={'Select Base Currency'}
            options={baseCurrencyOptions}
            isRequired={true}
          />

          <ButtonStyled
            variant="contained"
            data-testid="create-button-form"
            type="submit"
            disabled={handleDisabledButton}
          >
            {CREATE_TOUR_TEXT.submitText}
          </ButtonStyled>
        </>
      </form>
      <DevTool control={control} />
    </>
  );
}
