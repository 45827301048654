import MenuItem from '@mui/material/MenuItem';
import { CustomTheme, styled } from '@mui/material/styles';

export const SelectContainer = styled('div')(({ theme }) => ({
  width: 'fit-content',
  minWidth: theme.spacing(25),
  fontSize: theme.spacing(1.5),
}));

export const SelectLabelContainer = styled('label')(({ theme }) => ({
  color: theme.palette.primary.main,
}));

export const SelectControlContainer = styled('div')(({ theme }) => ({
  position: 'relative',
  'div.alternate': {
    paddingLeft: theme.spacing(0),
  },
}));

export const MenuItemStyled = styled(MenuItem, {
  shouldForwardProp: (prop) => prop !== 'showDivider',
})(({ theme, showDivider }: { theme?: CustomTheme, showDivider?: boolean }) => ({
  borderTopWidth: showDivider ? 1 : 0,
  borderStyle: 'solid',
  borderColor: theme?.palette?.action?.disabledBackground,
}));

export const SelectItemContainer = styled('div')(({ theme }) => ({
  '&.alternate': {
    paddingLeft: theme.spacing(4),
  },
  whiteSpace: 'normal',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: 'box',
  WebkitLineClamp: '1',
  WebkitBoxOrient: 'vertical',
}));
