import React from 'react';

export type SortedEventIds = {
  sortedEventIds: string[];
};

const BundleContext = React.createContext<SortedEventIds>({
  sortedEventIds: [],
});

const BundleDispatchContext = React.createContext<
React.Dispatch<React.SetStateAction<SortedEventIds>>
>(() => {});

type ProviderProps = {
  children: React.ReactNode;
};

function BundleProvider({ children }: ProviderProps) {
  const [sortedEventIds, setSortedEventIds] = React.useState<SortedEventIds>({ sortedEventIds: [] });

  return (
    <BundleContext.Provider value={sortedEventIds}>
      <BundleDispatchContext.Provider value={setSortedEventIds}>
        {children}
      </BundleDispatchContext.Provider>
    </BundleContext.Provider>
  );
}

export {
  BundleProvider,
  BundleContext,
  BundleDispatchContext,
};
