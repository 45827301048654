/* eslint-disable max-lines-per-function */
import { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useMutation, useQuery } from '@apollo/client';
import { useExcel } from '@hooks';
import { WORKBOOK_CUSTOM_PROPERTIES } from '@utils/excel/shared/constants';
import { GET_WORKBOOK_VERSION_INFO } from 'src/gql/queries/excel';
import { useCallbackOnWindowFocus } from 'src/hooks/useCallbackOnWindowFocus';
import { GET_TOUR_OFFER_REFRESH_STATUS } from '@gql/queries/tours';
import { DataValidationSnapshot, WorkbookStatusType } from '@gql/types/graphql';
import { ReviewUpdatesModal } from '@components';
import { ACKNOWLEDGE_WORKBOOK_REFRESH } from '@gql/mutations/tours';
import {
  FlexRow,
  StyledButton,
  ButtonWhiteText,
  FailedContainer,
  SuccessContainer,
  WarningContainer,
} from './RefreshStatusBanner.styled';

interface BannersDismissedState {
  failed: string | null;
  warning: string | null;
}

interface RefreshStatusBannerProps {
  tourId: string;
  offerId: string;
  openRefreshModal: () => void;
}

export function RefreshStatusBanner({ tourId, offerId, openRefreshModal }: RefreshStatusBannerProps) {
  const { getCustomProperties, setCustomProperty } = useExcel();
  const [isReviewUpdatesModalOpen, setReviewUpdatesModalOpen] = useState(false);
  const [bannersDismissed, setBannersDismissed] = useState<BannersDismissedState>({
    failed: null,
    warning: null,
  });

  const { data: workbookInfo, refetch, loading: workbookInfoLoading } = useQuery(GET_WORKBOOK_VERSION_INFO, {
    variables: {
      tourId,
      offerId,
    },
  });

  const { data: refreshInfo, loading: refreshInfoLoading } = useQuery(GET_TOUR_OFFER_REFRESH_STATUS, {
    variables: {
      tourId,
      offerId,
    },
    onCompleted: (info) => {
      if (info.tour?.offers?.[0]?.workbookRefreshStatus?.status === WorkbookStatusType.Failed) {
        void setCustomProperty(
          WORKBOOK_CUSTOM_PROPERTIES.IS_RETIRED,
          '',
        );
      }
    },
  });

  const [acknowledgeWorkbookRefresh] = useMutation(ACKNOWLEDGE_WORKBOOK_REFRESH);

  const templateVersion = workbookInfo?.getVersionInformation?.templateVersion;
  const currentVersionDismissed = bannersDismissed.warning === templateVersion;
  const offer = refreshInfo?.tour?.offers?.[0];
  const refreshId = offer?.workbookRefreshStatus?.messageId;
  const refreshStatus = offer?.workbookRefreshStatus?.status;
  const previousWorkbookDetails = offer?.previousWorkbookDetails?.[
    offer.previousWorkbookDetails.length - 1
  ];

  useCallbackOnWindowFocus(refetch);

  useEffect(() => {
    const loadCustomProperties = async () => {
      const customProperties = await getCustomProperties();
      if (customProperties) {
        setBannersDismissed({
          failed: customProperties[WORKBOOK_CUSTOM_PROPERTIES.FAILED_REFRESH_DISMISSED] as string ?? '',
          warning: customProperties[WORKBOOK_CUSTOM_PROPERTIES.REFRESH_DISMISSED] as string ?? '',
        });
      }
    };

    void loadCustomProperties();
  }, []);

  const handleSuccessDismiss = async () => {
    if (!previousWorkbookDetails?.workbookFileId) return;
    await acknowledgeWorkbookRefresh({
      variables: {
        tourId,
        offerId,
        workbookFileId: previousWorkbookDetails?.workbookFileId,
      },
    });
  };

  const handleWarningDismiss = async () => {
    if (!templateVersion) return;
    await setCustomProperty(
      WORKBOOK_CUSTOM_PROPERTIES.REFRESH_DISMISSED,
      templateVersion,
    );
    setBannersDismissed((prevState) => ({
      ...prevState,
      warning: templateVersion,
    }));
  };

  const handleFailedDismiss = async () => {
    if (!refreshId) return;
    await setCustomProperty(
      WORKBOOK_CUSTOM_PROPERTIES.FAILED_REFRESH_DISMISSED,
      refreshId,
    );
    setBannersDismissed((prevState) => ({
      ...prevState,
      failed: refreshId,
    }));
  };

  // If either request is still loading, return null
  if (refreshInfoLoading || workbookInfoLoading) return null;

  // If file properties haven't been read yet, return null
  if (
    bannersDismissed.failed === null
    && bannersDismissed.warning === null
  ) return null;

  // If the version is current or the warning banner has been dismissed,
  // and the refresh status isn't failed, return null
  if (
    (workbookInfo?.getVersionInformation?.isCurrent || currentVersionDismissed)
    && refreshStatus !== WorkbookStatusType.Failed && refreshStatus !== WorkbookStatusType.Done
  ) return null;

  const updateBtn = (
    <StyledButton
      color="secondary"
      variant="contained"
      data-testid="workbook-refresh-update-button"
      onClick={() => {
        void openRefreshModal();
      }}
    >
      Update to the Latest Version
    </StyledButton>
  );

  const notifyNewVersionAvailable = refreshStatus !== WorkbookStatusType.Failed && !currentVersionDismissed
    && !workbookInfo?.getVersionInformation?.isCurrent
    && workbookInfo?.getVersionInformation?.notify
    && workbookInfo?.getVersionInformation?.version; // i.e., workbook version >= v2.0

  if (notifyNewVersionAvailable) {
    return (
      <>
        <WarningContainer>
          <Typography data-testid="workbook-refresh-warning-text">
            This offer is using an old model template version. Would you like to update to the latest version?
          </Typography>
          <FlexRow>
            {updateBtn}
            <Button variant="text" onClick={() => {
              void handleWarningDismiss();
            }} data-testid="workbook-refresh-dismiss-button">
              Skip this Version
            </Button>
          </FlexRow>
        </WarningContainer>
      </>
    );
  }

  if (refreshStatus === WorkbookStatusType.Failed && bannersDismissed.failed !== refreshId) {
    return (
      <>
        <FailedContainer>
          <Typography data-testid="workbook-refresh-failed-text">
            {`The update to your offer template failed, please try again. 
          If you continue to experience issues, contact booking support.`}
          </Typography>
          <FlexRow>
            {updateBtn}
            <ButtonWhiteText variant="text" onClick={() => {
              void handleFailedDismiss();
            }} data-testid="workbook-refresh-dismiss-button">
              Dismiss
            </ButtonWhiteText>
          </FlexRow>
        </FailedContainer>
      </>
    );
  }

  if (
    refreshStatus === WorkbookStatusType.Done
    && !previousWorkbookDetails?.refreshValidated
  ) {
    return (
      <>
        {isReviewUpdatesModalOpen && (
          <ReviewUpdatesModal
            setOpen={setReviewUpdatesModalOpen}
            onClose={() => {
              void handleSuccessDismiss();
            }}
            previousDataValidationSnapshot={previousWorkbookDetails?.dataValidationSnapshot as DataValidationSnapshot}
          />
        )}
        <SuccessContainer>
          <Typography data-testid="workbook-refresh-success-text">
            This offer has been successfully updated to the latest template version.
          </Typography>
          <FlexRow>
            <StyledButton
              color="secondary"
              variant="contained"
              data-testid="workbook-refresh-success-button"
              onClick={() => setReviewUpdatesModalOpen(true)}
            >
              Review Updates
            </StyledButton>
            <ButtonWhiteText
              variant="text"
              onClick={() => {
                void handleSuccessDismiss();
              }}
              data-testid="workbook-refresh-success-dismiss-button"
            >
              Dismiss
            </ButtonWhiteText>
          </FlexRow>
        </SuccessContainer>
      </>
    );
  }
}
