import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

export const SearchContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  padding: theme.spacing(0),
  margin: theme.spacing(2),
  maxWidth: theme.spacing(80),
}));

export const SearchContainerMarket = styled('div')(() => ({
  display: 'flex',
  flexWrap: 'wrap',
  width: '100%',
}));

export const StateCityContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  margin: theme.spacing(0, 1, 1),
  maxWidth: theme.spacing(80),
}));

export const SearchStateCity = styled(Box)(({ theme }) => ({
  '.MuiOutlinedInput-root.Mui-disabled': {
    backgroundColor: theme.palette.action.hoverOpacity,
    color: theme.palette.primary.main,
    WebkitTextFillColor: `${theme.palette.primary.main}!important`,
  },
  margin: theme.spacing(0, 1, 1),
  width: '100%',
}));
