import ButtonBase from '@mui/material/ButtonBase';
import { NosVersionTableFragment } from '@gql/types/graphql';
import { SET_PRIMARY_NOS_SETTLEMENT } from '@gql/mutations/settlement';
import { useMutation } from '@apollo/client';
import { YellowStar, GreyStar, IsPrimaryContainer } from './NOSVersionsTable.styled';

interface NOSVersionsPrimaryButtonProps {
  row: NosVersionTableFragment;
}

export const NOSVersionsPrimaryButton = ({ row }: NOSVersionsPrimaryButtonProps) => {
  const [setPrimaryNosSettlement] = useMutation(SET_PRIMARY_NOS_SETTLEMENT);

  const onSetPrimary = async (id: string) => {
    await setPrimaryNosSettlement({ variables: { nosSettlementId: id } });
  };

  return (
    <IsPrimaryContainer>{row?.isPrimary ? (
        <YellowStar data-testid={`primary-${row.id}`} />
    ) : (
      <ButtonBase
        onClick={() => { void onSetPrimary(row.id); }}
      >
        <GreyStar
          data-testid={`not-primary-${row.id}`}
        />
      </ButtonBase>
    )}</IsPrimaryContainer>
  );
};
