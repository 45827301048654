// save state for auto complete idea 1

import {
  Control,
  UseFormGetValues,
  useFieldArray,
} from 'react-hook-form';
import { AddElementLinkContainer, AddSubjectTypography } from '@components';
import { UserModel } from '@types';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import AddIcon from '@mui/icons-material/Add';
import { kebabCase } from '@utils/stringHelpers';
import { ReactNode } from 'react';
import { User } from '@gql/types/graphql';
import { AutocompleteInput } from '../../shared/Form/AutocompleteInput';
import { InputContainer, InputLabel } from '../../shared/Form/Form.styled';
import {
  CloseIconWithDisable,
  ColoredTableRow,
  InputCell,
  RemoveButtonCell,
} from './AutocompleteTableArray.styled';
import { ModifyTeamMembersFormFields } from '../ModifyTeamMembersForm.models';

type AutocompleteTableArrayProps = {
  subjectName: string;
  labelSuffix?: string;
  fieldName: 'operators' | 'buyers' | 'viewOnly';
  placeholderText: string;
  control: Control<ModifyTeamMembersFormFields>;
  options: Array<UserModel>;
  filterOut?: Array<string>;
  onInputChange: React.Dispatch<React.SetStateAction<string>>;
  isRequired?: boolean;
  currentUserId: string;
  canUserRemoveSelf?: boolean;
  formGetValues: UseFormGetValues<ModifyTeamMembersFormFields>;
  areOptionsLoading?: boolean;
};

// eslint-disable-next-line max-lines-per-function
export const AutocompleteTableArray = ({
  subjectName,
  labelSuffix,
  fieldName,
  placeholderText,
  control,
  options,
  filterOut,
  onInputChange,
  isRequired,
  currentUserId,
  canUserRemoveSelf,
  formGetValues,
  areOptionsLoading,
}: AutocompleteTableArrayProps) => {
  const { append, remove } = useFieldArray({
    name: fieldName,
    control,
    rules: { minLength: 1 },
  });

  const itemInput = (item: User | null, index: number): ReactNode => {
    if (item?.id === currentUserId) {
      return (
        <InputContainer
          inputProps={{
            'data-testid': `input-${kebabCase(fieldName)}-${index}`,
          }}
          disabled
          value={`${item.name} - ${item.email}`}
        />
      );
    }

    return (
      <AutocompleteInput
        areOptionsLoading={areOptionsLoading}
        data-testid={`autocomplete-${kebabCase(subjectName)}-${index}`}
        fieldName={`${fieldName}.${index}`}
        control={control}
        options={options}
        dataDisplayField={{ primary: 'name', secondary: 'email' }}
        dataFilterField={'id'}
        filterOut={filterOut}
        onInputChange={onInputChange}
        placeholderText={placeholderText}
        isRequired={false}
      />
    );
  };

  const list = formGetValues(fieldName) ?? [];

  return (
    <div>
      <Table>
        <TableHead>
          <ColoredTableRow>
            <TableCell>
              <InputLabel
                required={isRequired}
                data-testid={`${kebabCase(fieldName)}-role-label`}
              >
                {subjectName} {labelSuffix}
              </InputLabel>
            </TableCell>
            <RemoveButtonCell></RemoveButtonCell>
          </ColoredTableRow>
        </TableHead>
        <TableBody>
          {list.map((item, idx) => (
            <TableRow key={item?.id ?? idx}>
              <InputCell>
                {itemInput(item, idx)}
              </InputCell>
              <RemoveButtonCell className="borderless">
                <CloseIconWithDisable
                  className={canUserRemoveSelf || currentUserId !== item?.id ? '' : 'disabled'}
                  onClick={() => remove(idx)}
                  data-testid={`close-autocomplete-${fieldName}-${idx}`}
                />
              </RemoveButtonCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <AddElementLinkContainer
        data-testid={`autocomplete-table-link-container-${fieldName}`}
      >
        <AddIcon />
        <AddSubjectTypography
          variant="body2"
          data-testid={`autocomplete-array-link-${fieldName}`}
          onClick={() => append(null)}
        >
          Add {subjectName}
        </AddSubjectTypography>
      </AddElementLinkContainer>
    </div>
  );
};
