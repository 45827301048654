import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import DialogActions from '@mui/material/DialogActions';
import { styled } from '@mui/material/styles';

export const DialogActionsContainer = styled(DialogActions)(({ theme }) => ({
  justifyContent: 'flex-start',
  paddingLeft: theme.spacing(3),
  paddingRight: theme.spacing(3),
  paddingBottom: theme.spacing(3),
}));

export const ButtonStyled = styled(Button)({
  textTransform: 'none',
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '&:disabled > div': {
    color: 'transparent',
  },
});

export const LoadingIconStyled = styled(CircularProgress)({
  color: 'inherit',
  position: 'absolute',
});

export const AlertList = styled('ul')(({ theme }) => ({
  paddingLeft: theme.spacing(3),
  margin: 0,
}));

export const DialogDescription = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 3),
}));

export const OptionalCloseButtonContainer = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(1),
  marginRight: theme.spacing(1),
  marginBottom: theme.spacing(-2),
  display: 'flex',
  justifyContent: 'flex-end',
  zIndex: 1,
}));
