import { styled, CustomTheme } from '@mui/material/styles';
import { DataGridPro } from '@mui/x-data-grid-pro';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Stars from '@mui/icons-material/Stars';

export const EventsContainer = styled('div')(({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
}));

export const StyledNoEventsTypography = styled(Typography)(({ theme }: { theme?: CustomTheme }) => ({
  variant: 'body1',
  color: theme?.palette.primary.states?.outlineBorder,
  padding: theme?.spacing(3, 0),
}));

export const StyledDataGrid = styled(DataGridPro)(
  ({ theme }: { theme?: CustomTheme }) => ({
    width: '100%',
    borderRadius: '0px',
    // eslint-disable-next-line max-len
    '& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus, & .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-columnHeader:focus':
      {
        outline: 'none',
      },
    '& .MuiDataGrid-columnHeaders': {
      backgroundColor: theme?.palette.primary.states?.hover,
    },
    '& .MuiDataGrid-columnHeader, & .MuiDataGrid-cell': {
      paddingLeft: theme?.spacing(3),
    },
  }),
);

export const StyledButton = styled(Button)(() => ({
  minWidth: 'initial',
  padding: '0',
}));

export const SwitchContainer = styled('div')(({ theme }) => ({
  marginLeft: theme?.spacing(-1.25),
}));

export const PrimaryStar = styled(Stars)(({ theme }: { theme?: CustomTheme }) => ({
  color: theme?.palette.primary.main,
}));

export const PrimaryStarDisabled = styled(Stars)(({ theme }: { theme?: CustomTheme }) => ({
  color: theme?.palette.action.disabledBackground,
}));
