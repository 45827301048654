import Button from '@mui/material/Button';
import { ButtonsContainer } from './ConfirmButtons.styled';

interface ConfirmButtonsProps {
  discard: string;
  confirm: string;
  onDiscard?: () => void;
  onConfirm?: () => void;
  disabled?: boolean;
}

export function ConfirmButtons({
  discard,
  confirm,
  onDiscard,
  onConfirm,
  disabled,
}: ConfirmButtonsProps) {
  return (
    <ButtonsContainer>
      <Button
        variant="outlined"
        size='medium'
        onClick={onDiscard}
        disabled={disabled}
      >
        {discard}
      </Button>
      <Button
        variant="contained"
        size='medium'
        onClick={onConfirm}
        disabled={disabled}
      >
        {confirm}
      </Button>
    </ButtonsContainer>
  );
}
