/* eslint-disable max-len */
/**
 * NOS Settlement Sheet Names
 */
export const AS_BOX_OFFICE_SHEET_NAME = 'AS_Box Office';
export const AS_SETTLEMENT_SHEET_NAME = 'Artist Settlement';
export const CATERING_SHEET_NAME = 'Catering';
export const CO_PRO_SETTLEMENT_SHEET_NAME = 'Co-Pro Settlement';
export const FAR_BOX_OFFICE_SHEET_NAME = 'FAR_Box Office';
export const FAR_SETTLEMENT_SHEET_NAME = 'Final Audit Report';
export const OTHER_SHEET_NAME = 'Other';
export const PREMIUM_SHEET_NAME = 'Premium';
export const PRODUCTION_SHEET_NAME = 'Production';
export const RENT_SHEET_NAME = 'Rent';
export const ROAD_CHECK_LOG_SHEET_NAME = 'Road Check Log';
export const SETTLEMENT_SUMMARY_SHEET_NAME = 'Show Summary';
export const SETTLEMENT_EMAIL_TEMPLATE_SHEET_NAME = 'Settlement Email Template';
export const STAFFING_SHEET_NAME = 'Staffing';
export const STAGEHANDS_SHEET_NAME = 'Stagehands';
export const SUPPORT_SHEET_NAME = 'Support';
export const SUPPORT_SETTLEMENT_SHEET_NAME = 'Support Settlement';
export const TICKET_AUDIT_DETAIL_SHEET_NAME = 'Ticket Audit Detail';
export const TRANSPORT_SHEET_NAME = 'Transport';
export const VENUE_SHEET_NAME = 'Venue';
export const WH_TAXES_SHEET_NAME = 'WH Taxes';

export const SHEETS_WITH_AEG_LOGO = [
  AS_SETTLEMENT_SHEET_NAME,
  CO_PRO_SETTLEMENT_SHEET_NAME,
  FAR_SETTLEMENT_SHEET_NAME,
  PREMIUM_SHEET_NAME,
  ROAD_CHECK_LOG_SHEET_NAME,
  SETTLEMENT_SUMMARY_SHEET_NAME,
  SUPPORT_SETTLEMENT_SHEET_NAME,
  TICKET_AUDIT_DETAIL_SHEET_NAME,
  WH_TAXES_SHEET_NAME,
];

// Initialize Workbook Key
export const NOS_SETTLEMENT_INITIALIZED_KEY = 'nos_settlement_workbook_initialized';

/**
 * Reference Sheet Tables
 */
export const AS_ARTIST_PAYMENT_TABLE_NAME = 'AS_Artist_Payment';
export const AS_VENUE_SETTLEMENT_TABLE_NAME = 'AS_Venue_Settlement';
export const FAR_ARTIST_PAYMENT_TABLE_NAME = 'FAR_Artist_Payment';
export const FAR_VENUE_SETTLEMENT_TABLE_NAME = 'FAR_Venue_Settlement';
export const FAR_ANCILLARY_EARNINGS_TABLE_NAME = 'FAR_Ancillary';
export const AS_VARIABLE_COST_TABLE_NAME = 'AS_VariableCosts_Budget';

/**
 * Common Named Ranges
 */
const EVENT_INFO = {
  LOCAL_CURRENCY: 'LocalCurrency',
  BASE_CURRENCY: 'BaseCurrency',
  EXCHANGE_RATE: 'ExchangeRate',
  PREPARED_BY: 'PreparedBy',
  PREPARED_ON: 'PreparedOn',
  ARTIST_REP: 'ArtistRep',
  CONTACT: 'Contact',
  NOTE_COMMENTS: 'NotesComments',
};

// ARTIST_SETTLEMENT = FAR_SETTLEMENT for these named ranges.
export const ARTIST_SETTLEMENT = {
  GUARANTEE: 'Guarantee',
  GUARANTEE_BASE: 'GuaranteeToBase',
  PCT_NET_POOL_EARN: 'PctOfNetPoolEarnings',
  NET_POOL_EARN: 'NetPoolEarnings',
  NET_POOL_EARN_BASE: 'NetPoolEarningsToBase',
  ADJ_NOTES: 'ArtistAdjustmentNote',
  ADJUSTMENT: 'ArtistAdjustmentTotal',
  ADJUSTMENT_BASE: 'ArtistAdjustmentTotalToBase',
  SETTLEMENT_TOTAL: 'ArtistNosSettlement',
  SETTLEMENT_TOTAL_BASE: 'ArtistNosSettlementToBase',
  TOTAL_AVAILABLE_CAPACITY: 'TotalAvailableCapacity',
  TOTAL_SELLABLE_CAPACITY: 'TotalSellableCapacity',
  TOTAL_GROSS: 'TotalGross',
  TOTAL_GROSS_TO_BASE: 'TotalGrossToBase',
  POST_TAX_ADJUSTMENTS_TOTAL: 'PostTaxAdjustmentsTotal',
  POST_TAX_ADJUSTMENTS_TOTAL_TO_BASE: 'PostTaxAdjustmentsTotalToBase',
  NET_GROSS_RECEIPTS: 'NetGrossReceipts',
  NET_GROSS_RECEIPTS_TO_BASE: 'NetGrossReceiptsToBase',
  SHOW_EXPENSES: 'ShowExpenses',
  SHOW_EXPENSES_TO_BASE: 'ShowExpensesToBase',
  TOTAL_BUDGET_VARIABLE_COSTS_ACTUAL_TO_BASE: 'TotalBudgetVariableCostsActualToBase',
  TOTAL_BUDGET_TOTAL_ACTUAL_TO_BASE: 'TotalBudgetTotalActualToBase',
  SETTLEMENT_PROGRESS_STATUS: 'SettlementProgressStatus',
};

export const SETTLEMENT_ADJUSTMENTS_BUILDER = {
  MAX_TOTAL_ADJUSTMENT: 5,
  TEXT: (i: number) => `AdjustmentText${i}`,
  RATE: (i: number) => `AdjustmentRate${i}`,
  TOTAL: (i: number) => `AdjustmentTotal${i}`,
  TOTAL_TO_BASE: (i: number) => `AdjustmentTotal${i}ToBase`,
};
export const SETTLEMENT_ADJUSTMENTS: string[] = Array(SETTLEMENT_ADJUSTMENTS_BUILDER.MAX_TOTAL_ADJUSTMENT)
  .fill(0)
  .reduce((accumulator: string[], _, i) => {
    const index = i + 1;
    accumulator.push(SETTLEMENT_ADJUSTMENTS_BUILDER.TEXT(index));
    accumulator.push(SETTLEMENT_ADJUSTMENTS_BUILDER.RATE(index));
    accumulator.push(SETTLEMENT_ADJUSTMENTS_BUILDER.TOTAL(index));
    accumulator.push(SETTLEMENT_ADJUSTMENTS_BUILDER.TOTAL_TO_BASE(index));
    return accumulator;
  }, []);

export const AEG_SETTLEMENT = {
  NET_SHOW_RECEIPTS: 'NetShowReceipts',
  NET_SHOW_RECEIPTS_BASE: 'NetShowReceiptsToBase',
  ARTIST_EARN: 'ArtistEarnings',
  ARTIST_EARN_BASE: 'ArtistEarningsToBase',
  NET_PROFIT_LOSS: 'NetProfitLoss',
  NET_PROFIT_LOSS_BASE: 'NetProfitLossToBase',
  PCT_CO_PRO_SHARE: 'CoProSharePercent',
  CO_PRO_SHARE: 'CoProShare',
  CO_PRO_SHARE_BASE: 'CoProShareToBase',
  AEG_NET_PROFIT_LOSS: 'AegNetProfitLoss',
  AEG_NET_PROFIT_LOSS_BASE: 'AegNetProfitLossToBase',
  EXPENSE_ADJUSTMENT: 'ExpenseAdjustment',
  EXPENSE_ADJUSTMENT_BASE: 'ExpenseAdjustmentToBase',
  OTHER_ADJUSTMENT: 'OtherAdjustment',
  OTHER_ADJUSTMENT_BASE: 'OtherToBase',
};

const COMP_TICKET_SUMMARY = {
  ARTIST_TOUR: 'CompTktTotal1',
  VENUE: 'CompTktTotal2',
  PROMOTER: 'CompTktTotal3',
  PROMOTION: 'CompTktTotal4',
  RELOCATE_TECH: 'CompTktTotal5',
  SPONSOR: 'CompTktTotal6',
};

const DROP_COUNT_SUMMARY = {
  PAID_COMP: 'DropCount1',
  SUITE: 'DropCount2',
  OTHER: 'DropCount3',
  UNREDEEMED: 'DropCount4',
};

export const NAMED_RANGES = {
  SETTLEMENT: {
    ARTIST_NAME: 'ArtistName',
    TOUR_NAME: 'TourName',
    MARKET_NAME: 'MarketName',
    VENUE_NAME: 'VenueName',
    SHOW_DATE: 'ShowDate',
    NUM_SHOWS: 'NumShows',
    NUM_TRUCKS: 'NumTrucks',
    STATUS: 'Status',
    LOCAL_CURRENCY: 'LocalCurrency',
    BASE_CURRENCY: 'BaseCurrency',
    EXCHANGE_RATE: 'ExchangeRate',
    COUNTRY: 'Country',
  },
  AS_DETAILS: {
    ...EVENT_INFO,
    ...ARTIST_SETTLEMENT,
    ...AEG_SETTLEMENT,
    ...COMP_TICKET_SUMMARY,
    ...DROP_COUNT_SUMMARY,
  },
  FAR_DETAILS: {
    ...EVENT_INFO,
    ...ARTIST_SETTLEMENT,
    ...AEG_SETTLEMENT,
    ANCILLARY_INCOME_TO_BASE: 'AncillaryIncomeToBase',
    TOTAL_ANCILLARIES: 'TotalAncillaries',
    TOTAL_ANCILLARIES_TO_BASE: 'TotalAncillariesToBase',
    USE_FAR_BOX_OFFICE: 'ActivateFARBoxOffice',
  },
  NOS_SETTLEMENT_SUMMARY: { // sheet name = 'Show Summary'
    EARNINGS_SUMMARY: {
      GROSS: 'Gross',
      GROSS_BASE: 'GrossToBase',
      PRE_TAX_ADJ: 'PreTaxAdjustment',
      PRE_TAX_ADJ_BASE: 'PreTaxAdjustmentToBase',
      TAXES: 'Taxes',
      TAXES_BASE: 'TaxesToBase',
      POST_TAX_ADJ: 'PostTaxAdjustment',
      POST_TAX_ADJ_BASE: 'PostTaxAdjustmentToBase',
      NET_GROSS: 'NetGross',
      NET_GROSS_BASE: 'NetGrossToBase',
    },
    SHOW_EXPENSES: {
      ADVERTISING: 'Advertising',
      ADVERTISING_BASE: 'AdvertisingToBase',
      ARTISTS_PRORATES: 'ArtistProrates',
      ARTISTS_PRORATES_BASE: 'ArtistProratesToBase',
      PRODUCTION_PRORATES: 'ProductionProrates',
      PRODUCTION_PRORATES_BASE: 'ProductionProratesToBase',
      SUPPORT: 'Support',
      SUPPORT_BASE: 'SupportToBase',
      RENT: 'Rent',
      RENT_BASE: 'RentToBase',
      STAGEHANDS: 'Stagehands',
      STAGEHANDS_BASE: 'StagehandsToBase',
      CATERING: 'Catering',
      CATERING_BASE: 'CateringToBase',
      STAFFING: 'Staffing',
      STAFFING_BASE: 'StaffingToBase',
      VENUE: 'Venue',
      VENUE_BASE: 'VenueToBase',
      PRODUCTION: 'Production',
      PRODUCTION_BASE: 'ProductionToBase',
      TRANSPORT: 'Transport',
      TRANSPORT_BASE: 'TransportToBase',
      OTHER: 'ShowExpensesOther',
      OTHER_BASE: 'ShowExpensesOtherToBase',
      VARIABLE: 'Variable',
      VARIABLE_BASE: 'VariableToBase',
      TOTAL_EXPENSES: 'TotalExpenses',
      TOTAL_EXPENSES_BASE: 'TotalExpensesToBase',
    },
    ADJUSTMENTS: {
      NET_SHOW_RECEIPTS: 'NetShowReceipts',
      NET_SHOW_RECEIPTS_BASE: 'NetShowReceiptsToBase',
    },
    ARTIST_SETTLEMENT: {
      GUARANTEE: 'Guarantee',
      GUARANTEE_BASE: 'GuaranteeToBase',
      PCT_OF_NET_POOL_EARNINGS: 'PctOfNetPoolEarnings',
      NET_POOL_EARNINGS: 'NetPoolEarnings',
      NET_POOL_EARNINGS_BASE: 'NetPoolEarningsToBase',
      ARTIST_ADJUSTMENT: 'ArtistAdjustment',
      ARTIST_ADJUSTMENT_BASE: 'ArtistAdjustmentToBase',
      ARTIST_EARNINGS: 'ArtistEarnings',
      ARTIST_EARNINGS_BASE: 'ArtistEarningsToBase',
      DEPOSITS: 'Deposits',
      DEPOSITS_BASE: 'DepositsToBase',
      ARTIST_TICKETS: 'ArtistTickets',
      ARTIST_TICKETS_BASE: 'ArtistTicketsToBase',
      CASH_ADVANCES: 'CashAdvances',
      CASH_ADVANCES_BASE: 'CashAdvancesToBase',
      BAND_REIMBURSEMENTS: 'BandReimbursements',
      BAND_REIMBURSEMENTS_BASE: 'BandReimbursementsToBase',
      AS_SUM_OTHER: 'Other',
      AS_SUM_OTHER_BASE: 'OtherToBase',
      TOTAL_DUE_ARTIST: 'TotalDueArtist',
      TOTAL_DUE_ARTIST_BASE: 'TotalDueArtistToBase',
    },
    BASE_CURRENCY_DECIMAL: 'BaseCurrencyShowSummaryBoxOfficeDecimal',
    LOCAL_CURRENCY_DECIMAL: 'LocalCurrencyShowSummaryBoxOfficeDecimal',
    BASE_CURRENCY_ROUNDED: 'BaseCurrencyShowSummarySummaryRounded',
    LOCAL_CURRENCY_ROUNDED: 'LocalCurrencyShowSummarySummaryRounded',
  },
  ARTIST_SETTLEMENT: {
    BASE_CURRENCY_DECIMAL: [
      'BaseCurrencyArtistSettlementBoxOfficeDecimal',
      'BaseCurrencyArtistSettlementShowEarningsDecimal',
    ],
    BASE_CURRENCY_ROUNDED: [
      'BaseCurrencyArtistSettlementExpenseBudgetRounded',
      'BaseCurrencyArtistSettlementExpenseBreakdownRounded',
      'BaseCurrencyArtistSettlementItemizedChargebacksRounded',
    ],
    LOCAL_CURRENCY_DECIMAL: [
      'LocalCurrencyArtistSettlementBoxOfficeDecimal',
      'LocalCurrencyArtistSettlementShowEarningsDecimal',
    ],
    LOCAL_CURRENCY_ROUNDED: [
      'LocalCurrencyArtistSettlementExpenseBudgetRounded',
      'LocalCurrencyArtistSettlementExpenseBreakdownRounded',
      'LocalCurrencyArtistSettlementItemizedChargebacksRounded',
    ],
    LOCAL_CURRENCY_VARIABLE_COSTS_RATES: 'LocalCurrencyASVariableCostsRatesCenteredDecimal',
  },
  AS_BOX_OFFICE: {
    LOCAL_CURRENCY_DECIMAL: [
      'LocalCurrencyASBoxOfficeShow1Decimal',
      'LocalCurrencyASBoxOfficeShow2Decimal',
      'LocalCurrencyASBoxOfficeShow3Decimal',
      'LocalCurrencyASBoxOfficeShow4Decimal',
      'LocalCurrencyASBoxOfficeShow5Decimal',
      'LocalCurrencyASBoxOfficeShow6Decimal',
      'LocalCurrencyASBoxOfficeShow7Decimal',
      'LocalCurrencyASBoxOfficeShow8Decimal',
      'LocalCurrencyASBoxOfficeShow9Decimal',
      'LocalCurrencyASBoxOfficeShow10Decimal',
      'LocalCurrencyASBoxOfficeShow11Decimal',
      'LocalCurrencyASBoxOfficeShow12Decimal',
      'LocalCurrencyASBoxOfficeShow13Decimal',
      'LocalCurrencyASBoxOfficeShow14Decimal',
      'LocalCurrencyASBoxOfficeShow15Decimal',
      'LocalCurrencyASBoxOfficeShow16Decimal',
      'LocalCurrencyASBoxOfficeShow17Decimal',
      'LocalCurrencyASBoxOfficeShow18Decimal',
    ],
  },
  CATERING: {
    LOCAL_CURRENCY_ROUNDED: 'LocalCurrencyStadiumCateringRounded',
  },
  CO_PRO_SETTLEMENT: {
    BASE_CURRENCY_ROUNDED: 'BaseCurrencyCoProSettlementRounded',
    LOCAL_CURRENCY_ROUNDED: 'LocalCurrencyCoProSettlementRounded',
  },
  FAR_BOX_OFFICE: {
    LOCAL_CURRENCY_DECIMAL: [
      'LocalCurrencyFARBoxOfficeShow1Decimal',
      'LocalCurrencyFARBoxOfficeShow2Decimal',
      'LocalCurrencyFARBoxOfficeShow3Decimal',
      'LocalCurrencyFARBoxOfficeShow4Decimal',
      'LocalCurrencyFARBoxOfficeShow5Decimal',
      'LocalCurrencyFARBoxOfficeShow6Decimal',
      'LocalCurrencyFARBoxOfficeShow7Decimal',
      'LocalCurrencyFARBoxOfficeShow8Decimal',
      'LocalCurrencyFARBoxOfficeShow9Decimal',
      'LocalCurrencyFARBoxOfficeShow10Decimal',
      'LocalCurrencyFARBoxOfficeShow11Decimal',
      'LocalCurrencyFARBoxOfficeShow12Decimal',
      'LocalCurrencyFARBoxOfficeShow13Decimal',
      'LocalCurrencyFARBoxOfficeShow14Decimal',
      'LocalCurrencyFARBoxOfficeShow15Decimal',
      'LocalCurrencyFARBoxOfficeShow16Decimal',
      'LocalCurrencyFARBoxOfficeShow17Decimal',
      'LocalCurrencyFARBoxOfficeShow18Decimal',
    ],
  },
  FAR_SETTLEMENT: { // sheet name: 'Final Audit Report'
    BASE_CURRENCY_DECIMAL: [
      'BaseCurrencyFARBoxOfficeDecimal',
      'BaseCurrencyFARShowEarningsDecimal',
    ],
    BASE_CURRENCY_ROUNDED: [
      'BaseCurrencyFARExpenseBudgetRounded',
      'BaseCurrencyFARExpenseBreakdownRounded',
      'BaseCurrencyFARItemizedChargebacksRounded',
    ],
    LOCAL_CURRENCY_DECIMAL: [
      'LocalCurrencyFARBoxOfficeDecimal',
      'LocalCurrencyFARShowEarningsDecimal',
    ],
    LOCAL_CURRENCY_ROUNDED: [
      'LocalCurrencyFARExpenseBudgetRounded',
      'LocalCurrencyFARExpenseBreakdownRounded',
      'LocalCurrencyFARItemizedChargebacksRounded',
    ],
    LOCAL_CURRENCY_VARIABLE_COSTS_RATES: 'LocalCurrencyFARVariableCostsRatesCenteredDecimal',
  },
  OTHER: {
    LOCAL_CURRENCY_ROUNDED: 'LocalCurrencyStadiumOtherRounded',
  },
  PREMIUM: {
    BASE_CURRENCY_ROUNDED: [
      'BaseCurrencyPremiumShowTotalRounded',
      'BaseCurrencyPremiumShow1Rounded',
      'BaseCurrencyPremiumShow2Rounded',
      'BaseCurrencyPremiumShow3Rounded',
      'BaseCurrencyPremiumShow4Rounded',
      'BaseCurrencyPremiumShow5Rounded',
      'BaseCurrencyPremiumShow6Rounded',
      'BaseCurrencyPremiumShow7Rounded',
      'BaseCurrencyPremiumShow8Rounded',
    ],
    LOCAL_CURRENCY_ROUNDED: [
      'LocalCurrencyPremiumShowTotalRounded',
      'LocalCurrencyPremiumShow1Rounded',
      'LocalCurrencyPremiumShow2Rounded',
      'LocalCurrencyPremiumShow3Rounded',
      'LocalCurrencyPremiumShow4Rounded',
      'LocalCurrencyPremiumShow5Rounded',
      'LocalCurrencyPremiumShow6Rounded',
      'LocalCurrencyPremiumShow7Rounded',
      'LocalCurrencyPremiumShow8Rounded',
    ],
  },
  PRODUCTION: {
    LOCAL_CURRENCY_ROUNDED: 'LocalCurrencyStadiumProductionRounded',
  },
  RENT: {
    LOCAL_CURRENCY_ROUNDED: 'LocalCurrencyStadiumRentRounded',
  },
  SETTLEMENT_EMAIL_TEMPLATE: {
    BASE_CURRENCY_ROUNDED: 'BaseCurrencyEmailTemplateRounded',
  },
  STAFFING: {
    LOCAL_CURRENCY_ROUNDED: 'LocalCurrencyStadiumStaffingRounded',
  },
  STAGEHANDS: {
    LOCAL_CURRENCY_ROUNDED: 'LocalCurrencyStadiumStagehandsRounded',
  },
  SUPPORT: {
    LOCAL_CURRENCY_ROUNDED: 'LocalCurrencyStadiumSupportRounded',
  },
  SUPPORT_SETTLEMENT: {
    BASE_CURRENCY_ROUNDED: 'BaseCurrencySupportSettlementRounded',
    LOCAL_CURRENCY_ROUNDED: 'LocalCurrencySupportSettlementRounded',
  },
  TRANSPORT: {
    LOCAL_CURRENCY_ROUNDED: 'LocalCurrencyStadiumTransportRounded',
  },
  VENUE: {
    LOCAL_CURRENCY_ROUNDED: 'LocalCurrencyStadiumVenueRounded',
  },
  WH_TAXES: {
    LOCAL_CURRENCY_DECIMAL: [
      'LocalCurrencyWHTaxesHeadlinerDecimal',
      'LocalCurrencyWHTaxesSupport1Decimal',
      'LocalCurrencyWHTaxesSupport2Decimal',
      'LocalCurrencyWHTaxesSupport3Decimal',
      'LocalCurrencyWHTaxesSupport4Decimal',
      'LocalCurrencyWHTaxesSupport5Decimal',
      'LocalCurrencyWHTaxesSupport6Decimal',
      'LocalCurrencyWHTaxesSupport7Decimal',
      'LocalCurrencyWHTaxesSupport8Decimal',
      'LocalCurrencyWHTaxesSupport9Decimal',
    ],
    SUBLEDGER: 'Subledger',
  },
  FIXED_COSTS_TOTALS: [
    { categoryId: 'Advertising', names: { totalBudgetToBaseName: 'TotalBudgetAdvertisingActualToBase' } },
    { categoryId: 'Artist Prorates', names: { totalBudgetToBaseName: 'TotalBudgetArtistProratesActualToBase' } },
    { categoryId: 'Catering', names: { totalBudgetToBaseName: 'TotalBudgetCateringActualToBase' } },
    { categoryId: 'Other Costs', names: { totalBudgetToBaseName: 'TotalBudgetOtherCostsActualToBase' } },
    { categoryId: 'Production', names: { totalBudgetToBaseName: 'TotalBudgetProductionActualToBase' } },
    { categoryId: 'Production Prorates 1', names: { totalBudgetToBaseName: 'TotalBudgetProductionProratesActualToBase' } },
    { categoryId: 'Rent', names: { totalBudgetToBaseName: 'TotalBudgetRentActualToBase' } },
    { categoryId: 'Staffing', names: { totalBudgetToBaseName: 'TotalBudgetStaffingActualToBase' } },
    { categoryId: 'Stagehands', names: { totalBudgetToBaseName: 'TotalBudgetStagehandsActualToBase' } },
    { categoryId: 'Support', names: { totalBudgetToBaseName: 'TotalBudgetSupportActualToBase' } },
    { categoryId: 'Band Transport', names: { totalBudgetToBaseName: 'TotalBudgetTransportActualToBase' } },
    { categoryId: 'Venue', names: { totalBudgetToBaseName: 'TotalBudgetVenueActualToBase' } },
  ],
  NET_TO_POOL: {
    PROJECTED: 'EmailProjectedNetToPool',
    ACTUAL: 'EmailActualNetToPool',
    VARIANCE: 'EmailVariance',
  },
};

export const FEE_TABLE_NAME_KEY = '{FEE_TABLE_NAME_KEY}';
export const PRE_TAX_TABLE_NAME_KEY = '{PRE_TAX_TABLE_NAME_KEY}';
export const TAX_FEE_FORMULAS = {
  PRE_TAX_TOTAL:
    '=@ASBoxOfficePreTaxTotalFormula([@[formula]],[@amount],{FEE_TABLE_NAME_KEY}[[#Totals],[grossPotential]],{FEE_TABLE_NAME_KEY}[[#Totals],[sellableCapacity]])',
  TAX_TOTAL:
    '=@ASBoxOfficeTaxTotalFormula([@[formula]],[@amount],{FEE_TABLE_NAME_KEY}[[#Totals],[grossPotential]]+{PRE_TAX_TABLE_NAME_KEY}[[#Totals],[total]],{FEE_TABLE_NAME_KEY}[[#Totals],[sellableCapacity]])',
  POST_TAX_TOTAL: '=IFERROR([@amount], 0)',
};

/**
 * Prefixes for expenses tables in the NOS workbook
 */
export const EXPENSES_BUDGET_TABLE_NAME_KEY = '{EXPENSES_BUDGET_NAME_KEY}';
export const EXPENSES_BREAKDOWN_TABLE_NAME_KEY = '{EXPENSES_BREAKDOWN_NAME_KEY}';
export const EXPENSES_FORMULAS = {
  NOTES:
    '=LET(note, INDEX({EXPENSES_BREAKDOWN_NAME_KEY}[notes],  TableRowIndex({EXPENSES_BUDGET_NAME_KEY}[#Totals],[Notes])), IF(note="", "", note))',
  ACTUAL: '=INDEX({EXPENSES_BREAKDOWN_NAME_KEY}[total], TableRowIndex({EXPENSES_BUDGET_NAME_KEY}[#Totals],[Expenses]))',
  DIFFERENCE: '=[@Budget]-[@Actual]',
  ACTUAL_BASE: '=IFERROR([@Actual]/ExchangeRate, 0)',
  BREAKDOWN_TOTAL: '=SUM([@venue],[@partner],[@aegRoad],[@aegCash],[@aegOffice],[@artist])',
  BREAKDOWN_TOTAL_TO_BASE: '=IFERROR([@total]/ExchangeRate, 0)',
};

export const VARIABLE_COSTS_FORMULAS = {
  NOTES: '=LET(note, INDEX(AS_VariableCosts_Breakdown[rate],  TableRowIndex(AS_VariableCosts_Budget[#Totals],[Notes])), IF(note="", "", note))',
};

const mapCategoryName = (tableName: string): string => {
  switch (tableName) {
    case 'AS_Advertising_Breakdown':
      return 'Advertising';
    case 'AS_ArtistProrate_Breakdown':
      return 'Artist Prorates';
    case 'AS_ProdProrate_Breakdown':
      return 'Production Prorates';
    case 'AS_Support_Breakdown':
      return 'Support';
    case 'AS_Rent_Breakdown':
      return 'Rent';
    case 'AS_Stagehands_Breakdown':
      return 'Stagehands';
    case 'AS_Catering_Breakdown':
      return 'Catering';
    case 'AS_Staffing_Breakdown':
      return 'Staffing';
    case 'AS_Venue_Breakdown':
      return 'Venue';
    case 'AS_Production_Breakdown':
      return 'Production';
    case 'AS_Transport_Breakdown':
      return 'Transport';
    case 'AS_OtherCosts_Breakdown':
      return 'Other Costs';
    default:
      return undefined;
  }
};

export const getAegRoadFormula = (tableName: string) => `=RoadSum("${mapCategoryName(tableName)}", [@name], "far")`;

/**
 * Prefixes for tables in the NOS workbook
 */
export const NOS_TABLE_PREFIXES = {
  ARTIST_SETTLEMENT: 'AS',
  FAR_SETTLEMENT: 'FAR',
};
