// save state for auto complete idea 1

import {
  ArrayPath,
  Control,
  FieldValues,
  UseFormGetValues,
  useController,
  useFieldArray,
} from 'react-hook-form';
import { AddElementLinkContainer, AddSubjectTypography } from '@components';
import { UserModel } from '@types';
import AddIcon from '@mui/icons-material/Add';
import Radio from '@mui/material/Radio';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { kebabCase } from '@utils/stringHelpers';
import { AutocompleteInput } from '../../shared/Form/AutocompleteInput';
import { InputContainer, InputLabel } from '../../shared/Form/Form.styled';
import {
  CloseIconWithDisable,
  ColoredTableRow,
  InputCell,
  PrimaryRadioCell,
  RadioContainer,
  RemoveButtonCell,
} from './AutocompleteTableArrayWithPrimary.styled';
import { ModifyTeamMembersFormFields } from '../ModifyTeamMembersForm.models';

type AutocompleteTableArrayProps<
  TFieldValues extends FieldValues = ModifyTeamMembersFormFields,
  TContext = any,
> = {
  subjectName: string;
  fieldName: ArrayPath<Pick<ModifyTeamMembersFormFields, 'buyers' | 'operators'>>;
  fieldNameRadio: 'primaryBuyerId' | 'primaryOperatorId';
  placeholderText: string;
  control: Control<TFieldValues, TContext>;
  options: Array<UserModel>;
  filterOut?: Array<string>;
  onInputChange: React.Dispatch<React.SetStateAction<string>>;
  isRequired?: boolean;
  currentUserId?: string;
  canUserRemoveSelf?: boolean;
  formGetValues: UseFormGetValues<ModifyTeamMembersFormFields>;
  onSelect: (value: string) => void;
  radioChange: (value: `operators.${number}` | `buyers.${number}`) => void;
  areOptionsLoading?: boolean;
};

// eslint-disable-next-line max-lines-per-function
export const AutocompleteTableArrayWithPrimary = ({
  subjectName,
  fieldName,
  fieldNameRadio,
  placeholderText,
  control,
  options,
  filterOut,
  onInputChange,
  isRequired,
  currentUserId,
  canUserRemoveSelf,
  formGetValues,
  onSelect,
  radioChange,
  areOptionsLoading,
}: AutocompleteTableArrayProps) => {
  const {
    append, remove,
  } = useFieldArray({
    name: fieldName,
    control,
    rules: { minLength: 1 },
  });

  useController<
  ModifyTeamMembersFormFields,
  'primaryOperatorId' | 'primaryBuyerId'
  >({
    name: fieldNameRadio,
    control,
    rules: {
      required: isRequired,
    },
  });

  const list = formGetValues(fieldName) ?? [];

  const handleRadioChecked = (item: UserModel): boolean => {
    const formRadioValue = formGetValues(fieldNameRadio);
    if (item.id === formRadioValue) {
      return true;
    }
    return false;
  };

  const userInput = (item: UserModel | null, idx: number) => {
    const validUser = item !== null && item !== undefined;
    if (validUser && item.id === currentUserId) {
      return (
        <InputContainer
          inputProps={
            {
              'data-testid': `input-${kebabCase(fieldName)}-${idx}`,
            }
          }
          disabled={true}
          value={item ? `${item.name} - ${item.email}` : ''}
        />
      );
    }
    return (
      <AutocompleteInput
        areOptionsLoading={areOptionsLoading}
        data-testid={`autocomplete-${kebabCase(subjectName)}-${idx}`}
        fieldName={`${fieldName}.${idx}`}
        control={control}
        options={options}
        dataDisplayField={{ primary: 'name', secondary: 'email' }}
        dataFilterField={'id'}
        filterOut={filterOut}
        onInputChange={onInputChange}
        // eslint-disable-next-line max-len
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
        onSelect={(value) => value?.id && onSelect(value.id.toString())}
        placeholderText={placeholderText}
        isRequired={false}
      />
    );
  };

  return (
    <div>
      <Table>
        <TableHead>
          <ColoredTableRow>
            <TableCell>
              <InputLabel
                required={isRequired}
                data-testid={`${kebabCase(fieldName)}-role-label`}
              >
                {subjectName}
              </InputLabel>
            </TableCell>
            <PrimaryRadioCell>
              <InputLabel
                data-testid={`${kebabCase(fieldName)}-primary-label`}
              >
                Primary
              </InputLabel>
            </PrimaryRadioCell>
            <RemoveButtonCell></RemoveButtonCell>
          </ColoredTableRow>
        </TableHead>
        <TableBody>
          {list.length > 0
            && list.map((item, idx) => (
              <TableRow key={(item && item.id) || idx}>
                <InputCell>
                  {userInput(item, idx)}
                </InputCell>
                <PrimaryRadioCell className="borderless">
                  <RadioContainer data-testid={`${kebabCase(fieldName)}-radio-cell-${idx}`}>
                    <Radio
                      inputProps={
                        {
                          'data-testid': `radio-primary-${kebabCase(fieldName)}-${idx}`,
                        } as React.InputHTMLAttributes<HTMLInputElement>
                      }
                      checked={item === null ? false : handleRadioChecked(item)}
                      disabled={item !== null && list.length <= 1}
                      onChange={() => item && radioChange(`${fieldName}.${idx}`)}
                      value={`${fieldName}.${idx}`}
                    />
                  </RadioContainer>
                </PrimaryRadioCell>
                <RemoveButtonCell className="borderless">
                  <CloseIconWithDisable
                    className={canUserRemoveSelf || currentUserId !== item?.id ? '' : 'disabled'}
                    onClick={() => remove(idx)}
                    data-testid={`close-autocomplete-${fieldName}-${idx}`}
                  />
                </RemoveButtonCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
      <AddElementLinkContainer
        data-testid={`autocomplete-table-link-container-${fieldName}`}
      >
        <AddIcon />
        <AddSubjectTypography
          variant="body2"
          data-testid={`autocomplete-array-link-${fieldName}`}
          onClick={() => append(null)}
        >
          Add {subjectName}
        </AddSubjectTypography>
      </AddElementLinkContainer>
    </div>
  );
};
