/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable max-lines-per-function */
import {
  useContext, useEffect, useMemo, useRef, useState,
} from 'react';
import { useParams } from 'react-router-dom';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import { BreadcrumbDispatchContext, NotificationDispatchContext, DialogDispatchContext } from '@providers';
import { PageDetailLayout, WrapperType } from '@layouts/PageDetailLayout';
import Edit from '@mui/icons-material/Edit';
import {
  AegDrawer,
  BundleFinancesTab,
  BundleOfferForm,
  BundleProjectionTab,
  BundleRoutingTab,
  ConfirmActionModal,
  ConfirmationEmailForm,
  ConfirmationEmailFormFields,
  DetailsPageTitle,
  DropdownButton,
  DUPLICATE_BUNDLE_OFFER_MODAL,
  EditBundleForm,
  FormErrorComponent,
  FormStylingMode,
  GenericModal,
  ModifyBundleTeamMembers,
  OfferHeader,
  OneOffEventsModal,
  PDFDownloadButton,
  ProtectedComponent,
  SnackbarType,
} from '@components';
import {
  Bundle,
  BundlesConfirmationEmailInput,
  BundleEvent,
  BundleOffer,
  CompanyPermission,
  CreateBundleOfferInput,
  ModifyBundleInput,
  ModifyBundleOfferInput,
} from '@gql/types/graphql';
import {
  AegResourceTypes, OfferEvent, OfferStatus, UserPermissions, LineOfBusiness,
} from '@types';
import { getNetworkErrorInfo } from '@utils/errorHelpers';
import { useQuery, useMutation } from '@apollo/client';
import { GET_BUNDLE, GET_BUNDLE_OFFER_FINANCES } from '@gql/queries/bundles';
import {
  ADD_EVENTS_TO_BUNDLE,
  CONFIRM_BUNDLE,
  CREATE_BUNDLE_OFFER,
  DELETE_BUNDLE_OFFER,
  EDIT_BUNDLE_OFFER,
  CLONE_BUNDLE_OFFER,
  SEND_BUNDLE_CONFIRMATION_EMAIL,
  SET_PRIMARY_BUNDLE_OFFER,
  UNCONFIRM_BUNDLE,
  UPDATE_BUNDLE,
} from '@gql/mutations/bundles';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { logError } from '@services/telemetry-service';
import { useNavigateOnError } from '@hooks';
import {
  ConfirmModalSteps,
  CONFIRM_MODAL_TEXT,
  UNCONFIRM_MODAL_TEXT,
  NOTIFY_MODAL_TEXT,
  getBundleMetadata,
  getOfferActions,
  OfferActionsOptions,
  DELETE_BUNDLE_OFFER_MODAL_TEXT,
} from './BundleDetails.models';
import { TabHeaderContainer } from './BundleDetails.styled';

enum BundleDropdownOptions {
  EditBundle,
  ManageTeam,
  CreateNewBundleOffer,
}

export function BundleDetails() {
  const [oneOffEventsModal, setOneOffEventsModal] = useState(false);
  const [currentOfferIndex, setCurrentOfferIndex] = useState<number>(0);
  const [isEditBundleDrawerOpen, setIsEditBundleDrawerOpen] = useState(false);
  const [isManageTeamDrawerOpen, setIsManageTeamDrawerOpen] = useState(false);
  const [isCreateNewBundleOfferDrawerOpen, setIsCreateNewBundleOfferDrawerOpen] = useState(false);
  const [isEditBundleOfferDrawerOpen, setIsEditBundleOfferDrawerOpen] = useState(false);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [modalStep, setModalStep] = useState<ConfirmModalSteps>(ConfirmModalSteps.confirm);
  const [sendConfirmationEmailModalOpen, setConfirmationEmailModalOpen] = useState(false);
  const [isFormErrorOpen, setIsFormErrorOpen] = useState(false);
  const appInsights = useAppInsightsContext();
  const isInitialLoadRef = useRef(true);

  const setBreadcrumbs = useContext(BreadcrumbDispatchContext);
  const setNotification = useContext(NotificationDispatchContext);
  const setDialog = useContext(DialogDispatchContext);

  const bundleId = useParams().id ?? '';

  const navigateOnError = useNavigateOnError();

  const {
    data: bundleDetails,
    loading: loadingBundleDetails,
    client: prefetchClient,
  } = useQuery(GET_BUNDLE, {
    variables: { bundleId },
    errorPolicy: 'all',
    onCompleted: (data) => {
      const bundle = data.bundle as Bundle;
      if (!bundle) return;

      const bundleName = bundle.name;
      if (bundleName) {
        document.title = `${bundleName} - Modern Elvis`;
      }

      const breadcrumbs = [
        {
          text: 'Bundles',
          href: '/bundles/',
        },
        {
          text: bundle.name ?? '',
          href: `/bundles/${bundleId ?? ''}`,
        },
      ];
      setBreadcrumbs(breadcrumbs);

      if (isInitialLoadRef.current) {
        setCurrentOfferIndex(bundle.primaryOfferId
          ? bundle?.offers?.findIndex((offer) => offer.id === bundle.primaryOfferId) || 0
          : 0);
        isInitialLoadRef.current = false;
      }
    },
    onError: (error) => {
      navigateOnError(error);
    },
  });

  const currentBundleOffer = bundleDetails?.bundle?.offers?.[currentOfferIndex];
  const bundleEvents = currentBundleOffer?.offerEventRouting as BundleEvent[];
  const currentEvents = bundleEvents?.map(
    (offerEvent) => ({
      id: offerEvent.event.id as string,
      isActive: offerEvent.isActive,
    }),
  ) ?? [];

  useEffect(() => {
    const bundleOfferId = currentBundleOffer?.id;
    if (!bundleOfferId) return;

    // Pre-fetch data for the Finances page
    void prefetchClient.query({
      query: GET_BUNDLE_OFFER_FINANCES,
      variables: { bundleId, bundleOfferId },
    });
  }, [currentBundleOffer?.id]);

  const metadataItems = useMemo(() => {
    if (!bundleDetails) return [];
    return getBundleMetadata(bundleDetails);
  }, [bundleDetails]);

  const [updateBundle, { loading: savingBundle }] = useMutation(UPDATE_BUNDLE, {
    onCompleted: () => setIsEditBundleDrawerOpen(false),
  });

  const [createBundleOffer, { loading: savingCreateBundleOffer, error: createBundleOfferError }] = useMutation(
    CREATE_BUNDLE_OFFER,
    {
      onCompleted: (data) => {
        const bundle = data.createBundleOffer as Bundle;
        setCurrentOfferIndex(bundle?.offers?.length ? bundle.offers.length - 1 : 0);
        setIsCreateNewBundleOfferDrawerOpen(false);
        setNotification({
          text: 'New bundle offer successfully created.',
          type: SnackbarType.SUCCESS,
          duration: 6000,
        });
      },
      onError: () => {
        setNotification({
          text: 'Create new bundle offer failed. Please contact Booking Support.',
          type: SnackbarType.ERROR,
          duration: 6000,
        });
      },
    },
  );

  const [editBundleOffer, { loading: savingEditBundleOffer, error: editBundleOfferError }] = useMutation(
    EDIT_BUNDLE_OFFER,
    {
      onCompleted: () => {
        setIsEditBundleOfferDrawerOpen(false);
        setNotification({
          text: 'Offer details have been successfully saved.',
          type: SnackbarType.SUCCESS,
          duration: 6000,
        });
      },
      onError: () => {
        setNotification({
          text: 'Error: Offer details could not be saved.',
          type: SnackbarType.ERROR,
          duration: 6000,
        });
      },
    },
  );

  const [cloneBundleOffer] = useMutation(CLONE_BUNDLE_OFFER, {
    onCompleted(data) {
      const bundleOffers = data.cloneBundleOffer?.offers;
      if (!bundleOffers) return;
      const latestOfferIndex = bundleOffers.reduce((latestIndex, offer, index) => {
        if (new Date(offer.createdDate) > new Date(bundleOffers[latestIndex].createdDate)) {
          return index;
        }
        return latestIndex;
      }, 0);
      setCurrentOfferIndex(latestOfferIndex);
      setDialog(null);
      setNotification({
        type: SnackbarType.SUCCESS,
        text: `${currentBundleOffer?.name ?? ''} has been successfully duplicated within this bundle.`,
        duration: 6000,
      });
    },
    onError: () => {
      setNotification({
        text: `Error: ${currentBundleOffer?.name ?? ''} could not be duplicated.`,
        type: SnackbarType.ERROR,
        duration: 6000,
      });
      setDialog(null);
    },
  });

  const [confirmBundle, { loading: confirmingBundle, error: confirmError }] = useMutation(CONFIRM_BUNDLE, {
    onCompleted: () => {
      setModalStep(ConfirmModalSteps.notify);
    },
  });

  const [unconfirmBundle, { loading: unconfirmingBundle, error: unconfirmError }] = useMutation(UNCONFIRM_BUNDLE, {
    onCompleted: () => {
      setNotification({
        text: `${currentBundleOffer?.name} has been successfully unconfirmed.`,
        type: SnackbarType.SUCCESS,
        duration: 6000,
      });
      setConfirmModalOpen(false);
    },
    onError: () => {
      setNotification({
        text: 'Unconfirming the offer failed. Please contact support.',
        type: SnackbarType.ERROR,
        duration: 6000,
      });
    },
  });

  const [deleteBundleOffer, { loading: deletingBundleOffer, error: deleteBundleOfferError }] = useMutation(
    DELETE_BUNDLE_OFFER,
    {
      onCompleted: () => {
        setNotification({
          text: `${currentBundleOffer?.name} has been successfully deleted from this bundle.`,
          type: SnackbarType.SUCCESS,
          duration: 6000,
        });
        setCurrentOfferIndex(0);
        setConfirmModalOpen(false);
      },
      onError: () => {
        setNotification({
          text: `Error: ${currentBundleOffer?.name} could not be deleted from this bundle.`,
          type: SnackbarType.ERROR,
          duration: 6000,
        });
      },
    },
  );

  const [sendConfirmationEmail,
    { loading: sendingEmail, error: emailError },
  ] = useMutation(SEND_BUNDLE_CONFIRMATION_EMAIL, {
    onCompleted: () => {
      setConfirmationEmailModalOpen(false);
      setNotification({
        text: 'Confirmation email has been successfully sent.',
        type: SnackbarType.SUCCESS,
        duration: 6000,
      });
    },
    onError: (error) => {
      setIsFormErrorOpen(true);
      logError(appInsights, 'Bundle Confirmation Email submit error', error);
    },
  });

  const [addEventsToBundle] = useMutation(ADD_EVENTS_TO_BUNDLE, {
    errorPolicy: 'all',
    onCompleted: () => {
      setOneOffEventsModal(false);
      setNotification({
        text: 'Bundle One-Offs have been successfully saved. Routing is Updated',
        type: SnackbarType.SUCCESS,
        duration: 6000,
      });
    },
    onError: () => {
      setNotification({
        text: 'Bundle One-Offs save failed. Please contact Booking Support.',
        type: SnackbarType.ERROR,
        duration: 6000,
      });
    },
  });

  const [setAsPrimary, { loading: setAsPrimaryLoading }] = useMutation(SET_PRIMARY_BUNDLE_OFFER, {
    onCompleted: () => {
      setNotification({
        text: `${currentBundleOffer?.name} has been set as primary for this bundle.`,
        type: SnackbarType.SUCCESS,
        duration: 6000,
      });
      setConfirmModalOpen(false);
    },
    onError: () => {
      setNotification({
        text: `Error: ${currentBundleOffer?.name} could not be set as primary.`,
        type: SnackbarType.ERROR,
        duration: 6000,
      });
    },
  });

  const handleUpdateBundle = async (bundle: ModifyBundleInput) => {
    await updateBundle({ variables: { bundle } });
  };

  const handleCreateNewBundleOffer = async (bundleOffer: CreateBundleOfferInput) => {
    await createBundleOffer({ variables: { bundleId, bundleOffer } });
  };

  const handleEditBundleOffer = async (bundleOffer: Omit<ModifyBundleOfferInput, 'id'>) => {
    if (!currentBundleOffer) {
      return;
    }
    await editBundleOffer({
      variables: {
        bundleId,
        bundleOffer: {
          ...bundleOffer,
          id: currentBundleOffer.id,
        },
      },
    });
  };

  const handleConfirmBundle = async () => {
    await confirmBundle({
      variables: {
        bundleId,
        bundleOfferId: currentBundleOffer?.id as string,
      },
    });
  };

  const handleUnconfirmBundle = async () => {
    await unconfirmBundle({
      variables: {
        bundleId,
        bundleOfferId: currentBundleOffer?.id as string,
      },
    });
  };

  const handleSetAsPrimary = async () => {
    await setAsPrimary({
      variables: {
        bundleId,
        bundleOfferId: currentBundleOffer?.id as string,
      },
    });
  };

  const handleSendConfirmationEmail = async (formData: ConfirmationEmailFormFields) => {
    const { toRecipients, ccRecipients, comments } = formData;

    const bundlesConfirmationEmailData: BundlesConfirmationEmailInput = {
      bundleId,
      bundleOfferId: currentBundleOffer?.id as string,
      toEmails: toRecipients.map((r) => r.email),
      ccEmails: ccRecipients.map((r) => r.email),
    };

    if (comments) { bundlesConfirmationEmailData.comments = comments; }
    await sendConfirmationEmail({ variables: { bundlesConfirmationEmailData } });
  };

  const handleSendNotification = () => {
    setConfirmModalOpen(false);
    setConfirmationEmailModalOpen(true);
  };

  const handleDeleteBundleOffer = async () => {
    await deleteBundleOffer({
      variables: {
        bundleId,
        bundleOfferId: currentBundleOffer?.id as string,
      },
    });
  };

  const handleManageTeamCompleted = () => setIsManageTeamDrawerOpen(false);

  const handleSaveOneOffEvents = async (bundleEventsArr: OfferEvent[]) => {
    await addEventsToBundle({
      variables: {
        bundleId,
        bundleOfferId: currentBundleOffer?.id as string,
        bundleEvents: bundleEventsArr,
      },
    });
  };

  const handleDuplicateBundleOffer = async () => {
    if (!currentBundleOffer) {
      return;
    }

    if (currentBundleOffer?.name.length > 44) {
      setNotification({
        type: SnackbarType.ERROR,
        text: 'Duplicating the offer resulted in too many characters. Shorten the offer name and try again',
        duration: 6000,
      });
      setDialog(null);
      return;
    }

    setDialog((prevDialog) => ({
      ...(prevDialog ?? {}),
      isLoading: true,
    }));

    await cloneBundleOffer({
      variables: {
        bundleId,
        bundleOfferId: currentBundleOffer.id,
      },
    });
  };

  const handleDuplicateBundleOfferModal = () => {
    const {
      TITLES,
      DESCRIPTION,
      SUBMIT,
      CANCEL,
    } = DUPLICATE_BUNDLE_OFFER_MODAL(currentBundleOffer?.name ?? '');
    setDialog({
      titles: TITLES,
      description: DESCRIPTION,
      submit: {
        text: SUBMIT,
        action: () => {
          void handleDuplicateBundleOffer();
        },
      },
      cancel: {
        text: CANCEL,
        action: () => setDialog(null),
      },
      buttonsPosition: 'right',
      includeCloseButton: true,
      rightSubmit: true,
    });
  };

  const handleDropdownMenu = (value: BundleDropdownOptions) => {
    switch (value) {
      case BundleDropdownOptions.EditBundle:
        setIsEditBundleDrawerOpen(true);
        break;
      case BundleDropdownOptions.ManageTeam:
        setIsManageTeamDrawerOpen(true);
        break;
      case BundleDropdownOptions.CreateNewBundleOffer:
        setIsCreateNewBundleOfferDrawerOpen(true);
        break;
      default:
        break;
    }
  };

  const handleOfferActionsDropdown = (value: OfferActionsOptions) => {
    switch (value) {
      case OfferActionsOptions.ConfirmOffer:
        setModalStep(ConfirmModalSteps.confirm);
        setConfirmModalOpen(true);
        break;
      case OfferActionsOptions.EditOffer:
        setIsEditBundleOfferDrawerOpen(true);
        break;
      case OfferActionsOptions.UnconfirmOffer:
        setModalStep(ConfirmModalSteps.unconfirm);
        setConfirmModalOpen(true);
        break;
      case OfferActionsOptions.SendConfirmationEmail:
        setConfirmationEmailModalOpen(true);
        break;
      case OfferActionsOptions.DeleteOffer:
        setModalStep(ConfirmModalSteps.deleteBundleOffer);
        setConfirmModalOpen(true);
        break;
      case OfferActionsOptions.SetAsPrimary:
        void handleSetAsPrimary();
        break;
      case OfferActionsOptions.DuplicateOffer:
        handleDuplicateBundleOfferModal();
        break;
      default:
        break;
    }
  };

  const modalContentMap = {
    [ConfirmModalSteps.confirm]: {
      title: `Are you sure you want to confirm ${currentBundleOffer?.name}?`,
      description: CONFIRM_MODAL_TEXT.bodyText,
      submitAction: handleConfirmBundle,
      loading: confirmingBundle,
    },
    [ConfirmModalSteps.unconfirm]: {
      title: 'Are you sure you want to unconfirm this offer?',
      description: UNCONFIRM_MODAL_TEXT.bodyText,
      submitAction: handleUnconfirmBundle,
      loading: unconfirmingBundle,
    },
    [ConfirmModalSteps.notify]: {
      title: `${currentBundleOffer?.name} successfully confirmed`,
      description: NOTIFY_MODAL_TEXT.bodyText,
      submitAction: handleSendNotification,
      loading: false,
    },
    [ConfirmModalSteps.deleteBundleOffer]: {
      title: 'Are you sure you want to delete this offer?',
      description: DELETE_BUNDLE_OFFER_MODAL_TEXT.bodyText,
      submitAction: handleDeleteBundleOffer,
      loading: deletingBundleOffer,
    },
  };

  const actions = (
    <ProtectedComponent
      checkPermission={{
        permission: UserPermissions.UpdateBundle,
        resourceType: AegResourceTypes.Bundle,
        resourceId: bundleId,
      }}
    >
      <DropdownButton
        label="Actions"
        onSelect={handleDropdownMenu}
        dropdownOptions={[
          {
            label: 'Edit Bundle',
            value: BundleDropdownOptions.EditBundle,
          },
          {
            label: 'Manage Team',
            value: BundleDropdownOptions.ManageTeam,
          },
          {
            label: 'Create New Offer',
            value: BundleDropdownOptions.CreateNewBundleOffer,
          },
        ]}
      ></DropdownButton>
    </ProtectedComponent>
  );

  const downloadPdfButton = (
    <ProtectedComponent
      checkPermission={{
        permission: UserPermissions.UpdateBundle,
        resourceType: AegResourceTypes.Bundle,
        resourceId: bundleId,
      }}
    >
      <div>
        <PDFDownloadButton
          bundleId={bundleId}
          offerId={currentBundleOffer?.id as string}
        />
      </div>
    </ProtectedComponent>
  );

  const allEventsInactive = bundleEvents?.every((event) => !event.isActive);

  const offerActionsButton = (
    <ProtectedComponent
      checkPermission={{
        permission: UserPermissions.UpdateBundle,
        resourceType: AegResourceTypes.Bundle,
        resourceId: bundleId,
      }}
    >
      <DropdownButton
        label="Offer Actions"
        onSelect={handleOfferActionsDropdown}
        dropdownOptions={getOfferActions(
          currentBundleOffer?.status as OfferStatus,
          bundleEvents?.length,
          allEventsInactive,
          (bundleDetails?.bundle?.primaryOfferId === currentBundleOffer?.id || setAsPrimaryLoading),
        )}
      ></DropdownButton>
    </ProtectedComponent>
  );

  const oneOffEventsModalButton = (
    <ProtectedComponent
      checkPermission={{
        permission: CompanyPermission.ModifyBundles,
        resourceType: AegResourceTypes.Company,
      }}
    >
      <Button
        variant="outlined"
        startIcon={
          loadingBundleDetails
            ? <CircularProgress size={24} color="inherit" data-testid="progress-icon" />
            : <Edit data-testid="edit-icon" />
        }
        onClick={() => setOneOffEventsModal(true)}
        data-testid="one-off-events-button"
        disabled={loadingBundleDetails}
      >
        Add / Remove One-Offs
      </Button>
    </ProtectedComponent>
  );

  const routingTabButtons = [
    downloadPdfButton,
    offerActionsButton,
  ];

  const routingTabActions = [
    <MenuItem key='mock-action-1'>
      Mock Action 1
    </MenuItem>,
    <MenuItem key='mock-action-2'>
      Mock Action 2
    </MenuItem>,
  ];

  if (loadingBundleDetails) {
    return <div>Loading...</div>;
  }

  const pageContentHeader = (
    <OfferHeader
      actions={routingTabActions}
      buttons={routingTabButtons}
      offers={(bundleDetails?.bundle?.offers ?? []) as BundleOffer[]}
      currentOfferIndex={currentOfferIndex}
      setCurrentOfferIndex={setCurrentOfferIndex}
      primaryOfferId={bundleDetails?.bundle?.primaryOfferId as string}
      lineOfBusiness={LineOfBusiness.BUNDLES}
    />
  );

  const tabs = [
    {
      name: 'Routing',
      idPrefix: 'bundles',
      pageContent: (
        <>
          <TabHeaderContainer>
            <Typography variant="h6" data-testid="tab-header-text">
              {`Local Offers (${bundleEvents?.length ?? 0})`}
            </Typography>
            {oneOffEventsModalButton}
          </TabHeaderContainer>
          <BundleRoutingTab
            bundleEvents={bundleEvents}
            bundleId={bundleId}
            bundleOfferId={currentBundleOffer?.id as string}
          />
        </>
      ),
      wrapperType: WrapperType.Paper,
    },
    {
      name: 'Finances',
      idPrefix: 'bundles',
      pageContent: (
        <BundleFinancesTab
          bundleId={bundleId}
          bundleOfferId={currentBundleOffer?.id ?? ''}
        />
      ),
      wrapperType: WrapperType.Div,
      pageContentStyle: { padding: 0, height: 'auto', overflow: 'hidden' },
    },
    {
      name: 'Projection',
      idPrefix: 'bundles',
      pageContent: (
        <BundleProjectionTab bundleId={bundleId} bundleOfferId={currentBundleOffer?.id as string} />
      ),
      wrapperType: WrapperType.Div,
      pageContentStyle: { padding: 0, height: 'auto', overflow: 'hidden' },
    },
  ];

  const {
    title, description, submitAction, loading,
  } = modalContentMap[modalStep];

  return (
    <>
      <PageDetailLayout
        pageTitle={<DetailsPageTitle title={bundleDetails?.bundle?.name ?? ''} />}
        pageTitleColSize={2.5}
        metaColSize={8.5}
        actionsColSize={1}
        metadata={metadataItems}
        actions={actions}
        wrapperType={WrapperType.Div}
        row2={pageContentHeader}
        tabs={tabs}
      />
      {oneOffEventsModal && (
        <OneOffEventsModal
          open={oneOffEventsModal}
          setOpen={setOneOffEventsModal}
          currentEvents={currentEvents}
          artistIds={bundleDetails?.bundle?.headliners?.map((headliner) => headliner.artist?.id) as string[]}
          onSaveOneOffEvents={handleSaveOneOffEvents}
        />
      )}
      <AegDrawer
        openDrawer={isEditBundleDrawerOpen}
        anchorValue="right"
        onClose={() => setIsEditBundleDrawerOpen(false)}
      >
        <EditBundleForm
          onFormSubmit={handleUpdateBundle}
          isFormSaving={savingBundle}
          bundle={bundleDetails?.bundle as Bundle}
        />
      </AegDrawer>
      <AegDrawer
        openDrawer={isManageTeamDrawerOpen}
        anchorValue="right"
        onClose={() => setIsManageTeamDrawerOpen(false)}
      >
        <ModifyBundleTeamMembers
          closeDrawer={handleManageTeamCompleted}
          bundleDetails={bundleDetails?.bundle as Bundle}
        />
      </AegDrawer>
      <AegDrawer
        openDrawer={isCreateNewBundleOfferDrawerOpen}
        anchorValue="right"
        onClose={() => setIsCreateNewBundleOfferDrawerOpen(false)}
      >
        <BundleOfferForm
          title='Create New Bundle Offer'
          subTitle='Please provide the details below to create a new offer.'
          submitText='Create'
          mutationError={createBundleOfferError}
          onFormSubmit={handleCreateNewBundleOffer}
          isFormSaving={savingCreateBundleOffer}
          defaultValues={{
            name: `Offer ${(bundleDetails?.bundle?.offers?.length ?? 0) + 1}`,
            crossed: false,
          }}
        />
      </AegDrawer>
      <AegDrawer
        openDrawer={isEditBundleOfferDrawerOpen}
        anchorValue="right"
        onClose={() => setIsEditBundleOfferDrawerOpen(false)}
      >
        <BundleOfferForm
          title='Edit Offer Details'
          subTitle='Please edit the offer details below.'
          mutationError={editBundleOfferError}
          onFormSubmit={handleEditBundleOffer}
          onFormCancel={() => setIsEditBundleOfferDrawerOpen(false)}
          isFormSaving={savingEditBundleOffer}
          defaultValues={{
            name: currentBundleOffer?.name ?? '',
            crossed: currentBundleOffer?.crossed ?? false,
          }}
        />
      </AegDrawer>
      {confirmModalOpen && (
        <GenericModal
          open
          setOpen={setConfirmModalOpen}
          title={title}
          description={description}
          mode={FormStylingMode.MINI}
          errorComponent={
            <FormErrorComponent
              isDismissable={true}
              error={confirmError || unconfirmError || deleteBundleOfferError}
              isOpen={isFormErrorOpen}
              setIsOpen={setIsFormErrorOpen}
            />
          }
        >
          <ConfirmActionModal
            submitAction={submitAction as () => void}
            mutationLoading={loading}
            handleClose={() => setConfirmModalOpen(false)}
            modalStep={modalStep}
          />
        </GenericModal>
      )}
      {sendConfirmationEmailModalOpen && (
        <GenericModal
          open
          setOpen={setConfirmationEmailModalOpen}
          mode={FormStylingMode.COMPACT}
          title={`Send Confirmation Email: ${bundleDetails?.bundle?.name}`}
          errorComponent={
            <FormErrorComponent
              isDismissable={true}
              error={emailError}
              isOpen={isFormErrorOpen}
              setIsOpen={setIsFormErrorOpen}
              errorMessage={getNetworkErrorInfo(emailError)?.message as string}
            />
          }
        >
          <ConfirmationEmailForm
            submitAction={handleSendConfirmationEmail}
            bundleMetadata={metadataItems}
            bundleDetails={bundleDetails?.bundle as Bundle}
            mutationLoading={sendingEmail}
            handleClose={() => setConfirmationEmailModalOpen(false)}
            setIsFormErrorOpen={setIsFormErrorOpen}
          />
        </GenericModal>
      )}
    </>
  );
}
