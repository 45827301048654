import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

export const SubHeader = styled(Typography)(({ theme }) => ({
  paddingTop: theme.spacing(3),
})) as typeof Typography;

export const ButtonContainer = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'end',
  alignItems: 'center',
});
