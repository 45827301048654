import { Controller, Control, FieldValues } from 'react-hook-form';
import { kebabCase } from '@utils/stringHelpers';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { InputLabel, InputLabelContainer } from './Form.styled';

interface TestInputProps extends React.HTMLAttributes<HTMLDivElement> {
  'data-testid'?: string;
}

export type RadioOptions = {
  value: string | boolean;
  label: string;
};

type RadioInputProps<TFieldValues extends FieldValues = any, TContext = any> = {
  fieldName: string;
  control: Control<TFieldValues, TContext>;
  label: string;
  options: Array<RadioOptions>;
  showInfo?: boolean;
};

export const RadioInput = ({
  fieldName,
  control,
  label,
  options,
  showInfo = false,
}: RadioInputProps) => {
  const radioOptions = options.map((option, index: number) => (
    <FormControlLabel
      value={option.value}
      key={`radio-option-${index}`}
      control={
        <Radio
          inputProps={
            {
              'data-testid': `${kebabCase(fieldName)}-${kebabCase(
                String(option.value),
              )}`,
            } as TestInputProps
          }
        />
      }
      label={option.label}
    />
  ));

  return (
    <>
      {label && (
        <>
          <InputLabelContainer>
            <InputLabel data-testid={`${kebabCase(fieldName)}-label`}>
              {label}
            </InputLabel>
            {showInfo ? <InfoOutlinedIcon /> : null}
          </InputLabelContainer>
        </>
      )}
      <Controller
        name={fieldName}
        control={control}
        render={({
          field: {
            onChange, value,
          },
        }) => (
          <RadioGroup row onChange={onChange} value={value as string}>
            {radioOptions}
          </RadioGroup>
        )}
      />
    </>
  );
};
