import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Radio from '@mui/material/Radio';
import { CustomTheme, styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

export const VenueSearchOptionsHeader = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
}));

export const ClickableTypography = styled(Typography)(({ theme }: { theme?: CustomTheme }) => ({
  color: theme?.palette.info.dark,
  cursor: 'pointer',
}));

export const DisabledText = styled(Typography)(() => ({
  opacity: 0.5,
}));

export const RadioOptionContainer = styled(Box)(({ theme }: { theme?: CustomTheme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  margin: theme?.spacing(0, 3, 0, 2.5),
}));

export const PlainTextButton = styled(Button)(({ theme }: { theme?: CustomTheme }) => ({
  background: 'none',
  border: 'none',
  color: 'black',
  display: 'inline-flex',
  alignItems: 'center',
  transitionDuration: '0s !important',
  margin: theme?.spacing(0),
  padding: theme?.spacing(0),
  fontWeight: 300,
}));

export const ModalContainer = styled(Box)(({ theme }: { theme?: CustomTheme }) => ({
  marginTop: theme?.spacing(1),
}));

export const RadioStyled = styled(Radio)(({ theme }: { theme?: CustomTheme }) => ({
  padding: theme?.spacing(0, 1),
}));
