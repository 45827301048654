import { useEffect } from 'react';
import { AegLogoBox } from '@components';
import Alert from '@mui/material/Alert';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import {
  PageContainer,
  Header,
  ContentContainer,
  WarningMessage,
} from './WarningModal.styled';

export function WarningModal() {
  useEffect(() => {
    const script = document.createElement('script');
    script.setAttribute('data-testid', 'officejs-script');
    script.src = 'https://appsforoffice.microsoft.com/lib/1/hosted/office.js';
    script.onload = () => {
      void Office.onReady(() => {
        Office.context.ui.messageParent('DialogLoaded');
      });
    };
    document.body.appendChild(script);
  }, []);

  return (
    <PageContainer data-testid="warning-page-container">
      <Header>
        <AegLogoBox />
      </Header>
      <ContentContainer>
        <Dialog
          open={true}>
          <DialogContent>
            <WarningMessage data-testid="warning-message">
              Do not interact with the workbook while data is loading
            </WarningMessage>
            <Alert severity="warning" data-testid="warning-alert-message">
              Clicking or editing the workbook may compromise the accuracy of your data.
              Please wait as we securely load your data.
            </Alert>
          </DialogContent>
        </Dialog>
      </ContentContainer>
    </PageContainer>
  );
}
