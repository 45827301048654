import { useContext, useEffect } from 'react';
import { useBeforeUnload, useBlocker as useBlockerOriginal } from 'react-router-dom';

import { AegDialogProps } from '@components';
import { DialogDispatchContext } from '@providers';

export function useBlocker(isDirty: boolean, isLoading: boolean, dialog: AegDialogProps) {
  const setDialog = useContext(DialogDispatchContext);

  // Prevent document navigations
  useBeforeUnload((e) => {
    if (isDirty) {
      e.preventDefault();
      // Included for legacy support, e.g. Chrome/Edge < 119
      e.returnValue = true;
    }
  });

  // Prevent client-side navigations within React Router application
  const blocker = useBlockerOriginal(
    ({ currentLocation, nextLocation }) =>
      isDirty
      && currentLocation.pathname !== nextLocation.pathname,
  );

  useEffect(() => {
    if (isDirty && blocker.state === 'blocked') {
      setDialog({
        ...dialog,
        isLoading,
        rightSubmit: true,
        includeCloseButton: true,
        onClose: () => {
          blocker.reset?.();
          setDialog(null);
        },
      });
    }

    return () => {
      setDialog(null);
    };
  }, [blocker, isLoading]);

  return blocker;
}
