import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Tab from '@mui/material/Tab';

export const TabBox = styled(Box)(
  () => ({
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    overflow: 'auto',
  }),
);

export const TabStyled = styled(Tab)({
  textTransform: 'capitalize',
});
