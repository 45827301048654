/* eslint-disable max-lines-per-function */
import {
  ReactNode,
  useContext, useEffect, useMemo, useState,
} from 'react';
import {
  useAddInAction, useAegModeling, useExcel, useExcelErrorHandler,
  useFeatureFlags,
} from '@hooks';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import { kebabCase } from '@utils/stringHelpers';
import {
  ProtectedComponent, AddEventToOffer, LastSyncedFooter, RoutingTab, TabPanel, KebabPopperMenu, TabStyled,
  FeatureGate,
} from '@components';
import {
  DialogDispatchContext,
  EnvStatusContext,
  ExcelErrorType,
  ModelingSearchProvider,
} from '@providers';
import { useMutation, useQuery } from '@apollo/client';
import { GET_OFFER_AND_EVENTS } from '@gql/queries/tours';
import { UPDATE_OFFER } from '@gql/mutations/tours';
import {
  AegResourceTypes, EventStatus, ManagedSheetType, OfferStatus, SnackBarMessages, UserPermissions,
} from '@types';
import { Event, ModifyOfferInput } from '@gql/types/graphql';
import { GridRowId, useGridApiRef, GridSortModel } from '@mui/x-data-grid-pro';
import {
  MANAGED_MODELING_SHEETS,
  SHOW_DETAIL_TABLE_NAME,
} from '@utils/excel/modeling/constants';
import FeatureFlags from '@utils/featureFlags';
import { GET_WORKBOOK_VERSION_INFO } from '@gql/queries/excel';
import {
  ActionContainer,
  ContainerStyled,
  DropdownActions,
  FlexBox,
  LineElement,
  TabsContainer,
} from './ModelingContainer.styled';
import {
  ModelingActionOptions,
  actionOptions,
} from './ModelingContainer.options';
import {
  CONFIRM_OFFER_CONFIRMATION_DIALOG,
  INVALID_OFFER_CONFIRMATION_DIALOG,
  SORT_BY_SHOW_DATE_CONFIRMATION_DIALOG,
  SORT_BY_MARKET_CONFIRMATION_DIALOG,
} from './ModelingContainer.constants';
import { MarketLink } from '../RoutingTab/RoutingTab.styled';

export enum ModelingViewMode {
  Default,
  SelectRoutingConfirmedEvents,
  SelectConfirmedEvents,
}

const modelingTabs = ['Routing', 'Add Event'];

type ExcelSortOrder = {
  date: Excel.SortField[];
  market: Excel.SortField[];
};

interface ModelingContainerProps {
  openRefreshModal: () => void;
}

export const ModelingContainer = ({ openRefreshModal }: ModelingContainerProps) => {
  const [tabValue, setTabValue] = useState(0);
  const { envStatus } = useContext(EnvStatusContext);
  const { tourId, offerId } = envStatus;
  const setDialog = useContext(DialogDispatchContext);
  const setErrorState = useExcelErrorHandler();

  const [viewMode, setViewMode] = useState(ModelingViewMode.Default);
  const [updatedStatus, setUpdatedStatus] = useState<OfferStatus>();
  const [selectedEvents, setSelectedEvents] = useState<GridRowId[]>([]);
  const [initCompleted, setInitCompleted] = useState(false);
  const [rowHeaders, setRowHeaders] = useState<string[]>([]);
  const [excelSortOrder, setExcelSortOrder] = useState<ExcelSortOrder>({} as ExcelSortOrder);

  const {
    initModelingWorkbook,
    syncModelingWorkbook,
    postUpdateSyncToWorksheets,
    sortWorkbook,
    selectEventWorksheet,
    getEventsWithoutExchangeRateOrBaseCurrency,
    getManagedSheetName,
  } = useAegModeling();
  const { handleAddInAction } = useAddInAction();
  const { getTableHeaders } = useExcel();
  const gridApiRef = useGridApiRef();

  const initSortOrder = async () => {
    const showDetailSheetName = await getManagedSheetName({
      sheetType: ManagedSheetType.SHOW_DETAIL,
      defaultSheetName: MANAGED_MODELING_SHEETS.show_detail,
    });

    const headers = await getTableHeaders(showDetailSheetName, SHOW_DETAIL_TABLE_NAME);
    setRowHeaders(headers);

    const dateIndex = headers.findIndex((header) => header === 'Date');
    const marketIndex = headers.findIndex((header) => header === 'City');
    const venueIndex = headers.findIndex((header) => header === 'Venue Name');

    const dateSortOrder = [
      {
        key: dateIndex,
        ascending: true,
      },
      {
        key: marketIndex,
        ascending: true,
      },
      {
        key: venueIndex,
        ascending: true,
      },
    ];

    const marketSortOrder = [
      {
        key: marketIndex,
        ascending: true,
      },
      {
        key: dateIndex,
        ascending: true,
      },
      {
        key: venueIndex,
        ascending: true,
      },
    ];

    const sortOrder = {
      date: dateSortOrder,
      market: marketSortOrder,
    };

    setExcelSortOrder(sortOrder);
  };

  useEffect(() => {
    const initWorkbook = async () => {
      try {
        await initModelingWorkbook();
        await initSortOrder();
        setInitCompleted(true);
      } catch (error) {
        const errorMessage = error instanceof Error ? error.message : error as string;
        if (errorMessage === 'CloneFail') {
          setErrorState({
            type: ExcelErrorType.CloneFail,
            message: errorMessage,
          }, 'Clone error.');
        } else {
          setErrorState({
            type: ExcelErrorType.InitFail,
            message: errorMessage,
          }, 'Init error.');
        }
      }
    };

    void initWorkbook();
  }, []);

  const [updateOffer] = useMutation(UPDATE_OFFER, {
    onCompleted: () => {
      setViewMode(ModelingViewMode.Default);
      setDialog(null);
    },
  });

  const {
    data,
    loading,
    refetch,
  } = useQuery(GET_OFFER_AND_EVENTS, {
    variables: {
      tourId: tourId ?? '',
      offerId: offerId ?? '',
    },
  });

  const [tour, offer, events] = useMemo(() => (data?.tour?.offers
    ? [data.tour, data.tour.offers[0], data.tour.offers[0]?.events]
    : []), [data]);

  const featureFlags = useFeatureFlags();
  const { data: workbookVersionInfo } = useQuery(GET_WORKBOOK_VERSION_INFO, {
    variables: {
      tourId: tourId as string,
      offerId: offerId as string,
    },
    skip: !tourId || !offerId || !featureFlags.getFlag(FeatureFlags.CAN_REFRESH_WORKBOOK),
  });

  const isSaveDisabled = useMemo(() => {
    if (selectedEvents.length > 0) {
      return false;
    }
    return true;
  }, [selectedEvents]);

  const showConfirmOfferButton = useMemo(() => {
    if (!offer?.status) {
      return false;
    }
    switch (offer.status) {
      case OfferStatus.Draft:
      case OfferStatus.RoutingConfirmed:
      case OfferStatus.Confirmed:
      case OfferStatus.OnTour:
        return true;
      default:
        return false;
    }
  }, [offer]);

  const showSortWorkbookByShowDatesButton = useMemo(() => {
    if (!offer?.events || offer.events.length <= 1) {
      return false;
    }

    return offer.events.some((event) => {
      if (event && event.shows) {
        return event.shows.some((show) => show?.showDateTime !== null);
      }
      return false;
    });
  }, [offer]);

  const showSortWorkbookByMarketButton = useMemo(() => {
    if (!offer?.events || offer.events.length <= 1) {
      return false;
    }

    return true;
  }, [offer]);

  const handleDropdownSelect = (selected: ModelingActionOptions) => {
    const preSelectedEvents = events?.reduce<string[]>((acc, event) => {
      if (selected === ModelingActionOptions.RoutingConfirmed
        && event?.status !== EventStatus.Draft
        && event?.id) {
        acc.push(event?.id);
      }
      if (selected === ModelingActionOptions.Confirmed
        && event?.status !== EventStatus.Dead
        && event?.status !== EventStatus.Draft
        && event?.status !== EventStatus.RoutingConfirmed
        && event?.isActive
        && event?.id) {
        acc.push(event?.id);
      }
      return acc;
    }, []);

    setSelectedEvents(preSelectedEvents || []);

    if (selected === ModelingActionOptions.Confirmed) {
      setUpdatedStatus(OfferStatus.Confirmed);
      if (tabValue !== 0) {
        setTabValue(0);
      }
      setViewMode(ModelingViewMode.SelectConfirmedEvents);
    }

    if (selected === ModelingActionOptions.RoutingConfirmed) {
      setUpdatedStatus(OfferStatus.RoutingConfirmed);
      if (tabValue !== 0) {
        setTabValue(0);
      }
      setViewMode(ModelingViewMode.SelectRoutingConfirmedEvents);
    }
  };

  const handleModifyOffer = async () => {
    if (!offerId || !tourId || !selectedEvents.length) {
      return;
    }
    const modifiedOffer: ModifyOfferInput = {
      id: offerId,
      status: updatedStatus,
    };

    const eventIdsToUpdateStatus = selectedEvents.map((id) => id.toString());

    await handleAddInAction(
      async () => {
        await syncModelingWorkbook();
        await updateOffer({
          variables: {
            tourId,
            offerId,
            offer: modifiedOffer,
            eventIdsToUpdateStatus,
          },
        });
        await postUpdateSyncToWorksheets();
      },
      SnackBarMessages.WorkbookSyncComplete,
    );
  };

  const handleSortWorkbookByShowDates = () => {
    // Needs to be on routing tab to be able to get access to the gridApiRef
    setTabValue(0);

    const { TITLES, SUBMIT, CANCEL } = SORT_BY_SHOW_DATE_CONFIRMATION_DIALOG();

    setDialog({
      titles: TITLES,
      submit: {
        text: SUBMIT,
        action: () => {
          const rowOrder = gridApiRef.current.state.rows.dataRowIds as string[];

          void handleAddInAction(
            async () => {
              await sortWorkbook(rowOrder, excelSortOrder.date);
            },
            SnackBarMessages.SortByShowDateComplete,
          );

          setDialog(null);
        },
      },
      cancel: {
        text: CANCEL,
        action: () => setDialog(null),
      },
    });
  };

  const handleSortWorkbookByMarket = () => {
    // Needs to be on routing tab to be able to get access to the gridApiRef
    setTabValue(0);

    const { TITLES, SUBMIT, CANCEL } = SORT_BY_MARKET_CONFIRMATION_DIALOG();

    setDialog({
      titles: TITLES,
      submit: {
        text: SUBMIT,
        action: () => {
          const gridSortModel: GridSortModel = [
            {
              field: 'active',
              sort: 'desc',
            },
            {
              field: 'market',
              sort: 'asc',
            },
            {
              field: 'firstEventDate',
              sort: 'asc',
            },
            {
              field: 'venueName',
              sort: 'asc',
            },
          ];
          gridApiRef.current.setSortModel(gridSortModel);
          const rowOrder = gridApiRef.current.state.sorting.sortedRows as string[];
          gridApiRef.current.setSortModel([]);

          void handleAddInAction(
            async () => {
              await sortWorkbook(rowOrder, excelSortOrder.market);
            },
            SnackBarMessages.SortByMarketComplete,
          );

          setDialog(null);
        },
      },
      cancel: {
        text: CANCEL,
        action: () => setDialog(null),
      },
    });
  };

  const handleCancelAction = () => {
    setViewMode(ModelingViewMode.Default);
    setSelectedEvents([]);
  };

  const getMissingRangeRequirements = async () => {
    const worksheetsWithMissingRanges = await getEventsWithoutExchangeRateOrBaseCurrency(selectedEvents as string[]);
    return worksheetsWithMissingRanges
      .map(({ id, missingRanges, sheetName }) => {
        const missingRangesString = missingRanges.join(', ');
        const marketLink = (
          <MarketLink onClick={() => { void selectEventWorksheet(id); }}>
            {sheetName}
          </MarketLink>
        );
        return (
          <span data-testid={`missing-ranges-warning-${id}`}>
            The following ranges are missing from the {marketLink} market sheet: {missingRangesString}
          </span>
        );
      });
  };

  const handleSaveAction = async () => {
    // BaseCurrency and ExchangeRate may not be synced at the time of sharing or confirming,
    // this informs the user of the missing ranges by appending them to the confirmation dialogue
    const missingRangeRequirements = await getMissingRangeRequirements();

    if (updatedStatus === OfferStatus.RoutingConfirmed) {
      if (missingRangeRequirements.length) {
        const { TITLES, SUBMIT } = INVALID_OFFER_CONFIRMATION_DIALOG(
          missingRangeRequirements,
        );

        setDialog({
          titles: TITLES,
          submit: {
            text: SUBMIT,
            action: () => setDialog(null),
          },
        });
        return;
      }

      await handleModifyOffer();
    }

    if (updatedStatus === OfferStatus.Confirmed) {
      const { data: latest } = await refetch();
      const { tour: latestTour } = latest;
      let requirements: ReactNode[] = [];

      if (!selectedEvents.length || !latestTour) {
        return;
      }

      if (latestTour.canConfirm?.requirements?.length) {
        requirements.push(...latestTour.canConfirm.requirements);
      }
      // Confirming needs to append the missing range requirements to
      // the requirements supplied by GQL
      requirements = requirements.concat(missingRangeRequirements);

      if (requirements.length) {
        const { TITLES, SUBMIT } = INVALID_OFFER_CONFIRMATION_DIALOG(
          requirements,
        );

        setDialog({
          titles: TITLES,
          submit: {
            text: SUBMIT,
            action: () => setDialog(null),
          },
        });
        return;
      }

      const { TITLES, SUBMIT, CANCEL } = CONFIRM_OFFER_CONFIRMATION_DIALOG(latestTour.jdeBusinessUnit?.id ?? '');

      setDialog({
        titles: TITLES,
        submit: {
          text: SUBMIT,
          action: () => {
            void handleModifyOffer();
          },
        },
        cancel: {
          text: CANCEL,
          action: () => setDialog(null),
        },
      });
    }
  };

  const actionsButtons = () => (
    <FlexBox>
      {viewMode === ModelingViewMode.Default
        ? <>
          <DropdownActions
            onSelect={handleDropdownSelect}
            dropdownOptions={actionOptions({
              canConfirm: showConfirmOfferButton,
            })}
            label="Actions"
            data-testid="actions-dropdown"
            disabled={!offer?.canTakeActions ?? false}
          />
          <KebabPopperMenu kebabIconColor={'primary'}>
            <MenuItem
              disabled={!showSortWorkbookByShowDatesButton}
              data-testid='sort-workbook-date'
              onClick={() => {
                void handleSortWorkbookByShowDates();
              }}>
              Sort Worksheets by Date
            </MenuItem>
            <MenuItem
              disabled={!showSortWorkbookByMarketButton}
              data-testid='sort-workbook-market'
              onClick={() => {
                void handleSortWorkbookByMarket();
              }}>
              Sort Worksheets by Market
            </MenuItem>
            <FeatureGate configFlag={FeatureFlags.CAN_REFRESH_WORKBOOK}>
              <ProtectedComponent checkPermission={{
                permission: UserPermissions.CanRefreshWorkbook,
                resourceType: AegResourceTypes.Tour,
                resourceId: tourId as string,
              }}>
                <MenuItem
                  data-testid='refresh-workbook-button'
                  disabled={workbookVersionInfo?.getVersionInformation?.isCurrent
                    || !workbookVersionInfo?.getVersionInformation?.version} // i.e., workbook version <= v2.0
                    onClick={() => {
                      void openRefreshModal();
                    }}
                >
                  Update Offer Template Version
                </MenuItem>
              </ProtectedComponent>
            </FeatureGate>
        </KebabPopperMenu>
      </>
        : <>
          <Button
            data-testid="cancel-button"
            variant="text"
            onClick={() => handleCancelAction()}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            data-testid="save-button"
            disabled={isSaveDisabled}
            onClick={() => {
              void handleSaveAction();
            }}
          >
            Save
          </Button>
        </>
      }
    </FlexBox>
  );

  if (loading || !offer || !tour || !initCompleted) {
    return <>Loading...</>;
  }

  return (
    <ProtectedComponent
      checkPermission={{
        permission: UserPermissions.UpdateTour,
        resourceType: AegResourceTypes.Tour,
        resourceId: envStatus.tourId,
      }}
      navigateAway={'/unauthorized'}
    >
      <ContainerStyled data-testid="modeling-container">
        <FlexBox>
          <TabsContainer
            className={
              viewMode === ModelingViewMode.SelectConfirmedEvents
              || viewMode === ModelingViewMode.SelectRoutingConfirmedEvents
                ? 'hidden'
                : ''
            }
            value={tabValue}
            onChange={(_, tab: number) => setTabValue(tab)}
          >
            {modelingTabs.map((tab) => (
              <TabStyled data-testid={`${kebabCase(tab)}-tab`} key={`${tab}-tab`} label={tab} />
            ))}
          </TabsContainer>
          <ActionContainer>{actionsButtons()}</ActionContainer>
        </FlexBox>
        <LineElement />
        <ModelingSearchProvider>
          <TabPanel value={tabValue} index={0} idPrefix="routing">
            <RoutingTab
              handleTabChange={() => setTabValue(1)}
              mode={viewMode}
              selectedEvents={selectedEvents}
              onSelectedEventsChange={setSelectedEvents}
              events={events as Event[]}
              gridApiRef={gridApiRef}
            />
          </TabPanel>
          <TabPanel value={tabValue} index={1} idPrefix="events">
            <AddEventToOffer tour={tour} offer={offer}/>
          </TabPanel>
        </ModelingSearchProvider>
      </ContainerStyled>
      <LastSyncedFooter lastSynced={offer?.lastSynced ?? undefined} />
    </ProtectedComponent>
  );
};
