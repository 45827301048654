/* eslint-disable max-len */
/* eslint-disable max-lines-per-function */
import {
  useContext, useEffect, useMemo, useState,
} from 'react';
import {
  FeatureGate,
  ModelingContainer,
  ProtectedComponent,
  SyncButton,
  TaskPaneHeader,
  RefreshStatusBanner,
  WorkbookRefreshModals,
} from '@components';
import { useQuery } from '@apollo/client';
import { GET_TOUR_AND_OFFER_ONLY } from '@gql/queries/tours';
import { EnvStatusContext, ExcelErrorType } from '@providers';
import { useAegModeling, useExcelErrorHandler } from '@hooks';
import FeatureFlags from '@utils/featureFlags';
import { AegResourceTypes, UserPermissions } from '@types';
import { Offer } from '@gql/types/graphql';
import { TaskPanePaperContainer } from './TaskPane.styled';

export const ModelingView = () => {
  const { envStatus } = useContext(EnvStatusContext);
  const { offerId, tourId } = envStatus;
  const { syncModelingWorkbook, checkWorkbookIsRetired } = useAegModeling();
  const setErrorState = useExcelErrorHandler();
  const [workbookIsRetired, setWorkbookIsRetired] = useState<boolean | null>(null);
  const [isRefreshModalOpen, setIsRefreshModalOpen] = useState(false);

  const { data, loading } = useQuery(GET_TOUR_AND_OFFER_ONLY, {
    variables: { tourId: tourId ?? '', offerId: offerId ?? '' },
  });

  const [tour, offer] = useMemo(() => {
    if (data?.tour?.offers) {
      return [data.tour, data.tour.offers[0]];
    }
    return [];
  }, [data]);

  const handleWorkbookIsRetired = async () => {
    const isRetired = await checkWorkbookIsRetired(offer as Offer);
    if (isRetired) {
      setErrorState({
        type: ExcelErrorType.NewVersionAvailable,
        context: {
          tourId,
        },
      });
    }
    setWorkbookIsRetired(isRetired);
  };

  useEffect(() => {
    if (offer != null) {
      void handleWorkbookIsRetired();
    }
  }, [offer?.id]);

  const openRefreshModal = () => {
    setIsRefreshModalOpen(true);
  };

  return loading || workbookIsRetired !== false ? null : (
    <>
      <FeatureGate configFlag={FeatureFlags.CAN_REFRESH_WORKBOOK}>
        <ProtectedComponent
          checkPermission={{
            permission: UserPermissions.CanRefreshWorkbook,
            resourceType: AegResourceTypes.Tour,
            resourceId: tourId as string,
          }}
        >
          <RefreshStatusBanner
            tourId={tourId as string}
            offerId={offer?.id as string}
            openRefreshModal={openRefreshModal}
          />
          {isRefreshModalOpen && <WorkbookRefreshModals setOpen={setIsRefreshModalOpen} />}
        </ProtectedComponent>
      </FeatureGate>
      <TaskPaneHeader
        loading={loading}
        tourName={tour?.name}
        offerName={offer?.name}
        offerStatus={offer?.status}
        actions={<SyncButton syncFn={syncModelingWorkbook} />}
      ></TaskPaneHeader>
      <TaskPanePaperContainer>
        <ModelingContainer openRefreshModal={openRefreshModal} />
      </TaskPanePaperContainer>
    </>
  );
};
