import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

export const RowContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  margin: theme.spacing(1),
  padding: theme.spacing(0, 2),
}));

export const Header = styled(RowContainer)(({ theme }) => ({
  padding: theme.spacing(1, 2),
  justifyContent: 'space-between',
}));

export const TourName = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
}));

export const HorizontalRule = styled(Box)(({ theme }) => ({
  width: '100%',
  backgroundColor: theme.palette.primary.light[50],
  height: theme.spacing(0.125),
  marginBottom: theme.spacing(1),
}));

export const OfferStatusChip = styled(Box)(({ theme }) => ({
  marginLeft: theme.spacing(1),
}));
