// TODO: Remove linting exceptions. Active now while feeTableMapper is being utilized for table column headers
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-return */ /* eslint-disable max-lines-per-function */
/* eslint-disable no-console */
/* eslint-disable no-restricted-syntax */
// Excel API actions require sequential execution, so "no-await-in-loop" is disabled to ensure correct order.
/* eslint-disable no-await-in-loop */
import {
  AddEventInput,
  BoxOfficeItem,
  Event,
  ModifyOfferInput,
  SyncValidationFieldsInput,
  Expense,
  ShowInput,
  BoxOfficeFee,
  ExpenseInput,
  ModifyEventInput,
  ArtistOfferInput,
  Offer,
  VariableCostInput,
  FixedCostInput,
  FixedCategoryItemInput,
  TourFinancialSummary,
  ModifyShowInput,
  AncillaryCategoryItem,
  ProjectedAegEarnings,
  ProjectedArtistOffer,
  ProjectedBoxOfficeOverview,
  ProjectedNetShowReceipts,
  ProjectedShowIncome,
  ModifyBoxOfficePhaseInput,
  CoProCalculation,
  SellOffCalculations,
  Tour,
  IdMapObj,
  UpdateEventVenueInput,
  UpdateEventVenueMutation,
  SellOffCalc,
  BreakEvenCalculations,
  EventArtistDealSummary,
} from '@gql/types/graphql';
import {
  ADD_OFFER_EVENT,
  REMOVE_OFFER_EVENT,
  UPDATE_OFFER_WORKBOOK_POPULATED,
} from '@gql/mutations/tours';
import {
  ARTIST_DEAL_SUMMARY_WHT_TOGGLE_VALUE,
  BELOW_SHOW_DETAIL_TABLE_NAME,
  DEFAULT_TAB_COLOR,
  INACTIVE_TAB_COLOR,
  MANAGED_MODELING_SHEETS,
  MANAGED_SHEETS_TABLES,
  MANAGED_SHEETS_SHEET_NAME,
  MARKET_SHEET_TABLE_IDENTIFIERS,
  MODELING_INITIALIZED_KEY,
  NAMED_RANGES,
  OVERHEAD_COSTS_SHEET_TABLES,
  SHEETS_WITH_AEG_LOGO,
  SHOW_DETAIL_TABLE_NAME,
  TAX_FEE_FORMULAS,
  TEMPLATE_MARKET_SHEET_NAME,
  WORKBOOK_REFRESH_INITIALIZED_KEY,
} from '@utils/excel/modeling/constants';
import {
  findMissingManagedSheets,
  generateAncillaryItemsTableData,
  generateBoxOfficeSummaryTableData,
  generateFixedCostTableData,
  generateShowDetailRowForSheet,
  generateTourFinancialSummaryData,
  generateVariableCostTableData,
  generateWorkbookInitCurrencyRanges,
  getManagedSheetNameFromFormula,
  getManagedSheetRowForEventSheet,
  mapOverheadCostsFields,
  numerateMarketSheetName,
} from '@utils/excel/modeling/helpers';
import {
  AncillarySheetValue,
  buildPlCategoryPayload,
  convertToBaseOrUndefined,
  formatCurrencyCell,
  generateTableName,
  getPlCategoryMap,
  generateBoxOfficeFeeItems,
  generateAncillaryCostItems,
} from '@utils/excel/shared/helpers';
import {
  CMN_COMPANY_ID,
  EXPENSE_CATEGORY_IDS,
  Phases,
  PROFIT_LOSS_REF_SHEET_NAME,
  WORKBOOK_CUSTOM_PROPERTIES,
  WORKBOOK_ORIGINAL_PARENT_DIR_PATH,
  WORKBOOK_ORIGINAL_PARENT_DIR_TOUR_FOLDER,
} from '@utils/excel/shared/constants';
import { useLazyQuery, useMutation } from '@apollo/client';
import { GET_PL_CATEGORIES } from '@gql/queries/profitLossCategories';
import {
  ADD_SHOW_TO_EVENT,
  CLONE_EVENT,
  MODIFY_SHOW,
  REMOVE_SHOW_FROM_EVENT,
  UPDATE_EVENT,
  UPDATE_EVENT_VENUE,
} from '@gql/mutations/events';
import {
  GET_OFFER_AND_EVENTS,
  GET_TOUR,
  GET_TOUR_AND_OFFER_TO_POPULATE_WORKBOOK,
  GET_TOUR_AND_OFFER_ONLY,
  GET_WORKBOOK_FILE_ID,
  GET_TOUR_CURRENCY,
} from '@gql/queries/tours';
import {
  EventStatus,
  ManagedSheet,
  ManagedSheetType,
  OfferStatus,
  WorkbookRefreshInitialized,
} from '@types';
import { useLoading, useFeatureFlags } from '@hooks';
import { useContext } from 'react';
import { EnvStatusContext, NotificationDispatchContext } from '@providers';
import { SnackbarType } from '@components';
import { AEGModeling } from '@utils/excel/modeling/tableLibrary';
import {
  BOX_OFFICE_TABLE_ROW,
  FIXED_COST_TABLE_ROW,
  FixedCostParsedTableData,
  FixedCostRow,
  MANAGED_SHEETS_ROW,
  VARIABLE_COST_TABLE_ROW,
  VariableCostRow,
} from '@utils/excel/modeling/tableShapes';
import { BOX_OFFICE_FEE_TABLE_ROW, BoxOfficeFeeRow } from '@utils/excel/shared/tableShapes';
import { toNumberOrUndefined, toRoundedNumberOrUndefined } from '@utils/numberHelpers';
import { toStringOrUndefined, eventMarketName, getTimezoneDate } from '@utils/stringHelpers';
import { getCurrencySymbol } from '@utils/currencyHelpers';
import { GET_EVENT } from '@gql/queries/events';
import { SheetTableData, EventSheet } from '@utils/excel/shared/types';
import { SYNC_MODELING_BOX_OFFICE_DATA, SYNC_MODELING_EVENT_DATA, SYNC_MODELING_OFFER_DATA } from '@gql/mutations/sync';
import { logError } from '@services/telemetry-service';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { capitalize } from '@mui/material/utils';
import { REFRESH_OFFER_WORKBOOK } from '../gql/mutations/excel';
import {
  ApplyNamedRangeAssignments,
  CustomProperty,
  ExcelCalcMode,
  NamedRangeAssignment,
  PopulateTablesData,
  TableDataArray,
  useExcel,
} from './useExcel';
import { useMaestro } from './useMaestro';
import cmnLogoPath from '../../public/images/cmn-logo.png';

const {
  EVENT_INFO, ARTIST_OFFER, SUMMARY, ARTIST_DEAL_SUMMARY,
} = NAMED_RANGES;

export type AddEventData = {
  name: string;
  spaceId: string;
  venueId: string;
};

type SheetContextIDMetaData = {
  newId: string;
  eventSheetNameFromId: string;
};

interface EventWorksheetSyncData {
  // data to sync
  boxOfficesInput: {
    boxOfficeId: string;
    boxOfficePhaseId: string;
    boxOfficePhase: ModifyBoxOfficePhaseInput;
  }[];
  expenseInput: ExpenseInput;
  eventInput: ModifyEventInput;
  // data to update sheet
  eventSheetStatus?: string;
}

type ManagedSheetRow = {
  sheetNameFormula: string;
  sheetType: string;
} & (
  {
    eventId?: null;
    numeratedEventMarketSheetName?: null;
  } | {
    eventId: string;
    numeratedEventMarketSheetName: string;
  }
);

export function useAegModeling() {
  const appInsights = useAppInsightsContext();
  const { envStatus } = useContext(EnvStatusContext);
  const { displayLoadingSpinner, hideLoadingSpinner, displayAegLoadingDialog } = useLoading();

  const { tourId, offerId } = envStatus;

  const [getTour] = useLazyQuery(GET_TOUR);
  const [getTourCurrency] = useLazyQuery(GET_TOUR_CURRENCY);
  const [getTourAndOfferOnly] = useLazyQuery(GET_TOUR_AND_OFFER_ONLY);
  // eslint-disable-next-line max-len
  const [GetTourAndOfferToPopulateWorkbook] = useLazyQuery(GET_TOUR_AND_OFFER_TO_POPULATE_WORKBOOK, {
    errorPolicy: 'all',
  });

  const [getPLCategories] = useLazyQuery(GET_PL_CATEGORIES);
  const { handleShareWithMarketing } = useMaestro(tourId, offerId);

  // TODO Error handling from this hook
  const [addOfferEvent] = useMutation(ADD_OFFER_EVENT, {
    refetchQueries: [GET_OFFER_AND_EVENTS],
  });
  const [cloneEventMutation] = useMutation(CLONE_EVENT, {
    refetchQueries: [GET_OFFER_AND_EVENTS],
  });
  const [updateEvent, { error: updateEventError }] = useMutation(UPDATE_EVENT, {
    refetchQueries: [GET_OFFER_AND_EVENTS],
  });
  const [updateVenue] = useMutation(UPDATE_EVENT_VENUE);
  const [modifyOfferWorkbookPopulated] = useMutation(UPDATE_OFFER_WORKBOOK_POPULATED);
  const [addEventShow] = useMutation(ADD_SHOW_TO_EVENT, {
    refetchQueries: [GET_OFFER_AND_EVENTS],
  });
  const [removeOfferEvent] = useMutation(REMOVE_OFFER_EVENT, {
    refetchQueries: [GET_OFFER_AND_EVENTS],
  });
  const [cancelOfferEvent] = useMutation(UPDATE_EVENT, {
    refetchQueries: [GET_OFFER_AND_EVENTS],
    onCompleted: () => {
      void handleShareWithMarketing();
    },
  });
  const [killOfferEvent] = useMutation(UPDATE_EVENT, {
    refetchQueries: [GET_OFFER_AND_EVENTS],
    onCompleted: () => {
      void handleShareWithMarketing();
    },
  });
  const [modifyShow] = useMutation(MODIFY_SHOW, {
    onCompleted: () => {
      void handleShareWithMarketing();
    },
    refetchQueries: [GET_OFFER_AND_EVENTS],
  });
  const [getEvent] = useLazyQuery(GET_EVENT, {
    errorPolicy: 'all',
    nextFetchPolicy: 'cache-only',
  });
  const [syncModelingEventData] = useMutation(SYNC_MODELING_EVENT_DATA);
  const [syncModelingOfferData] = useMutation(
    SYNC_MODELING_OFFER_DATA,
    { refetchQueries: [GET_TOUR, GET_OFFER_AND_EVENTS] },
  );
  const [syncModelingBoxOfficeData] = useMutation(SYNC_MODELING_BOX_OFFICE_DATA);
  const [refreshOfferWorkbook] = useMutation(REFRESH_OFFER_WORKBOOK);
  const [getWorkbookFileId] = useLazyQuery(GET_WORKBOOK_FILE_ID, {
    fetchPolicy: 'cache-first',
  });

  const {
    saveWorkbook,
    sortTable,
    tableHasFilters,
    removeFiltersOnTable,
    setTabColor,
    getTableHeaders,
    reorderWorksheets,
    checkIfWorkbookIsSyncedToSharepoint,
    checkWorkbookForNamedRange,
    checkWorksheetForNamedRange,
    saveWorkbookOriginalFileLocation,
    addActivationListenerToCheckForSharepoint,
    addSheetRenameListenerToWorkbook,
    removeSheetRenameListenerToWorkbook,
    loadExcelParentDirectory,
    applyValuesToNamedRanges,
    applyFormatsToNamedRanges,
    copySheet,
    currentWorkbookSheetNames,
    getCustomProperties,
    parseTable,
    populateMultipleTables,
    setCalculationMode,
    setCustomProperty,
    deleteSheet,
    protectSheet,
    findCellReferencingSheet,
    addRowOnTable,
    updateRowOnTable,
    removeRowByReferencingSheet,
    getValueByRangeName,
    getTextFromNamedRanges,
    getValuesFromNamedRanges,
    setSheetVisibility,
    setWorkbookLogo,
    renameSheet,
    addSheetNamePrefixToTables,
    activateWorksheet,
    checkForOtherActiveUsers,
    deleteTableData,
    hideRowGroup,
  } = useExcel();

  const setNotification = useContext(NotificationDispatchContext);

  const { modelingTables } = AEGModeling(parseTable);

  const getCurrentManagedSheetsTable = async () => await parseTable(
    MANAGED_SHEETS_SHEET_NAME,
    MANAGED_SHEETS_TABLES.MANAGED_SHEETS,
    undefined, // defaultObj
    undefined, // includeTotals
    true, // returnFormulas
  ) as ManagedSheetRow[];

  const getManagedSheetName = async ({ sheetType, defaultSheetName }: ManagedSheet) => {
    try {
      const currentManagedSheetsTable = await getCurrentManagedSheetsTable();

      const sheetTypeRow = currentManagedSheetsTable.find((row) => row.sheetType === sheetType);

      if (sheetTypeRow) {
        return getManagedSheetNameFromFormula(sheetTypeRow.sheetNameFormula);
      }

      return defaultSheetName;
    } catch {
      // table not found -- return default sheet name for backwards compatibility
      return defaultSheetName;
    }
  };

  const addEventShowDetailRow = async (sheet: EventSheet, tableName: string, preventDuplicates?: boolean) => {
    const showDetailSheetName = await getManagedSheetName({
      sheetType: ManagedSheetType.SHOW_DETAIL,
      defaultSheetName: MANAGED_MODELING_SHEETS.show_detail,
    });

    if (preventDuplicates) {
      const columnName = 'Status';
      const alreadyExists = await findCellReferencingSheet(
        showDetailSheetName,
        tableName,
        sheet.sheetName,
        columnName,
      );

      if (alreadyExists !== -1) {
        console.log(`Details row for ${sheet.sheetName} already exists, skipping...`);
        return;
      }

      console.log(`No duplicate row exists for ${sheet.sheetName}, continuing...`);
    }

    const headers = await getTableHeaders(showDetailSheetName, tableName);
    const data = generateShowDetailRowForSheet(sheet, headers, appInsights);
    await addRowOnTable(showDetailSheetName, tableName, [data]);
  };

  const populateAncillaryEarnings = async (eventSheet: EventSheet, expense: Expense): Promise<PopulateTablesData> => {
    let ancillaryEarningTableData = {} as PopulateTablesData;
    const expenseModeling = expense.phases?.find((phase) => phase.id === Phases.MODELING);
    // eslint-disable-next-line max-len
    const ancillaryEarningItems = expenseModeling?.ancillaryCategoryItems && expenseModeling.ancillaryCategoryItems[0].items;

    if (!ancillaryEarningItems) {
      return ancillaryEarningTableData;
    }

    const { name: tableName, referenceTable: ancillaryItemsIdsMap } = await modelingTables.ancillaryItems(
      eventSheet.numeratedEventMarketName,
    );

    const templateAncillaryValues = await parseTable<AncillarySheetValue>(
      TEMPLATE_MARKET_SHEET_NAME,
      `${MARKET_SHEET_TABLE_IDENTIFIERS.ANCILLARY_EARNINGS}_`,
    );

    const tableData = generateAncillaryItemsTableData(
      ancillaryEarningItems,
      ancillaryItemsIdsMap,
      eventSheet.numeratedEventMarketName,
      templateAncillaryValues,
    );

    if (tableData.length > 0) {
      ancillaryEarningTableData = {
        tableData: {
          name: tableName,
          data: tableData,
        },
        cleanInsert: true,
        batchInsert: false,
      };
    }

    return ancillaryEarningTableData;
  };

  const populateBoxOfficeOverview = (eventSheet: EventSheet, event: Event): PopulateTablesData => {
    const tableName = generateTableName(eventSheet.numeratedEventMarketName, 'BoxOffice', 0);
    const boxOfficeModelingData = event.defaultBoxOffice?.phases?.find(
      (boxOfficePhase) => boxOfficePhase.id === Phases.MODELING,
    );
    let boxOfficeOverviewTableData = {} as PopulateTablesData;

    const boxOfficePhaseItems = boxOfficeModelingData?.items;

    // TODO: update GQL fields to remove unnecessary maybe wrapper around items.
    const boxOfficeItems = boxOfficePhaseItems
      && boxOfficePhaseItems.filter((phaseItems): phaseItems is BoxOfficeItem => phaseItems !== null);

    if (!boxOfficeItems) {
      return boxOfficeOverviewTableData;
    }

    const tableData = generateBoxOfficeSummaryTableData(boxOfficeItems);

    boxOfficeOverviewTableData = {
      tableData: {
        name: tableName,
        data: tableData,
      },
      cleanInsert: true,
      batchInsert: false,
    };

    return boxOfficeOverviewTableData;
  };

  const populateBoxOfficeFees = async (sheet: EventSheet, event: Event): Promise<PopulateTablesData[]> => {
    const boxOfficeFeesTableData: PopulateTablesData[] = [];
    const boxOfficeModelingData = event.defaultBoxOffice?.phases?.find(
      (boxOfficePhase) => boxOfficePhase.id === Phases.MODELING,
    );

    const boxOfficesFees = boxOfficeModelingData?.fees;

    if (!boxOfficesFees || boxOfficesFees?.length === 0) return boxOfficeFeesTableData;

    // Pre Tax
    const {
      name: preTaxTableNameForSheet,
      data: preTaxTableData,
      referenceTable: preTaxNameIdsMap,
    } = await modelingTables.potentialEarnings.preTax(sheet.numeratedEventMarketName, sheet.sheetName);
    const arrayOfPreTaxIds: string[] = preTaxNameIdsMap.map((mapOfIds) => mapOfIds.id);

    // Tax
    const {
      name: taxTableNameForSheet,
      data: taxTableData,
      referenceTable: taxNameIdsMap,
    } = await modelingTables.potentialEarnings.tax(sheet.numeratedEventMarketName, sheet.sheetName);
    const arrayOfTaxIds: string[] = taxNameIdsMap.map((mapOfIds) => mapOfIds.id);

    // POST TAX
    const {
      name: postTaxTableNameForSheet,
      data: postTaxTableData,
      referenceTable: postTaxNameIdsMap,
    } = await modelingTables.potentialEarnings.postTax(sheet.numeratedEventMarketName, sheet.sheetName);
    const arrayOfPostTaxIds: string[] = postTaxNameIdsMap.map((mapOfIds) => mapOfIds.id);

    const appendItemToTable = (table: TableDataArray, item: (string | number)[]) => {
      const name = item[0];
      const existingItemIndex = table.findIndex((row) => row[0] === name);
      if (existingItemIndex !== -1) {
        table.splice(existingItemIndex, 1, item);
      } else {
        table.push(item);
      }
    };

    boxOfficesFees.forEach((item) => {
      const itemId = item?.profitLossCategoryId as string;
      if (!item || !itemId) return;

      // Items for pre tax table
      if (arrayOfPreTaxIds.includes(itemId)) {
        const name = preTaxNameIdsMap.find((mapElement) => mapElement.id === itemId)?.name || '';
        const preTaxItem = [
          name,
          item?.formula || '',
          item?.amount?.toString() || '',
          TAX_FEE_FORMULAS.PRE_TAX.TOTAL,
          TAX_FEE_FORMULAS.PRE_TAX.TOTAL_TO_BASE,
        ];

        appendItemToTable(preTaxTableData, preTaxItem);
        // Items for tax table
      } else if (arrayOfTaxIds.includes(itemId)) {
        const name = taxNameIdsMap.find((mapElement) => mapElement.id === itemId)?.name || '';
        const taxItem = [
          name,
          item?.formula || '',
          item?.amount?.toString() || '',
          TAX_FEE_FORMULAS.TAX.TOTAL,
          TAX_FEE_FORMULAS.TAX.TOTAL_TO_BASE,
        ];

        appendItemToTable(taxTableData, taxItem);
      } else if (arrayOfPostTaxIds.includes(itemId)) {
        const name = postTaxNameIdsMap.find((mapElement) => mapElement.id === itemId)?.name || '';
        const taxItem = [
          name,
          item?.notes || '',
          item?.amount?.toString() || '',
          TAX_FEE_FORMULAS.POST_TAX.TOTAL,
          TAX_FEE_FORMULAS.POST_TAX.TOTAL_TO_BASE,
        ];

        appendItemToTable(postTaxTableData, taxItem);
      }
    });

    // ensure all rows have formulas for total and total to base
    // items read from table, and not replaced, have formula results for [3] and [4] instead of formulas.
    const preTaxTableDataCleaned = preTaxTableData.map((row) => [
      row[0],
      row[1],
      row[2],
      TAX_FEE_FORMULAS.PRE_TAX.TOTAL,
      TAX_FEE_FORMULAS.PRE_TAX.TOTAL_TO_BASE,
    ]);
    const taxTableDataCleaned = taxTableData.map((row) => [
      row[0],
      row[1],
      row[2],
      TAX_FEE_FORMULAS.TAX.TOTAL,
      TAX_FEE_FORMULAS.TAX.TOTAL_TO_BASE,
    ]);
    const postTaxTableDataCleaned = postTaxTableData.map((row) => [
      row[0],
      row[1],
      row[2],
      TAX_FEE_FORMULAS.POST_TAX.TOTAL,
      TAX_FEE_FORMULAS.POST_TAX.TOTAL_TO_BASE,
    ]);

    // Pre Tax
    if (preTaxTableData.length > 0) {
      boxOfficeFeesTableData.push({
        tableData: {
          name: preTaxTableNameForSheet,
          data: preTaxTableDataCleaned,
        },
        cleanInsert: true,
        batchInsert: false,
      });
    }
    // Tax
    if (taxTableData.length > 0) {
      boxOfficeFeesTableData.push({
        tableData: {
          name: taxTableNameForSheet,
          data: taxTableDataCleaned,
        },
        cleanInsert: true,
        batchInsert: false,
      });
    }
    // Post Tax
    if (postTaxTableData.length > 0) {
      boxOfficeFeesTableData.push({
        tableData: {
          name: postTaxTableNameForSheet,
          data: postTaxTableDataCleaned,
        },
        cleanInsert: true,
        batchInsert: false,
      });
    }

    return boxOfficeFeesTableData;
  };

  const populateVariableCostTable = async (sheet: EventSheet, expense: Expense): Promise<PopulateTablesData> => {
    const expenseModeling = expense.phases?.find((phase) => phase.id === Phases.MODELING);
    const expenseItems = expenseModeling?.variableCategoryItems && expenseModeling.variableCategoryItems[0].items;
    let variableCostTableData = {} as PopulateTablesData;

    if (!expenseItems) {
      return variableCostTableData;
    }

    const { name: varCostTableName, referenceTable: variableCostIdsMap } = await modelingTables.variableCosts(
      sheet.numeratedEventMarketName,
    );
    const currentVariableCosts = await parseTable<VariableCostRow>(
      sheet.sheetName,
      varCostTableName,
      VARIABLE_COST_TABLE_ROW,
    );

    const tableData = generateVariableCostTableData(
      expenseItems,
      variableCostIdsMap,
      currentVariableCosts,
    );

    if (tableData.length > 0) {
      variableCostTableData = {
        tableData: {
          name: varCostTableName,
          data: tableData,
        },
        cleanInsert: true,
        batchInsert: false,
      };
    }
    return variableCostTableData;
  };

  // eslint-disable-next-line max-len
  const populateFixedCostTables = async (eventSheet: EventSheet, expense: Expense): Promise<PopulateTablesData[]> => {
    const expenseModeling = expense.phases?.find((phase) => phase.id === 'modeling');
    const expenseFixedCategories = expenseModeling?.fixedCategoryItems;
    const fixedCostTablesData: PopulateTablesData[] = [];

    if (!expenseFixedCategories) {
      return fixedCostTablesData;
    }

    const fixedCostReferenceMap = async (category: string): Promise<SheetTableData | null> => {
      const { FIXED_COST_PARENT_CATEGORIES } = EXPENSE_CATEGORY_IDS;
      switch (category) {
        case FIXED_COST_PARENT_CATEGORIES.ADVERTISING:
          return modelingTables.fixedCosts.advertising(eventSheet.numeratedEventMarketName);
        case FIXED_COST_PARENT_CATEGORIES.ARTIST_PRORATES:
          return modelingTables.fixedCosts.artistProrates(eventSheet.numeratedEventMarketName);
        case FIXED_COST_PARENT_CATEGORIES.CATERING:
          return modelingTables.fixedCosts.catering(eventSheet.numeratedEventMarketName);
        case FIXED_COST_PARENT_CATEGORIES.OTHER_COSTS:
          return modelingTables.fixedCosts.otherCosts(eventSheet.numeratedEventMarketName);
        case FIXED_COST_PARENT_CATEGORIES.PRODUCTION:
          return modelingTables.fixedCosts.production(eventSheet.numeratedEventMarketName);
        case FIXED_COST_PARENT_CATEGORIES.PRODUCTION_PRORATES:
          return modelingTables.fixedCosts.productionProrates(eventSheet.numeratedEventMarketName);
        case FIXED_COST_PARENT_CATEGORIES.RENT:
          return modelingTables.fixedCosts.rent(eventSheet.numeratedEventMarketName);
        case FIXED_COST_PARENT_CATEGORIES.STAFFING:
          return modelingTables.fixedCosts.staffing(eventSheet.numeratedEventMarketName);
        case FIXED_COST_PARENT_CATEGORIES.STAGEHANDS:
          return modelingTables.fixedCosts.stagehands(eventSheet.numeratedEventMarketName);
        case FIXED_COST_PARENT_CATEGORIES.SUPPORTS:
          return modelingTables.fixedCosts.supports(eventSheet.numeratedEventMarketName);
        case FIXED_COST_PARENT_CATEGORIES.TRANSPORT:
          return modelingTables.fixedCosts.transport(eventSheet.numeratedEventMarketName);
        case FIXED_COST_PARENT_CATEGORIES.VENUE:
          return modelingTables.fixedCosts.venue(eventSheet.numeratedEventMarketName);
        default:
          return null;
      }
    };

    // Avoid Promise.all in Excel ops
    for (const category of expenseFixedCategories) {
      const categoryName = category?.categoryId;
      const categoryTableData = categoryName && (await fixedCostReferenceMap(categoryName));
      const items = category?.items;
      if (!categoryTableData || !items || !items.length) {
        // Avoid Promise.all in Excel ops
        // eslint-disable-next-line no-continue
        continue;
      }
      const { name: tableName, referenceTable } = categoryTableData;
      const currentTableData = await parseTable<FixedCostParsedTableData>(eventSheet.sheetName, tableName);

      const data = generateFixedCostTableData(items, referenceTable, currentTableData);

      if (data.length > 0) {
        const populateTableData = {
          tableData: {
            name: tableName,
            data,
          },
          cleanInsert: true,
          batchInsert: false,
        };
        fixedCostTablesData.push(populateTableData);
      }
    }

    return fixedCostTablesData;
  };

  const setNamedRangesForNewEvent = (event: Event, spaceConfigurationId?: string): NamedRangeAssignment[] => {
    const marketName = eventMarketName(event);

    const modelingBoxOffice = event.defaultBoxOffice?.phases?.find(
      (boxOfficePhase) => boxOfficePhase.id === Phases.MODELING,
    );

    const modelingExpenses = event.expense?.phases?.find((phase) => phase.id === Phases.MODELING);

    const namedRangeAssignments: NamedRangeAssignment[] = [
      { range: EVENT_INFO.MARKET_NAME, value: marketName },
      { range: EVENT_INFO.COUNTRY, value: event.venue?.country },
      { range: EVENT_INFO.VENUE_NAME, value: event.space?.name },
      { range: EVENT_INFO.NUM_SHOWS, value: event.shows?.length.toString() },
      { range: EVENT_INFO.PROJECTED_PCT_SOLD, value: modelingBoxOffice?.percentSold?.toString() },
      {
        range: EVENT_INFO.STAGE_HANDS_COST_PER_TRUCK,
        value: event.stagehandsPerTruckFromConfig?.toString() || event.venueStagehandsCost?.toString(),
      }, // sets stagehands cost per truck
      { range: EVENT_INFO.STATUS, value: event.status as string },
      { range: EVENT_INFO.LOCAL_CURRENCY, value: event.venue?.localCurrencyCode as string },
      { range: EVENT_INFO.PREPARED_BY, value: event.preparedBy as string },
      { range: EVENT_INFO.PREPARED_ON, value: event.preparedOn as string },
      { range: EVENT_INFO.EXCHANGE_RATE, value: event.exchangeRate?.toString() },
      {
        range: EVENT_INFO.BREAK_EVEN_CALCULATIONS.BREAK_EVEN_AVG_OTHER,
        value: event?.breakEvenCalculations?.breakEvenAvgOther?.toString(),
      },
      {
        range: EVENT_INFO.BREAK_EVEN_CALCULATIONS.BREAK_EVEN_SEQ_OTHER,
        value: event?.breakEvenCalculations?.breakEvenSeqOther?.toString(),
      },
      {
        range: ARTIST_OFFER.GUARANTEE,
        value: event.artistOffer?.guarantee?.toString(),
      },
      {
        range: ARTIST_OFFER.NET_POOL_EARN,
        value: event.artistOffer?.percentNetPoolEarnings?.toString(),
      },
      {
        range: ARTIST_OFFER.ADJ_NOTES,
        value: event.artistOffer?.artistAdjustmentNotes as string,
      },
      {
        range: ARTIST_OFFER.ADJUSTMENT,
        value: event.artistOffer?.artistAdjustment?.toString(),
      },
      {
        range: EVENT_INFO.COMP_BREAKDOWN,
        value: event.compBreakdown || '',
      },
      {
        range: EVENT_INFO.MERCHANDISE_DEAL,
        value: event.merchandiseDeal || '',
      },
      {
        range: EVENT_INFO.NOTES_AND_COMMENTS,
        value: event.notesAndComments || '',
      },
      {
        range: EVENT_INFO.DEAL_LANGUAGE,
        value: event.dealLanguage || '',
      },
      {
        range: EVENT_INFO.CO_PRO_1_POTENTIAL_PERCENT,
        // eslint-disable-next-line max-len
        value: modelingExpenses?.coProCalculations
          ?.find((item) => item?.name === 'Co-Pro 1')
          ?.percentage?.potential?.toString(),
      },
      {
        range: EVENT_INFO.CO_PRO_2_POTENTIAL_PERCENT,
        // eslint-disable-next-line max-len
        value: modelingExpenses?.coProCalculations
          ?.find((item) => item?.name === 'Co-Pro 2')
          ?.percentage?.potential?.toString(),
      },
      {
        range: EVENT_INFO.SELL_OFF_CALC_POTENTIAL_GUARANTEE,
        value: modelingExpenses?.sellOffCalculations?.guarantee?.toString(),
      },
      {
        range: EVENT_INFO.SELL_OFF_CALC_POTENTIAL_VS_PERCENT,
        value: modelingExpenses?.sellOffCalculations?.versusPercent?.toString(),
      },
      {
        range: EVENT_INFO.SELL_OFF_CALC_POTENTIAL_INCOME_POOL,
        value: modelingExpenses?.sellOffCalculations?.incomePoolPercent?.toString(),
      },
    ];

    if (event.artistDealSummary) {
      const artistDealSummary = [
        {
          range: ARTIST_DEAL_SUMMARY.WHT_TOGGLE,
          value: event.artistDealSummary.useWithholdingTax ? ARTIST_DEAL_SUMMARY_WHT_TOGGLE_VALUE : undefined,
        },
        {
          range: ARTIST_DEAL_SUMMARY.EXPENSE_BUYOUT_NOTE,
          value: event.artistDealSummary.expenseBuyoutNotes ?? undefined,
        },
        {
          range: ARTIST_DEAL_SUMMARY.VS_NOTE,
          value: event.artistDealSummary.vsPercentNotes ?? undefined,
        },
        {
          range: ARTIST_DEAL_SUMMARY.WHT_VALUE,
          value: event.artistDealSummary.withholdingTax?.toString() ?? undefined,
        },
        {
          range: ARTIST_DEAL_SUMMARY.WHT_NOTE,
          value: event.artistDealSummary.withholdingTaxNotes ?? undefined,
        },
        {
          range: ARTIST_DEAL_SUMMARY.PRODUCTION_CONTRIBUTION_NOTE,
          value: event.artistDealSummary.productionContributionNotes ?? undefined,
        },
        {
          range: ARTIST_DEAL_SUMMARY.OTHER_LABEL,
          value: event.artistDealSummary.otherLabel ?? undefined,
        },
        {
          range: ARTIST_DEAL_SUMMARY.OTHER_NOTE,
          value: event.artistDealSummary.otherNotes ?? undefined,
        },
        {
          range: ARTIST_DEAL_SUMMARY.OTHER_VALUE,
          value: event.artistDealSummary.otherValue?.toString() ?? undefined,
        },
      ];
      namedRangeAssignments.push(...artistDealSummary);
    }

    if (event.sellableCapacityFromConfig) {
      namedRangeAssignments.push({
        range: EVENT_INFO.EVENT_SELLABLE_CAPACITY,
        value: event.sellableCapacityFromConfig.toString(),
      });
    } else if (spaceConfigurationId && event.space?.configurations?.length) {
      const matchingConfiguration = event.space.configurations.find((item) => item?.id === spaceConfigurationId);
      if (matchingConfiguration) {
        namedRangeAssignments.push({
          range: EVENT_INFO.EVENT_SELLABLE_CAPACITY,
          value: matchingConfiguration.totalCapacity?.toString(),
        });
      }
    }

    return namedRangeAssignments;
  };

  const getEventWorksheets = async (): Promise<EventSheet[]> => {
    const currentSheetNames = await currentWorkbookSheetNames();
    const matchingSheets = [];

    // Avoid Promise.all in Excel ops
    for (const sheetName of currentSheetNames) {
      const sheetNameMatch = await getCustomProperties(sheetName);

      if (sheetNameMatch && sheetNameMatch.sheetType === 'Event' && sheetNameMatch.contextId) {
        matchingSheets.push({
          id: sheetNameMatch.contextId as string,
          numeratedEventMarketName: sheetNameMatch.numeratedEventMarketName as string ?? '',
          sheetName,
          sheetType: sheetNameMatch.sheetType,
        });
      }
    }

    return matchingSheets;
  };

  const getEventWorksheetFromEventId = async (eventId: string): Promise<EventSheet | undefined> => {
    const eventWorksheets = await getEventWorksheets();
    return eventWorksheets.find((sheet) => sheet.id === eventId);
  };

  const setShowRelatedNamedRangesForEvent = async (event: Event) => {
    if (!event.id) {
      throw new Error('Missing Event Id');
    }

    const eventSheet = await getEventWorksheetFromEventId(event.id);

    if (!eventSheet) {
      setNotification({
        text: `Unable to find worksheet from event ID - ${event.id}`,
        type: SnackbarType.WARNING,
        duration: 6000,
      });
      return;
    }

    const [eventDate] = getTimezoneDate(event.date, event.venue?.timezone);

    const namedRangeAssignments = [
      { range: EVENT_INFO.SHOW_DATE, value: eventDate },
      {
        range: EVENT_INFO.NUM_SHOWS,
        value: (event.shows?.length ?? 0).toString(),
      },
    ];

    await applyValuesToNamedRanges({
      sheet: eventSheet.sheetName,
      namedRangeAssignments,
    });
  };

  const removeEventShowDetailRow = async (eventSheetName: string, tableName: string) => {
    const showDetailSheetName = await getManagedSheetName({
      sheetType: ManagedSheetType.SHOW_DETAIL,
      defaultSheetName: MANAGED_MODELING_SHEETS.show_detail,
    });

    await removeRowByReferencingSheet(
      showDetailSheetName,
      tableName,
      'Status',
      eventSheetName,
    );
  };

  /**
   * Parse through the current excel sheet names to generate a unique name for a new sheet
   * @param name desired sheet name
   * @returns numerated sheet name
   */
  const generateModelingMarketSheetName = async (name: string): Promise<string> => {
    const eventWorksheets = await getEventWorksheets();
    const existingEventMarkets = eventWorksheets.map((sheet) => sheet.numeratedEventMarketName ?? sheet.sheetName);
    return numerateMarketSheetName(name, existingEventMarkets);
  };

  const formatMarketSheetCurrencyRanges = async (
    marketSheet: string,
    localCurrencyISO: string,
    baseCurrencySymbol: string,
  ) => {
    const namedRangeAssignments: ApplyNamedRangeAssignments[] = [];

    // set base currency ranges with currency symbol, rounded (no decimal places)
    const baseCurrencyRoundedFormat = formatCurrencyCell(baseCurrencySymbol, true, false);
    NAMED_RANGES.EVENT_INFO.BASE_CURRENCY_PARENT_ROUNDED.forEach((range) => {
      namedRangeAssignments.push({
        sheet: marketSheet,
        namedRangeAssignments: [
          {
            range,
            format: baseCurrencyRoundedFormat,
          }],
      });
    });

    // set base currency ranges with base currency symbol, two decimal places
    const baseCurrencyDecimalFormat = formatCurrencyCell(baseCurrencySymbol, true, true);
    NAMED_RANGES.EVENT_INFO.BASE_CURRENCY_PARENT_DECIMAL.forEach((range) => {
      namedRangeAssignments.push({
        sheet: marketSheet,
        namedRangeAssignments: [
          {
            range,
            format: baseCurrencyDecimalFormat,
          }],
      });
    });

    // set local currency ranges with currency ISO code, rounded (no decimal places)
    const localCurrencyRoundedFormat = formatCurrencyCell(localCurrencyISO, true, false, true);
    NAMED_RANGES.EVENT_INFO.LOCAL_CURRENCY_PARENT_ROUNDED.forEach((range) => {
      namedRangeAssignments.push({
        sheet: marketSheet,
        namedRangeAssignments: [
          {
            range,
            format: localCurrencyRoundedFormat,
          }],
      });
    });

    // set local currency ranges with currency ISO code, two decimal places
    const localCurrencyDecimalFormat = formatCurrencyCell(localCurrencyISO, true, true, true);
    NAMED_RANGES.EVENT_INFO.LOCAL_CURRENCY_PARENT_DECIMAL.forEach((range) => {
      namedRangeAssignments.push({
        sheet: marketSheet,
        namedRangeAssignments: [
          {
            range,
            format: localCurrencyDecimalFormat,
          }],
      });
    });

    // set local currency ranges with currency ISO code, two decimal places, ISO code close to value (not left-aligned)
    const localCurrencyCenteredDecimalFormat = formatCurrencyCell(localCurrencyISO, false, true, true);
    namedRangeAssignments.push({
      sheet: marketSheet,
      namedRangeAssignments: [
        {
          range: NAMED_RANGES.EVENT_INFO.LOCAL_CURRENCY_VARIABLE_COSTS_RATES,
          format: localCurrencyCenteredDecimalFormat,
        }],
    });

    for (const assignment of namedRangeAssignments) {
      await applyFormatsToNamedRanges({
        sheet: assignment.sheet,
        namedRangeAssignments: assignment.namedRangeAssignments,
      });
    }
  };

  const validateAndApplyNewCurrency = async (
    numeratedEventMarketName: string,
    localCurrency: string | undefined | null,
    baseCurrency: string | undefined | null,
  ) => {
    console.time('setCurrencyFormatRangesForNewEvent');

    // check for existence of workbook v1.13 currency named range before adding currency formatting
    const workbookHasCurrencyRanges = await checkWorkbookForNamedRange(
      NAMED_RANGES.APPROVAL_EMAIL.BASE_CURRENCY_ROUNDED, // one of many currency ranges added in workbook version v1.13
    );

    if (workbookHasCurrencyRanges && baseCurrency && localCurrency) {
      const baseCurrencySymbol = getCurrencySymbol(baseCurrency);
      if (!baseCurrencySymbol) {
        throw new Error(`Currency symbol not found for ISO code ${baseCurrency}`);
      }
      await formatMarketSheetCurrencyRanges(numeratedEventMarketName, localCurrency, baseCurrencySymbol);
    } else {
      const warningMessage = workbookHasCurrencyRanges
      // eslint-disable-next-line max-len, @typescript-eslint/restrict-template-expressions
        ? `Currency formatting not set. Base currency (${baseCurrency}) or local currency (${localCurrency}) not found for event`
        : 'Currency formatting not set. Workbook does not have currency named ranges';
      console.warn(warningMessage);
    }

    console.timeEnd('setCurrencyFormatRangesForNewEvent');
  };

  const generateSheetFromEvent = async (
    event: Event,
    baseCurrency: string,
    isPopulatingWorkbook = false,
    spaceConfigurationId?: string,
  ) => {
    console.group('generateSheetFromEvent');
    const existingWorksheet = await getEventWorksheetFromEventId(event.id ?? '');

    if (isPopulatingWorkbook && existingWorksheet) {
      const workbookProperties = await getCustomProperties(existingWorksheet.sheetName ?? '');

      if (workbookProperties.sheetPopulated === 'complete') {
        console.groupEnd();
        return undefined;
      }

      await deleteSheet({
        sheetName: existingWorksheet.sheetName,
      });
      console.groupEnd();
    }

    console.time('generateSheetFromEvent');
    if (!isPopulatingWorkbook) {
      await setCalculationMode(ExcelCalcMode.manual);
    }

    const marketName = eventMarketName(event);
    const numeratedEventMarketName = await generateModelingMarketSheetName(marketName);
    const customSheetProperties: CustomProperty[] = [
      { property: 'sheetType', value: 'Event' },
      {
        property: 'contextId',
        value: event.id ?? '',
      },
      { property: 'sheetPopulated', value: 'started' },
      {
        property: 'numeratedEventMarketName',
        value: numeratedEventMarketName,
      },
    ];

    console.time(`copy sheet ${TEMPLATE_MARKET_SHEET_NAME}`);
    await copySheet({
      sheetToCopy: TEMPLATE_MARKET_SHEET_NAME,
      newSheetName: numeratedEventMarketName,
      customProperties: customSheetProperties,
    });
    console.timeEnd(`copy sheet ${TEMPLATE_MARKET_SHEET_NAME}`);

    console.time('setNamedRangesForNewEvent');
    const namedRangeAssignments = setNamedRangesForNewEvent(event, spaceConfigurationId);
    console.timeEnd('setNamedRangesForNewEvent');

    const localCurrency = event.venue?.localCurrencyCode;

    await validateAndApplyNewCurrency(numeratedEventMarketName, localCurrency, baseCurrency);

    await applyValuesToNamedRanges({
      sheet: numeratedEventMarketName,
      namedRangeAssignments,
    });

    const eventSheet: EventSheet = {
      id: event.id ?? '',
      numeratedEventMarketName,
      sheetName: numeratedEventMarketName,
      sheetType: 'Event',
    };

    console.time('BoxOfficeOverview Data');
    const boxOfficeOverviewTableData = populateBoxOfficeOverview(eventSheet, event);
    console.timeEnd('BoxOfficeOverview Data');
    console.time('BoxOfficeFees Data');
    const boxOfficeFeesTableData = await populateBoxOfficeFees(eventSheet, event);
    console.timeEnd('BoxOfficeFees Data');

    let variableCostTableData = {} as PopulateTablesData;
    let ancillaryEarningsTableData = {} as PopulateTablesData;
    let fixedCostTablesData = [] as PopulateTablesData[];

    if (event.expense) {
      console.time('VariableCostTable Data');
      variableCostTableData = await populateVariableCostTable(eventSheet, event.expense);
      console.timeEnd('VariableCostTable Data');
      console.time('AncillaryEarnings Data');
      ancillaryEarningsTableData = await populateAncillaryEarnings(eventSheet, event.expense);
      console.timeEnd('AncillaryEarnings Data');
      console.time('FixedCostTables Data');
      fixedCostTablesData = await populateFixedCostTables(eventSheet, event.expense);
      console.timeEnd('FixedCostTables Data');
    }

    const allTablesDataToPopulateSheet = [
      boxOfficeOverviewTableData,
      boxOfficeFeesTableData,
      variableCostTableData,
      ancillaryEarningsTableData,
      fixedCostTablesData,
    ].flat();

    await populateMultipleTables(numeratedEventMarketName, allTablesDataToPopulateSheet);

    // Set visibility of new market sheet to true
    await setSheetVisibility(numeratedEventMarketName, true);

    if (!isPopulatingWorkbook) {
      await setCalculationMode(ExcelCalcMode.automatic);
    }

    if (!event.artistDealSummary || !event.artistDealSummary.withholdingTax) {
      await hideRowGroup(numeratedEventMarketName, NAMED_RANGES.ARTIST_DEAL_SUMMARY.OTHER_LABEL);
    }

    console.time('addEventToManagedSheets');
    const row = getManagedSheetRowForEventSheet(eventSheet);
    await addRowOnTable(MANAGED_SHEETS_SHEET_NAME, MANAGED_SHEETS_TABLES.MANAGED_SHEETS, [row]);
    console.timeEnd('addEventToManagedSheets');

    try {
      // Insert the Show Detail row in show detail sheet before any Box Office Table names are updated
      console.time('addEventShowDetailRow');
      await setCalculationMode(ExcelCalcMode.manual);
      // timeout to mitigate excel crashing
      await new Promise((resolve) => { setTimeout(resolve, 2000); });

      const tableToAddEventTo = event.isActive === false ? BELOW_SHOW_DETAIL_TABLE_NAME : SHOW_DETAIL_TABLE_NAME;
      await addEventShowDetailRow(eventSheet, tableToAddEventTo);

      if (event.isActive === false) {
        await setTabColor(eventSheet.sheetName, INACTIVE_TAB_COLOR);
      }

      console.timeEnd('addEventShowDetailRow');
    } catch (error) {
      setNotification({
        text: 'We could not populate show detail row. Please contact booking support.',
        type: SnackbarType.ERROR,
        duration: 6000,
      });
    } finally {
      console.timeEnd('generateSheetFromEvent');
      console.groupEnd();
      await setCalculationMode(ExcelCalcMode.automatic);
      await setCustomProperty('sheetPopulated', 'complete', numeratedEventMarketName);
    }

    return numeratedEventMarketName;
  };

  const activateSheetRenameHandler = async () => {
    await addSheetRenameListenerToWorkbook(async (args) => {
      const { nameAfter, nameBefore } = args;

      if (nameAfter !== nameAfter.trim()) {
        try {
          await renameSheet({
            currentSheetName: nameAfter,
            newSheetName: nameAfter.trim(),
          });
        } catch {
          setNotification({
            text: `${nameAfter} is already taken. Please try a different worksheet name.`,
            type: SnackbarType.WARNING,
            duration: 6000,
          });
        }
      }

      const eventSheets = await getEventWorksheets();
      const updatedEventSheet = eventSheets.find((sheet) => sheet.sheetName === nameAfter);

      if (updatedEventSheet) {
        const showDetailSheetName = await getManagedSheetName({
          sheetType: ManagedSheetType.SHOW_DETAIL,
          defaultSheetName: MANAGED_MODELING_SHEETS.show_detail,
        });

        const headers = await getTableHeaders(showDetailSheetName, SHOW_DETAIL_TABLE_NAME);
        const newShowDetailRow = generateShowDetailRowForSheet(updatedEventSheet, headers, appInsights);
        const tableData = await parseTable(updatedEventSheet.sheetName, SHOW_DETAIL_TABLE_NAME);

        const indexOfRowToUpdate = tableData.findIndex(
          ({ EVENT_SAFE_SHEET_NAME }) => EVENT_SAFE_SHEET_NAME === nameBefore,
        );

        // DEPRECATED: We only update the Show Details row for compatibility with older workbooks,
        // it's no longer needed by the code (nor by newer workbooks)
        await updateRowOnTable(
          updatedEventSheet.sheetName,
          SHOW_DETAIL_TABLE_NAME,
          indexOfRowToUpdate,
          [newShowDetailRow],
        );
      }
    });
  };

  const cloneEvent = async (eventId: string) => {
    await checkIfWorkbookIsSyncedToSharepoint();

    console.time('cloneEvent');
    console.group('cloneEvent');

    if (!tourId || !offerId || !eventId) {
      throw new Error('Missing required parameters');
    }

    const hideAegLoadingDialog = await displayAegLoadingDialog();

    const result = await cloneEventMutation({
      variables: { tourId, offerId, eventId },
    });
    const newEvent = result.data?.cloneEvent;

    const summarySheetName = await getManagedSheetName({
      sheetType: ManagedSheetType.SUMMARY,
      defaultSheetName: MANAGED_MODELING_SHEETS.summary,
    });

    const baseCurrency = await getValueByRangeName<string>(SUMMARY.BASE_CURRENCY, summarySheetName);

    if (!newEvent) {
      throw new Error('Unable to find cloned event.');
    }

    await removeSheetRenameListenerToWorkbook();
    try {
      await generateSheetFromEvent(newEvent, baseCurrency);
      hideAegLoadingDialog();
      setNotification({
        type: SnackbarType.SUCCESS,
        text: 'Event has been added',
        duration: 6000,
      });
    } catch (err) {
      logError(appInsights, 'generateSheetFromEvent error', err);
      await removeOfferEvent({
        variables: {
          tourId,
          offerId,
          eventId: newEvent.id as string,
        },
      });
      hideAegLoadingDialog();
      setNotification({
        type: SnackbarType.ERROR,
        text: 'Event could not be added. Please try again.',
        duration: 6000,
      });
    } finally {
      await activateSheetRenameHandler();
    }

    console.timeEnd('cloneEvent');
    console.groupEnd();
  };

  const populateTourFinancialSummarySheet = async (financialSummary: TourFinancialSummary) => {
    const namedRangeAssignments: NamedRangeAssignment[] = generateTourFinancialSummaryData(financialSummary);

    const summarySheetName = await getManagedSheetName({
      sheetType: ManagedSheetType.SUMMARY,
      defaultSheetName: MANAGED_MODELING_SHEETS.summary,
    });

    await applyValuesToNamedRanges({
      sheet: summarySheetName,
      namedRangeAssignments,
    });
  };

  const populatePLCATReferenceSheet = async () => {
    const { data: plCatResponse, error: plCatError } = await getPLCategories();
    if (plCatError || !plCatResponse?.profitLossCategories) {
      throw new Error('Failed to get data for workbook init');
    }
    const plCategoryMap = getPlCategoryMap(plCatResponse);
    const populatePLCategoryTablesData: PopulateTablesData[] = [];

    console.time('populatePLCategory');
    console.group('populatePLCategory');

    plCategoryMap.forEach((entry, key) => {
      const payload = buildPlCategoryPayload(key, entry);
      populatePLCategoryTablesData.push(payload);
    });

    await populateMultipleTables(PROFIT_LOSS_REF_SHEET_NAME, populatePLCategoryTablesData);
    console.timeEnd('populatePLCategory');
    console.groupEnd();
  };

  const populateSummarySheet = async (tour: Tour, offer: Offer) => {
    const summaryNamedRanges: NamedRangeAssignment[] = [
      {
        range: SUMMARY.ARTIST_NAME,
        value: tour?.headliner?.name as string,
      },
      {
        range: SUMMARY.TOUR_NAME,
        value: tour?.name,
      },
      {
        range: SUMMARY.NUM_TRUCKS,
        value: offer?.numberOfTrucks?.toString() || '0',
      },
      {
        range: SUMMARY.BASE_CURRENCY,
        value: tour?.currency as string,
      },
    ];

    console.time('populateSummaryInfo');
    console.group('populateSummaryInfo');

    const summarySheetName = await getManagedSheetName({
      sheetType: ManagedSheetType.SUMMARY,
      defaultSheetName: MANAGED_MODELING_SHEETS.summary,
    });

    await applyValuesToNamedRanges({
      sheet: summarySheetName,
      namedRangeAssignments: summaryNamedRanges,
    });

    console.timeEnd('populateSummaryInfo');
    console.groupEnd();
  };

  const populateModelingWorkbook = async () => {
    await checkIfWorkbookIsSyncedToSharepoint();

    if (!tourId || !offerId) {
      throw new Error('Missing required parameters');
    }

    console.time('populateModelingWorkbook');
    console.group('populateModelingWorkbook');

    // Get tour, offer, event, expense, and box office data to populate workbook
    const { data: queryResponse } = await GetTourAndOfferToPopulateWorkbook({
      variables: { tourId, offerId },
    });

    const tour = queryResponse?.tour;
    const offer = tour?.offers?.find((offerItem) => offerItem?.id === offerId) as Offer;
    const baseCurrency = tour?.currency as string;

    // Populate TFINSUM sheet
    if (offer?.financialSummary) {
      console.group('populateTourFinancialSummarySheet');
      await populateTourFinancialSummarySheet(offer.financialSummary);
      console.group('populateTourFinancialSummarySheet');
    }

    // Populate Market Sheets for each Event
    const events = offer?.events;
    let didFail = false;

    if (events && events.length > 0) {
      console.group('populateEventSheets');
      // sheets must be generated sequentially to have accurate
      // sheet names and therefore cannot use Promise.all
      for (const event of events) {
        if (!event) {
          return;
        }
        console.time(`generateSheetFromEvent ${event.name}`);
        await removeSheetRenameListenerToWorkbook();
        try {
          await generateSheetFromEvent(event, baseCurrency, true);
          await setShowRelatedNamedRangesForEvent(event);
        } catch (e) {
          didFail = true;
          logError(appInsights, `failed to generateSheetFromEvent for event: ${event.name}`, e);
        } finally {
          await activateSheetRenameHandler();
        }
        console.timeEnd(`generateSheetFromEvent ${event.name}`);
      }
      console.groupEnd();
    }

    // Update offer.populateWorkbook to false
    const modifyOfferPayload: ModifyOfferInput = {
      id: offerId,
      populateWorksheet: didFail,
    };

    const updateOfferPayload = {
      variables: {
        tourId,
        offerId,
        offer: modifyOfferPayload,
      },
    };

    await modifyOfferWorkbookPopulated(updateOfferPayload);

    console.timeEnd('populateModelingWorkbook');
    console.groupEnd();
  };

  const updateCopiedWorkbookContextIds = async (cloneMap: IdMapObj[]): Promise<boolean> => {
    if (!tourId || !offerId) {
      throw new Error('Missing required parameters');
    }
    const metadataToUpdate: SheetContextIDMetaData[] = [];
    // try to find sheets with contextId matching original event ids.
    for (const map of cloneMap) {
      const { originalId, newId } = map;
      const eventSheetFromId = await getEventWorksheetFromEventId(originalId);
      if (eventSheetFromId) {
        metadataToUpdate.push({ newId, eventSheetNameFromId: eventSheetFromId.sheetName });
      }
    }

    // if all sheets have been found, update the contextIds
    if (metadataToUpdate.length === cloneMap.length) {
      for (const metadata of metadataToUpdate) {
        const { newId, eventSheetNameFromId } = metadata;
        await setCustomProperty('contextId', newId, eventSheetNameFromId);
      }
      // null clone map so operation does not perform again.
      const modifyOfferPayload: ModifyOfferInput = {
        id: offerId,
        cloneMap: null,
      };
      const updateOfferPayload = {
        variables: {
          tourId,
          offerId,
          offer: modifyOfferPayload,
        },
      };
      await modifyOfferWorkbookPopulated(updateOfferPayload);
      return true;
    }
    return false;
  };

  const checkEventSheetsForMissingCustomProperties = async () => {
    const eventWorksheets = await getEventWorksheets();

    for (const sheet of eventWorksheets) {
      const customProperties = await getCustomProperties(sheet.sheetName);
      if (!customProperties.numeratedEventMarketName) {
        await setCustomProperty('numeratedEventMarketName', sheet.sheetName, sheet.sheetName);
      }
    }
  };

  const formatInitCurrencyRanges = async (currencyISOCode: string) => {
    console.time('formatInitSheetsCurrencyRanges');

    const currencySymbol = getCurrencySymbol(currencyISOCode);

    if (!currencySymbol) {
      throw new Error(`Currency symbol not found for ISO code ${currencyISOCode}`);
    }

    const requiredSheets = [
      ManagedSheetType.APPROVAL_EMAIL,
      ManagedSheetType.APPROVAL_REVISED,
      ManagedSheetType.LATERAL_SUM_POTENTIAL,
      ManagedSheetType.OVERHEAD_COSTS,
      ManagedSheetType.QUICK_SUM,
      ManagedSheetType.SUMMARY,
      // Summary Uncrossed technically required, but not managed sheet
      ManagedSheetType.SHOW_DETAIL,
    ];

    const requiredSheetsWithCurrentNames = await Promise.all(
      requiredSheets.map(async (sheetType) => ({
        sheetType,
        defaultSheetName: MANAGED_MODELING_SHEETS[sheetType],
        currentSheetName: await getManagedSheetName({
          sheetType,
          defaultSheetName: MANAGED_MODELING_SHEETS[sheetType],
        }),
      })),
    );

    const allNamedRangeAssignments = generateWorkbookInitCurrencyRanges(currencySymbol, requiredSheetsWithCurrentNames);

    for (const { sheet, namedRangeAssignments } of allNamedRangeAssignments) {
      await applyFormatsToNamedRanges({
        sheet,
        namedRangeAssignments,
      });
    }

    console.timeEnd('formatInitSheetsCurrencyRanges');
  };

  const repopulateCustomProperties = async () => {
    const currentManagedSheetsTable = await getCurrentManagedSheetsTable();

    for (const row of currentManagedSheetsTable ?? []) {
      if (row.numeratedEventMarketSheetName) {
        const eventSheetName = getManagedSheetNameFromFormula(row.sheetNameFormula);

        await setCustomProperty(
          'numeratedEventMarketName',
          row.numeratedEventMarketSheetName,
          eventSheetName,
        );
        await setCustomProperty(
          'sheetType',
          row.sheetType,
          eventSheetName,
        );
        await setCustomProperty(
          'contextId',
          row.eventId,
          eventSheetName,
        );
      }
    }
  };

  const replaceAegLogosWithCMN = async () => {
    const currentSheetNames = await currentWorkbookSheetNames();
    const currentSheetsWithAEGLogo = currentSheetNames.filter((sheetName) => SHEETS_WITH_AEG_LOGO.includes(sheetName));
    for (const sheetName of currentSheetsWithAEGLogo) {
      await setWorkbookLogo(sheetName, cmnLogoPath);
    }
  };

  const checkCompanyIdForCMN = async (id?: string) => {

    if (id && id === CMN_COMPANY_ID) {
      await replaceAegLogosWithCMN();
    }
  };

  /**
   * Updates the managed_sheets table with new event IDs for a duplicated offer
   * via the cloneMap property.
   */
  const updateManagedSheetEventRows = async (cloneMap: IdMapObj[]) => {
    try {
      const currentManagedSheetsTable = await getCurrentManagedSheetsTable();

      const updatedRows = currentManagedSheetsTable.map((row) => {
        const matchingCloneMap = cloneMap.find((map) => map.originalId === row.eventId);

        if (matchingCloneMap?.newId && row.numeratedEventMarketSheetName) {
          const newEventId = matchingCloneMap.newId;
          return [
            row.sheetNameFormula,
            row.sheetType,
            newEventId,
            row.numeratedEventMarketSheetName,
          ];
        }

        return [row.sheetNameFormula, row.sheetType, '', ''];
      });

      // Clean insert will improperly infer the formulas, delete the range then batch insert the new data
      await deleteTableData(MANAGED_SHEETS_SHEET_NAME, MANAGED_SHEETS_TABLES.MANAGED_SHEETS);
      await populateMultipleTables(
        MANAGED_SHEETS_SHEET_NAME,
        [{
          cleanInsert: false,
          batchInsert: true,
          tableData: {
            name: MANAGED_SHEETS_TABLES.MANAGED_SHEETS,
            data: updatedRows,
          },
        }],
      );
    } catch (error) {
      console.warn('Unable to update managed_sheets table with new event IDs', error);
    }
  };

  const initModelingWorkbook = async () => {
    const workbookParentDir = await loadExcelParentDirectory();

    if (!tourId || !offerId) {
      throw new Error('Missing required parameters');
    }

    console.time('initModelingWorkbook');
    console.group('initModelingWorkbook');

    const { data: tourLatest } = await getTourAndOfferOnly({
      variables: { tourId, offerId },
    });
    const tour = tourLatest?.tour as Tour;
    const offer = tour?.offers?.find((offerItem) => offerItem?.id === offerId) as Offer;
    const currency = tour?.currency;

    // Backwards compatibility for worksheets without the numeratedEventMarketName Custom Property
    await checkEventSheetsForMissingCustomProperties();

    const workbookProperties = await getCustomProperties();

    const showDetailSheetName = await getManagedSheetName({
      sheetType: ManagedSheetType.SHOW_DETAIL,
      defaultSheetName: MANAGED_MODELING_SHEETS.show_detail,
    });

    // Removing legacy filters from showDetailTable if they exist as filters are no longer being used
    const hasFilters = await tableHasFilters(showDetailSheetName, SHOW_DETAIL_TABLE_NAME);
    if (hasFilters) {
      await removeFiltersOnTable(showDetailSheetName, SHOW_DETAIL_TABLE_NAME);
    }

    if (offer.populateWorksheet === false && offer.cloneMap) {
      const hideAegLoadingDialog = await displayAegLoadingDialog();
      // DUPLICATED WORKBOOK

      // Update event rows in managed_sheets table with new event IDs
      await updateManagedSheetEventRows(offer.cloneMap);

      console.time('ReplaceWorkbookIds');
      const isSuccessful = await updateCopiedWorkbookContextIds(offer.cloneMap);

      if (isSuccessful) {
        await setCustomProperty(MODELING_INITIALIZED_KEY, new Date().toString());
        await saveWorkbookOriginalFileLocation(workbookParentDir);
      } else {
        console.log('Error occurred replacing contextIds: Sheet update quantity does not match cloned event quantity.');
        console.timeEnd('ReplaceWorkbookIds');
        console.timeEnd('initModelingWorkbook');
        console.groupEnd();
        hideAegLoadingDialog();
        logError(appInsights, 'Error occurred replacing contextIds.');
        throw new Error('CloneFail');
      }

      console.timeEnd('ReplaceWorkbookIds');
      hideAegLoadingDialog();
    } else if (MODELING_INITIALIZED_KEY in workbookProperties) {
      displayLoadingSpinner();
      // This applies backwards compatibility with existing workbooks:
      // eslint-disable-next-line max-len
      if (!(WORKBOOK_ORIGINAL_PARENT_DIR_PATH in workbookProperties) || !(WORKBOOK_ORIGINAL_PARENT_DIR_TOUR_FOLDER in workbookProperties)) {
        await saveWorkbookOriginalFileLocation(workbookParentDir);
      }

      // if workbook needs refresh key exists and is set to true ...
      if (WORKBOOK_REFRESH_INITIALIZED_KEY in workbookProperties
        && workbookProperties[WORKBOOK_REFRESH_INITIALIZED_KEY] === WorkbookRefreshInitialized.NOT_STARTED
      ) {
        await repopulateCustomProperties();
        await setCustomProperty(WORKBOOK_REFRESH_INITIALIZED_KEY, WorkbookRefreshInitialized.DONE as string);
        await checkCompanyIdForCMN(tour?.company?.id);
      }

      // PREVIOUSLY INITIALIZED WORKBOOK
      await checkIfWorkbookIsSyncedToSharepoint();
      hideLoadingSpinner();
    } else {
      displayLoadingSpinner();
      // NEW & MIGRATED WORKBOOKS
      await setCalculationMode(ExcelCalcMode.manual);

      await checkCompanyIdForCMN(tour?.company?.id);

      // Needs to run before any other sheet operations
      // sheet operations check if workbook is synced to sharepoint
      // sharepoint sync check requires custom properties set by this method.
      await saveWorkbookOriginalFileLocation(workbookParentDir);

      await populatePLCATReferenceSheet();
      await populateSummarySheet(tour, offer);

      // MIGRATED WORKBOOKS ONLY
      if (offer && offer.populateWorksheet) {
        // Sheet operation checks sync with sharepoint
        await populateModelingWorkbook();
      }

      await setCalculationMode(ExcelCalcMode.automatic);

      // check for existence of workbook v1.13 currency named range before adding currency formatting
      const workbookHasCurrencyRanges = await checkWorkbookForNamedRange(
        NAMED_RANGES.APPROVAL_EMAIL.BASE_CURRENCY_ROUNDED, // currency range added in workbook version v1.13
      );

      if (workbookHasCurrencyRanges && currency) {
        await formatInitCurrencyRanges(currency);
      } else {
        console.warn('Currency formatting not set. workbook does not have currency named ranges');
      }

      await setCustomProperty(MODELING_INITIALIZED_KEY, new Date().toString());

      hideLoadingSpinner();
    }

    // Sheet listener checks sync with sharepoint
    await addActivationListenerToCheckForSharepoint();
    // Sheet listener checks sync with sharepoint
    await activateSheetRenameHandler();

    console.timeEnd('initModelingWorkbook');
    console.groupEnd();
  };

  const addEventToOffer = async (addEventInput: AddEventInput, spaceConfigurationId?: string) => {
    // check if wb location changed
    await checkIfWorkbookIsSyncedToSharepoint();

    console.time('addEventToOffer');
    console.group('addEventToOffer');
    const hideAegLoadingDialog = await displayAegLoadingDialog();
    if (!tourId || !offerId) {
      throw new Error('Missing required parameters');
    }

    const tourLatest = await getTour({
      variables: { tourId, offerId, phaseId: Phases.MODELING },
    });
    const tour = tourLatest.data?.tour;
    const offer = tour?.offers?.find((offerItem) => offerItem?.id === offerId);
    const baseCurrency = tour?.currency;

    const addEventPayload = {
      variables: {
        event: addEventInput,
        offerId: offer?.id as string,
        tourId: tour?.id as string,
        spaceConfigurationId,
      },
    };
    await removeSheetRenameListenerToWorkbook();
    try {
      const newEventDataResponse = await addOfferEvent(addEventPayload);
      const newEvent: Event = newEventDataResponse.data?.addOfferEvent as Event;
      await generateSheetFromEvent(newEvent, baseCurrency as string, false, spaceConfigurationId);
    } finally {
      await activateSheetRenameHandler();
    }
    hideAegLoadingDialog();
    console.timeEnd('addEventToOffer');
    console.groupEnd();
  };

  const parseBoxOfficeOverviewFees = async (eventSheet: EventSheet): Promise<BoxOfficeFee[]> => {
    const preTaxTable = generateTableName(eventSheet.numeratedEventMarketName, 'PreTaxFees', 0);
    const taxTable = generateTableName(eventSheet.numeratedEventMarketName, 'Taxes', 0);
    const postTaxTable = generateTableName(eventSheet.numeratedEventMarketName, 'PostTaxFees', 0);

    const preTaxData = await parseTable<BoxOfficeFeeRow>(eventSheet.sheetName, preTaxTable, BOX_OFFICE_FEE_TABLE_ROW);

    const taxData = await parseTable<BoxOfficeFeeRow>(eventSheet.sheetName, taxTable, BOX_OFFICE_FEE_TABLE_ROW);

    const postTaxData = await parseTable<BoxOfficeFeeRow>(eventSheet.sheetName, postTaxTable, BOX_OFFICE_FEE_TABLE_ROW);

    // Ref tables
    const { referenceTable: preTaxRefTable } = await modelingTables.potentialEarnings.preTax(
      eventSheet.numeratedEventMarketName,
      eventSheet.sheetName,
    );

    const { referenceTable: taxRefTable } = await modelingTables.potentialEarnings.tax(
      eventSheet.numeratedEventMarketName,
      eventSheet.sheetName,
    );
    const { referenceTable: postTaxRefTable } = await modelingTables.potentialEarnings.postTax(
      eventSheet.numeratedEventMarketName,
      eventSheet.sheetName,
    );
    const boxOfficeFees: BoxOfficeFee[] = [];

    boxOfficeFees.push(...generateBoxOfficeFeeItems(preTaxData, preTaxRefTable));
    boxOfficeFees.push(...generateBoxOfficeFeeItems(taxData, taxRefTable));
    boxOfficeFees.push(...generateBoxOfficeFeeItems(postTaxData, postTaxRefTable));

    return boxOfficeFees;
  };

  const getEventBoxOffices = async (event: Event, eventSheet: EventSheet) => {
    if (!event.shows) return [];
    const overviewTableName = generateTableName(eventSheet.numeratedEventMarketName, 'BoxOffice', 0);
    let overviewData = await parseTable<BoxOfficeItem>(eventSheet.sheetName, overviewTableName, BOX_OFFICE_TABLE_ROW);

    overviewData = overviewData.map((item) => ({
      ...item,
      kills: toRoundedNumberOrUndefined(item.kills),
      comps: toRoundedNumberOrUndefined(item.comps),
      capacity: toRoundedNumberOrUndefined(item.capacity),
      sellableCapacity: toRoundedNumberOrUndefined(item.sellableCapacity),
    }));

    const overviewFees = await parseBoxOfficeOverviewFees(eventSheet);

    const projectedPercentSold = await getValueByRangeName<number>(EVENT_INFO.PROJECTED_PCT_SOLD, eventSheet.sheetName);

    const boxOfficePromises = event.shows.map((show) => {
      if (!show || !show.id) {
        throw new Error('Show ID does not exist on Event Show');
      }

      const result = {
        id: show.id,
        phases: [
          {
            id: 'modeling',
            items: overviewData,
            fees: overviewFees,
            percentSold: projectedPercentSold,
          },
        ],
      };
      return result;
    });

    const boxOffices = boxOfficePromises;

    return boxOffices;
  };

  const getVariableCostInputFromExcel = async (eventSheet: EventSheet) => {
    const { variableCosts } = modelingTables;
    const variableCostsFactory = await variableCosts(eventSheet.numeratedEventMarketName);

    const variableCostsTableData = await parseTable<VariableCostRow>(
      eventSheet.sheetName,
      variableCostsFactory.name,
      VARIABLE_COST_TABLE_ROW,
    );

    const variableCategoryItems: VariableCostInput[] = [];

    variableCostsTableData.forEach((item) => {
      const { name, ...variableCostInputItem } = item;
      const id = variableCostsFactory.referenceTable.find((referenceRow) => referenceRow.name === name)?.id;

      if (id) {
        variableCostInputItem.id = id;
        variableCategoryItems.push(variableCostInputItem);
      }
    });

    return { categoryId: variableCostsFactory.categoryId, items: variableCategoryItems };
  };

  const getEventFixedCostInputs = async (eventSheet: EventSheet) => {
    const { fixedCosts } = modelingTables;
    const fixedCostsFactories = Object.values(fixedCosts);
    const fixedCostItems: FixedCategoryItemInput[] = [];

    const callbacks = fixedCostsFactories.map((factoryFunction) => async () => {
      const fixedCostsFactory = await factoryFunction(eventSheet.numeratedEventMarketName);
      const fixedCostsTableData = await parseTable<FixedCostRow>(
        eventSheet.sheetName,
        fixedCostsFactory.name,
        FIXED_COST_TABLE_ROW,
      );

      const fixedCostInput: FixedCostInput[] = [];
      const totalBudgetArray: number[] = [];

      fixedCostsTableData.forEach((item) => {
        const { name, ...fixedCostInputItem } = item;
        const id = fixedCostsFactory.referenceTable.find((referenceRow) => referenceRow.name === name)?.id;
        if (id) {
          fixedCostInputItem.id = id;
          fixedCostInput.push(fixedCostInputItem);
          totalBudgetArray.push(fixedCostInputItem?.budget ?? 0);
        }
      });

      const totalBudgetCalculated = totalBudgetArray.reduce((a, b) => a + b, 0);

      const totalBudgetNames = EVENT_INFO.FIXED_COSTS_TOTALS.find(
        (namedRange) => namedRange.categoryId === fixedCostsFactory.categoryId,
      )?.names;

      let totalBudgetToBase;

      if (totalBudgetNames) {
        totalBudgetToBase = await getValueByRangeName<number>(
          totalBudgetNames.totalBudgetToBaseName,
          eventSheet.sheetName,
        );
      }

      fixedCostItems.push({
        categoryId: fixedCostsFactory.categoryId,
        items: fixedCostInput,
        totalBudget: totalBudgetCalculated, // TODO: TECH DEBT - Create and use named range instead of calculation
        totalBudgetToBase,
      });
    });

    // Force await the parsing of each box office table to avoid making too many requests to Excel API
    for (const cb of callbacks) {
      await cb();
    }

    return fixedCostItems;
  };

  const getAncillaryCategoryItems = async (
    ranges: Record<string, string | number | undefined>,
    eventSheet: EventSheet,
  ) => {
    const totalProjected = toNumberOrUndefined(ranges[NAMED_RANGES.EVENT_INFO.TOTAL_ANCILLARY_PROJECTED]);
    const totalProjectedToBase = toNumberOrUndefined(ranges[NAMED_RANGES.EVENT_INFO.TOTAL_ANCILLARY_PROJECTED_TO_BASE]);
    const totalGrossPotential = toNumberOrUndefined(ranges[NAMED_RANGES.EVENT_INFO.TOTAL_ANCILLARY_POTENTIAL]);
    const { name: tableName, referenceTable: ancillaryItemsIdsMap } = await modelingTables.ancillaryItems(
      eventSheet.numeratedEventMarketName,
    );
    const ancillarySheetData = await parseTable<AncillarySheetValue>(eventSheet.sheetName, tableName);

    const items = generateAncillaryCostItems(ancillarySheetData, ancillaryItemsIdsMap);

    const ancillaryItem: AncillaryCategoryItem = {
      categoryId: 'Ancillary',
      items,
      totalProjected,
      totalGrossPotential,
      totalProjectedToBase,
    };
    const ancillaryCategoryItems: AncillaryCategoryItem[] = [ancillaryItem];

    return ancillaryCategoryItems;
  };

  const getCoProCalculations = (ranges: Record<string, string | number | undefined>) => {
    const coPro1PotentialPercent = toNumberOrUndefined(ranges[NAMED_RANGES.EVENT_INFO.CO_PRO_1_POTENTIAL_PERCENT]);
    const coPro2PotentialPercent = toNumberOrUndefined(ranges[NAMED_RANGES.EVENT_INFO.CO_PRO_2_POTENTIAL_PERCENT]);

    const coProCalculations: CoProCalculation[] = [
      {
        name: 'Co-Pro 1',
        percentage: {
          potential: coPro1PotentialPercent,
        },
      },
      {
        name: 'Co-Pro 2',
        percentage: {
          potential: coPro2PotentialPercent,
        },
      },
    ];
    return coProCalculations;
  };

  const getSellOffCalculations = (range: Record<string, string | number | undefined>) => {
    const sellOffCalculations: SellOffCalculations = {
      guarantee: toNumberOrUndefined(range[NAMED_RANGES.EVENT_INFO.SELL_OFF_CALC_POTENTIAL_GUARANTEE]),
      versusPercent: toNumberOrUndefined(range[NAMED_RANGES.EVENT_INFO.SELL_OFF_CALC_POTENTIAL_VS_PERCENT]),
      incomePoolPercent: toNumberOrUndefined(range[NAMED_RANGES.EVENT_INFO.SELL_OFF_CALC_POTENTIAL_INCOME_POOL]),
    };
    return sellOffCalculations;
  };

  // get event expense data
  const getEventExpenseData = async (
    eventId: string,
    eventSheet: EventSheet,
    ranges: Record<string, string | number | undefined>,
    exchangeRate: number,
  ): Promise<ExpenseInput> => {
    const variableCategoryItems = await getVariableCostInputFromExcel(eventSheet);
    const fixedCategoryItems = await getEventFixedCostInputs(eventSheet);
    const ancillaryCategoryItems = await getAncillaryCategoryItems(ranges, eventSheet);
    const sellOffCalculations = getSellOffCalculations(ranges);
    const coProCalculations = getCoProCalculations(ranges);
    const getNum = (key: string) => toNumberOrUndefined(ranges[key]);

    const expense: ExpenseInput = {
      id: eventId,
      phases: [
        {
          id: 'modeling',
          variableCategoryItems: [variableCategoryItems],
          fixedCategoryItems,
          ancillaryCategoryItems,
          seqVariableCostsToBase: convertToBaseOrUndefined(
            getNum(EVENT_INFO.SHOW_INCOME_SEQ_VARIABLE_COSTS),
            exchangeRate,
          ),
          totalVariableCostsToBase: getNum(EVENT_INFO.TOTAL_VARIABLE_COSTS_TO_BASE),
          coProCalculations,
          sellOffCalculations,
          // TODO: TECH DEBT: make a ToBase version totalBudgetVariableFixedCostsToBase
          totalBudgetVariableFixedCosts: getNum(EVENT_INFO.TOTAL_COSTS_TO_BASE),
        },
      ],
    };

    return expense;
  };

  const addShowToEvent = async (eventId: string, addShowInput: ShowInput) => {
    await checkIfWorkbookIsSyncedToSharepoint();

    console.time('addShowToEvent');
    console.group('addShowToEvent');

    displayLoadingSpinner();
    const addShowPayload = {
      variables: {
        eventId,
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        show: addShowInput,
      },
    };

    await addEventShow(addShowPayload);
    const eventResponse = await getEvent({ variables: { eventId: eventId ?? '' } });

    if (eventResponse.data && eventResponse.data.event) {
      // Update the first show date in the market sheet
      const updatedEvent = eventResponse.data.event as Event;
      await setShowRelatedNamedRangesForEvent(updatedEvent);
    }

    hideLoadingSpinner();
    console.timeEnd('addShowToEvent');
    console.groupEnd();
  };

  const removeEventFromOffer = async (eventId: string, isActive: boolean) => {
    await checkIfWorkbookIsSyncedToSharepoint();

    console.time('removeEventFromOffer');
    console.group('removeEventFromOffer');

    const hideAegLoadingDialog = await displayAegLoadingDialog();

    console.time('getEventWorksheetFromEventId');
    const worksheet = await getEventWorksheetFromEventId(eventId);
    console.timeEnd('getEventWorksheetFromEventId');
    if (worksheet) {
      console.time('removeEventShowDetailRow');
      const tableName = isActive ? SHOW_DETAIL_TABLE_NAME : BELOW_SHOW_DETAIL_TABLE_NAME;
      await removeEventShowDetailRow(worksheet.sheetName, tableName);
      console.timeEnd('removeEventShowDetailRow');

      console.time('removeManagedSheet');
      await removeRowByReferencingSheet(
        MANAGED_SHEETS_SHEET_NAME,
        MANAGED_SHEETS_TABLES.MANAGED_SHEETS,
        'sheetNameFormula',
        worksheet.sheetName,
      );
      console.timeEnd('removeManagedSheet');

      await deleteSheet({
        sheetName: worksheet.sheetName,
      });
    }

    const removeEventPayload = {
      variables: {
        tourId: tourId as string,
        offerId: offerId as string,
        eventId,
      },
    };
    await removeOfferEvent(removeEventPayload);

    hideAegLoadingDialog();

    setNotification({
      type: SnackbarType.SUCCESS,
      text: 'This event has been removed.',
      duration: 6000,
    });
    console.timeEnd('removeEventFromOffer');
    console.groupEnd();
  };

  const cancelEventFromOffer = async (eventId: string) => {
    await checkIfWorkbookIsSyncedToSharepoint();

    const hideAegLoadingDialog = await displayAegLoadingDialog();
    const cancelOfferEventPayload = {
      variables: {
        event: {
          id: eventId,
          status: EventStatus.Canceled,
        },
      },
    };

    await cancelOfferEvent(cancelOfferEventPayload);

    const worksheet = await getEventWorksheetFromEventId(eventId);
    if (worksheet) {
      await applyValuesToNamedRanges({
        sheet: worksheet.sheetName,
        namedRangeAssignments: [{ range: EVENT_INFO.STATUS, value: EventStatus.Canceled }],
      });
      await protectSheet({
        sheetName: worksheet.sheetName,
      });
    }

    hideAegLoadingDialog();

    setNotification({
      type: SnackbarType.SUCCESS,
      text: 'This event has been canceled.',
      duration: 6000,
    });
  };

  const activateEvent = async (eventId: string, isActive: boolean) => {
    await checkIfWorkbookIsSyncedToSharepoint();
    const hideAegLoadingDialog = await displayAegLoadingDialog();

    const sheet = await getEventWorksheetFromEventId(eventId);

    if (!updateEventError && sheet) {
      const addTableName = isActive ? SHOW_DETAIL_TABLE_NAME : BELOW_SHOW_DETAIL_TABLE_NAME;
      const removeTableName = isActive ? BELOW_SHOW_DETAIL_TABLE_NAME : SHOW_DETAIL_TABLE_NAME;
      const tabColor = isActive ? DEFAULT_TAB_COLOR : INACTIVE_TAB_COLOR;

      await addEventShowDetailRow(sheet, addTableName, true);
      await removeEventShowDetailRow(sheet.sheetName, removeTableName);
      await setTabColor(sheet?.sheetName, tabColor);
    }

    const updateEventPayload = {
      variables: {
        event: {
          id: eventId,
          isActive,
        },
      },
    };

    await updateEvent(updateEventPayload);

    hideAegLoadingDialog();
    void handleShareWithMarketing();
  };

  const [removeShowMutation] = useMutation(REMOVE_SHOW_FROM_EVENT, {
    onCompleted: () => {
      void handleShareWithMarketing();
    },
  });

  const removeShowFromEvent = async (eventId: string, showId: string, _showIndex: number) => {
    console.time('removeShowFromEvent');
    console.group('removeShowFromEvent');
    displayLoadingSpinner();
    await setCalculationMode(ExcelCalcMode.manual);
    await removeShowMutation({
      variables: {
        eventId,
        showId,
      },
    });

    console.time('getEventWorksheetFromEventId');
    const workSheet = await getEventWorksheetFromEventId(eventId);
    console.timeEnd('getEventWorksheetFromEventId');

    if (!workSheet) {
      hideLoadingSpinner();
      setNotification({
        text: `Unable to find worksheet from event ID - ${eventId}`,
        type: SnackbarType.WARNING,
        duration: 6000,
      });
      return;
    }

    // set the number of shows and first show date to the updated amount
    const eventResponse = await getEvent({ variables: { eventId } });
    console.time('setShowRelatedNamedRangesForEvent');
    if (eventResponse.data && eventResponse.data.event) {
      const updatedEvent = eventResponse.data.event as Event;
      await setShowRelatedNamedRangesForEvent(updatedEvent);
    }
    console.timeEnd('setShowRelatedNamedRangesForEvent');
    await setCalculationMode(ExcelCalcMode.automatic);
    hideLoadingSpinner();

    setNotification({
      type: SnackbarType.SUCCESS,
      text: 'Show has been removed from the event.',
      duration: 6000,
    });
    console.timeEnd('removeShowFromEvent');
    console.groupEnd();
  };

  const modifyShowOnEvent = async (eventId: string, modifyShowInput: ModifyShowInput) => {
    await checkIfWorkbookIsSyncedToSharepoint();

    await modifyShow({
      variables: {
        eventId,
        show: modifyShowInput,
      },
    });

    const eventResponse = await getEvent({ variables: { eventId } });
    if (eventResponse.data && eventResponse.data.event) {
      // Update the first show date in the market sheet
      const updatedEvent = eventResponse.data.event as Event;

      await setShowRelatedNamedRangesForEvent(updatedEvent);
    }
  };

  const killEvent = async (eventId: string) => {
    await checkIfWorkbookIsSyncedToSharepoint();

    const hideAegLoadingDialog = await displayAegLoadingDialog();
    const killEventPayload = {
      variables: {
        event: {
          id: eventId,
          status: EventStatus.Dead,
        },
      },
    };

    await killOfferEvent(killEventPayload);

    const worksheet = await getEventWorksheetFromEventId(eventId);
    if (worksheet) {
      await applyValuesToNamedRanges({
        sheet: worksheet.sheetName,
        namedRangeAssignments: [{ range: EVENT_INFO.STATUS, value: EventStatus.Dead }],
      });
      await protectSheet({
        sheetName: worksheet.sheetName,
      });
    }

    hideAegLoadingDialog();

    setNotification({
      type: SnackbarType.SUCCESS,
      text: 'This event has been killed.',
      duration: 6000,
    });
  };

  const generateTourFinancialSummaryInput = async () => {
    if (!offerId) {
      throw new Error('Missing required parameters');
    }
    const {
      TOTAL_POOL_INCOME,
      TOTAL_ARTIST_EARNINGS,
      NET_GROSS_PROMOTER_INCOME,
      BREAK_EVEN,
      NUM_TRUCKS,
    } = SUMMARY;

    const {
      TICKETING_REVENUE_AVG,
      SPONSORSHIP_REVENUE_AVG,
      PREMIUM_TICKETING_MODIFIER,
      CANCEL_INSURANCE_POTENTIAL,
      TOUR_POOL_BONUS_POTENTIAL,
      TOUR_POOL_BONUS_MODIFIER,
      FINANCING_COSTS_AVG,
      MANUAL_ADJUSTMENTS: TPI_MANUAL_ADJUSTMENTS,
    } = TOTAL_POOL_INCOME;

    const {
      PERCENT_OVER_SPLIT_POINT,
      SPLIT_POINT_PERCENT,
      BONUS_PER_TICKET_POTENTIAL,
      BONUS_PER_TICKET_MODIFIER,
      BONUS_FLAT_AVG,
      MARKETING_FUND_AVG,
      MANUAL_ADJUSTMENTS: TAE_MANUAL_ADJUSTMENTS,
    } = TOTAL_ARTIST_EARNINGS;

    const {
      AEG_PARTNER_SHARE_AVG,
      MANUAL_ADJUSTMENTS: NGPI_MANUAL_ADJUSTMENTS,
    } = NET_GROSS_PROMOTER_INCOME;

    const {
      ARTIST_BONUS_FLAT: BE_ARTIST_BONUS_FLAT,
      MARKETING_FUND: BE_MARKETING_FUND,
      MANUAL_ADJUSTMENTS: BE_MANUAL_ADJUSTMENTS,
    } = BREAK_EVEN;

    const namedRangeLookups: string[] = [
      // Number of trucks
      NUM_TRUCKS,
      // Total Pool Income Ranges
      TICKETING_REVENUE_AVG,
      SPONSORSHIP_REVENUE_AVG,
      PREMIUM_TICKETING_MODIFIER,
      CANCEL_INSURANCE_POTENTIAL,
      TOUR_POOL_BONUS_POTENTIAL,
      TOUR_POOL_BONUS_MODIFIER,
      FINANCING_COSTS_AVG,
      // Total Artist Earnings Ranges
      PERCENT_OVER_SPLIT_POINT,
      SPLIT_POINT_PERCENT,
      BONUS_PER_TICKET_POTENTIAL,
      BONUS_PER_TICKET_MODIFIER,
      BONUS_FLAT_AVG,
      MARKETING_FUND_AVG,
      // Net Gross Promoter Income Ranges
      AEG_PARTNER_SHARE_AVG,
      // Break Even Ranges
      BE_ARTIST_BONUS_FLAT.NAME,
      BE_ARTIST_BONUS_FLAT.AVG,
      BE_ARTIST_BONUS_FLAT.SEQ,
      BE_ARTIST_BONUS_FLAT.BLENDED,
      BE_MARKETING_FUND.NAME,
      BE_MARKETING_FUND.AVG,
      BE_MARKETING_FUND.SEQ,
      BE_MARKETING_FUND.BLENDED,
    ];

    // Manual Adjustments enumeration for Total Pool Income
    for (let i = 0; i < TOTAL_POOL_INCOME.MAX_MANUAL_ADJUSTMENTS; i += 1) {
      namedRangeLookups.push(`${TPI_MANUAL_ADJUSTMENTS.AVG}${i + 1}`);
      namedRangeLookups.push(`${TPI_MANUAL_ADJUSTMENTS.NAME}${i + 1}`);
    }

    // Manual Adjustments enumeration for Total Artist Earnings
    for (let i = 0; i < TOTAL_ARTIST_EARNINGS.MAX_MANUAL_ADJUSTMENTS; i += 1) {
      namedRangeLookups.push(`${TAE_MANUAL_ADJUSTMENTS.AVG}${i + 1}`);
      namedRangeLookups.push(`${TAE_MANUAL_ADJUSTMENTS.NAME}${i + 1}`);
    }

    // Manual Adjustments enumeration for Net Gross Promoter Income
    for (let i = 0; i < NET_GROSS_PROMOTER_INCOME.MAX_MANUAL_ADJUSTMENTS; i += 1) {
      namedRangeLookups.push(`${NGPI_MANUAL_ADJUSTMENTS.AVG}${i + 1}`);
      namedRangeLookups.push(`${NGPI_MANUAL_ADJUSTMENTS.NAME}${i + 1}`);
    }

    // Manual Adjustments enumeration for Break Even
    for (let i = 0; i < BREAK_EVEN.MAX_MANUAL_ADJUSTMENTS; i += 1) {
      namedRangeLookups.push(`${BE_MANUAL_ADJUSTMENTS.NAME}${i + 1}`);
      namedRangeLookups.push(`${BE_MANUAL_ADJUSTMENTS.AVG}${i + 1}`);
      namedRangeLookups.push(`${BE_MANUAL_ADJUSTMENTS.SEQ}${i + 1}`);
      namedRangeLookups.push(`${BE_MANUAL_ADJUSTMENTS.BLENDED}${i + 1}`);
    }

    const summarySheetName = await getManagedSheetName({
      sheetType: ManagedSheetType.SUMMARY,
      defaultSheetName: MANAGED_MODELING_SHEETS.summary,
    });

    const namedRangeValues = await getValuesFromNamedRanges({
      sheet: summarySheetName,
      namedRanges: namedRangeLookups,
    });

    const totalPoolIncomeManualAdjustments = [
      ...Array(TOTAL_POOL_INCOME.MAX_MANUAL_ADJUSTMENTS),
    ].map((_, index) => ({
      name: toStringOrUndefined(namedRangeValues[`${TPI_MANUAL_ADJUSTMENTS.NAME}${index + 1}`]),
      avgTicketPriceTotal: toNumberOrUndefined(namedRangeValues[`${TPI_MANUAL_ADJUSTMENTS.AVG}${index + 1}`]),
    }));

    const totalArtistEarningsManualAdjustments = [
      ...Array(TOTAL_ARTIST_EARNINGS.MAX_MANUAL_ADJUSTMENTS),
    ].map((_, index) => ({
      name: toStringOrUndefined(namedRangeValues[`${TAE_MANUAL_ADJUSTMENTS.NAME}${index + 1}`]),
      avgTicketPriceTotal: toNumberOrUndefined(namedRangeValues[`${TAE_MANUAL_ADJUSTMENTS.AVG}${index + 1}`]),
    }));

    const netGrossPromoterIncomeManualAdjustments = [
      ...Array(NET_GROSS_PROMOTER_INCOME.MAX_MANUAL_ADJUSTMENTS),
    ].map((_, index) => ({
      name: toStringOrUndefined(namedRangeValues[`${NGPI_MANUAL_ADJUSTMENTS.NAME}${index + 1}`]),
      avgTicketPriceTotal: toNumberOrUndefined(namedRangeValues[`${NGPI_MANUAL_ADJUSTMENTS.AVG}${index + 1}`]),
    }));

    const breakEvenArtistBonusFlat = {
      name: toStringOrUndefined(namedRangeValues[BE_ARTIST_BONUS_FLAT.NAME]),
      avgTicketPriceTotal: toNumberOrUndefined(namedRangeValues[BE_ARTIST_BONUS_FLAT.AVG]),
      sequentialTotals: toNumberOrUndefined(namedRangeValues[BE_ARTIST_BONUS_FLAT.SEQ]),
      blendedAvgTotal: toNumberOrUndefined(namedRangeValues[BE_ARTIST_BONUS_FLAT.BLENDED]),
    };

    const breakEvenMarketingFund = {
      name: toStringOrUndefined(namedRangeValues[BE_MARKETING_FUND.NAME]),
      avgTicketPriceTotal: toNumberOrUndefined(namedRangeValues[BE_MARKETING_FUND.AVG]),
      sequentialTotals: toNumberOrUndefined(namedRangeValues[BE_MARKETING_FUND.SEQ]),
      blendedAvgTotal: toNumberOrUndefined(namedRangeValues[BE_MARKETING_FUND.BLENDED]),
    };

    const breakEvenManualAdjustments = [
      ...Array(BREAK_EVEN.MAX_MANUAL_ADJUSTMENTS),
    ].map((_, index) => ({
      name: toStringOrUndefined(namedRangeValues[`${BE_MANUAL_ADJUSTMENTS.NAME}${index + 1}`]),
      avgTicketPriceTotal: toNumberOrUndefined(namedRangeValues[`${BE_MANUAL_ADJUSTMENTS.AVG}${index + 1}`]),
      sequentialTotals: toNumberOrUndefined(namedRangeValues[`${BE_MANUAL_ADJUSTMENTS.SEQ}${index + 1}`]),
      blendedAvgTotal: toNumberOrUndefined(namedRangeValues[`${BE_MANUAL_ADJUSTMENTS.BLENDED}${index + 1}`]),
    }));

    return {
      id: offerId,
      totalPoolIncomeTicketingRevenueAverage: toNumberOrUndefined(namedRangeValues[TICKETING_REVENUE_AVG]),
      totalPoolIncomeSponsorshipRevenueAverage: toNumberOrUndefined(namedRangeValues[SPONSORSHIP_REVENUE_AVG]),
      totalPoolIncomePremiumTicketingModifier: toNumberOrUndefined(namedRangeValues[PREMIUM_TICKETING_MODIFIER]),
      totalPoolIncomeCancellationInsurancePotential: toNumberOrUndefined(namedRangeValues[CANCEL_INSURANCE_POTENTIAL]),
      totalPoolIncomeTourPoolBonusPotential: toNumberOrUndefined(namedRangeValues[TOUR_POOL_BONUS_POTENTIAL]),
      totalPoolIncomeTourPoolBonusModifier: toNumberOrUndefined(namedRangeValues[TOUR_POOL_BONUS_MODIFIER]),
      totalPoolIncomeFinancingCostsAverage: toNumberOrUndefined(namedRangeValues[FINANCING_COSTS_AVG]),
      artistEarningsTotalSplitPointFigure: toNumberOrUndefined(namedRangeValues[SPLIT_POINT_PERCENT]),
      artistEarningsOverSplitPoint: toNumberOrUndefined(namedRangeValues[PERCENT_OVER_SPLIT_POINT]),
      artistEarningsArtistBonusPerTicketPotential: toNumberOrUndefined(namedRangeValues[BONUS_PER_TICKET_POTENTIAL]),
      artistEarningsArtistBonusPerTicketModifier: toNumberOrUndefined(namedRangeValues[BONUS_PER_TICKET_MODIFIER]),
      artistEarningsArtistBonusFlatAverage: toNumberOrUndefined(namedRangeValues[BONUS_FLAT_AVG]),
      artistEarningsMarketingFundAverage: toNumberOrUndefined(namedRangeValues[MARKETING_FUND_AVG]),
      netPromoterIncomeAegPartnerShareAverage: toNumberOrUndefined(namedRangeValues[AEG_PARTNER_SHARE_AVG]),
      totalPoolIncomeManualAdjustments,
      totalArtistEarningsManualAdjustments,
      netGrossPromoterIncomeManualAdjustments,
      breakEvenArtistBonusFlat,
      breakEvenMarketingFund,
      breakEvenManualAdjustments,
      numberOfTrucks: toNumberOrUndefined(namedRangeValues[NUM_TRUCKS]),
    };
  };

  const getArtistOffer = (ranges: Record<string, string | number | undefined>): ArtistOfferInput => {
    const guarantee = toNumberOrUndefined(ranges[NAMED_RANGES.ARTIST_OFFER.GUARANTEE]);

    const percentNetPoolEarnings = toNumberOrUndefined(ranges[NAMED_RANGES.ARTIST_OFFER.NET_POOL_EARN]);
    const artistAdjustmentNotes = toStringOrUndefined(ranges[NAMED_RANGES.ARTIST_OFFER.ADJ_NOTES]);
    const artistAdjustment = toNumberOrUndefined(ranges[NAMED_RANGES.ARTIST_OFFER.ADJUSTMENT]);

    const artistOffer: ArtistOfferInput = {
      guarantee,
      percentNetPoolEarnings,
      artistAdjustmentNotes,
      artistAdjustment,
    };

    return artistOffer;
  };

  const getProjectedBoxOfficeOverview = (ranges: Record<string, string | number | undefined>) => {
    const projectedBoxOfficeOverview: ProjectedBoxOfficeOverview = {};
    projectedBoxOfficeOverview.totalCapacity = toRoundedNumberOrUndefined(
      ranges[NAMED_RANGES.EVENT_INFO.TOTAL_CAPACITY],
    );

    projectedBoxOfficeOverview.totalAvailableCapacity = toRoundedNumberOrUndefined(
      ranges[NAMED_RANGES.EVENT_INFO.TOTAL_AVAILABLE_CAPACITY],
    );

    projectedBoxOfficeOverview.totalSellableCapacity = toRoundedNumberOrUndefined(
      ranges[NAMED_RANGES.EVENT_INFO.TOTAL_SELLABLE],
    );
    projectedBoxOfficeOverview.totalGross = toNumberOrUndefined(ranges[NAMED_RANGES.EVENT_INFO.TOTAL_GROSS]);

    projectedBoxOfficeOverview.totalGrossToBase = toNumberOrUndefined(
      ranges[NAMED_RANGES.EVENT_INFO.TOTAL_GROSS_TO_BASE],
    );

    return projectedBoxOfficeOverview;
  };

  const getProjectedNetShowReceipts = (ranges: Record<string, string | number | undefined>, exchangeRate: number) => {
    const projectedNetShowReceipts: ProjectedNetShowReceipts = {};
    projectedNetShowReceipts.netGrossReceiptsTotal = toNumberOrUndefined(ranges[EVENT_INFO.NET_GROSS_RECEIPTS]);

    projectedNetShowReceipts.netGrossReceiptsTotalToBase = toNumberOrUndefined(
      ranges[EVENT_INFO.NET_GROSS_RECEIPTS_TO_BASE],
    );

    projectedNetShowReceipts.netShowExpensesTotal = toNumberOrUndefined(ranges[EVENT_INFO.SEQUENTIAL_TOTAL_SHOW_COSTS]);

    projectedNetShowReceipts.netShowExpensesTotalToBase = convertToBaseOrUndefined(
      projectedNetShowReceipts.netShowExpensesTotal,
      exchangeRate,
    );

    projectedNetShowReceipts.budgetedShowExpensesToBase = toNumberOrUndefined(
      ranges[EVENT_INFO.BUDGETED_SHOW_EXPENSES_TO_BASE],
    );

    projectedNetShowReceipts.netSellOffAdjustmentTotal = toNumberOrUndefined(ranges[EVENT_INFO.SELL_OFF_ADJUSTMENT]);

    projectedNetShowReceipts.netSellOffAdjustmentTotalToBase = toNumberOrUndefined(
      ranges[EVENT_INFO.SELL_OFF_ADJUSTMENT_TO_BASE],
    );
    projectedNetShowReceipts.netShowReceiptsTotal = toNumberOrUndefined(ranges[EVENT_INFO.NET_SHOW_RECEIPTS]);

    projectedNetShowReceipts.netShowReceiptsTotalToBase = toNumberOrUndefined(
      ranges[EVENT_INFO.NET_SHOW_RECEIPTS_TO_BASE],
    );

    projectedNetShowReceipts.sequentialNetToPoolToBase = convertToBaseOrUndefined(
      toNumberOrUndefined(ranges[EVENT_INFO.SEQUENTIAL_NET_TO_POOL]),
      exchangeRate,
    );

    return projectedNetShowReceipts;
  };

  const getArtistDealSummary = (ranges: Record<string, string | number | undefined>): EventArtistDealSummary => {
    const eventArtistDealSummary: EventArtistDealSummary = {};

    const withholdingToggle = toStringOrUndefined(ranges[ARTIST_DEAL_SUMMARY.WHT_TOGGLE]);
    eventArtistDealSummary.useWithholdingTax = !!withholdingToggle;

    eventArtistDealSummary.expenseBuyoutNotes = toStringOrUndefined(ranges[ARTIST_DEAL_SUMMARY.EXPENSE_BUYOUT_NOTE]);
    eventArtistDealSummary.otherLabel = toStringOrUndefined(ranges[ARTIST_DEAL_SUMMARY.OTHER_LABEL]);
    eventArtistDealSummary.otherNotes = toStringOrUndefined(ranges[ARTIST_DEAL_SUMMARY.OTHER_NOTE]);
    eventArtistDealSummary.otherValue = toNumberOrUndefined(ranges[ARTIST_DEAL_SUMMARY.OTHER_VALUE]);
    eventArtistDealSummary.productionContributionNotes = toStringOrUndefined(
      ranges[ARTIST_DEAL_SUMMARY.PRODUCTION_CONTRIBUTION_NOTE],
    );
    eventArtistDealSummary.vsPercentNotes = toStringOrUndefined(ranges[ARTIST_DEAL_SUMMARY.VS_NOTE]);
    eventArtistDealSummary.withholdingTax = toNumberOrUndefined(ranges[ARTIST_DEAL_SUMMARY.WHT_VALUE]);
    eventArtistDealSummary.withholdingTaxNotes = toStringOrUndefined(ranges[ARTIST_DEAL_SUMMARY.WHT_NOTE]);

    return eventArtistDealSummary;
  };

  const getProjectedArtistOffer = (ranges: Record<string, string | number | undefined>, exchangeRate: number) => {
    const projectedArtistOffer: ProjectedArtistOffer = {};
    projectedArtistOffer.guarantee = toNumberOrUndefined(ranges[EVENT_INFO.GUARANTEE]);
    projectedArtistOffer.guaranteeToBase = toNumberOrUndefined(ranges[EVENT_INFO.GUARANTEE_TO_BASE]);
    projectedArtistOffer.pctOfNetPoolEarnings = toNumberOrUndefined(ranges[EVENT_INFO.PCT_NET_POOL_EARNINGS]);
    projectedArtistOffer.pctOfNetPoolEarningsAmount = toNumberOrUndefined(ranges[EVENT_INFO.NET_POOL_EARNINGS]);

    projectedArtistOffer.pctOfNetPoolEarningsAmountToBase = toNumberOrUndefined(
      ranges[EVENT_INFO.NET_POOL_EARNINGS_TO_BASE],
    );
    projectedArtistOffer.artistAdjustmentNotes = toStringOrUndefined(ranges[EVENT_INFO.ARTIST_ADJ_NOTES]);
    projectedArtistOffer.artistAdjustmentTotal = toNumberOrUndefined(ranges[EVENT_INFO.ARTIST_ADJUSTMENT]);

    projectedArtistOffer.artistAdjustmentTotalToBase = toNumberOrUndefined(
      ranges[EVENT_INFO.ARTIST_ADJUSTMENT_TO_BASE],
    );
    projectedArtistOffer.artistEarnings = toNumberOrUndefined(ranges[EVENT_INFO.ARTIST_EARNINGS]);
    projectedArtistOffer.artistEarningsToBase = toNumberOrUndefined(ranges[EVENT_INFO.ARTIST_EARNINGS_TO_BASE]);

    projectedArtistOffer.artistEarningSeqRoundedToBase = convertToBaseOrUndefined(
      toNumberOrUndefined(ranges[EVENT_INFO.ARTIST_EARNINGS_SEQ_ROUNDED]),
      exchangeRate,
    );
    return projectedArtistOffer;
  };

  const getProjectedAegEarnings = (ranges: Record<string, string | number | undefined>, exchangeRate: number) => {
    const projectedAegEarnings: ProjectedAegEarnings = {};
    projectedAegEarnings.coPromoterShareTotal = toNumberOrUndefined(ranges[EVENT_INFO.SEQUENTIAL_CO_PRO_SHARE]);
    projectedAegEarnings.coPromoterShareTotalToBase = convertToBaseOrUndefined(
      projectedAegEarnings.coPromoterShareTotal,
      exchangeRate,
    );
    projectedAegEarnings.aegNetShowEarningsTotal = toNumberOrUndefined(
      ranges[EVENT_INFO.SEQUENTIAL_AEG_NET_SHOW_EARNINGS],
    );
    projectedAegEarnings.aegNetShowEarningsTotalToBase = convertToBaseOrUndefined(
      projectedAegEarnings.aegNetShowEarningsTotal,
      exchangeRate,
    );

    return projectedAegEarnings;
  };

  const getProjectedShowIncome = (ranges: Record<string, string | number | undefined>, exchangeRate: number) => {
    const projectedShowIncome: ProjectedShowIncome = {};
    projectedShowIncome.grossShowReceipts = toNumberOrUndefined(ranges[EVENT_INFO.SEQUENTIAL_GROSS_SHOW_RECEIPTS]);
    projectedShowIncome.grossShowReceiptsToBase = convertToBaseOrUndefined(
      projectedShowIncome.grossShowReceipts,
      exchangeRate,
    );
    projectedShowIncome.netGrossReceipts = toNumberOrUndefined(ranges[EVENT_INFO.SEQUENTIAL_NET_GROSS_RECEIPTS]);
    projectedShowIncome.netGrossReceiptsToBase = convertToBaseOrUndefined(
      projectedShowIncome.netGrossReceipts,
      exchangeRate,
    );

    return projectedShowIncome;
  };

  const getEventSellOffCalculations = (range: Record<string, string | number | undefined>) => {
    const sellOffCalculations: SellOffCalc = {
      sellOffSeqAdjustmentsPool: toNumberOrUndefined(
        range[NAMED_RANGES.EVENT_INFO.SELL_OFF_CALCULATIONS.SELL_OFF_ADJUSTMENTS_POOL],
      ),
    };
    return sellOffCalculations;
  };

  const getEventBreakEvenCalculations = (range: Record<string, string | number | undefined>) => {
    const sellOffCalculations: BreakEvenCalculations = {
      breakEvenAvgOther: toNumberOrUndefined(
        range[NAMED_RANGES.EVENT_INFO.BREAK_EVEN_CALCULATIONS.BREAK_EVEN_AVG_OTHER],
      ),
      breakEvenSeqOther: toNumberOrUndefined(
        range[NAMED_RANGES.EVENT_INFO.BREAK_EVEN_CALCULATIONS.BREAK_EVEN_SEQ_OTHER],
      ),
    };
    return sellOffCalculations;
  };

  const getEventInformation = (sheet: EventSheet, ranges: Record<string, string | number | undefined>) => {
    const event: ModifyEventInput = {
      id: sheet.id,
      name: sheet.sheetName.trim(),
    };

    event.status = toStringOrUndefined(ranges[EVENT_INFO.STATUS]);
    event.baseCurrency = toStringOrUndefined(ranges[EVENT_INFO.BASE_CURRENCY]);
    event.localCurrency = toStringOrUndefined(ranges[EVENT_INFO.LOCAL_CURRENCY]);
    event.exchangeRate = toNumberOrUndefined(ranges[EVENT_INFO.EXCHANGE_RATE]);
    const exchangeRate = event.exchangeRate || 1;
    event.preparedBy = toStringOrUndefined(ranges[EVENT_INFO.PREPARED_BY]);
    event.preparedOn = toStringOrUndefined(ranges[EVENT_INFO.PREPARED_ON]);
    event.compBreakdown = toStringOrUndefined(ranges[EVENT_INFO.COMP_BREAKDOWN]);
    event.merchandiseDeal = toStringOrUndefined(ranges[EVENT_INFO.MERCHANDISE_DEAL]);
    event.notesAndComments = toStringOrUndefined(ranges[EVENT_INFO.NOTES_AND_COMMENTS]);
    event.dealLanguage = toStringOrUndefined(ranges[EVENT_INFO.DEAL_LANGUAGE]);
    event.artistOffer = getArtistOffer(ranges);
    event.projectedBoxOfficeOverview = getProjectedBoxOfficeOverview(ranges);
    event.projectedPostTaxAdjustmentsTotalToBase = toNumberOrUndefined(ranges[EVENT_INFO.POST_TAX_ADJUSTMENTS_TO_BASE]);
    event.projectedNetShowReceipts = getProjectedNetShowReceipts(ranges, exchangeRate);
    event.projectedArtistOffer = getProjectedArtistOffer(ranges, exchangeRate);
    event.projectedShowIncome = getProjectedShowIncome(ranges, exchangeRate);
    event.projectedAegEarnings = getProjectedAegEarnings(ranges, exchangeRate);

    event.projectedSales = toNumberOrUndefined(ranges[EVENT_INFO.SEQUENTIAL_SALES]);
    event.breakEvenCalculations = getEventBreakEvenCalculations(ranges);
    event.sellOffCalculations = getEventSellOffCalculations(ranges);
    event.sellableCapacityFromConfig = toNumberOrUndefined(ranges[EVENT_INFO.EVENT_SELLABLE_CAPACITY]);
    event.stagehandsPerTruckFromConfig = toNumberOrUndefined(ranges[EVENT_INFO.STAGE_HANDS_COST_PER_TRUCK]);

    event.artistDealSummary = getArtistDealSummary(ranges);

    return event;
  };

  const getApprovalEmailInfo = async () => {
    const approvalEmailRangeLookups = [
      ...Object.values(NAMED_RANGES.APPROVAL_EMAIL.CURRENT_MODEL),
      ...Object.values(NAMED_RANGES.APPROVAL_EMAIL.ORIGINAL_MODEL),
    ];

    const approvalEmailSheetName = await getManagedSheetName({
      sheetType: ManagedSheetType.APPROVAL_EMAIL,
      defaultSheetName: MANAGED_MODELING_SHEETS.approval_email,
    });

    const rangeValues = await getValuesFromNamedRanges({
      sheet: approvalEmailSheetName,
      namedRanges: approvalEmailRangeLookups,
    });

    const { CURRENT_MODEL, ORIGINAL_MODEL } = NAMED_RANGES.APPROVAL_EMAIL;

    const approvalEmailInfo = {
      currentModel: {
        territory: toStringOrUndefined(rangeValues[CURRENT_MODEL.TERRITORY]),
        venueType: toStringOrUndefined(rangeValues[CURRENT_MODEL.VENUE_TYPE]),
        timePeriod: toStringOrUndefined(rangeValues[CURRENT_MODEL.TIME_PERIOD]),
        flatBonusNote: toStringOrUndefined(rangeValues[CURRENT_MODEL.FLAT_BONUS_NOTE]),
        manualAdjustmentNote1: toStringOrUndefined(rangeValues[CURRENT_MODEL.MANUAL_ADJUSTMENT_NOTE_1]),
        manualAdjustmentNote2: toStringOrUndefined(rangeValues[CURRENT_MODEL.MANUAL_ADJUSTMENT_NOTE_2]),
        manualAdjustmentNote3: toStringOrUndefined(rangeValues[CURRENT_MODEL.MANUAL_ADJUSTMENT_NOTE_3]),
        manualAdjustmentNote4: toStringOrUndefined(rangeValues[CURRENT_MODEL.MANUAL_ADJUSTMENT_NOTE_4]),
        manualAdjustmentNote5: toStringOrUndefined(rangeValues[CURRENT_MODEL.MANUAL_ADJUSTMENT_NOTE_5]),
        artistAdvance: toNumberOrUndefined(rangeValues[CURRENT_MODEL.ARTIST_ADVANCE]),
        support: toStringOrUndefined(rangeValues[CURRENT_MODEL.SUPPORT]),
        agent: toStringOrUndefined(rangeValues[CURRENT_MODEL.AGENT]),
        manager: toStringOrUndefined(rangeValues[CURRENT_MODEL.MANAGER]),
        aegPointPerson: toStringOrUndefined(rangeValues[CURRENT_MODEL.AEG_POINT_PERSON]),
        operator: toStringOrUndefined(rangeValues[CURRENT_MODEL.OPERATOR]),
      },
      originalModel: {
        territory: toStringOrUndefined(rangeValues[ORIGINAL_MODEL.TERRITORY]),
        numberOfShows: toNumberOrUndefined(rangeValues[ORIGINAL_MODEL.NUMBER_OF_SHOWS]),
        venueType: toStringOrUndefined(rangeValues[ORIGINAL_MODEL.VENUE_TYPE]),
        timePeriod: toStringOrUndefined(rangeValues[ORIGINAL_MODEL.TIME_PERIOD]),
        totalCommitment: toNumberOrUndefined(rangeValues[ORIGINAL_MODEL.TOTAL_COMMITMENT]),
        totalCommitmentPerShow: toNumberOrUndefined(rangeValues[ORIGINAL_MODEL.TOTAL_COMMITMENT_PER_SHOW]),
        guarantee: toNumberOrUndefined(rangeValues[ORIGINAL_MODEL.GUARANTEE]),
        guaranteePerShow: toNumberOrUndefined(rangeValues[ORIGINAL_MODEL.GUARANTEE_PER_SHOW]),
        production: toNumberOrUndefined(rangeValues[ORIGINAL_MODEL.PRODUCTION]),
        productionPerShow: toNumberOrUndefined(rangeValues[ORIGINAL_MODEL.PRODUCTION_PER_SHOW]),
        // supportCommitment = "Support" in "Total Commitment" section
        supportCommitment: toNumberOrUndefined(rangeValues[ORIGINAL_MODEL.SUPPORT_COMMITMENT]),
        supportPerShow: toNumberOrUndefined(rangeValues[ORIGINAL_MODEL.SUPPORT_PER_SHOW]),
        producedTourProrates: toNumberOrUndefined(rangeValues[ORIGINAL_MODEL.PRODUCED_TOUR_PRORATES]),
        producedTourProratesPerShow: toNumberOrUndefined(rangeValues[ORIGINAL_MODEL.PRODUCED_TOUR_PRORATES_PER_SHOW]),
        marketingFund: toNumberOrUndefined(rangeValues[ORIGINAL_MODEL.MARKETING_FUND]),
        flatBonus: toNumberOrUndefined(rangeValues[ORIGINAL_MODEL.FLAT_BONUS]),
        flatBonusNote: toStringOrUndefined(rangeValues[ORIGINAL_MODEL.FLAT_BONUS_NOTE]),
        manualAdjustment1: toNumberOrUndefined(rangeValues[ORIGINAL_MODEL.MANUAL_ADJUSTMENT_1]),
        manualAdjustment2: toNumberOrUndefined(rangeValues[ORIGINAL_MODEL.MANUAL_ADJUSTMENT_2]),
        manualAdjustment3: toNumberOrUndefined(rangeValues[ORIGINAL_MODEL.MANUAL_ADJUSTMENT_3]),
        manualAdjustment4: toNumberOrUndefined(rangeValues[ORIGINAL_MODEL.MANUAL_ADJUSTMENT_4]),
        manualAdjustment5: toNumberOrUndefined(rangeValues[ORIGINAL_MODEL.MANUAL_ADJUSTMENT_5]),
        manualAdjustmentLabel1: toStringOrUndefined(rangeValues[ORIGINAL_MODEL.MANUAL_ADJUSTMENT_LABEL_1]),
        manualAdjustmentLabel2: toStringOrUndefined(rangeValues[ORIGINAL_MODEL.MANUAL_ADJUSTMENT_LABEL_2]),
        manualAdjustmentLabel3: toStringOrUndefined(rangeValues[ORIGINAL_MODEL.MANUAL_ADJUSTMENT_LABEL_3]),
        manualAdjustmentLabel4: toStringOrUndefined(rangeValues[ORIGINAL_MODEL.MANUAL_ADJUSTMENT_LABEL_4]),
        manualAdjustmentLabel5: toStringOrUndefined(rangeValues[ORIGINAL_MODEL.MANUAL_ADJUSTMENT_LABEL_5]),
        manualAdjustmentNote1: toStringOrUndefined(rangeValues[ORIGINAL_MODEL.MANUAL_ADJUSTMENT_NOTE_1]),
        manualAdjustmentNote2: toStringOrUndefined(rangeValues[ORIGINAL_MODEL.MANUAL_ADJUSTMENT_NOTE_2]),
        manualAdjustmentNote3: toStringOrUndefined(rangeValues[ORIGINAL_MODEL.MANUAL_ADJUSTMENT_NOTE_3]),
        manualAdjustmentNote4: toStringOrUndefined(rangeValues[ORIGINAL_MODEL.MANUAL_ADJUSTMENT_NOTE_4]),
        manualAdjustmentNote5: toStringOrUndefined(rangeValues[ORIGINAL_MODEL.MANUAL_ADJUSTMENT_NOTE_5]),
        artistAdvance: toNumberOrUndefined(rangeValues[ORIGINAL_MODEL.ARTIST_ADVANCE]),
        breakeven: toNumberOrUndefined(rangeValues[ORIGINAL_MODEL.BREAKEVEN]),
        breakevenPercentage: toNumberOrUndefined(rangeValues[ORIGINAL_MODEL.BREAKEVEN_PERCENTAGE]),
        averageNetTicketPrice: toNumberOrUndefined(rangeValues[ORIGINAL_MODEL.AVERAGE_NET_TICKET_PRICE]),
        netGlobalTouringProfit: toNumberOrUndefined(rangeValues[ORIGINAL_MODEL.NET_GLOBAL_TOURING_PROFIT]),
        netGlobalTouringProfitPercentage: toNumberOrUndefined(
          rangeValues[ORIGINAL_MODEL.NET_GLOBAL_TOURING_PROFIT_PERCENTAGE],
        ),
        ancillaries: toNumberOrUndefined(rangeValues[ORIGINAL_MODEL.ANCILLARIES]),
        numberOfTrucksCap: toNumberOrUndefined(rangeValues[ORIGINAL_MODEL.NUMBER_OF_TRUCKS_CAP]),
        // support = "Support" in "Please Note ..." section
        support: toStringOrUndefined(rangeValues[ORIGINAL_MODEL.SUPPORT]),
        tourPoolBonus: toNumberOrUndefined(rangeValues[ORIGINAL_MODEL.TOUR_POOL_BONUS]),
        perTicketBonus: toNumberOrUndefined(rangeValues[ORIGINAL_MODEL.PER_TICKET_BONUS]),
        premiumTicketing: toNumberOrUndefined(rangeValues[ORIGINAL_MODEL.PREMIUM_TICKETING]),
        premiumTicketingPerShow: toNumberOrUndefined(rangeValues[ORIGINAL_MODEL.PREMIUM_TICKETING_PER_SHOW]),
        sponsorship: toStringOrUndefined(rangeValues[ORIGINAL_MODEL.SPONSORSHIP]),
        agent: toStringOrUndefined(rangeValues[ORIGINAL_MODEL.AGENT]),
        manager: toStringOrUndefined(rangeValues[ORIGINAL_MODEL.MANAGER]),
        aegPointPerson: toStringOrUndefined(rangeValues[ORIGINAL_MODEL.AEG_POINT_PERSON]),
        operator: toStringOrUndefined(rangeValues[ORIGINAL_MODEL.OPERATOR]),
      },
    };

    return approvalEmailInfo;
  };

  /**
   * These fields need to have their content retrieved as text because value is improperly translated by Excel API
   * (e.g.: trying to pull the value from a Date-formatted field returns a serial number rather than text/string)
   */
  const getEventRangeTextValues = async (eventSheet: EventSheet) => {
    const eventNamedRangeLookups = [
      NAMED_RANGES.EVENT_INFO.PREPARED_ON,
    ];

    const eventNamedRanges = await getTextFromNamedRanges({
      sheet: eventSheet.sheetName,
      namedRanges: eventNamedRangeLookups,
    });

    return eventNamedRanges;
  };

  const getEventRangeValues = async (eventSheet: EventSheet) => {
    const eventNamedRangeLookups = [
      // ANCILLARY
      NAMED_RANGES.EVENT_INFO.TOTAL_ANCILLARY_PROJECTED,
      NAMED_RANGES.EVENT_INFO.TOTAL_ANCILLARY_PROJECTED_TO_BASE,
      NAMED_RANGES.EVENT_INFO.TOTAL_ANCILLARY_POTENTIAL,
      // ARTIST OFFER
      NAMED_RANGES.EVENT_INFO.ARTIST_EARNINGS_SEQ_ROUNDED,
      NAMED_RANGES.ARTIST_OFFER.GUARANTEE,
      NAMED_RANGES.ARTIST_OFFER.NET_POOL_EARN,
      NAMED_RANGES.ARTIST_OFFER.ADJ_NOTES,
      NAMED_RANGES.ARTIST_OFFER.ADJUSTMENT,
      // BOX OFFICE OVERVIEW
      NAMED_RANGES.EVENT_INFO.PROJECTED_PCT_SOLD,
      NAMED_RANGES.EVENT_INFO.TOTAL_CAPACITY,
      NAMED_RANGES.EVENT_INFO.TOTAL_AVAILABLE_CAPACITY,
      NAMED_RANGES.EVENT_INFO.TOTAL_SELLABLE,
      NAMED_RANGES.EVENT_INFO.TOTAL_GROSS,
      NAMED_RANGES.EVENT_INFO.TOTAL_GROSS_TO_BASE,
      // PROJECTED NET SHOW RECEIPTS
      NAMED_RANGES.EVENT_INFO.NET_GROSS_RECEIPTS,
      NAMED_RANGES.EVENT_INFO.NET_GROSS_RECEIPTS_TO_BASE,
      NAMED_RANGES.EVENT_INFO.BUDGETED_SHOW_EXPENSES,
      NAMED_RANGES.EVENT_INFO.BUDGETED_SHOW_EXPENSES_TO_BASE,
      NAMED_RANGES.EVENT_INFO.SELL_OFF_ADJUSTMENT,
      NAMED_RANGES.EVENT_INFO.SELL_OFF_ADJUSTMENT_TO_BASE,
      NAMED_RANGES.EVENT_INFO.NET_SHOW_RECEIPTS,
      NAMED_RANGES.EVENT_INFO.NET_SHOW_RECEIPTS_TO_BASE,
      NAMED_RANGES.EVENT_INFO.SEQUENTIAL_TOTAL_SHOW_COSTS,
      NAMED_RANGES.EVENT_INFO.SEQUENTIAL_NET_TO_POOL,
      // PROJECTED ARTIST OFFER
      NAMED_RANGES.EVENT_INFO.GUARANTEE_TO_BASE,
      NAMED_RANGES.EVENT_INFO.PCT_NET_POOL_EARNINGS,
      NAMED_RANGES.EVENT_INFO.NET_POOL_EARNINGS,
      NAMED_RANGES.EVENT_INFO.NET_POOL_EARNINGS_TO_BASE,
      NAMED_RANGES.EVENT_INFO.ARTIST_ADJ_NOTES,
      NAMED_RANGES.EVENT_INFO.ARTIST_ADJUSTMENT,
      NAMED_RANGES.EVENT_INFO.ARTIST_ADJUSTMENT_TO_BASE,
      NAMED_RANGES.EVENT_INFO.ARTIST_EARNINGS,
      NAMED_RANGES.EVENT_INFO.ARTIST_EARNINGS_TO_BASE,
      // PROJECTED AEG EARNINGS
      NAMED_RANGES.EVENT_INFO.SEQUENTIAL_CO_PRO_SHARE,
      NAMED_RANGES.EVENT_INFO.SEQUENTIAL_AEG_NET_SHOW_EARNINGS,
      NAMED_RANGES.EVENT_INFO.SEQUENTIAL_GROSS_SHOW_RECEIPTS,
      NAMED_RANGES.EVENT_INFO.SEQUENTIAL_NET_GROSS_RECEIPTS,
      // EVENT INFORMATION
      NAMED_RANGES.EVENT_INFO.BASE_CURRENCY,
      NAMED_RANGES.EVENT_INFO.LOCAL_CURRENCY,
      NAMED_RANGES.EVENT_INFO.EXCHANGE_RATE,
      NAMED_RANGES.EVENT_INFO.COMP_BREAKDOWN,
      NAMED_RANGES.EVENT_INFO.MERCHANDISE_DEAL,
      NAMED_RANGES.EVENT_INFO.NOTES_AND_COMMENTS,
      NAMED_RANGES.EVENT_INFO.DEAL_LANGUAGE,
      NAMED_RANGES.EVENT_INFO.POST_TAX_ADJUSTMENTS_TO_BASE,
      NAMED_RANGES.EVENT_INFO.PREPARED_BY,
      NAMED_RANGES.EVENT_INFO.SEQUENTIAL_SALES,
      NAMED_RANGES.EVENT_INFO.STATUS,
      NAMED_RANGES.EVENT_INFO.STAGE_HANDS_COST_PER_TRUCK,
      NAMED_RANGES.EVENT_INFO.EVENT_SELLABLE_CAPACITY,
      // SELL OFF CALCULATIONS POTENTIAL
      NAMED_RANGES.EVENT_INFO.SELL_OFF_CALC_POTENTIAL_GUARANTEE,
      NAMED_RANGES.EVENT_INFO.SELL_OFF_CALC_POTENTIAL_VS_PERCENT,
      NAMED_RANGES.EVENT_INFO.SELL_OFF_CALC_POTENTIAL_INCOME_POOL,
      // CO PROMOTER CALCULATIONS
      NAMED_RANGES.EVENT_INFO.CO_PRO_1_POTENTIAL_PERCENT,
      NAMED_RANGES.EVENT_INFO.CO_PRO_2_POTENTIAL_PERCENT,
      // VARIABLE COSTS OVERVIEW
      NAMED_RANGES.EVENT_INFO.TOTAL_VARIABLE_COSTS_TO_BASE,
      NAMED_RANGES.EVENT_INFO.SHOW_INCOME_SEQ_VARIABLE_COSTS,
      // FIXED COSTS OVERVIEW
      NAMED_RANGES.EVENT_INFO.TOTAL_COSTS_TO_BASE,
      // CO PRO POTENTIAL PERCENT
      NAMED_RANGES.EVENT_INFO.CO_PRO_1_POTENTIAL_PERCENT,
      NAMED_RANGES.EVENT_INFO.CO_PRO_2_POTENTIAL_PERCENT,
      // SELL OFF CALCULATIONS
      NAMED_RANGES.EVENT_INFO.SELL_OFF_CALC_POTENTIAL_GUARANTEE,
      NAMED_RANGES.EVENT_INFO.SELL_OFF_CALC_POTENTIAL_INCOME_POOL,
      NAMED_RANGES.EVENT_INFO.SELL_OFF_CALC_POTENTIAL_VS_PERCENT,
      NAMED_RANGES.EVENT_INFO.SELL_OFF_CALCULATIONS.SELL_OFF_ADJUSTMENTS_POOL,
      // BREAK EVEN CALCULATIONS
      NAMED_RANGES.EVENT_INFO.BREAK_EVEN_CALCULATIONS.BREAK_EVEN_AVG_OTHER,
      NAMED_RANGES.EVENT_INFO.BREAK_EVEN_CALCULATIONS.BREAK_EVEN_SEQ_OTHER,
      // ARTIST DEAL SUMMARY
      NAMED_RANGES.ARTIST_DEAL_SUMMARY.EXPENSE_BUYOUT_NOTE,
      NAMED_RANGES.ARTIST_DEAL_SUMMARY.OTHER_LABEL,
      NAMED_RANGES.ARTIST_DEAL_SUMMARY.OTHER_NOTE,
      NAMED_RANGES.ARTIST_DEAL_SUMMARY.OTHER_VALUE,
      NAMED_RANGES.ARTIST_DEAL_SUMMARY.PRODUCTION_CONTRIBUTION_NOTE,
      NAMED_RANGES.ARTIST_DEAL_SUMMARY.VS_NOTE,
      NAMED_RANGES.ARTIST_DEAL_SUMMARY.WHT_NOTE,
      NAMED_RANGES.ARTIST_DEAL_SUMMARY.WHT_TOGGLE,
      NAMED_RANGES.ARTIST_DEAL_SUMMARY.WHT_VALUE,
    ];
    const eventNamedRanges = await getValuesFromNamedRanges({
      sheet: eventSheet.sheetName,
      namedRanges: eventNamedRangeLookups,
    });

    return eventNamedRanges;
  };

  // rename collect event info for sync
  const eventWorksheetData = async (eventSheet: EventSheet, offer: Offer): Promise<EventWorksheetSyncData> => {
    const event = offer?.events?.find((eventItem) => eventItem?.id === eventSheet.id);
    if (!event) {
      throw new Error('Event in sheet not found in list of events in Offer.');
    }

    const boxOffices = await getEventBoxOffices(event, eventSheet);
    const eventValueRanges = await getEventRangeValues(eventSheet);
    const eventTextRanges = await getEventRangeTextValues(eventSheet);

    const eventNamedRanges = {
      ...eventValueRanges,
      ...eventTextRanges,
    };

    const boxOfficesInput = boxOffices.map((boxOffice) => {
      const modifyBoxOfficeInput = {
        items: boxOffice.phases[0].items,
        fees: boxOffice.phases[0].fees,
        percentSold: boxOffice.phases[0].percentSold,
      };

      const modifyPayload = {
        boxOfficeId: boxOffice.id,
        boxOfficePhaseId: boxOffice.phases[0].id,
        boxOfficePhase: modifyBoxOfficeInput,
      };
      return modifyPayload;
    });

    const expenseInput = await getEventExpenseData(
      event.id ?? '',
      eventSheet,
      eventNamedRanges,
      event.exchangeRate || 1,
    );

    const { status, ...eventInput } = getEventInformation(eventSheet, eventNamedRanges);

    return {
      boxOfficesInput,
      expenseInput,
      eventInput,
      eventSheetStatus: status || undefined,
    };
  };

  const sortWorkbook = async (rowOrder: string[], sortOrder: Excel.SortField[]) => {
    await checkIfWorkbookIsSyncedToSharepoint();

    console.time('sortWorkbook');
    console.group('sortWorkbook');

    const hideAegLoadingDialog = await displayAegLoadingDialog();

    const showDetailSheetName = await getManagedSheetName({
      sheetType: ManagedSheetType.SHOW_DETAIL,
      defaultSheetName: MANAGED_MODELING_SHEETS.show_detail,
    });

    await sortTable(showDetailSheetName, SHOW_DETAIL_TABLE_NAME, sortOrder);

    const requiredSheets = [
      ManagedSheetType.APPROVAL_EMAIL,
      ManagedSheetType.APPROVAL_REVISED,
      ManagedSheetType.LATERAL_SUM_POTENTIAL,
      ManagedSheetType.OVERHEAD_COSTS,
      ManagedSheetType.QUICK_SUM,
      ManagedSheetType.SUMMARY,
      // Summary Uncrossed technically required, but not managed sheet
      ManagedSheetType.SHOW_DETAIL,
    ];

    const requiredSheetNames = await Promise.all(
      requiredSheets.map(async (sheetType) =>
        getManagedSheetName({
          sheetType,
          defaultSheetName: MANAGED_MODELING_SHEETS[sheetType],
        })),
    );

    await reorderWorksheets(rowOrder, [showDetailSheetName, ...requiredSheetNames]);

    hideAegLoadingDialog();

    console.timeEnd('sortWorkbook');
    console.groupEnd();
  };

  const getOverheadCosts = async () => {
    if (!offerId) {
      throw new Error('Missing required parameters');
    }

    const overheadCosts: Record<string, object> = {};

    const overheadCostsSheetName = await getManagedSheetName({
      sheetType: ManagedSheetType.OVERHEAD_COSTS,
      defaultSheetName: MANAGED_MODELING_SHEETS.overhead_costs,
    });

    for (const [key, tableName] of Object.entries(OVERHEAD_COSTS_SHEET_TABLES)) {
      const tableData = await parseTable(
        overheadCostsSheetName,
        `OverheadCosts_${capitalize(tableName)}`,
        undefined,
        false,
        false,
        true,
      );

      const filteredData = tableData.map((items) => mapOverheadCostsFields(tableName, items));
      overheadCosts[tableName] = filteredData;
    }

    const OVERHEAD_COSTS = NAMED_RANGES.OVERHEAD_COSTS.FINANCING_COSTS;

    const rangeValues = await getValuesFromNamedRanges({
      sheet: overheadCostsSheetName,
      namedRanges: Object.values(OVERHEAD_COSTS),
    });

    overheadCosts.financing = {
      depositAmount: toNumberOrUndefined(rangeValues[OVERHEAD_COSTS.DEPOSIT_AMOUNT]),
      financingCosts: [
        {
          amountDueRate: toNumberOrUndefined(rangeValues[OVERHEAD_COSTS.AMOUNT_DUE_RATE_1]),
          months: toNumberOrUndefined(rangeValues[OVERHEAD_COSTS.FINANCING_COSTS_MONTHS_1]),
          rate: toNumberOrUndefined(rangeValues[OVERHEAD_COSTS.FINANCING_COSTS_RATE_1]),
        },
        {
          amountDueRate: toNumberOrUndefined(rangeValues[OVERHEAD_COSTS.AMOUNT_DUE_RATE_2]),
          months: toNumberOrUndefined(rangeValues[OVERHEAD_COSTS.FINANCING_COSTS_MONTHS_2]),
          rate: toNumberOrUndefined(rangeValues[OVERHEAD_COSTS.FINANCING_COSTS_RATE_2]),
        },
        {
          amountDueRate: toNumberOrUndefined(rangeValues[OVERHEAD_COSTS.AMOUNT_DUE_RATE_3]),
          months: toNumberOrUndefined(rangeValues[OVERHEAD_COSTS.FINANCING_COSTS_MONTHS_3]),
          rate: toNumberOrUndefined(rangeValues[OVERHEAD_COSTS.FINANCING_COSTS_RATE_3]),
        },
      ],
    };

    return overheadCosts;
  };

  const getValidationFields = async () => {
    // Get the range values from a sheet
    const getRangeValues = async (sheet: string, namedRanges: string[]) => {
      const rangeValues = await getValuesFromNamedRanges({
        sheet,
        namedRanges,
      });
      return rangeValues;
    };

    // Named ranges for the validation fields
    const SUMMARY_VALIDATION = NAMED_RANGES.VALIDATION_FIELDS.SUMMARY;

    const summarySheetName = await getManagedSheetName({
      sheetType: ManagedSheetType.SUMMARY,
      defaultSheetName: MANAGED_MODELING_SHEETS.summary,
    });

    // Get the range values from the summary sheet
    const summaryRangeValues = await getRangeValues(summarySheetName, Object.values(SUMMARY_VALIDATION));

    const validationFieldsSnapshot: SyncValidationFieldsInput = {
      numberOfShows: toNumberOrUndefined(summaryRangeValues[SUMMARY_VALIDATION.NUM_OF_SHOWS]),
      grossTicketSales: toNumberOrUndefined(summaryRangeValues[SUMMARY_VALIDATION.GROSS_TICKET_SALES]),
      lessShowCosts: toNumberOrUndefined(summaryRangeValues[SUMMARY_VALIDATION.LESS_SHOW_COSTS]),
      netPoolIncomeBeforeArtistSplit: toNumberOrUndefined(
        summaryRangeValues[SUMMARY_VALIDATION.NET_POOL_INCOME_ARTIST_SPLIT],
      ),
      totalArtistEarnings: toNumberOrUndefined(summaryRangeValues[SUMMARY_VALIDATION.TOTAL_ARTIST_EARNINGS]),
      netPromoterIncome: toNumberOrUndefined(summaryRangeValues[SUMMARY_VALIDATION.NET_PROMOTER_INCOME]),
    };

    return validationFieldsSnapshot;
  };

  const syncModelingWorkbook = async () => {
    if (!tourId || !offerId) {
      throw new Error('Missing required parameters');
    }

    console.group('syncModelingWorkbook');
    console.time('syncModelingWorkbook');

    console.time('checkIfWorkbookIsSyncedToSharepoint');
    await checkIfWorkbookIsSyncedToSharepoint();
    console.timeEnd('checkIfWorkbookIsSyncedToSharepoint');

    console.time('generateSyncData');
    console.group('generateSyncData');
    const tourLatest = await getTour({
      variables: { tourId, offerId, phaseId: Phases.MODELING },
    });
    const tour = tourLatest.data?.tour;
    const offer = tour?.offers?.find((offerItem) => offerItem?.id === offerId);

    // Get the Tour Financial Summary payload
    console.time('generateTFinSum');
    const { numberOfTrucks, ...tourFinancialSummary } = await generateTourFinancialSummaryInput();
    console.timeEnd('generateTFinSum');

    // Get all Event Market Sheets
    const eventSheets = await getEventWorksheets();

    const eventSheetSyncData: Pick<EventWorksheetSyncData, 'boxOfficesInput' | 'eventInput' | 'expenseInput'>[] = [];
    const eventSheetStatusData: { sheet: EventSheet; status: string }[] = [];

    // go through each sheet and gather all data needed for sync & post sync sheet updates.
    console.time('generateEventWorksheetData');
    for (const eventSheet of eventSheets) {
      const { eventSheetStatus, ...dataToSync } = await eventWorksheetData(eventSheet, offer as Offer);
      eventSheetSyncData.push(dataToSync);

      // status found in the sheet. record it to see if we need to update.
      if (eventSheetStatus) {
        eventSheetStatusData.push({ sheet: eventSheet, status: eventSheetStatus });
      }
    }
    console.timeEnd('generateEventWorksheetData');

    console.groupEnd();
    console.timeEnd('generateSyncData');

    console.time('syncAPIRequests');

    const approvalEmailSheetName = await getManagedSheetName({
      sheetType: ManagedSheetType.APPROVAL_EMAIL,
      defaultSheetName: MANAGED_MODELING_SHEETS.approval_email,
    });

    // check for existence of workbook v1.15 approval email named ranges before syncing data
    const workbookHasApprovalEmailRanges = await checkWorksheetForNamedRange(
      approvalEmailSheetName,
      NAMED_RANGES.APPROVAL_EMAIL.ORIGINAL_MODEL.TERRITORY,
    );

    console.time('getOverheadCosts');
    const overheadCosts = workbookHasApprovalEmailRanges ? await getOverheadCosts() : undefined;
    console.timeEnd('getOverheadCosts');

    console.time('getApprovalEmailInfo');
    const approvalEmailInfo = workbookHasApprovalEmailRanges ? await getApprovalEmailInfo() : undefined;
    console.timeEnd('getApprovalEmailInfo');

    console.time('getValidationFields');
    const syncValidationFields = await getValidationFields();
    console.timeEnd('getValidationFields');

    const modifyOfferPayload: ModifyOfferInput = {
      id: offerId,
      numberOfTrucks,
      overheadCosts,
      approvalEmailInfo,
      syncValidationFields,
      lastSynced: new Date().toISOString(),
    };

    const syncEventsData = eventSheetSyncData.flatMap((eventData) => {
      const { boxOfficesInput, eventInput, expenseInput } = eventData;
      const boxOfficeRequests = boxOfficesInput.map(
        async (boxOfficeInput) => syncModelingBoxOfficeData({ variables: boxOfficeInput }),
      );

      const eventExpenseRequest = syncModelingEventData({
        variables: {
          event: eventInput,
          expense: expenseInput,
        },
      });

      return [eventExpenseRequest, ...boxOfficeRequests];
    });

    await Promise.all(syncEventsData);

    const { data } = await syncModelingOfferData({
      variables: {
        tourId,
        offerId,
        offer: modifyOfferPayload,
        summary: tourFinancialSummary,
      },
    });

    console.timeEnd('syncAPIRequests');

    console.groupEnd();

    // look at the updated offer data. iterate through events and see if we need to update the status.
    console.time('ApplyEventStatusChangesToSheet');
    if (data && data.modifyOffer) {
      const { events } = data.modifyOffer;
      const eventSheetStatusUpdates = eventSheetStatusData.flatMap(({ sheet, status }) => {
        const event = events?.find((eventItem) => eventItem?.id === sheet.id);
        // if we have a matching event and the status is different.
        if (event && event.status !== status) {
          return [async () => applyValuesToNamedRanges({
            sheet: sheet.sheetName,
            namedRangeAssignments: [{ range: EVENT_INFO.STATUS, value: event.status as string }],
          })];
        }
        return [];
      });
      // apply all status updates to sheets that need it
      if (eventSheetStatusUpdates.length) {
        // sheet manipulation needs to happen one at a time or excel overloads and throws.
        for (const eventSheetUpdate of eventSheetStatusUpdates) {
          await eventSheetUpdate();
        }
      }
    }
    console.timeEnd('ApplyEventStatusChangesToSheet');

    await saveWorkbook();

    console.timeEnd('syncModelingWorkbook');
  };

  const postUpdateSyncToWorksheets = async () => {
    if (!tourId || !offerId) {
      throw new Error('Missing required parameters');
    }

    const tourLatest = await getTour({
      variables: { tourId, offerId, phaseId: Phases.MODELING },
    });
    const tour = tourLatest.data?.tour;
    const offer = tour?.offers?.find((offerItem) => offerItem?.id === offerId);

    // Get all Event Market Sheets
    const eventSheets = await getEventWorksheets();

    for (const eventSheet of eventSheets) {
      const event = offer?.events?.find((eventItem) => eventItem?.id === eventSheet.id);
      if (event && event?.status !== OfferStatus.Canceled && event?.status !== OfferStatus.Dead) {
        await applyValuesToNamedRanges({
          sheet: eventSheet.sheetName,
          namedRangeAssignments: [{ range: EVENT_INFO.STATUS, value: event.status as string }],
        });
      }
    }
  };

  // TODO: Find a better home for this call
  const getAegContext = async () => {
    const customProperties = await getCustomProperties();
    return String(customProperties.AEGContext ?? '');
  };

  const updateVenueDataInWorkbook = async (
    event: UpdateEventVenueMutation['updateEventVenue'],
    baseCurrency?: string | null,
    previousVenueLocalCurrencyCode?: string,
  ) => {
    console.time('updateVenueDataInWorkbook');
    if (!event?.id) {
      return;
    }

    try {
      await removeSheetRenameListenerToWorkbook();
      const originalWorksheet = await getEventWorksheetFromEventId(event.id);
      if (!originalWorksheet) {
        setNotification({
          text: `Unable to find worksheet from event ID - ${event.id}`,
          type: SnackbarType.WARNING,
          duration: 6000,
        });
        return;
      }

      // replace sheet name with new market name
      const marketName = eventMarketName(event as Event);
      const numeratedEventMarketName = await generateModelingMarketSheetName(marketName);

      await setCalculationMode(ExcelCalcMode.manual);
      await renameSheet({
        currentSheetName: originalWorksheet?.sheetName || '',
        newSheetName: numeratedEventMarketName,
      });

      await setCustomProperty('numeratedEventMarketName', numeratedEventMarketName, numeratedEventMarketName);

      await addSheetNamePrefixToTables({ sheetName: numeratedEventMarketName });
      await setCalculationMode(ExcelCalcMode.automatic);
      const updatedWorksheet = await getEventWorksheetFromEventId(event.id);

      if (updatedWorksheet) {
        const localCurrency = event.venue?.localCurrencyCode as string;

        // update event info table with new venue data
        const namedRangeAssignments: NamedRangeAssignment[] = [
          { range: EVENT_INFO.MARKET_NAME, value: marketName },
          { range: EVENT_INFO.COUNTRY, value: event.venue?.country },
          { range: EVENT_INFO.VENUE_NAME, value: event.space?.name },
          { range: EVENT_INFO.LOCAL_CURRENCY, value: localCurrency },
        ];
        if (previousVenueLocalCurrencyCode !== localCurrency) {
          await validateAndApplyNewCurrency(numeratedEventMarketName, localCurrency, baseCurrency);
        }

        await setCalculationMode(ExcelCalcMode.manual);
        await applyValuesToNamedRanges({
          sheet: updatedWorksheet.sheetName,
          namedRangeAssignments,
        });
        await setCalculationMode(ExcelCalcMode.automatic);

        const showDetailSheetName = await getManagedSheetName({
          sheetType: ManagedSheetType.SHOW_DETAIL,
          defaultSheetName: MANAGED_MODELING_SHEETS.show_detail,
        });

        // update show details table with new venue data
        const headers = await getTableHeaders(showDetailSheetName, SHOW_DETAIL_TABLE_NAME);
        const newShowDetailRow = generateShowDetailRowForSheet(updatedWorksheet, headers, appInsights);
        const indexOfRowToUpdate = await findCellReferencingSheet(
          showDetailSheetName,
          SHOW_DETAIL_TABLE_NAME,
          originalWorksheet.sheetName,
          'Status',
        );

        if (indexOfRowToUpdate !== -1) {
          await setCalculationMode(ExcelCalcMode.manual);
          await updateRowOnTable(showDetailSheetName, SHOW_DETAIL_TABLE_NAME, indexOfRowToUpdate, [newShowDetailRow]);
        }
      }
    } finally {
      await setCalculationMode(ExcelCalcMode.automatic);
      await activateSheetRenameHandler();
      console.timeEnd('updateVenueDataInWorkbook');
    }
  };

  const updateEventVenue = async (
    eventId: string,
    venue: UpdateEventVenueInput,
    previousVenueLocalCurrencyCode?: string,
  ) => {
    if (!eventId) {
      throw new Error('Missing required parameters');
    }

    console.time('updateEventVenue');
    const response = await updateVenue({
      variables: {
        eventId,
        venue,
      },
    });

    console.timeEnd('updateEventVenue');

    if (response.data?.updateEventVenue) {
      let baseCurrency;

      if (tourId) {
        const { data: tourLatest } = await getTourCurrency({
          variables: { tourId },
        });
        baseCurrency = tourLatest?.tour?.currency;
      }

      await updateVenueDataInWorkbook(response.data.updateEventVenue, baseCurrency, previousVenueLocalCurrencyCode);
      const eventStatus = response.data.updateEventVenue.status;
      if (eventStatus === EventStatus.Confirmed || eventStatus === EventStatus.RoutingConfirmed) {
        void handleShareWithMarketing();
      }
    }

    return response;
  };

  const selectEventWorksheet = async (eventId: string) => {
    const worksheet = await getEventWorksheetFromEventId(eventId);
    if (worksheet) {
      await activateWorksheet(worksheet.sheetName);
    } else {
      setNotification({
        text: 'This event is not linked to a market worksheet',
        type: SnackbarType.WARNING,
        duration: 6000,
      });
    }
  };

  const getEventsWithoutExchangeRateOrBaseCurrency = async (selectedEvents: string[]) => {
    const allEventSheets = await getEventWorksheets();
    const worksheetsToCheck = allEventSheets.filter((sheet) => selectedEvents.includes(sheet.id));
    const sheetsWithoutExchangeOrBase: { id: string, sheetName: string, missingRanges: string[] }[] = [];

    for (const sheet of worksheetsToCheck) {
      const namedRanges = await getValuesFromNamedRanges({
        sheet: sheet.sheetName,
        namedRanges: [
          NAMED_RANGES.EVENT_INFO.BASE_CURRENCY,
          NAMED_RANGES.EVENT_INFO.EXCHANGE_RATE,
        ],
      });

      const missingRanges = [];
      if (!namedRanges[NAMED_RANGES.EVENT_INFO.BASE_CURRENCY]) {
        missingRanges.push(NAMED_RANGES.EVENT_INFO.BASE_CURRENCY);
      }

      if (!namedRanges[NAMED_RANGES.EVENT_INFO.EXCHANGE_RATE]) {
        missingRanges.push(NAMED_RANGES.EVENT_INFO.EXCHANGE_RATE);
      }

      if (missingRanges.length > 0) {
        sheetsWithoutExchangeOrBase.push({
          id: sheet.id,
          sheetName: sheet.sheetName,
          missingRanges,
        });
      }
    }

    return sheetsWithoutExchangeOrBase;
  };

  const canRefreshWorkbook = async () => {
    const otherActiveUsers = await checkForOtherActiveUsers();
    return !otherActiveUsers;
  };

  const prepareWorkbookRefresh = async () => {
    const currentManagedSheetsTable = await parseTable(
      MANAGED_SHEETS_SHEET_NAME,
      MANAGED_SHEETS_TABLES.MANAGED_SHEETS,
      MANAGED_SHEETS_ROW,
      false,
      true,
    );

    const managedSheetNames = currentManagedSheetsTable.map(
      (row) => getManagedSheetNameFromFormula(row.sheetNameFormula),
    );

    // Validate the sheet names from the managed sheets table against the current workbook sheet names
    const currentSheetNames = await currentWorkbookSheetNames();
    const missingSheets = findMissingManagedSheets(currentSheetNames, managedSheetNames);
    if (missingSheets.length > 0) {
      setNotification({
        type: SnackbarType.ERROR,
        text: `The following sheets weren't found in the workbook: ${
          missingSheets.join(', ')
        }. Please contact booking support.`,
      });
      return false;
    }

    const eventSheets = await getEventWorksheets();
    const currentEventsInTable = currentManagedSheetsTable.map((row) => row.eventId);
    const managedSheetsTableData = currentManagedSheetsTable.map(Object.values);

    // @typescript-eslint/no-for-in-array
    eventSheets.forEach((sheet) => {
      const row = getManagedSheetRowForEventSheet(sheet);

      // Check if the event is already in the managed sheets table, update or create accordingly
      const currentRow = currentEventsInTable.findIndex((eventId) => eventId === sheet.id);
      if (currentRow !== -1) {
        managedSheetsTableData.splice(currentRow, 1, row);
      } else {
        managedSheetsTableData.push(row);
      }
    });

    // Clean insert will improperly infer the formulas, delete the range then batch insert the new data
    await deleteTableData(MANAGED_SHEETS_SHEET_NAME, MANAGED_SHEETS_TABLES.MANAGED_SHEETS);
    await populateMultipleTables(MANAGED_SHEETS_SHEET_NAME, [{
      cleanInsert: false,
      batchInsert: true,
      tableData: {
        name: MANAGED_SHEETS_TABLES.MANAGED_SHEETS,
        data: managedSheetsTableData,
      },
    }]);

    return true;
  };

  const prepareRefreshWorkbook = async () => {
    const successfullyPreppedRefresh = await prepareWorkbookRefresh();

    if (successfullyPreppedRefresh) {
      const variables = {
        tourId: tourId as string,
        offerId: offerId as string,
      };

      const { data: refreshInfo } = await getWorkbookFileId({ variables });
      await setCustomProperty(
        WORKBOOK_CUSTOM_PROPERTIES.IS_RETIRED,
        refreshInfo?.tour?.offers?.[0]?.workbookFileId as string,
      );
    }
  };

  const checkWorkbookIsRetired = async (offer: Offer): Promise<boolean> => {
    if (offer.populateWorksheet === false && offer.cloneMap) {
      // Offer is duplicated, clear the retired property
      await setCustomProperty(WORKBOOK_CUSTOM_PROPERTIES.IS_RETIRED, '');
      return false;
    }

    const workbookProperties = await getCustomProperties();
    return (
      !!workbookProperties[WORKBOOK_CUSTOM_PROPERTIES.IS_RETIRED]
      && workbookProperties[WORKBOOK_CUSTOM_PROPERTIES.IS_RETIRED] !== offer.workbookFileId
    );
  };

  return {
    addEventToOffer,
    addShowToEvent,
    cloneEvent,
    syncModelingWorkbook,
    postUpdateSyncToWorksheets,
    getEventInformation,
    getArtistOffer,
    generateBoxOfficeSummaryTableData,
    generateTableName,
    getOverheadCosts,
    initModelingWorkbook,
    populateModelingWorkbook,
    sortWorkbook,
    // TODO: Move these into their own base hook
    activateEvent,
    addEventShowDetailRow,
    generateSheetFromEvent,
    getEventWorksheetFromEventId,
    removeEventFromOffer,
    populateTourFinancialSummarySheet,
    cancelEventFromOffer,
    updateEventVenue,
    setNamedRangesForNewEvent,
    removeShowFromEvent,
    modifyShowOnEvent,
    killEvent,
    getVariableCostInputFromExcel,
    getEventExpenseData,
    getAegContext,
    prepareRefreshWorkbook,
    canRefreshWorkbook,
    getManagedSheetName,
    // For unit testing only
    populateBoxOfficeFees,
    updateVenueDataInWorkbook,
    selectEventWorksheet,
    getEventsWithoutExchangeRateOrBaseCurrency,
    checkWorkbookIsRetired,
    getValidationFields,
  };
}
