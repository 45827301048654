import { Dayjs } from 'dayjs';
import { DatePickerProps } from '@mui/x-date-pickers';
import CalendarToday from '@mui/icons-material/CalendarToday';
import { BorderlessDateInput } from './DatePickers.styled';

/**
 * Styled MUI/Dayjs DatePicker with only a bottom border.
 * @param props - props passed will override defaults defined in the component
 * @returns
 */
export const BorderlessDatePicker = (props: DatePickerProps<Dayjs>) => (
  <BorderlessDateInput
      label={<></>}
      slots={{
        openPickerIcon: () => <CalendarToday fontSize='small'/>,
      }}
      {...props}
  />);
