// TODO: Resolve type issues in this view
/* eslint-disable max-lines-per-function */
/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable-next-line import/no-extraneous-dependencies */
import { DevTool } from '@hookform/devtools';
import Typography from '@mui/material/Typography';
import { useMemo, useEffect, useState } from 'react';
import { SubmitErrorHandler, SubmitHandler, useForm } from 'react-hook-form';
import {
  AddTourInput,
  Artist,
  CompanyPermission,
  JdeBusinessUnit,
  ModifyTourInput,
  Tour,
} from '@gql/types/graphql';
import { SEARCH_PARENT_TOURS } from '@gql/queries/parentTours';
import { SEARCH_ARTISTS } from '@gql/queries/artists';
import { ApolloError, useQuery } from '@apollo/client';
import { useYupValidationResolver } from '@hooks';
import { GET_COMPANIES } from '@gql/queries/companies';
import { GET_JDE_BUSINESS_UNITS } from '@gql/queries/jdeBusinessUnits';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { logError } from '@services/telemetry-service';
import { MAX_CHARS_TOUR_NAME } from '@utils/excel/shared/constants';
import { FormError } from '@types';
import { ButtonContainer, SubHeader } from './EditTourForm.styled';
import {
  FormTextInput,
  AutocompleteInput,
  FormErrorComponent,
} from '../shared';
import { editTourFormValidationSchema } from './EditTourForm.schema';
import {
  AutocompleteFieldArray,
  ButtonStyled,
  CheckboxInput,
} from '../shared/Form';

const EDIT_TOUR_TEXT = {
  title: 'Edit Tour Details',
  submitText: 'Save',
  subTitle: null,
};

interface EditTourField {
  id: string;
  name: string;
}

export interface EditTourFormFields {
  id: string;
  companyId: string;
  companyName: string;
  role: string;
  isMultiLeg: boolean;
  parentTour: EditTourField | null;
  name: string;
  headliner: EditTourField | null;
  coHeadliners: EditTourField[] | null;
  coPromoters: string;
  businessUnitId: EditTourField | null;
}

type EditTourFormErrors = {
  companyId: FormError[];
  name: FormError[];
  headliner: FormError[];
  coHeadliners: FormError[];
  company: FormError[];
  businessUnitId: FormError[];
  parentTour: FormError[];
};

interface EditTourFormProps {
  onFormSubmit: SubmitHandler<AddTourInput | ModifyTourInput>;
  isFormSaving: boolean; // Push saving state into form
  tour: Tour;
  mutationError?: ApolloError;
}

export function EditTourForm({
  onFormSubmit,
  isFormSaving,
  tour,
  mutationError,
}: EditTourFormProps) {
  const appInsights = useAppInsightsContext();
  const [isFormErrorOpen, setIsFormErrorOpen] = useState(false);
  const [artistSearchVal, setArtistSearchVal] = useState('');
  const [parentTourSearchVal, setParentTourSearchVal] = useState('');

  const validationSchema = useMemo(editTourFormValidationSchema, []);
  const resolver = useYupValidationResolver(validationSchema);

  const { title, subTitle, submitText } = useMemo(() => EDIT_TOUR_TEXT, []);

  const defaultValues = useMemo(() => {
    const {
      id, name, headliner, coHeadliners, parentTour, company, jdeBusinessUnit,
    } = tour;

    return {
      id,
      name,
      headliner,
      coHeadliners,
      isMultiLeg: parentTour?.id !== undefined,
      parentTour: parentTour?.id ? { name: parentTour?.name, id: parentTour?.id } : undefined,
      companyId: company?.id,
      companyName: company?.name,
      businessUnitId: { id: jdeBusinessUnit?.id },
    } as EditTourFormFields;
  }, []);

  const {
    formState: { isDirty },
    control,
    watch,
    reset,
    handleSubmit,
    getValues,
    setValue,
  } = useForm<EditTourFormFields>({
    resolver,
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues,
  });

  const isMultiLegWatch = watch('isMultiLeg');
  const parentTourWatch = watch('parentTour');
  const coHeadlinersWatch = watch('coHeadliners');

  const { loading: artistsLoading, data: artistsData } = useQuery(
    SEARCH_ARTISTS,
    {
      errorPolicy: 'all',
      variables: { searchTerm: artistSearchVal },
    },
  );

  const { loading: parentToursLoading, data: parentToursData } = useQuery(
    SEARCH_PARENT_TOURS,
    {
      errorPolicy: 'all',
      variables: { searchTerm: parentTourSearchVal },
      skip: parentTourSearchVal.length < 1,
    },
  );

  const { loading: jdeLoading, data: jdeData } = useQuery(
    GET_JDE_BUSINESS_UNITS,
    {
      errorPolicy: 'all',
      // TODO: add filter type: 'TOURING' in SANE-4886,
      variables: { companyNumber: tour.company?.jdeCompanyNumber },
    },
  );

  const { data: companyData } = useQuery(GET_COMPANIES, {
    variables: {
      permission: CompanyPermission.CreateTours,
    },
  });

  let artistOptions: any[] = [];
  if (!artistsLoading && artistsData?.artists) {
    artistOptions = artistsData.artists as Artist[];
  }

  let jdeOptions: any[] = [];
  if (!jdeLoading && jdeData?.jdeBusinessUnits) {
    jdeOptions = jdeData.jdeBusinessUnits as JdeBusinessUnit[];
  }

  const getSelectedArtists = useMemo(() => {
    const selectedArtists = [getValues('headliner')?.id || ''];
    if (coHeadlinersWatch?.length) {
      selectedArtists.push(...coHeadlinersWatch.map((a) => a?.id || ''));
    }

    return selectedArtists;
  }, [getValues()]);

  const handleInvalidSubmit: SubmitErrorHandler<EditTourFormErrors> = (
    error,
  ) => {
    if (isFormErrorOpen) {
      setIsFormErrorOpen(false);
    }
  };

  // TODO Pull create/modify mutations into tour form
  const handleValidSubmit = async (data: EditTourFormFields) => {
    const {
      role,
      parentTour,
      headliner,
      coHeadliners,
      name,
      id,
      companyId,
      businessUnitId,
    } = data;

    const editTour = {
      id,
      name: name.trim(),
      headliner: headliner?.id,
      coHeadliners: coHeadliners?.filter((a) => a !== null && a.id && a.name).map((a) => a.id),
      role,
      companyId: tour.hasBeenConfirmed ? undefined : companyId, // these fields are disabled for a confirmed tour
      jdeBusinessUnitsId: tour.hasBeenConfirmed ? undefined : businessUnitId?.id,
      parentTourId: parentTour?.id,
    } as ModifyTourInput;

    try {
      await onFormSubmit(editTour);
      reset();
    } catch (error) {
      logError(appInsights, 'EditTourForm submit error', error as Error);
      setIsFormErrorOpen(true);
    }
  };

  useEffect(() => {
    if (!isMultiLegWatch) {
      setValue('parentTour', null);
    }
  }, [isMultiLegWatch]);

  return (
    <>
      <Typography variant="h5" data-testid="edit-tours-form-header-text">
        {title}
      </Typography>

      {subTitle && (
        <SubHeader
          variant="body2"
          data-testid="edit-tours-form-sub-header-text"
        >
          {subTitle}
        </SubHeader>
      )}

      <form onSubmit={handleSubmit(handleValidSubmit, handleInvalidSubmit)}>
        <FormErrorComponent
          isDismissable={false}
          error={mutationError}
          isOpen={isFormErrorOpen}
          setIsOpen={setIsFormErrorOpen}
        />

        {tour && (
          <>
            <AutocompleteInput
              fieldName="headliner"
              control={control}
              label="Headliner"
              options={artistOptions}
              dataDisplayField={{ primary: 'name' }}
              dataFilterField="id"
              filterOut={getSelectedArtists}
              onInputChange={setArtistSearchVal}
              placeholderText="Enter Headliner or Artist Name"
              isRequired={true}
              defaultValue={defaultValues.headliner}
            />

            <AutocompleteFieldArray
              subjectName="Co-Headliner"
              fieldName="coHeadliners"
              placeholderText="Enter Co-Headliner or Artist Name"
              control={control}
              options={artistOptions}
              filterOut={getSelectedArtists}
              onInputChange={setArtistSearchVal}
              defaultValue={defaultValues.coHeadliners}
            />
            {/* TODO: SANE-6056 Reimplement adding Co-Promoter */}
            {/* <FormTextInput
              control={control}
              fieldName="coPromoter"
              label="Co-Promoter"
              placeholderText="Enter Co-Promoter Name"
            />

            <InputFieldArray
              subjectName="Co-Promoter"
              fieldName="coPromoters"
              placeholderText="Enter Co-Promoter Name"
              control={control}
            /> */}

            <FormTextInput
              control={control}
              fieldName="name"
              label="Tour Name"
              isRequired={true}
              placeholderText="Enter Tour Name"
              maxLength={MAX_CHARS_TOUR_NAME}
            />

            {tour && (
              <CheckboxInput
                control={control}
                fieldName="isMultiLeg"
                label="This is part of an existing Multi-Leg Tour"
              />
            )}

            {tour && isMultiLegWatch && (
              <AutocompleteInput
                options={parentToursData?.parentTours || []}
                control={control}
                disabled={(parentTourWatch && parentTourWatch.id === tour.parentTour?.id) ?? false}
                fieldName="parentTour"
                label="Multi-Leg Tour Name"
                isRequired={true}
                placeholderText="Enter Multi-Leg Tour Name"
                dataDisplayField={{ primary: 'name' }}
                dataFilterField="id"
                onInputChange={setParentTourSearchVal}
                areOptionsLoading={parentToursLoading}
                minimumCharacters={1}
              />
            )}

            <AutocompleteInput
              fieldName="companyName"
              control={control}
              label="Company"
              options={[]}
              dataDisplayField={{ primary: 'name' }}
              placeholderText="Select Company"
              defaultValue={defaultValues.companyName}
              forcePopupIcon={true}
              disableClearable={true}
              disabled={true}
            />

            <AutocompleteInput
              fieldName="businessUnitId"
              control={control}
              label="Business Unit"
              options={jdeOptions}
              dataDisplayField={{ primary: 'id' }}
              placeholderText="Enter Business Unit"
              isRequired={false}
              defaultValue={defaultValues.businessUnitId}
              forcePopupIcon={true}
              disableClearable={true}
              areOptionsLoading={jdeLoading}
              disabled={
                tour.hasBeenConfirmed === true
                && tour.jdeBusinessUnit?.id !== undefined
                && tour.jdeBusinessUnit.id !== null
              }
            />

            <ButtonContainer>
              <ButtonStyled
                variant="contained"
                data-testid="create-button-form"
                type="submit"
                disabled={isFormSaving || (tour && !isDirty)}
              >
                {submitText}
              </ButtonStyled>
            </ButtonContainer>
          </>
        )}
      </form>
      <DevTool control={control} />
    </>
  );
}
