/* eslint-disable max-len */
import Star from '@mui/icons-material/Star';
import Stars from '@mui/icons-material/Stars';
import Box from '@mui/material/Box';
import { CustomTheme, styled } from '@mui/material/styles';
import { DataGridPro } from '@mui/x-data-grid-pro';

export const NOSVersionsGrid = styled(DataGridPro)(({ theme }: { theme?: CustomTheme }) => ({
  borderRadius: 0,
  borderColor: `${theme?.palette.primary.light[150] || ''}!important`,
  marginBottom: theme?.spacing(3),
  '& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus, & .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-columnHeader:focus':
    {
      outline: 'none',
    },
})) as typeof DataGridPro;

export const NOSGridContainer = styled(Box)(({ theme }: { theme?: CustomTheme }) => ({
  margin: theme?.spacing(1.5),
}));

export const YellowStar = styled(Stars)(({ theme }: { theme?: CustomTheme }) => ({
  color: theme?.palette?.icon?.main,
}));

export const GreyStar = styled(Star)(({ theme }: { theme?: CustomTheme }) => ({
  color: theme?.palette.action.disabledBackground,
  fontSize: theme?.spacing(3),
}));

export const IsPrimaryContainer = styled(Box)(({ theme }: { theme?: CustomTheme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
  width: '100%',
}));
