import Box from '@mui/material/Box';
import { CustomTheme, styled } from '@mui/material/styles';
import { FormStylingMode } from '../Form';

interface GenericModalProps {
  theme?: CustomTheme;
  mode?: FormStylingMode;
}

const modalWidth = (mode?: FormStylingMode) => {
  if (mode === FormStylingMode.MINI) {
    return '25%';
  }
  if (mode === FormStylingMode.COMPACT) {
    return '50%';
  }
  return '90%';
};

export const ModalTitle = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: theme.spacing(1),
}));

export const ModalContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'mode',
})<GenericModalProps>(
  ({ theme, mode }) => ({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: modalWidth(mode),
    backgroundColor: theme?.palette.primary.contrast,
    borderRadius: theme?.spacing(0.5),
    boxShadow: theme?.spacing(3),
    paddingBottom: (mode && mode !== FormStylingMode.DEFAULT) ? 0 : theme?.spacing(3),
  }),
);

export const ModalHeader = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'mode',
})<GenericModalProps>(
  ({ theme, mode }) => {
    let marginBottom = theme?.spacing(0);

    switch (mode) {
      case FormStylingMode.DEFAULT:
        marginBottom = theme?.spacing(5);
        break;
      case FormStylingMode.UNSET:
        marginBottom = theme?.spacing(0);
        break;
      default:
        marginBottom = theme?.spacing(2);
        break;
    }

    return ({
      marginBottom,
      padding: theme?.spacing(3, 3, 0, 3),
    });
  },
);
