/* eslint-disable max-lines-per-function */
import React, {
  useState,
  useEffect,
  useContext,
  SetStateAction,
} from 'react';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import { useAegModeling, useExcel } from '@hooks';
import { EnvStatusContext, EnvConfigContext, DialogDispatchContext } from '@providers';
import { FormStylingMode, GenericModal } from 'src/components/shared';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { logError } from '@services/telemetry-service';
import { useMutation } from '@apollo/client';
import { REFRESH_OFFER_WORKBOOK } from '@gql/mutations/excel';
import { WORKBOOK_CUSTOM_PROPERTIES } from '@utils/excel/shared/constants';
import {
  AlertContainer,
  ButtonContainer,
  ButtonStyled,
  LoadingIconStyled,
  ConfirmationModalContainer,
  ListContainer,
  ListItemStyled,
  DivStyled,
} from './WorkbookRefreshModals.styled';

interface WorkbookRefreshModalsProps {
  setOpen: React.Dispatch<SetStateAction<boolean>>;
}

export const WorkbookRefreshModals = ({ setOpen }: WorkbookRefreshModalsProps) => {
  const defaultSeconds = '10';
  const appInsights = useAppInsightsContext();
  const envConfig = useContext(EnvConfigContext);
  const { envStatus } = useContext(EnvStatusContext);
  const setDialog = useContext(DialogDispatchContext);
  const { canRefreshWorkbook, prepareRefreshWorkbook, syncModelingWorkbook } = useAegModeling();
  const { closeWorkbook, setCustomProperty } = useExcel();
  const [canRefresh, setCanRefresh] = useState<boolean>();
  const [isRefreshing, setIsRefreshing] = useState<boolean>(false);
  const [isClosing, setIsClosing] = useState<boolean>(false);
  const [secondsRemaining, setSecondsRemaining] = useState<string>(defaultSeconds);
  const [refreshOfferWorkbook] = useMutation(REFRESH_OFFER_WORKBOOK);

  useEffect(() => {
    const checkCanRefresh = async () => {
      setCanRefresh(await canRefreshWorkbook());
    };

    void checkCanRefresh();
  }, []);

  const handleCloseWorkbook = async () => {
    await closeWorkbook();
  };

  const handleCountdown = () => {
    const intervalId = setInterval(() => {
      setSecondsRemaining((prevSeconds) => {
        const numSeconds = parseInt(prevSeconds, 10);
        if (numSeconds <= 0) {
          void handleCloseWorkbook();
          clearInterval(intervalId);
          return '00';
        }
        const seconds = numSeconds - 1;
        if (seconds < 10) {
          return `0${seconds}`;
        }
        return seconds.toString();
      });
    }, 1000);

    return () => clearInterval(intervalId);
  };

  const handleRefreshWorkbook = async () => {
    try {
      setIsRefreshing(true);
      await prepareRefreshWorkbook();
      await syncModelingWorkbook();
      await refreshOfferWorkbook({
        variables: {
          tourId: envStatus.tourId as string,
          offerId: envStatus.offerId as string,
        },
        onError: (error) => {
          void setCustomProperty(
            WORKBOOK_CUSTOM_PROPERTIES.IS_RETIRED,
            '',
          );

          throw error;
        },
      });
      setIsClosing(true);
      handleCountdown();
    } catch (error) {
      logError(appInsights, 'Error in handleRefreshWorkbook:', error);
      setDialog({
        titles: {
          dialogTitle: 'Unknown Error',
          alertTitle: 'Something went wrong with the refresh. Please try again later.',
        },
        submit: {
          text: 'Okay',
          action: () => setDialog(null),
        },
      });
      setOpen(false);
      setIsRefreshing(false);
      setIsClosing(false);
      setSecondsRemaining(defaultSeconds);
    }
  };

  if (canRefresh === undefined) {
    return null;
  }

  if (isClosing) {
    return (
      <GenericModal open mode={FormStylingMode.UNSET} title="To complete the update, please close this workbook">
        <ListContainer>
          <ListItemStyled data-testid="list-item">
            After closing the workbook, please navigate to the tour detail page.
          </ListItemStyled>
          <ListItemStyled data-testid="list-item">
            A green checkmark will be displayed to indicate the update is complete.
          </ListItemStyled>
        </ListContainer>
        <AlertContainer>
          <Alert data-testid="workbook-close-warning" severity="warning">
            Do not make any further edits to this workbook
          </Alert>
        </AlertContainer>
        <DivStyled>
          Workbook will automatically close in 0:{secondsRemaining}
        </DivStyled>
      </GenericModal>
    );
  }

  return canRefresh ? (
    <GenericModal open mode={FormStylingMode.UNSET} title="Please confirm you want to update your offer">
      <ConfirmationModalContainer>
        <ListContainer>
          <ListItemStyled data-testid="list-item">
            <a
              href={`${window.location.origin}/tours?intercomArticle=${
                envConfig.intercomArticles.workbookReleaseNotes ?? ''
              }`}
              target="_blank"
            >
              Navigate to Modern Elvis
            </a>{' '}
            and open Intercom to learn about latest updates
          </ListItemStyled>
          <ListItemStyled data-testid="list-item">
            We will save a copy of the previous offer version for future reference.
          </ListItemStyled>
          <ListItemStyled data-testid="list-item">
            This may impact certain financials (e.g., PRS or insurance rates).
          </ListItemStyled>
        </ListContainer>
        <ButtonContainer>
          <ButtonStyled
            data-testid="update-workbook-button"
            variant="contained"
            disabled={isRefreshing}
            onClick={() => {
              void handleRefreshWorkbook();
            }}
          >
            <div>Update</div>
            {isRefreshing && <LoadingIconStyled size={24} />}
          </ButtonStyled>
          <Button
            data-testid="dismiss-modal-button"
            variant="outlined"
            disabled={isRefreshing}
            onClick={() => {
              setOpen(false);
            }}
          >
            Cancel
          </Button>
        </ButtonContainer>
      </ConfirmationModalContainer>
    </GenericModal>
  ) : (
    <GenericModal open mode={FormStylingMode.UNSET} title="Please ask your team to close this file">
      <ListContainer>
        <ListItemStyled data-testid="list-item">
          All users need to close this workbook in order for you to initiate the refresh
        </ListItemStyled>
        <ListItemStyled data-testid="list-item">
          You can see who else has the file open in the top right of Excel
        </ListItemStyled>
        <ListItemStyled data-testid="list-item">
          Once other users have closed the file, initiate the update again
        </ListItemStyled>
      </ListContainer>
      <ButtonContainer>
        <Button data-testid="dismiss-modal-button" onClick={() => setOpen(false)}>
          Got it
        </Button>
      </ButtonContainer>
    </GenericModal>
  );
};
