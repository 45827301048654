/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { AutocompleteGetTagProps } from '@mui/material/useAutocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';
import { useQuery } from '@apollo/client';
import { SEARCH_ENTRA_USERS } from '@gql/queries/users';
import { Bundle, User } from '@gql/types/graphql';
import { AutocompleteOption } from '@components';
import { useYupValidationResolver } from '@hooks';
import {
  ButtonStyled, FormAutocompleteWrapper, FormTextInput, StyledChip,
} from '../shared/Form';
import { CONFIRMATION_EMAIL_MODAL_TEXT } from '../../views/BundleDetails/BundleDetails.models';
import {
  ConfirmationEmailFormContainer,
  ButtonContainer,
  MetadataContainer,
  PrimaryText,
  NameContainer,
  SecondaryTextLarge,
  SecondaryTextSmall,
} from './ConfirmationEmailForm.styled';
import { ConfirmationEmailFormFields, getConfirmationEmailDefaults } from './ConfirmationEmailForm.models';
import { confirmationEmailValidationSchema } from './ConfirmationEmailForm.schema';

interface ConfirmationEmailFormProps {
  bundleMetadata: React.ReactNode;
  bundleDetails: Bundle;
  mutationLoading: boolean;
  handleClose: () => void;
  submitAction: (formData: ConfirmationEmailFormFields) => Promise<void>;
  setIsFormErrorOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

// eslint-disable-next-line max-lines-per-function
export function ConfirmationEmailForm({
  bundleMetadata,
  bundleDetails,
  mutationLoading,
  handleClose,
  submitAction,
  setIsFormErrorOpen,
}: Readonly<ConfirmationEmailFormProps>) {
  const [userSearchVal, setUserSearchVal] = useState('');
  const resolver = useYupValidationResolver(confirmationEmailValidationSchema);
  const { submitText, cancelText } = CONFIRMATION_EMAIL_MODAL_TEXT;

  const { loading: usersLoading, data: usersData } = useQuery(SEARCH_ENTRA_USERS, {
    errorPolicy: 'all',
    variables: { searchTerm: userSearchVal },
    skip: userSearchVal.trim().length < 2,
  });

  const userOptions = useMemo(() => {
    if (usersLoading || !usersData || !usersData.azureEntraUsers) return [];
    return usersData.azureEntraUsers
      .filter((userItem): userItem is User => userItem !== null)
      .sort((a, b) => {
        if (a.isModernElvisUser && !b.isModernElvisUser) return -1;
        if (!a.isModernElvisUser && b.isModernElvisUser) return 1;
        return a.name.localeCompare(b.name);
      });
  }, [usersData, usersData?.azureEntraUsers, usersLoading]);

  const defaultValues = useMemo(() => getConfirmationEmailDefaults(bundleDetails), [bundleDetails]);

  const formMethods = useForm<ConfirmationEmailFormFields>({
    resolver,
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues,
  });

  const {
    control, formState: { errors }, handleSubmit, watch,
  } = formMethods;

  const filterOutNullUserData = (fieldValues: User[]) => {
    const validUserIds = fieldValues
      ?.filter((user): user is User => user !== null && user.email !== null)
      .map((user) => user.id);
    return validUserIds;
  };

  const toRecipientsFormValue = watch('toRecipients');
  const ccRecipientsFormValue = watch('ccRecipients');

  const filterOutToRecipients = useMemo(() =>
    filterOutNullUserData(toRecipientsFormValue), [toRecipientsFormValue, usersData]);
  const filterOutCCRecipients = useMemo(() =>
    filterOutNullUserData(ccRecipientsFormValue), [ccRecipientsFormValue, usersData]);

  const renderUserOption = (
    props: React.HTMLAttributes<HTMLLIElement>,
    option: AutocompleteOption,
  ) => (
    <li {...props} key={option?.name}>
        <div>
          <NameContainer>
            <PrimaryText>{option?.name}</PrimaryText>
            <SecondaryTextLarge>{option.isModernElvisUser ? 'Modern Elvis User' : 'Non-user'}</SecondaryTextLarge>
          </NameContainer>
          <SecondaryTextSmall>
            {option.jobTitle}
            {option.jobTitle && option.department ? ', ' : ''}
            {option.department}
          </SecondaryTextSmall>
        </div>
    </li>
  );

  const handleInvalidSubmit = () => setIsFormErrorOpen(false);

  const handleValidSubmit = async (formData: ConfirmationEmailFormFields) => {
    setIsFormErrorOpen(false);
    await submitAction(formData);
  };

  const renderTags = (tagValue: User[], getTagProps: AutocompleteGetTagProps, errorIndices: number[]) => tagValue.map(
    (option, index) => {
      const itemError = errorIndices.includes(index);
      return (
        <Tooltip
          arrow
          title={!itemError ? option?.email : null}
          key={index}
        >
          <StyledChip
            {...getTagProps({ index })}
            label={option?.name ?? option?.email}
            error={itemError}
          />
        </Tooltip>
      );
    },
  );

  return (
    <ConfirmationEmailFormContainer data-testid="confirmation-email-form-modal">
      <MetadataContainer data-testid="confirmation-email-metadata">
        <Grid
          item
          container
          xs={12}
        >
          {bundleMetadata}
        </Grid>
      </MetadataContainer>
      <FormProvider {...formMethods}>
        <form onSubmit={(e) => {
          void handleSubmit(handleValidSubmit, handleInvalidSubmit)(e);
        }}>
          <FormAutocompleteWrapper
            fieldName={'toRecipients'}
            options={userOptions}
            label='To'
            onInputChange={setUserSearchVal}
            testId={'confirmation-email-to-autocomplete'}
            getOptionLabel={(user: User) => user?.name ?? ''}
            renderTags={(tagValue, getTagProps) => renderTags(
              tagValue as User[],
              getTagProps,
              errors?.toRecipients?.params?.indices ?? [],
            )}
            renderOption={renderUserOption}
            dataLoading={usersLoading}
            filterOut={filterOutToRecipients?.concat(filterOutCCRecipients)}
            freeSolo
            forceEmailValidation
          />
          <FormAutocompleteWrapper
            fieldName={'ccRecipients'}
            options={userOptions}
            label='CC'
            onInputChange={setUserSearchVal}
            testId={'confirmation-email-cc-autocomplete'}
            getOptionLabel={(user: User) => user?.name ?? ''}
            renderTags={(tagValue, getTagProps) => renderTags(
              tagValue as User[],
              getTagProps,
              errors?.ccRecipients?.params?.indices ?? [],
            )}
            renderOption={renderUserOption}
            dataLoading={usersLoading}
            filterOut={filterOutCCRecipients?.concat(filterOutToRecipients)}
            freeSolo
            forceEmailValidation
          />
          <FormTextInput control={control} fieldName="comments" label="Comments" minRow={3} />
          <ButtonContainer>
            <ButtonStyled
              data-testid="cancel-button"
              onClick={handleClose}
              type="button"
              variant="outlined"
            >
              {cancelText}
            </ButtonStyled>
            <ButtonStyled
              variant="contained"
              data-testid="send-confirmation-email-btn"
              type="submit"
              disabled={mutationLoading || Object.keys(errors).length > 0}
              startIcon={mutationLoading && <CircularProgress size={24} color="inherit" data-testid="progress-icon" />}
            >
              {!mutationLoading && submitText}
            </ButtonStyled>
          </ButtonContainer>
        </form>
      </FormProvider>
    </ConfirmationEmailFormContainer>
  );
}
