import React from 'react';
import { useMutation } from '@apollo/client';
import { RETRY_JDE_EVENT_SUBLEDGER } from '@gql/mutations/events';
import { StatusIcon } from '@components';
import Typography from '@mui/material/Typography';
import {
  SubledgerContainer,
} from './JDEEventSubledgerStatus.styled';

type JDEEventSubledgerStatusProps = {
  eventId: string;
  subledgerId: string;
  subledgerStatus: string;
};

export function JDEEventSubledgerStatus({ eventId, subledgerId, subledgerStatus }: JDEEventSubledgerStatusProps) {
  const [retryJdeEventSubledger] = useMutation(RETRY_JDE_EVENT_SUBLEDGER, {
    errorPolicy: 'all',
  });

  const handleRetryJdeEventSubledger = React.useCallback(
    async () => {
      await retryJdeEventSubledger({
        variables: {
          eventId,
        },
      });
    },
    [retryJdeEventSubledger],
  );

  return (
    <SubledgerContainer>
      <Typography>{subledgerId}</Typography>
      <StatusIcon
        status={subledgerStatus}
        messages={{
          pendingTooltip: 'Subledger Pending',
          failedTooltip: 'Retry Subledger',
          errorToast: 'We’re experiencing an issue with JDE. Please try again later.',
        }}
        clickFn={handleRetryJdeEventSubledger}
      />
    </SubledgerContainer>
  );
}
