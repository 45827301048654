import { graphql } from '@gql/types';

export const GET_WORKBOOK_VERSION_INFO = graphql(`
  query GetWorkbookVersionInfo($tourId: String!, $offerId: String!) {
    getVersionInformation(tourId: $tourId, offerId: $offerId) {
      id
      version
      templateVersion
      isCurrent
      notify
    }
  }
`);

export const GET_PREV_WORKBOOK_DOWNLOAD_URL = graphql(`
  query GetPreviousWorkbookDownloadUrl($tourId: ID!, $offerId: ID!) {
    getPreviousWorkbookDownloadUrl(tourId: $tourId, offerId: $offerId) {
      url
    }
  }
`);

export const GET_MODELING_DOWNLOAD_URL = graphql(`
  query GetModelingDownloadUrl($tourId: ID!, $offerId: ID!) {
    getModelingDownloadUrl(tourId: $tourId, offerId: $offerId) {
      url
    }
  }
`);
