/* eslint-disable max-len */
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export function ExcelIconFilled(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M18 4H24V20H18V4Z" fill="#052E54" />
      <path
        d="M0 20L16.8 24V0L0 4V20ZM4.19989 6.66667H6.86989L8.36989 9.62667L9.92995 6.66667H12.5399L9.80984 10.9066L12.8098 15.8933H10.1398L8.36989 12.3732L6.62989 15.8933H4.01995L6.92995 10.9866L4.19989 6.66667Z"
        fill="#052E54"
      />
    </SvgIcon>
  );
}
