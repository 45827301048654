/* eslint-disable max-lines-per-function */
import React, { useContext, useMemo } from 'react';
import { VenueDatagrid } from '@components';
import Add from '@mui/icons-material/Add';
import { VenueManagementContext } from '@providers';
import {
  DataGridProProps,
  GridActionsCellItem,
  GridCellParams,
  GRID_DETAIL_PANEL_TOGGLE_FIELD,
  GridRowId,
  GridRowModel,
} from '@mui/x-data-grid-pro';
import { SpaceConfiguration } from '@gql/types/graphql';
import { expandColumn, manageExpandedRows } from '@utils/datagridHelpers';
import { sumTierCapacity } from '@utils/venueHelpers';
import { SpaceConfigWithErrors } from '@types';
import { PriceTierTable } from './PriceTierTable';
import { AddButtonWithText, VenueDataGridContainer, VenueGridTitle } from '../VenueDatagrids.styled';
import { VenueGridProps, configurationColumns } from '../VenueDatagrids.models';

export const ConfigurationGrid = ({ disableVirtualization }: VenueGridProps) => {
  const context = useContext(VenueManagementContext);
  if (!context.data?.space || !context.data?.venue) return null;

  const {
    setConfiguration,
    addConfiguration,
    removeConfiguration,
    expandedRows,
    setExpandedRows,
    data: { space },
  } = context;
  const getDetailPanelContent = React.useCallback<NonNullable<DataGridProProps['getDetailPanelContent']>
  >(({ row }: { row: SpaceConfiguration }) => <PriceTierTable row={row} disableVirtualization />, []);

  const expandRowClick = (gridRowId: GridRowId) => {
    manageExpandedRows(gridRowId, expandedRows, setExpandedRows);
  };

  const rows = useMemo(() => {
    if (space.configurations) {
      return space.configurations.map((config) => {
        const {
          id,
          description,
          name,
          tierScaling,
          type,
          errors,
        } = config as SpaceConfigWithErrors;

        const capacity = sumTierCapacity(tierScaling);

        return {
          key: id,
          id,
          capacity,
          configType: type,
          description,
          name,
          tiers: tierScaling?.length,
          tierScaling,
          errors,
        };
      });
    }
    return [];
  }, [space.configurations]);

  const columns = [
    ...expandColumn(expandedRows, expandRowClick),
    ...configurationColumns,
    {
      field: 'moreActions',
      type: 'actions',
      resizable: false,
      width: 10,
      getActions: (params: GridCellParams<GridRowModel>) => [
        <GridActionsCellItem
          data-testid="remove-config-btn"
          showInMenu
          key={params.row.id as string}
          label='Remove Configuration'
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          onClick={() => removeConfiguration(params.row.id)}
        />,
      ],
    },
  ];

  return (
    <VenueDataGridContainer>
      <VenueGridTitle variant="h6" data-testid="configurations-grid-title">Configurations</VenueGridTitle>
      <VenueDatagrid
        autoHeight
        columns={columns}
        disableRowSelectionOnClick
        disableVirtualization={disableVirtualization}
        editMode="row"
        getDetailPanelContent={getDetailPanelContent}
        getDetailPanelHeight={() => 'auto'}
        data-testid="configurations-grid"
        hideFooter
        initialState={{
          pinnedColumns: {
            left: [GRID_DETAIL_PANEL_TOGGLE_FIELD],
            right: ['moreActions'],
          },
        }}
        localeText={{ noRowsLabel: 'There are no configurations yet.' }}
        processRowUpdate={setConfiguration}
        rows={rows}
      />
      <AddButtonWithText variant="text" onClick={addConfiguration} data-testid="add-config-btn">
        <Add /> Add New Configuration
      </AddButtonWithText>
    </VenueDataGridContainer>
  );
};
