import { styled } from '@mui/material/styles';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';

export const StyledAccordion = styled(Accordion)(({ theme }) => ({
  borderRadius: '4px',
  marginBottom: '20px',
  maxWidth: `calc(100vw - ${theme.spacing(23)})`,
  '&.MuiAccordion-root:before': {
    display: 'none',
  },
  '&.Mui-disabled': {
    backgroundColor: '#fff',
  },
  '&.MuiAccordionSummary-root': {
    margin: 0,
  },
}));

export const StyledAccordionSummary = styled(AccordionSummary)({
  fontSize: '20px',
  fontWeight: '500',
  lineHeight: '32px',
  minHeight: '72px',
  '&:hover': {
    cursor: 'default !important',
  },
  '& .MuiAccordionSummary-content': {
    margin: 0,
    justifyContent: 'space-between',
  },
});

export const StyledAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
}));

export const HeaderWrapper = styled('div')(({ theme }) => ({
  marginBottom: theme.spacing(0.5),
}));
