import { CustomTheme, styled } from '@mui/material/styles';
import { LineOfBusiness } from '@types';

export const SelectContainer = styled('div')({
  width: '100%',
});

export const SelectLabelContainer = styled('label')(({ theme }) => ({
  color: theme.palette.primary.main,
}));

export const SelectControlContainer = styled('div')(({ theme }) => ({
  position: 'relative',
  'div.alternate': {
    paddingLeft: theme.spacing(0),
  },
}));

export const SelectItemIconContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'lineOfBusiness',
})(({ theme, lineOfBusiness }: { theme?: CustomTheme, lineOfBusiness?: LineOfBusiness }) => {
  let color = theme?.palette?.icon?.main;

  switch (lineOfBusiness) {
    case LineOfBusiness.BUNDLES:
      color = theme?.palette?.alternate?.main;
      break;
    case LineOfBusiness.ONEOFFS:
      color = theme?.palette?.primary?.main;
      break;
    default:
  }

  return ({
    display: 'inline-flex',
    verticalAlign: 'top',
    marginRight: theme?.spacing(1),
    color,
  });
});

export const SelectItemContainer = styled('div')(({ theme }) => ({
  '&.alternate': {
    paddingLeft: theme.spacing(4),
  },
  whiteSpace: 'normal',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: '1',
  WebkitBoxOrient: 'vertical',
}));
