import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { CustomTheme, styled } from '@mui/material/styles';

type PageDetailLayoutProps = {
  pageContentStyle?: React.CSSProperties;
};

export const HeaderDetailRow = styled(Grid)(({ theme }) => ({
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(2.875),
  '& .MuiGrid-item': {
    paddingTop: 0,
  },
})) as typeof Grid;

export const PaperDetailsContentContainer = styled(Paper, {
  shouldForwardProp: (prop) => prop !== 'pageContentStyle',
})<PageDetailLayoutProps>(({ theme, pageContentStyle }) => ({
  height: pageContentStyle?.height ?? 'calc(100vh - 210px)',
  minHeight: theme.spacing(34.5),
  overflow: 'scroll',
}));

export const DivDetailsContentContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'pageContentStyle',
})<PageDetailLayoutProps>(({ theme, pageContentStyle }) => ({
  height: pageContentStyle?.height ?? 'calc(100vh - 210px)',
  minHeight: theme.spacing(34.5),
  overflow: 'scroll',
  padding: 0,
}));

export const PageDetailsContentHeader = styled('div')(({ theme }) => ({
  padding: theme.spacing(3),
  borderBottomColor: theme.palette.divider,
  borderBottomWidth: '1px',
  borderBottomStyle: 'solid',
}));

export const PageDetailsContentBody = styled('div', {
  shouldForwardProp: (prop) => prop !== 'pageContentStyle',
})<PageDetailLayoutProps>(({ theme, pageContentStyle }) => ({
  padding: pageContentStyle?.padding ?? theme.spacing(3),
  position: 'relative',
  minHeight: 'inherit',
}));

export const GridStyled = styled(Grid)(({ theme }) => ({
  paddingLeft: theme.spacing(7.5),
})) as typeof Grid;

export const TabContainer = styled(Box)(
  ({ theme }: { theme?: CustomTheme }) => ({
    background: 'none',
    borderRadius: theme?.spacing(0.5),
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    maxHeight: '100%',
  }),
);
