import { useQuery } from '@apollo/client';
import { getFragmentData } from '@gql/types';
import { useOnError, useCallbackOnWindowFocus } from '@hooks';
import { GET_EVENT_NOS_VERSIONS, NOS_VERSION_TABLE_VENUE, VERSION_TABLE_FRAGMENT } from '@gql/queries/events';
import { GRID_DETAIL_PANEL_TOGGLE_FIELD } from '@mui/x-data-grid-pro';
import { useParams } from 'react-router-dom';
import { useState } from 'react';
import Skeleton from '@mui/material/Skeleton';
import { GetEventNosVersionsQuery } from '@gql/types/graphql';
import { createVersionedId } from '@utils/settlementVersioning';
import { NOSVersionRow } from './NOSVersionsTable.models';
import { NOSGridContainer, NOSVersionsGrid } from './NOSVersionsTable.styled';
import { NOSVersionColumns } from './constants';
import { ModalLayout } from '../shared';
import { NOSSummaryEmail } from '../NOSSummaryEmail/NOSSummaryEmail';
import { SendNosEmailProps } from './NOSVersionTableActions';

// eslint-disable-next-line max-lines-per-function
export function NOSVersionsTable({ eventId }: { eventId: string }) {
  const { id: tourId } = useParams();
  const handleOnError = useOnError();

  const { data: response, loading, refetch: refetchEventData } = useQuery<GetEventNosVersionsQuery>(
    GET_EVENT_NOS_VERSIONS,
    {
      variables: { eventId },
      onError: handleOnError,
    },
  );

  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const [modalIds, setModalIds] = useState<SendNosEmailProps>({
    nosSettlementId: '',
    eventId: '',
    version: undefined,
  });
  const openEmailDialog = (data: SendNosEmailProps) => {
    setModalIds(data);
    setModalOpen(true);
  };

  useCallbackOnWindowFocus(async () => {
    await refetchEventData();
  });

  const event = response?.event;
  const NOSVersions = event?.nosSettlementVersions;

  const rows: NOSVersionRow[] = NOSVersions?.map((nos) => {
    const nosData = getFragmentData(VERSION_TABLE_FRAGMENT, nos);
    const venueData = getFragmentData(NOS_VERSION_TABLE_VENUE, event?.venue);

    return ({
      id: nosData?.id || '',
      tourId,
      venue: venueData,
      event,
      ...nosData,
    });
  }) ?? [];

  return (
    <>
      <NOSGridContainer>
        {loading ? (
          <Skeleton variant="rectangular" animation='wave' sx={{ bgcolor: 'rgba(0,0,0,0.018)' }} height={109} />
        ) : (
          <NOSVersionsGrid
            hideFooter
            columns={NOSVersionColumns(openEmailDialog)}
            rows={rows}
            data-testid="nos-version-grid"
            initialState={{
              sorting: {
                sortModel: [{ field: 'version', sort: 'desc' }],
              },
              pinnedColumns: {
                right: [GRID_DETAIL_PANEL_TOGGLE_FIELD, 'moreActions'],
              },
            }}
          />
        )}
      </NOSGridContainer>
      {isModalOpen && <ModalLayout
        title='Send NOS Summary'
        open={isModalOpen}
        setOpen={setModalOpen}
      >
        <NOSSummaryEmail
          onEmailSend={() => setModalOpen(false)}
          onEmailDiscarded={() => setModalOpen(false)}
          tourId={tourId as string}
          nosSettlementId={modalIds.nosSettlementId}
          expenseId={modalIds.eventId}
          expensePhaseId={createVersionedId('internal_settlement', modalIds.version)}
          version={modalIds.version}
        />
      </ModalLayout>}
    </>
  );
}
