/* eslint-disable max-lines-per-function */
import { Link } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { kebabCase } from '@utils/stringHelpers';

import {
  MetadataTitle,
  AdditionalItemsChip,
  MetadataGridItem,
  TypographyStyled,
  MedadataArrayContainer,
} from './DetailsMetadataItem.styled';

export enum MetadataItemTypes {
  Component = 'component',
}

export type MetadataObject = {
  title: string;
  value?: string | Array<string>;
  element?: JSX.Element;
  link?: string;
  showAll?: boolean;
};

type DetailsMetadataItemsProps = {
  metadata: MetadataObject;
  type?: MetadataItemTypes;
  last?: boolean;
  first?: boolean;
  xs?: number | 'auto';
};

export function DetailsMetadataItem(props: DetailsMetadataItemsProps) {
  const { type } = props;
  const theme = useTheme();

  const metadataValue = (metadataItem: MetadataObject) => {
    if (metadataItem.element) {
      return metadataItem.element;
    }
    if (typeof metadataItem.value === 'string') {
      if (metadataItem.link) {
        return (
          <TypographyStyled
            variant="body2"
            color={theme.palette.info.main}
            data-testid="detail-metadata-value"
          >
            <Link
              to={metadataItem.link}
              data-testid="detail-metadata-value-link"
            >
              {metadataItem.value}
            </Link>
          </TypographyStyled>
        );
      }
      return (
        <TypographyStyled variant="body2" data-testid="detail-metadata-value">
          {metadataItem.value}
        </TypographyStyled>
      );
    }
    if (type && type === MetadataItemTypes.Component) {
      return metadataItem.value;
    }
    if (metadataItem.value?.length) {
      if (metadataItem.showAll) {
        return metadataItem.value.map((item, index) => (
          <MedadataArrayContainer key={`${kebabCase(item)}-${index}`}>
            <TypographyStyled
              key={index}
              variant="body2"
              data-testid="detail-metadata-value"
              display="inline"
            >
              {item.trim() !== '' ? item : '\u00A0'}
            </TypographyStyled>
          </MedadataArrayContainer>
        ));
      }
      return (
        <MedadataArrayContainer>
          <TypographyStyled
            variant="body2"
            data-testid="detail-metadata-value"
            display="inline"
          >
            {metadataItem.value[0]}
          </TypographyStyled>
          {metadataItem.value.length > 1 ? (
            <AdditionalItemsChip
              label={`+${metadataItem.value.length - 1}`}
              size="small"
              data-testid="detail-metadata-value-chip"
            />
          ) : null}
        </MedadataArrayContainer>
      );
    }

    return null;
  };

  return (
    <MetadataGridItem
      item
      sx={props.last ? { minWidth: 'fit-content' } : { width: theme.spacing(20) }}
      data-testid="detail-metadata-item"
    >
      <MetadataTitle variant="subtitle2" data-testid="detail-metadata-title">
        {props.metadata.title}
      </MetadataTitle>
      {metadataValue(props.metadata)}
    </MetadataGridItem>
  );
}
