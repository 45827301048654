/* eslint-disable max-lines-per-function */
import * as React from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  getGridSingleSelectOperators,
  getGridStringOperators,
  GridColDef,
  GridFilterItem,
} from '@mui/x-data-grid-pro';
import { ListViewLayout } from '@layouts/ListViewLayout';
import {
  ProtectedComponent,
  SearchAutocomplete,
  TabPanel,
  FilteringListView,
  ListViewFilter,
  ListViewFilterColumn,
  StartDateFilterInput,
} from '@components';
import { AegResourceTypes } from '@types';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
import { useQuery } from '@apollo/client';
import { GET_ARTISTS_BY_ID } from '@gql/queries/artists';
import { GET_USERS_BY_ID } from '@gql/queries/users';
import { GET_BUNDLES_PAGE, SEARCH_BUNDLES } from '@gql/queries/bundles';
import {
  CompanyPermission, Bundle, BundlesPageFieldsFragment,
} from '@gql/types/graphql';
import { getBundlesColumns } from '@utils/datagridHelpers';
import { useOnError } from '@hooks';
import dayjs, { Dayjs } from 'dayjs';
import { CreateButton } from './BundlesListView.styled';
import {
  PrimaryBuyerFilterInput,
  HeadlinerFilter,
  CompanyFilter,
} from './Filters';

interface BundlesListViewProps {
  disableVirtualization?: boolean;
  listViewFilterModel: ListViewFilter | undefined;
  setListViewFilterModel: React.Dispatch<React.SetStateAction<ListViewFilter | undefined>>;
  setIsDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const BundlesListView = ({
  disableVirtualization,
  listViewFilterModel,
  setListViewFilterModel,
  setIsDrawerOpen,
}: BundlesListViewProps) => {
  const columns: GridColDef<BundlesPageFieldsFragment>[] = getBundlesColumns<BundlesPageFieldsFragment>();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const errorLog = useOnError();

  const {
    loading: loadingBundles,
    fetchMore,
    data: bundlesData,
  } = useQuery(GET_BUNDLES_PAGE, {
    // Note: If limit less than container height (~15), would need to
    // implement 'load more' link/functionality as onRowsScrollEnd will not fire
    variables: {
      page: 1,
      limit: 25,
      bundleSearch: {
        filters: listViewFilterModel?.queryFilter ?? [],
        sort: listViewFilterModel?.sort,
      },
    },
    errorPolicy: 'all',
    onError: (res) => errorLog(res),
  });

  const fetchPage = (page: number) => {
    void fetchMore({
      variables: {
        page,
      },
    });
  };

  const artistIds = searchParams.getAll('headliner').filter(Boolean);
  const { loading: loadingArtists, data: artistData } = useQuery(GET_ARTISTS_BY_ID, {
    errorPolicy: 'all',
    variables: { artistIds },
    skip: !artistIds.length,
  });

  const buyerIds = searchParams.getAll('buyer').filter(Boolean);
  const { loading: loadingBuyers, data: buyerData } = useQuery(GET_USERS_BY_ID, {
    errorPolicy: 'all',
    variables: { userIds: buyerIds },
    skip: !buyerIds.length,
  });

  const updateNameFilter = (searchTerm: string) => {
    const gridFilter = listViewFilterModel?.gridFilter ? [...listViewFilterModel.gridFilter] : [];
    const existingFilterIndex = gridFilter.findIndex((item) => item.field === 'name');

    if (searchTerm.length > 0) {
      if (existingFilterIndex >= 0) {
        gridFilter[existingFilterIndex].value = searchTerm;
      } else {
        gridFilter.push({ field: 'name', value: searchTerm, operator: 'contains' });
      }
    } else if (existingFilterIndex >= 0) {
      gridFilter.splice(existingFilterIndex, 1);
    }

    setListViewFilterModel({
      ...listViewFilterModel,
      gridFilter,
    });
  };

  const mapSearchParamsToFilter = (newSearchParams: URLSearchParams): GridFilterItem[] =>
    Array.from(newSearchParams.entries())
      .map(([field, value], index) => {
        const filterItem: GridFilterItem = {
          id: `${field}-${index}`,
          field,
          value: value ?? '',
          operator: 'is',
        };
        switch (field) {
          case 'name':
            filterItem.operator = 'contains';
            break;
          case 'headliner':
            filterItem.field = 'headlinerIds';
            if (value) {
              filterItem.value = artistData?.artists?.find(((a) => a?.id === value)) ?? { id: value };
            }
            break;
          case 'startDate':
            filterItem.value = value.split(',').map((date) => dayjs.utc(date));
            filterItem.operator = 'range';
            break;
          case 'buyer':
            filterItem.field = 'buyerIds';
            if (value) {
              filterItem.value = buyerData?.users?.find(((u) => u?.id === value)) ?? { id: value };
            }
            break;
          case 'company':
            filterItem.field = 'companyIdsFiltered';
            break;
          case 'status':
          default:
        }
        return filterItem;
      });

  const mapFilterToSearchParams = (gridFilter: GridFilterItem[]): URLSearchParams => {
    const newSearchParams = new URLSearchParams();

    gridFilter.forEach((filterItem) => {
      let { field, value } = filterItem as { field: string; value: unknown };

      let fromDate: Dayjs | undefined;
      let toDate: Dayjs | undefined;
      let from: string;
      let to: string;
      switch (filterItem.field) {
        case 'headlinerIds':
          field = 'headliner';
          value = (value as { id: string })?.id;
          break;
        case 'startDate':
          fromDate = (value as Dayjs[])?.at(0);
          toDate = (value as Dayjs[])?.at(1);
          from = fromDate?.isValid() ? fromDate.format('YYYY-MM-DD') : '';
          to = toDate?.isValid() ? toDate.format('YYYY-MM-DD') : '';
          value = from.length && to.length ? `${from},${to}` : '';
          break;
        case 'buyerIds':
          field = 'buyer';
          value = (value as { id: string })?.id;
          break;
        case 'companyIdsFiltered':
          field = 'company';
          break;
        case 'name':
        case 'status':
        default:
      }
      newSearchParams.append(field, (value ?? '') as string);
    });

    return newSearchParams;
  };

  const memoCols = React.useMemo<ListViewFilterColumn[]>(
    () =>
      columns.map((col) => {
        switch (col.field) {
          case 'name':
            return {
              ...col,
              filterOperators: getGridStringOperators().filter((operator) => operator.value === 'contains'),
              singleFilterAllowed: true,
            };
          case 'startDate':
            return {
              ...col,
              sortable: true,
              filterOperators: [{
                label: 'range',
                value: 'range',
                getApplyFilterFn: () => (): boolean => true, // prevents client-side filtering
                InputComponent: StartDateFilterInput,
              }],
            };
          case 'headlinerIds':
            return {
              ...col,
              sortable: false,
              filterOperators: [
                {
                  label: 'is',
                  value: 'is',
                  getApplyFilterFn: () => (): boolean => true, // prevents client-side filtering
                  InputComponent: HeadlinerFilter,
                },
              ],
            };
          case 'buyerIds':
            return {
              ...col,
              sortable: false,
              filterOperators: [
                {
                  label: 'is',
                  value: 'is',
                  getApplyFilterFn: () => (): boolean => true, // prevents client-side filtering
                  InputComponent: PrimaryBuyerFilterInput,
                },
              ],
            };
          case 'status':
            return {
              ...col,
              sortable: false,
              filterOperators: getGridSingleSelectOperators().filter((operator) => operator.value === 'is'),
            };
          case 'companyIdsFiltered':
            return {
              ...col,
              sortable: false,
              filterOperators: [
                {
                  label: 'is',
                  value: 'is',
                  getApplyFilterFn: () => (): boolean => true, // prevents client-side filtering
                  InputComponent: CompanyFilter,
                },
              ],
            };
          default:
            return ({
              ...col,
              sortable: false,
              filterable: false,
            });
        }
      }),
    [],
  );

  const nameSearchTerm = listViewFilterModel?.gridFilter?.find((item) => item.field === 'name')?.value as string | null;

  const inputAdornment = <InputAdornment position="start">
    <SearchIcon />
  </InputAdornment>;

  return (
    <ListViewLayout headerText="Bundles">
      <TabPanel
        value={0}
        index={0}
        idPrefix="bundles"
        data-testid="bundles-tab-all-bundles"
      >
        <FilteringListView
          filterModel={listViewFilterModel}
          columns={memoCols}
          disableVirtualization={disableVirtualization}
          fetchPage={fetchPage}
          isLoadingData={loadingBundles}
          isLoadingFilters={loadingArtists || loadingBuyers}
          onFilterChange={setListViewFilterModel}
          mapFilterToSearchParams={mapFilterToSearchParams}
          mapSearchParamsToFilter={mapSearchParamsToFilter}
          rows={bundlesData?.bundles ?? []}
          searchComponent={
            <SearchAutocomplete
              defaultValue={nameSearchTerm ?? ''}
              query={SEARCH_BUNDLES}
              getOptions={(data) => data.searchBundles ?? []}
              getOptionLabel={(bundles) => bundles?.name ?? ''}
              placeholder={'Search Bundle Name'}
              onSubmit={updateNameFilter}
              onSelect={(selectedBundle: Bundle) => {
                if (selectedBundle?.id) {
                  navigate(`/bundles/${selectedBundle.id}`);
                }
              }}
              inputAdornment={inputAdornment}
              testId={'bundles-search-autocomplete'}
              inputTestId={'search-autocomplete-input'}
            />
          }
          actions={
            <ProtectedComponent
              checkPermission={{
                permission: CompanyPermission.CreateBundles,
                resourceType: AegResourceTypes.Company,
              }}
            >
              <CreateButton
                data-testid="create-button"
                variant="contained"
                onClick={() => setIsDrawerOpen(true)}
                startIcon={<AddIcon />}
              >
                Create
              </CreateButton>
            </ProtectedComponent>
          }
        />
      </TabPanel>
    </ListViewLayout>
  );
};
