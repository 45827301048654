// import { DevTool } from '@hookform/devtools';
import { Loading } from '@components';
import { DataGridProProps } from '@mui/x-data-grid-pro';
import Typography from '@mui/material/Typography';
import React from 'react';
import {
  GridInfoText,
  StyledDataGrid,
  TableHeader,
  NoRowsContainer,
  ActionsStyled,
} from './ListView.styled';

export type DatagridProps = {
  isDataLoading?: boolean;
  isPaginationLoading?: boolean;
  showGridInfo?: boolean;
  gridInfoText?: string;
  heightSubtract?: number;
} & DataGridProProps;

export function ListView({
  datagrid,
  searchComponent,
  actions,
}: {
  datagrid: DatagridProps;
  searchComponent?: React.ReactNode;
  actions?: React.ReactNode;
}) {
  const {
    disableVirtualization,
    filterMode,
    sortingMode,
    gridInfoText,
    isDataLoading,
    isPaginationLoading,
    showGridInfo,
    slots,
    ...datagridProps
  } = datagrid;

  const gridInfo = (
    <>
      <GridInfoText data-testid="grid-info-text">{gridInfoText}</GridInfoText>
    </>
  );

  const noRowsOverlay = () => (
    <NoRowsContainer>
      <Typography>No Results Found.</Typography>
    </NoRowsContainer>
  );

  const memoSlots = React.useMemo(() => ({ ...slots, noRowsOverlay: slots?.noRowsOverlay || noRowsOverlay }), []);

  const gridComponent = isDataLoading ? (
    <Loading data-testid="loading-icon" />
  ) : (
    <StyledDataGrid
      {...datagridProps}
      slots={memoSlots}
      loading={isPaginationLoading} // for linear progress bar -- primary loading state is handled via isDataLoading
      filterMode={filterMode}
      sortingMode={sortingMode}
      disableVirtualization={disableVirtualization}
    />
  );
  return (
    <form onSubmit={(e) => { e.preventDefault(); }}>
      <TableHeader>
        {searchComponent}
        <ActionsStyled>
          {actions}
        </ActionsStyled>
      </TableHeader>
      { showGridInfo ? gridInfo : gridComponent }
      {/* <DevTool control={searchFormControl} */}
    </form>
  );
}
