import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

export const ChipContainer = styled('div')(({ theme }) => ({
  paddingTop: theme.spacing(1),
  display: 'flex',
  columnGap: '12px',
}));

export const TitleTypography = styled(Typography)(({ theme }) => ({
  whiteSpace: 'normal',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: '2',
  WebkitBoxOrient: 'vertical',
  paddingRight: theme.spacing(3),
}));
