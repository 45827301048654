import {
  FinancesSupport,
  FinancesBoxOffice,
  FinancesHeadliners,
  FinancesFixedCosts,
  FinancesVariableCosts,
  FinancesAdjustments,
} from '@types';

export const mockFinancesBoxOffice: FinancesBoxOffice = {
  maxShows: 2,
  totals: {
    capacity: {
      average: 2453,
      total: 27880,
    },
    kills: {
      average: 2,
      total: 56,
    },
    comps: {
      average: 7,
      total: 80,
    },
    sellableCapacity: {
      average: 2447,
      total: 28400,
    },
    price: 55.0,
    grossPotential: {
      average: 134577.0,
      total: 1203730.0,
    },
    netGrossPotential: {
      average: 127680.0,
      total: 1003730.0,
    },
  },
  tiers: [
    {
      id: 'box-office-tier-id-1',
      name: 'P1',
      localOfferTotal: 10,
      boxOffice: {
        capacity: {
          average: 2453,
          total: 7360,
        },
        kills: {
          average: 2,
          total: 16,
        },
        comps: {
          average: 7,
          total: 20,
        },
        sellableCapacity: {
          average: 2447,
          total: 7340,
        },
        price: 55.0,
        grossPotential: {
          average: 134577.0,
          total: 403730.0,
        },
        netGrossPotential: {
          average: 127680.0,
          total: 383040.0,
        },
      },
      eventOffers: [
        {
          id: '1a09f260-9d4d-4fc3-b4c3-4276a9f41181',
          updatedDate: '2023-10-09T23:07:47.650Z',
          showsCount: 1,
          venue: {
            name: 'Georgia Theatre',
            city: 'Athens',
            stateCode: 'GA',
          },
          boxOffice: {
            name: 'P1',
            capacity: 245,
            kills: 0,
            comps: 1,
            sellableCapacity: 244,
            price: 55.0,
            grossPotential: 13457.0,
            netGrossPotential: 127680.0,
          },
        },
        {
          id: '1a09f260-9d4d-4fc3-b4c3-4276a9f41182',
          updatedDate: '2023-10-09T23:07:47.650Z',
          showsCount: 3,
          venue: {
            name: 'Rams Head Live!',
            city: 'Baltimore',
            stateCode: 'MD',
          },
          boxOffice: {
            name: 'P1',
            capacity: 245,
            kills: 0,
            comps: 1,
            sellableCapacity: 244,
            price: 55.0,
            grossPotential: 13457.0,
            netGrossPotential: 127680.0,
          },
        },
        {
          id: '1a09f260-9d4d-4fc3-b4c3-4276a9f41183',
          updatedDate: '2023-10-09T23:07:47.650Z',
          showsCount: 2,
          venue: {
            name: 'Royale',
            city: 'Boston',
            stateCode: 'MA',
          },
          boxOffice: {
            name: 'P1',
            capacity: 245,
            kills: 0,
            comps: 1,
            sellableCapacity: 244,
            price: 55.0,
            grossPotential: 13457.0,
            netGrossPotential: 127680.0,
          },
        },
        {
          id: '1a09f260-9d4d-4fc3-b4c3-4276a9f41184',
          updatedDate: '2023-10-09T23:07:47.650Z',
          showsCount: 3,
          venue: {
            name: 'The Factory',
            city: 'Chesterfield',
            stateCode: 'MO',
          },
          boxOffice: {
            name: 'P1',
            capacity: 245,
            kills: 0,
            comps: 1,
            sellableCapacity: 244,
            price: 55.0,
            grossPotential: 13457.0,
            netGrossPotential: 127680.0,
          },
        },
        {
          id: '1a09f260-9d4d-4fc3-b4c3-4276a9f41185',
          updatedDate: '2023-10-09T23:07:47.650Z',
          showsCount: 1,
          venue: {
            name: 'Agora Theatre',
            city: 'Cleveland',
            stateCode: 'OH',
          },
          boxOffice: {
            name: 'P1',
            capacity: 245,
            kills: 0,
            comps: 1,
            sellableCapacity: 244,
            price: 55.0,
            grossPotential: 13457.0,
            netGrossPotential: 127680.0,
          },
        },
        {
          id: '1a09f260-9d4d-4fc3-b4c3-4276a9f41186',
          updatedDate: '2023-10-09T23:07:47.650Z',
          showsCount: 1,
          venue: {
            name: 'The Senate',
            city: 'Columbia',
            stateCode: 'SC',
          },
          boxOffice: {
            name: 'P1',
            capacity: 245,
            kills: 0,
            comps: 1,
            sellableCapacity: 244,
            price: 55.0,
            grossPotential: 13457.0,
            netGrossPotential: 127680.0,
          },
        },
        {
          id: '1a09f260-9d4d-4fc3-b4c3-4276a9f41187',
          updatedDate: '2023-10-09T23:07:47.650Z',
          showsCount: 2,
          venue: {
            name: 'Newport Music Hall',
            city: 'Columbus',
            stateCode: 'OH',
          },
          boxOffice: {
            name: 'P1',
            capacity: 245,
            kills: 0,
            comps: 1,
            sellableCapacity: 244,
            price: 55.0,
            grossPotential: 13457.0,
            netGrossPotential: 127680.0,
          },
        },
        {
          id: '1a09f260-9d4d-4fc3-b4c3-4276a9f41188',
          updatedDate: '2023-10-09T23:07:47.650Z',
          showsCount: 1,
          venue: {
            name: 'Trees',
            city: 'Dallas',
            stateCode: 'TX',
          },
          boxOffice: {
            name: 'P1',
            capacity: 245,
            kills: 0,
            comps: 1,
            sellableCapacity: 244,
            price: 55.0,
            grossPotential: 13457.0,
            netGrossPotential: 127680.0,
          },
        },
        {
          id: '1a09f260-9d4d-4fc3-b4c3-4276a9f41189',
          updatedDate: '2023-10-09T23:07:47.650Z',
          showsCount: 1,
          venue: {
            name: 'The Majestic',
            city: 'Detroit',
            stateCode: 'MI',
          },
          boxOffice: {
            name: 'P1',
            capacity: 245,
            kills: 0,
            comps: 1,
            sellableCapacity: 244,
            price: 55.0,
            grossPotential: 13457.0,
            netGrossPotential: 127680.0,
          },
        },
        {
          id: '1a09f260-9d4d-4fc3-b4c3-4276a9f41110',
          updatedDate: '2023-10-09T23:07:47.650Z',
          showsCount: 1,
          venue: {
            name: 'Gothic Theatre',
            city: 'Englewood',
            stateCode: 'CO',
          },
          boxOffice: {
            name: 'P1',
            capacity: 245,
            kills: 0,
            comps: 1,
            sellableCapacity: 244,
            price: 55.0,
            grossPotential: 13457.0,
            netGrossPotential: 127680.0,
          },
        },
      ],
    },
    {
      id: 'box-office-tier-id-2',
      name: 'P2',
      localOfferTotal: 10,
      boxOffice: {
        capacity: {
          average: 2453,
          total: 7360,
        },
        kills: {
          average: 2,
          total: 16,
        },
        comps: {
          average: 7,
          total: 20,
        },
        sellableCapacity: {
          average: 2447,
          total: 7340,
        },
        price: 55.0,
        grossPotential: {
          average: 134577.0,
          total: 403730.0,
        },
        netGrossPotential: {
          average: 127680.0,
          total: 383040.0,
        },
      },
      eventOffers: [
        {
          id: '1a09f260-9d4d-4fc3-b4c3-4276a9f41183',
          updatedDate: '2023-10-09T23:07:47.650Z',
          showsCount: 2,
          venue: {
            name: 'Royale',
            city: 'Boston',
            stateCode: 'MA',
          },
          boxOffice: {
            name: 'P1',
            capacity: 245,
            kills: 0,
            comps: 1,
            sellableCapacity: 244,
            price: 55.0,
            grossPotential: 13457.0,
            netGrossPotential: 127680.0,
          },
        },
        {
          id: '1a09f260-9d4d-4fc3-b4c3-4276a9f41184',
          updatedDate: '2023-10-09T23:07:47.650Z',
          showsCount: 3,
          venue: {
            name: 'The Factory',
            city: 'Chesterfield',
            stateCode: 'MO',
          },
          boxOffice: {
            name: 'P1',
            capacity: 245,
            kills: 0,
            comps: 1,
            sellableCapacity: 244,
            price: 55.0,
            grossPotential: 13457.0,
            netGrossPotential: 127680.0,
          },
        },
        {
          id: '1a09f260-9d4d-4fc3-b4c3-4276a9f41185',
          updatedDate: '2023-10-09T23:07:47.650Z',
          showsCount: 1,
          venue: {
            name: 'Agora Theatre',
            city: 'Cleveland',
            stateCode: 'OH',
          },
          boxOffice: {
            name: 'P1',
            capacity: 245,
            kills: 0,
            comps: 1,
            sellableCapacity: 244,
            price: 55.0,
            grossPotential: 13457.0,
            netGrossPotential: 127680.0,
          },
        },
        {
          id: '1a09f260-9d4d-4fc3-b4c3-4276a9f41186',
          updatedDate: '2023-10-09T23:07:47.650Z',
          showsCount: 1,
          venue: {
            name: 'The Senate',
            city: 'Columbia',
            stateCode: 'SC',
          },
          boxOffice: {
            name: 'P1',
            capacity: 245,
            kills: 0,
            comps: 1,
            sellableCapacity: 244,
            price: 55.0,
            grossPotential: 13457.0,
            netGrossPotential: 127680.0,
          },
        },
        {
          id: '1a09f260-9d4d-4fc3-b4c3-4276a9f41187',
          updatedDate: '2023-10-09T23:07:47.650Z',
          showsCount: 2,
          venue: {
            name: 'Newport Music Hall',
            city: 'Columbus',
            stateCode: 'OH',
          },
          boxOffice: {
            name: 'P1',
            capacity: 245,
            kills: 0,
            comps: 1,
            sellableCapacity: 244,
            price: 55.0,
            grossPotential: 13457.0,
            netGrossPotential: 127680.0,
          },
        },
        {
          id: '1a09f260-9d4d-4fc3-b4c3-4276a9f41188',
          updatedDate: '2023-10-09T23:07:47.650Z',
          showsCount: 1,
          venue: {
            name: 'Trees',
            city: 'Dallas',
            stateCode: 'TX',
          },
          boxOffice: {
            name: 'P1',
            capacity: 245,
            kills: 0,
            comps: 1,
            sellableCapacity: 244,
            price: 55.0,
            grossPotential: 13457.0,
            netGrossPotential: 127680.0,
          },
        },
        {
          id: '1a09f260-9d4d-4fc3-b4c3-4276a9f41189',
          updatedDate: '2023-10-09T23:07:47.650Z',
          showsCount: 1,
          venue: {
            name: 'The Majestic',
            city: 'Detroit',
            stateCode: 'MI',
          },
          boxOffice: {
            name: 'P1',
            capacity: 245,
            kills: 0,
            comps: 1,
            sellableCapacity: 244,
            price: 55.0,
            grossPotential: 13457.0,
            netGrossPotential: 127680.0,
          },
        },
        {
          id: '1a09f260-9d4d-4fc3-b4c3-4276a9f41110',
          updatedDate: '2023-10-09T23:07:47.650Z',
          showsCount: 1,
          venue: {
            name: 'Gothic Theatre',
            city: 'Englewood',
            stateCode: 'CO',
          },
          boxOffice: {
            name: 'P1',
            capacity: 245,
            kills: 0,
            comps: 1,
            sellableCapacity: 244,
            price: 55.0,
            grossPotential: 13457.0,
            netGrossPotential: 127680.0,
          },
        },
      ],
    },
    {
      id: 'box-office-tier-id-3',
      name: 'P3',
      localOfferTotal: 10,
      boxOffice: {
        capacity: {
          average: 2453,
          total: 7360,
        },
        kills: {
          average: 2,
          total: 16,
        },
        comps: {
          average: 7,
          total: 20,
        },
        sellableCapacity: {
          average: 2447,
          total: 7340,
        },
        price: 55.0,
        grossPotential: {
          average: 134577.0,
          total: 403730.0,
        },
        netGrossPotential: {
          average: 127680.0,
          total: 383040.0,
        },
      },
      eventOffers: [
        {
          id: '1a09f260-9d4d-4fc3-b4c3-4276a9f41186',
          updatedDate: '2023-10-09T23:07:47.650Z',
          showsCount: 1,
          venue: {
            name: 'The Senate',
            city: 'Columbia',
            stateCode: 'SC',
          },
          boxOffice: {
            name: 'P1',
            capacity: 245,
            kills: 0,
            comps: 1,
            sellableCapacity: 244,
            price: 55.0,
            grossPotential: 13457.0,
            netGrossPotential: 127680.0,
          },
        },
        {
          id: '1a09f260-9d4d-4fc3-b4c3-4276a9f41187',
          updatedDate: '2023-10-09T23:07:47.650Z',
          showsCount: 2,
          venue: {
            name: 'Newport Music Hall',
            city: 'Columbus',
            stateCode: 'OH',
          },
          boxOffice: {
            name: 'P1',
            capacity: 245,
            kills: 0,
            comps: 1,
            sellableCapacity: 244,
            price: 55.0,
            grossPotential: 13457.0,
            netGrossPotential: 127680.0,
          },
        },
        {
          id: '1a09f260-9d4d-4fc3-b4c3-4276a9f41188',
          updatedDate: '2023-10-09T23:07:47.650Z',
          showsCount: 1,
          venue: {
            name: 'Trees',
            city: 'Dallas',
            stateCode: 'TX',
          },
          boxOffice: {
            name: 'P1',
            capacity: 245,
            kills: 0,
            comps: 1,
            sellableCapacity: 244,
            price: 55.0,
            grossPotential: 13457.0,
            netGrossPotential: 127680.0,
          },
        },
        {
          id: '1a09f260-9d4d-4fc3-b4c3-4276a9f41189',
          updatedDate: '2023-10-09T23:07:47.650Z',
          showsCount: 1,
          venue: {
            name: 'The Majestic',
            city: 'Detroit',
            stateCode: 'MI',
          },
          boxOffice: {
            name: 'P1',
            capacity: 245,
            kills: 0,
            comps: 1,
            sellableCapacity: 244,
            price: 55.0,
            grossPotential: 13457.0,
            netGrossPotential: 127680.0,
          },
        },
        {
          id: '1a09f260-9d4d-4fc3-b4c3-4276a9f41110',
          updatedDate: '2023-10-09T23:07:47.650Z',
          showsCount: 1,
          venue: {
            name: 'Gothic Theatre',
            city: 'Englewood',
            stateCode: 'CO',
          },
          boxOffice: {
            name: 'P1',
            capacity: 245,
            kills: 0,
            comps: 1,
            sellableCapacity: 244,
            price: 55.0,
            grossPotential: 13457.0,
            netGrossPotential: 127680.0,
          },
        },
      ],
    },
    {
      id: 'box-office-tier-id-4',
      name: 'P4',
      localOfferTotal: 10,
      boxOffice: {
        capacity: {
          average: 2453,
          total: 7360,
        },
        kills: {
          average: 2,
          total: 16,
        },
        comps: {
          average: 7,
          total: 20,
        },
        sellableCapacity: {
          average: 2447,
          total: 7340,
        },
        price: 55.0,
        grossPotential: {
          average: 134577.0,
          total: 403730.0,
        },
        netGrossPotential: {
          average: 127680.0,
          total: 383040.0,
        },
      },
      eventOffers: [
        {
          id: '1a09f260-9d4d-4fc3-b4c3-4276a9f41189',
          updatedDate: '2023-10-09T23:07:47.650Z',
          showsCount: 1,
          venue: {
            name: 'The Majestic',
            city: 'Detroit',
            stateCode: 'MI',
          },
          boxOffice: {
            name: 'P1',
            capacity: 245,
            kills: 0,
            comps: 1,
            sellableCapacity: 244,
            price: 55.0,
            grossPotential: 13457.0,
            netGrossPotential: 127680.0,
          },
        },
        {
          id: '1a09f260-9d4d-4fc3-b4c3-4276a9f41110',
          updatedDate: '2023-10-09T23:07:47.650Z',
          showsCount: 2,
          venue: {
            name: 'Gothic Theatre',
            city: 'Englewood',
            stateCode: 'CO',
          },
          boxOffice: {
            name: 'P1',
            capacity: 245,
            kills: 0,
            comps: 1,
            sellableCapacity: 244,
            price: 55.0,
            grossPotential: 13457.0,
            netGrossPotential: 127680.0,
          },
        },
      ],
    },
  ],
};

export const mockFinancesAdjustments: FinancesAdjustments[] = [
  {
    id: '2408320e-0ca1-11ee-a75f-aa050edb9711',
    name: 'Adjustment 1',
    showsCount: 10,
    budget: {
      average: 15,
      total: 45,
    },
    internal: {
      average: 0,
      total: 0,
    },
    type: 'Varies',
    notes: '',
    eventOffers: [
      {
        id: '1a09f260-9d4d-4fc3-b4c3-4276a9f41187',
        venue: {
          name: 'Georgia Theatre',
          city: 'Athens',
          stateCode: 'GA',
          id: 'test-guid',
          country: 'US',
          cityCode: 'LA',
          ownership: '12234',
          status: 'confirmed',
        },
        eventId: '6e12426e-99a3-45e8-8481-6c611bfd61ce',
        offerId: 'e9e6a7cb-f1e9-4b4c-b523-37e9448e56f1',
        settlementId: '6e12426e-99a3-45e8-8481-6c611bfd61ce',
        budget: -15,
        internal: 0,
        type: 'Pre Tax',
        notes: 'Ipsum lorem sit dolor consect',
        updatedDate: '2023-10-09T23:07:47.650Z',
      },
    ],
  },
];

export const mockFinancesHeadliners: FinancesHeadliners[] = [
  {
    id: '2408320e-0ca1-11ee-a75f-aa050edb9711',
    name: 'Mitchell Tenpenny',
    dealType: 'Varies',
    showsCount: 10,
    localGuarantee: {
      average: 13348,
      total: 307000,
    },
    localDealRate: 'Varies',
    forecastedBonus: {
      average: 0,
      total: 0,
    },
    selloutBonus: {
      average: 43,
      total: 1000,
    },
    artistReimbursement: {
      average: 0,
      total: 0,
    },
    eventOffers: [
      {
        id: '1a09f260-9d4d-4fc3-b4c3-4276a9f41181',
        venue: {
          name: 'Georgia Theatre',
          city: 'Athens',
          stateCode: 'GA',
          id: 'test-guid',
          country: 'US',
          cityCode: 'LA',
          ownership: '12234',
          status: 'confirmed',
        },
        eventId: '6e12426e-99a3-45e8-8481-6c611bfd61ce',
        offerId: 'e9e6a7cb-f1e9-4b4c-b523-37e9448e56f1',
        settlementId: '6e12426e-99a3-45e8-8481-6c611bfd61ce',
        dealType: 'Plus',
        localGuarantee: 6500,
        localDealRate: 0.85,
        forecastedBonus: 0,
        selloutBonus: 0,
        artistReimbursement: 0,
        updatedDate: '2023-10-09T23:07:47.650Z',
      },
      {
        id: '1a09f260-9d4d-4fc3-b4c3-4276a9f41182',
        eventId: '6e12426e-99a3-45e8-8481-6c611bfd61ce',
        offerId: 'e9e6a7cb-f1e9-4b4c-b523-37e9448e56f1',
        settlementId: '6e12426e-99a3-45e8-8481-6c611bfd61ce',
        venue: {
          name: 'Rams Head Live!',
          city: 'Baltimore',
          stateCode: 'MD',
          id: 'test-guid',
          country: 'US',
          cityCode: 'LA',
          ownership: '12234',
          status: 'confirmed',
        },
        dealType: 'Net',
        localGuarantee: 6500,
        localDealRate: 0.85,
        forecastedBonus: 0,
        selloutBonus: 0,
        artistReimbursement: 0,
        updatedDate: '2023-10-09T23:07:47.650Z',
      },
      {
        id: '1a09f260-9d4d-4fc3-b4c3-4276a9f41183',
        eventId: '6e12426e-99a3-45e8-8481-6c611bfd61ce',
        offerId: 'e9e6a7cb-f1e9-4b4c-b523-37e9448e56f1',
        settlementId: '6e12426e-99a3-45e8-8481-6c611bfd61ce',
        venue: {
          name: 'Royale',
          city: 'Boston',
          stateCode: 'MA',
          id: 'test-guid',
          country: 'US',
          cityCode: 'LA',
          ownership: '12234',
          status: 'confirmed',
        },
        dealType: 'Plus',
        localGuarantee: 6500,
        localDealRate: 0.85,
        forecastedBonus: 0,
        selloutBonus: 0,
        artistReimbursement: 0,
        updatedDate: '2023-10-09T23:07:47.650Z',
      },
      {
        id: '1a09f260-9d4d-4fc3-b4c3-4276a9f41184',
        eventId: '6e12426e-99a3-45e8-8481-6c611bfd61ce',
        offerId: 'e9e6a7cb-f1e9-4b4c-b523-37e9448e56f1',
        settlementId: '6e12426e-99a3-45e8-8481-6c611bfd61ce',
        venue: {
          name: 'The Factory',
          city: 'Chesterfield',
          stateCode: 'MO',
          id: 'test-guid',
          country: 'US',
          cityCode: 'LA',
          ownership: '12234',
          status: 'confirmed',
        },
        dealType: 'Plus',
        localGuarantee: 6500,
        localDealRate: 0.85,
        forecastedBonus: 0,
        selloutBonus: 0,
        artistReimbursement: 0,
        updatedDate: '2023-10-09T23:07:47.650Z',
      },
      {
        id: '1a09f260-9d4d-4fc3-b4c3-4276a9f41185',
        eventId: '6e12426e-99a3-45e8-8481-6c611bfd61ce',
        offerId: 'e9e6a7cb-f1e9-4b4c-b523-37e9448e56f1',
        settlementId: '6e12426e-99a3-45e8-8481-6c611bfd61ce',
        venue: {
          name: 'Agora Theatre',
          city: 'Cleveland',
          stateCode: 'OH',
          id: 'test-guid',
          country: 'US',
          cityCode: 'LA',
          ownership: '12234',
          status: 'confirmed',
        },
        dealType: 'Plus',
        localGuarantee: 6500,
        localDealRate: 0.85,
        forecastedBonus: 0,
        selloutBonus: 0,
        artistReimbursement: 0,
        updatedDate: '2023-10-09T23:07:47.650Z',
      },
      {
        id: '1a09f260-9d4d-4fc3-b4c3-4276a9f41186',
        eventId: '6e12426e-99a3-45e8-8481-6c611bfd61ce',
        offerId: 'e9e6a7cb-f1e9-4b4c-b523-37e9448e56f1',
        settlementId: '6e12426e-99a3-45e8-8481-6c611bfd61ce',
        venue: {
          name: 'The Senate',
          city: 'Columbia',
          stateCode: 'SC',
          id: 'test-guid',
          country: 'US',
          cityCode: 'LA',
          ownership: '12234',
          status: 'confirmed',
        },
        dealType: 'Plus',
        localGuarantee: 6500,
        localDealRate: 0.85,
        forecastedBonus: 0,
        selloutBonus: 0,
        artistReimbursement: 0,
        updatedDate: '2023-10-09T23:07:47.650Z',
      },
      {
        id: '1a09f260-9d4d-4fc3-b4c3-4276a9f41187',
        eventId: '6e12426e-99a3-45e8-8481-6c611bfd61ce',
        offerId: 'e9e6a7cb-f1e9-4b4c-b523-37e9448e56f1',
        settlementId: '6e12426e-99a3-45e8-8481-6c611bfd61ce',
        venue: {
          name: 'Newport Music Hall',
          city: 'Columbus',
          stateCode: 'OH',
          id: 'test-guid',
          country: 'US',
          cityCode: 'LA',
          ownership: '12234',
          status: 'confirmed',
        },
        dealType: 'Plus',
        localGuarantee: 6500,
        localDealRate: 0.85,
        forecastedBonus: 0,
        selloutBonus: 0,
        artistReimbursement: 0,
        updatedDate: '2023-10-09T23:07:47.650Z',
      },
      {
        id: '1a09f260-9d4d-4fc3-b4c3-4276a9f41188',
        eventId: '6e12426e-99a3-45e8-8481-6c611bfd61ce',
        offerId: 'e9e6a7cb-f1e9-4b4c-b523-37e9448e56f1',
        settlementId: '6e12426e-99a3-45e8-8481-6c611bfd61ce',
        venue: {
          name: 'Trees',
          city: 'Dallas',
          stateCode: 'TX',
          id: 'test-guid',
          country: 'US',
          cityCode: 'LA',
          ownership: '12234',
          status: 'confirmed',
        },
        dealType: 'Plus',
        localGuarantee: 6500,
        localDealRate: 0.85,
        forecastedBonus: 0,
        selloutBonus: 0,
        artistReimbursement: 0,
        updatedDate: '2023-10-09T23:07:47.650Z',
      },
      {
        id: '1a09f260-9d4d-4fc3-b4c3-4276a9f41189',
        eventId: '6e12426e-99a3-45e8-8481-6c611bfd61ce',
        offerId: 'e9e6a7cb-f1e9-4b4c-b523-37e9448e56f1',
        settlementId: '6e12426e-99a3-45e8-8481-6c611bfd61ce',
        venue: {
          name: 'The Majestic',
          city: 'Detroit',
          stateCode: 'MI',
          id: 'test-guid',
          country: 'US',
          cityCode: 'LA',
          ownership: '12234',
          status: 'confirmed',
        },
        dealType: 'Plus',
        localGuarantee: 6500,
        localDealRate: 0.85,
        forecastedBonus: 0,
        selloutBonus: 0,
        artistReimbursement: 0,
        updatedDate: '2023-10-09T23:07:47.650Z',
      },
      {
        id: '1a09f260-9d4d-4fc3-b4c3-4276a9f41110',
        eventId: '6e12426e-99a3-45e8-8481-6c611bfd61ce',
        offerId: 'e9e6a7cb-f1e9-4b4c-b523-37e9448e56f1',
        settlementId: '6e12426e-99a3-45e8-8481-6c611bfd61ce',
        venue: {
          name: 'Gothic Theatre',
          city: 'Englewood',
          stateCode: 'CO',
          id: 'test-guid',
          country: 'US',
          cityCode: 'LA',
          ownership: '12234',
          status: 'confirmed',
        },
        dealType: 'Plus',
        localGuarantee: 6500,
        localDealRate: 0.85,
        forecastedBonus: 0,
        selloutBonus: 0,
        artistReimbursement: 0,
        updatedDate: '2023-10-09T23:07:47.650Z',
      },
    ],
  },
];

export const mockFinancesSupport: FinancesSupport[] = [
  {
    id: 'support-id-1',
    showsCount: 10,
    name: 'Arizona Zervas',
    marketTotals: 2000,
    amount: 2000,
    method: 'Show Cost',
    forecastedBonus: 0,
    selloutBonus: 0,
    eventOffers: [
      {
        id: '1a09f260-9d4d-4fc3-b4c3-4276a9f41187',
        venue: {
          name: 'Georgia Theatre',
          city: 'Athens',
          stateCode: 'GA',
          id: 'test-guid',
          country: 'US',
          cityCode: 'LA',
          ownership: '12234',
          status: 'confirmed',
        },
        eventId: '6e12426e-99a3-45e8-8481-6c611bfd61ce',
        offerId: 'e9e6a7cb-f1e9-4b4c-b523-37e9448e56f1',
        settlementId: '6e12426e-99a3-45e8-8481-6c611bfd61ce',
        marketTotals: 500,
        amount: 500,
        method: 'Guarantee',
        forecastedBonus: 0,
        selloutBonus: 0,
        updatedDate: '2023-10-09T23:07:47.650Z',
      },
      {
        id: '1a09f260-9d4d-4fc3-b4c3-4276a9f41182',
        eventId: '6e12426e-99a3-45e8-8481-6c611bfd61ce',
        offerId: 'e9e6a7cb-f1e9-4b4c-b523-37e9448e56f1',
        settlementId: '6e12426e-99a3-45e8-8481-6c611bfd61ce',
        venue: {
          name: 'Rams Head Live!',
          city: 'Baltimore',
          stateCode: 'MD',
          id: 'test-guid',
          country: 'US',
          cityCode: 'LA',
          ownership: '12234',
          status: 'confirmed',
        },
        marketTotals: 500,
        amount: 500,
        method: 'Guarantee',
        forecastedBonus: 0,
        selloutBonus: 0,
        updatedDate: '2023-10-09T23:07:47.650Z',
      },
      {
        id: '1a09f260-9d4d-4fc3-b4c3-4276a9f41183',
        eventId: '6e12426e-99a3-45e8-8481-6c611bfd61ce',
        offerId: 'e9e6a7cb-f1e9-4b4c-b523-37e9448e56f1',
        settlementId: '6e12426e-99a3-45e8-8481-6c611bfd61ce',
        venue: {
          name: 'Royale',
          city: 'Boston',
          stateCode: 'MA',
          id: 'test-guid',
          country: 'US',
          cityCode: 'LA',
          ownership: '12234',
          status: 'confirmed',
        },
        marketTotals: 500,
        amount: 500,
        method: 'Guarantee',
        forecastedBonus: 0,
        selloutBonus: 0,
        updatedDate: '2023-10-09T23:07:47.650Z',
      },
      {
        id: '1a09f260-9d4d-4fc3-b4c3-4276a9f41184',
        eventId: '6e12426e-99a3-45e8-8481-6c611bfd61ce',
        offerId: 'e9e6a7cb-f1e9-4b4c-b523-37e9448e56f1',
        settlementId: '6e12426e-99a3-45e8-8481-6c611bfd61ce',
        venue: {
          name: 'The Factory',
          city: 'Chesterfield',
          stateCode: 'MO',
          id: 'test-guid',
          country: 'US',
          cityCode: 'LA',
          ownership: '12234',
          status: 'confirmed',
        },
        marketTotals: 500,
        amount: 500,
        method: 'Guarantee',
        forecastedBonus: 0,
        selloutBonus: 0,
        updatedDate: '2023-10-09T23:07:47.650Z',
      },
    ],
  },
  {
    id: 'support-id-2',
    showsCount: 10,
    name: 'Bruno Mars',
    marketTotals: 2000,
    amount: 2000,
    method: 'Show Cost',
    forecastedBonus: 0,
    selloutBonus: 0,
    eventOffers: [
      {
        id: '1a09f260-9d4d-4fc3-b4c3-4276a9f41187',
        venue: {
          name: 'Georgia Theatre',
          city: 'Athens',
          stateCode: 'GA',
          id: 'test-guid',
          country: 'US',
          cityCode: 'LA',
          ownership: '12234',
          status: 'confirmed',
        },
        eventId: '6e12426e-99a3-45e8-8481-6c611bfd61ce',
        offerId: 'e9e6a7cb-f1e9-4b4c-b523-37e9448e56f1',
        settlementId: '6e12426e-99a3-45e8-8481-6c611bfd61ce',
        marketTotals: 500,
        amount: 500,
        method: 'Guarantee',
        forecastedBonus: 0,
        selloutBonus: 0,
        updatedDate: '2023-10-09T23:07:47.650Z',
      },
      {
        id: '1a09f260-9d4d-4fc3-b4c3-4276a9f41182',
        eventId: '6e12426e-99a3-45e8-8481-6c611bfd61ce',
        offerId: 'e9e6a7cb-f1e9-4b4c-b523-37e9448e56f1',
        settlementId: '6e12426e-99a3-45e8-8481-6c611bfd61ce',
        venue: {
          name: 'Rams Head Live!',
          city: 'Baltimore',
          stateCode: 'MD',
          id: 'test-guid',
          country: 'US',
          cityCode: 'LA',
          ownership: '12234',
          status: 'confirmed',
        },
        marketTotals: 500,
        amount: 500,
        method: 'Guarantee',
        forecastedBonus: 0,
        selloutBonus: 0,
        updatedDate: '2023-10-09T23:07:47.650Z',
      },
    ],
  },
];

export const mockFinancesFixedCosts: FinancesFixedCosts[] = [
  {
    id: '1a09f260-9d4d-4fc3-b4c3-4276a9f41187',
    name: 'AEG Digital Services',
    showsCount: 10,
    category: 'Marketing',
    budget: {
      average: 1250,
      total: 28000,
    },
    internal: {
      average: 350,
      total: 5000,
    },
    artistReimbursement: '',
    note: '',
    eventOffers: [
      {
        id: '1a09f260-9d4d-4fc3-b4c3-4276a9f41187',
        venue: {
          name: 'Georgia Theatre',
          city: 'Athens',
          stateCode: 'GA',
          id: 'test-guid',
          country: 'US',
          cityCode: 'LA',
          ownership: '12234',
          status: 'confirmed',
        },
        eventId: '6e12426e-99a3-45e8-8481-6c611bfd61ce',
        offerId: 'e9e6a7cb-f1e9-4b4c-b523-37e9448e56f1',
        settlementId: '6e12426e-99a3-45e8-8481-6c611bfd61ce',
        budget: 1000,
        internal: 150,
        artistReimbursement: 'Arizona Zervas',
        note: 'Ipsum lorem sit dolor consect',
        updatedDate: '2023-10-09T23:07:47.650Z',
      },
      {
        id: '1a09f260-9d4d-4fc3-b4c3-4276a9f41182',
        eventId: '6e12426e-99a3-45e8-8481-6c611bfd61ce',
        offerId: 'e9e6a7cb-f1e9-4b4c-b523-37e9448e56f1',
        settlementId: '6e12426e-99a3-45e8-8481-6c611bfd61ce',
        venue: {
          name: 'Rams Head Live!',
          city: 'Baltimore',
          stateCode: 'MD',
          id: 'test-guid',
          country: 'US',
          cityCode: 'LA',
          ownership: '12234',
          status: 'confirmed',
        },
        budget: 1000,
        internal: 150,
        artistReimbursement: '',
        note: 'Ipsum lorem sit dolor consect',
        updatedDate: '2023-10-09T23:07:47.650Z',
      },
      {
        id: '1a09f260-9d4d-4fc3-b4c3-4276a9f41183',
        eventId: '6e12426e-99a3-45e8-8481-6c611bfd61ce',
        offerId: 'e9e6a7cb-f1e9-4b4c-b523-37e9448e56f1',
        settlementId: '6e12426e-99a3-45e8-8481-6c611bfd61ce',
        venue: {
          name: 'Royale',
          city: 'Boston',
          stateCode: 'MA',
          id: 'test-guid',
          country: 'US',
          cityCode: 'LA',
          ownership: '12234',
          status: 'confirmed',
        },
        budget: 1000,
        internal: 150,
        artistReimbursement: '',
        note: 'Ipsum lorem sit dolor consect',
        updatedDate: '2023-10-09T23:07:47.650Z',
      },
      {
        id: '1a09f260-9d4d-4fc3-b4c3-4276a9f41184',
        eventId: '6e12426e-99a3-45e8-8481-6c611bfd61ce',
        offerId: 'e9e6a7cb-f1e9-4b4c-b523-37e9448e56f1',
        settlementId: '6e12426e-99a3-45e8-8481-6c611bfd61ce',
        venue: {
          name: 'The Factory',
          city: 'Chesterfield',
          stateCode: 'MO',
          id: 'test-guid',
          country: 'US',
          cityCode: 'LA',
          ownership: '12234',
          status: 'confirmed',
        },
        budget: 1000,
        internal: 150,
        artistReimbursement: 'Arizona Zervas',
        note: 'Ipsum lorem sit dolor consect',
        updatedDate: '2023-10-09T23:07:47.650Z',
      },
    ],
  },
  {
    id: '7a09f260-9d4d-4fc3-b4c3-4276a9f41188',
    name: 'Marketing',
    showsCount: 10,
    category: 'Marketing',
    budget: {
      average: 1250,
      total: 28000,
    },
    internal: {
      average: 350,
      total: 5000,
    },
    artistReimbursement: 'Arizona Zervas',
    note: 'Ipsum lorem sit dolor consect',
    eventOffers: [
      {
        id: '1a09f260-9d4d-4fc3-b4c3-4276a9f41187',
        venue: {
          name: 'Georgia Theatre',
          city: 'Athens',
          stateCode: 'GA',
          id: 'test-guid',
          country: 'US',
          cityCode: 'LA',
          ownership: '12234',
          status: 'confirmed',
        },
        eventId: '6e12426e-99a3-45e8-8481-6c611bfd61ce',
        offerId: 'e9e6a7cb-f1e9-4b4c-b523-37e9448e56f1',
        settlementId: '6e12426e-99a3-45e8-8481-6c611bfd61ce',
        budget: 1000,
        internal: 150,
        artistReimbursement: 'Arizona Zervas',
        note: 'Ipsum lorem sit dolor consect',
        updatedDate: '2023-10-09T23:07:47.650Z',
      },
      {
        id: '1a09f260-9d4d-4fc3-b4c3-4276a9f41182',
        eventId: '6e12426e-99a3-45e8-8481-6c611bfd61ce',
        offerId: 'e9e6a7cb-f1e9-4b4c-b523-37e9448e56f1',
        settlementId: '6e12426e-99a3-45e8-8481-6c611bfd61ce',
        venue: {
          name: 'Rams Head Live!',
          city: 'Baltimore',
          stateCode: 'MD',
          id: 'test-guid',
          country: 'US',
          cityCode: 'LA',
          ownership: '12234',
          status: 'confirmed',
        },
        budget: 1000,
        internal: 150,
        artistReimbursement: '',
        note: 'Ipsum lorem sit dolor consect',
        updatedDate: '2023-10-09T23:07:47.650Z',
      },
    ],
  },
  {
    id: '8a09f260-9d4d-4fc3-b4c3-4276a9f41188',
    name: 'Artist Production',
    showsCount: 10,
    category: 'Artist',
    budget: {
      average: 1250,
      total: 28000,
    },
    internal: {
      average: 350,
      total: 5000,
    },
    artistReimbursement: '',
    note: '',
    eventOffers: [
      {
        id: '1a09f260-9d4d-4fc3-b4c3-4276a9f41187',
        venue: {
          name: 'Georgia Theatre',
          city: 'Athens',
          stateCode: 'GA',
          id: 'test-guid',
          country: 'US',
          cityCode: 'LA',
          ownership: '12234',
          status: 'confirmed',
        },
        eventId: '6e12426e-99a3-45e8-8481-6c611bfd61ce',
        offerId: 'e9e6a7cb-f1e9-4b4c-b523-37e9448e56f1',
        settlementId: '6e12426e-99a3-45e8-8481-6c611bfd61ce',
        budget: 1000,
        internal: 150,
        artistReimbursement: 'Arizona Zervas',
        note: 'Ipsum lorem sit dolor consect',
        updatedDate: '2023-10-09T23:07:47.650Z',
      },
    ],
  },
  {
    id: '1a09f260-9d4d-4fc3-b4c3-4276a9f41189',
    name: 'Catering',
    showsCount: 10,
    category: 'Facility',
    budget: {
      average: 1250,
      total: 28000,
    },
    internal: {
      average: 350,
      total: 5000,
    },
    artistReimbursement: 'Arizona Zervas',
    note: 'Ipsum lorem sit dolor consect',
    eventOffers: [
      {
        id: '1a09f260-9d4d-4fc3-b4c3-4276a9f41187',
        venue: {
          name: 'Georgia Theatre',
          city: 'Athens',
          stateCode: 'GA',
          id: 'test-guid',
          country: 'US',
          cityCode: 'LA',
          ownership: '12234',
          status: 'confirmed',
        },
        eventId: '6e12426e-99a3-45e8-8481-6c611bfd61ce',
        offerId: 'e9e6a7cb-f1e9-4b4c-b523-37e9448e56f1',
        settlementId: '6e12426e-99a3-45e8-8481-6c611bfd61ce',
        budget: 1000,
        internal: 150,
        artistReimbursement: 'Arizona Zervas',
        note: 'Ipsum lorem sit dolor consect',
        updatedDate: '2023-10-09T23:07:47.650Z',
      },
      {
        id: '1a09f260-9d4d-4fc3-b4c3-4276a9f41182',
        eventId: '6e12426e-99a3-45e8-8481-6c611bfd61ce',
        offerId: 'e9e6a7cb-f1e9-4b4c-b523-37e9448e56f1',
        settlementId: '6e12426e-99a3-45e8-8481-6c611bfd61ce',
        venue: {
          name: 'Rams Head Live!',
          city: 'Baltimore',
          stateCode: 'MD',
          id: 'test-guid',
          country: 'US',
          cityCode: 'LA',
          ownership: '12234',
          status: 'confirmed',
        },
        budget: 1000,
        internal: 150,
        artistReimbursement: '',
        note: 'Ipsum lorem sit dolor consect',
        updatedDate: '2023-10-09T23:07:47.650Z',
      },
    ],
  },
  {
    id: '1a09f260-9d4d-4fc3-b4c3-4276a9f41180',
    name: 'House Flat',
    showsCount: 10,
    category: 'Facility',
    budget: {
      average: 1250,
      total: 28000,
    },
    internal: {
      average: 350,
      total: 5000,
    },
    artistReimbursement: '',
    note: '',
    eventOffers: [
      {
        id: '1a09f260-9d4d-4fc3-b4c3-4276a9f41187',
        venue: {
          name: 'Georgia Theatre',
          city: 'Athens',
          stateCode: 'GA',
          id: 'test-guid',
          country: 'US',
          cityCode: 'LA',
          ownership: '12234',
          status: 'confirmed',
        },
        eventId: '6e12426e-99a3-45e8-8481-6c611bfd61ce',
        offerId: 'e9e6a7cb-f1e9-4b4c-b523-37e9448e56f1',
        settlementId: '6e12426e-99a3-45e8-8481-6c611bfd61ce',
        budget: 1000,
        internal: 150,
        artistReimbursement: 'Arizona Zervas',
        note: 'Ipsum lorem sit dolor consect',
        updatedDate: '2023-10-09T23:07:47.650Z',
      },
    ],
  },
  {
    id: '1a09f260-9d4d-4fc3-b4c3-4276a9f41181',
    name: 'Internet',
    showsCount: 10,
    category: 'Facility',
    budget: {
      average: 1250,
      total: 28000,
    },
    internal: {
      average: 350,
      total: 5000,
    },
    artistReimbursement: '',
    note: '',
    eventOffers: [
      {
        id: '1a09f260-9d4d-4fc3-b4c3-4276a9f41187',
        venue: {
          name: 'Georgia Theatre',
          city: 'Athens',
          stateCode: 'GA',
          id: 'test-guid',
          country: 'US',
          cityCode: 'LA',
          ownership: '12234',
          status: 'confirmed',
        },
        eventId: '6e12426e-99a3-45e8-8481-6c611bfd61ce',
        offerId: 'e9e6a7cb-f1e9-4b4c-b523-37e9448e56f1',
        settlementId: '6e12426e-99a3-45e8-8481-6c611bfd61ce',
        budget: 1000,
        internal: 150,
        artistReimbursement: 'Arizona Zervas',
        note: 'Ipsum lorem sit dolor consect',
        updatedDate: '2023-10-09T23:07:47.650Z',
      },
      {
        id: '1a09f260-9d4d-4fc3-b4c3-4276a9f41182',
        eventId: '6e12426e-99a3-45e8-8481-6c611bfd61ce',
        offerId: 'e9e6a7cb-f1e9-4b4c-b523-37e9448e56f1',
        settlementId: '6e12426e-99a3-45e8-8481-6c611bfd61ce',
        venue: {
          name: 'Rams Head Live!',
          city: 'Baltimore',
          stateCode: 'MD',
          id: 'test-guid',
          country: 'US',
          cityCode: 'LA',
          ownership: '12234',
          status: 'confirmed',
        },
        budget: 1000,
        internal: 150,
        artistReimbursement: '',
        note: 'Ipsum lorem sit dolor consect',
        updatedDate: '2023-10-09T23:07:47.650Z',
      },
    ],
  },
  {
    id: '1a09f260-9d4d-4fc3-b4c3-4276a9f41182',
    name: 'Box Office / Will Call',
    showsCount: 10,
    category: 'Staffing',
    budget: {
      average: 1250,
      total: 28000,
    },
    internal: {
      average: 350,
      total: 5000,
    },
    artistReimbursement: '',
    note: '',
    eventOffers: [
      {
        id: '1a09f260-9d4d-4fc3-b4c3-4276a9f41187',
        venue: {
          name: 'Georgia Theatre',
          city: 'Athens',
          stateCode: 'GA',
          id: 'test-guid',
          country: 'US',
          cityCode: 'LA',
          ownership: '12234',
          status: 'confirmed',
        },
        eventId: '6e12426e-99a3-45e8-8481-6c611bfd61ce',
        offerId: 'e9e6a7cb-f1e9-4b4c-b523-37e9448e56f1',
        settlementId: '6e12426e-99a3-45e8-8481-6c611bfd61ce',
        budget: 1000,
        internal: 150,
        artistReimbursement: 'Arizona Zervas',
        note: 'Ipsum lorem sit dolor consect',
        updatedDate: '2023-10-09T23:07:47.650Z',
      },
    ],
  },
  {
    id: '1a09f260-9d4d-4fc3-b4c3-4276a9f41183',
    name: 'Cleaning',
    showsCount: 10,
    category: 'Staffing',
    budget: {
      average: 1250,
      total: 28000,
    },
    internal: {
      average: 350,
      total: 5000,
    },
    artistReimbursement: 'Arizona Zervas',
    note: 'Ipsum lorem sit dolor consect',
    eventOffers: [
      {
        id: '1a09f260-9d4d-4fc3-b4c3-4276a9f41187',
        venue: {
          name: 'Georgia Theatre',
          city: 'Athens',
          stateCode: 'GA',
          id: 'test-guid',
          country: 'US',
          cityCode: 'LA',
          ownership: '12234',
          status: 'confirmed',
        },
        eventId: '6e12426e-99a3-45e8-8481-6c611bfd61ce',
        offerId: 'e9e6a7cb-f1e9-4b4c-b523-37e9448e56f1',
        settlementId: '6e12426e-99a3-45e8-8481-6c611bfd61ce',
        budget: 1000,
        internal: 150,
        artistReimbursement: 'Arizona Zervas',
        note: 'Ipsum lorem sit dolor consect',
        updatedDate: '2023-10-09T23:07:47.650Z',
      },
      {
        id: '1a09f260-9d4d-4fc3-b4c3-4276a9f41182',
        eventId: '6e12426e-99a3-45e8-8481-6c611bfd61ce',
        offerId: 'e9e6a7cb-f1e9-4b4c-b523-37e9448e56f1',
        settlementId: '6e12426e-99a3-45e8-8481-6c611bfd61ce',
        venue: {
          name: 'Rams Head Live!',
          city: 'Baltimore',
          stateCode: 'MD',
          id: 'test-guid',
          country: 'US',
          cityCode: 'LA',
          ownership: '12234',
          status: 'confirmed',
        },
        budget: 1000,
        internal: 150,
        artistReimbursement: '',
        note: 'Ipsum lorem sit dolor consect',
        updatedDate: '2023-10-09T23:07:47.650Z',
      },
    ],
  },
  {
    id: '1a09f260-9d4d-4fc3-b4c3-4276a9f41184',
    name: 'Conversion',
    showsCount: 10,
    category: 'Staffing',
    budget: {
      average: 1250,
      total: 28000,
    },
    internal: {
      average: 350,
      total: 5000,
    },
    artistReimbursement: '',
    note: '',
    eventOffers: [
      {
        id: '1a09f260-9d4d-4fc3-b4c3-4276a9f41187',
        venue: {
          name: 'Georgia Theatre',
          city: 'Athens',
          stateCode: 'GA',
          id: 'test-guid',
          country: 'US',
          cityCode: 'LA',
          ownership: '12234',
          status: 'confirmed',
        },
        eventId: '6e12426e-99a3-45e8-8481-6c611bfd61ce',
        offerId: 'e9e6a7cb-f1e9-4b4c-b523-37e9448e56f1',
        settlementId: '6e12426e-99a3-45e8-8481-6c611bfd61ce',
        budget: 1000,
        internal: 150,
        artistReimbursement: 'Arizona Zervas',
        note: 'Ipsum lorem sit dolor consect',
        updatedDate: '2023-10-09T23:07:47.650Z',
      },
    ],
  },
];

export const mockFinancesVariableCosts: FinancesVariableCosts[] = [
  {
    id: '1a09f260-9d4d-4fc3-b4c3-4276a9f41187',
    showsCount: 10,
    name: 'Admission / Novelty Tax',
    budget: {
      average: 0,
      total: 0,
    },
    internal: {
      average: 103,
      total: 2000,
    },
    eventOffers: [
      {
        id: '1a09f260-9d4d-4fc3-b4c3-4276a9f41187',
        venue: {
          name: 'Georgia Theatre',
          city: 'Athens',
          stateCode: 'GA',
          id: 'test-guid',
          country: 'US',
          cityCode: 'LA',
          ownership: '12234',
          status: 'confirmed',
        },
        eventId: '6e12426e-99a3-45e8-8481-6c611bfd61ce',
        offerId: 'e9e6a7cb-f1e9-4b4c-b523-37e9448e56f1',
        settlementId: '6e12426e-99a3-45e8-8481-6c611bfd61ce',
        percentageNetGrossReceipt: 1.0,
        feeItem: {
          formula: '% of Net Gross',
          min: null,
          max: 300,
          note: '',
        },
        budget: 0,
        budgetPercentage: 0,
        internal: 900,
        internalPercentage: 0.05,
        updatedDate: '2023-10-09T23:07:47.650Z',
      },
      {
        id: '1a09f260-9d4d-4fc3-b4c3-4276a9f41182',
        eventId: '6e12426e-99a3-45e8-8481-6c611bfd61ce',
        offerId: 'e9e6a7cb-f1e9-4b4c-b523-37e9448e56f1',
        settlementId: '6e12426e-99a3-45e8-8481-6c611bfd61ce',
        venue: {
          name: 'Rams Head Live!',
          city: 'Baltimore',
          stateCode: 'MD',
          id: 'test-guid',
          country: 'US',
          cityCode: 'LA',
          ownership: '12234',
          status: 'confirmed',
        },
        percentageNetGrossReceipt: 1.0,
        feeItem: {
          formula: '% of Net Gross',
          min: null,
          max: 300,
          note: 'Lorem ipsum dolor sit amet',
        },
        budget: 0,
        budgetPercentage: 0,
        internal: 900,
        internalPercentage: 0.05,
        updatedDate: '2023-10-09T23:07:47.650Z',
      },
      {
        id: '1a09f260-9d4d-4fc3-b4c3-4276a9f41183',
        eventId: '6e12426e-99a3-45e8-8481-6c611bfd61ce',
        offerId: 'e9e6a7cb-f1e9-4b4c-b523-37e9448e56f1',
        settlementId: '6e12426e-99a3-45e8-8481-6c611bfd61ce',
        venue: {
          name: 'Royale',
          city: 'Boston',
          stateCode: 'MA',
          id: 'test-guid',
          country: 'US',
          cityCode: 'LA',
          ownership: '12234',
          status: 'confirmed',
        },
        percentageNetGrossReceipt: 1.0,
        feeItem: {
          formula: '% of Net Gross',
          min: null,
          max: 300,
          note: '',
        },
        budget: 0,
        budgetPercentage: 0,
        internal: 900,
        internalPercentage: 0.05,
        updatedDate: '2023-10-09T23:07:47.650Z',
      },
      {
        id: '1a09f260-9d4d-4fc3-b4c3-4276a9f41184',
        eventId: '6e12426e-99a3-45e8-8481-6c611bfd61ce',
        offerId: 'e9e6a7cb-f1e9-4b4c-b523-37e9448e56f1',
        settlementId: '6e12426e-99a3-45e8-8481-6c611bfd61ce',
        venue: {
          name: 'The Factory',
          city: 'Chesterfield',
          stateCode: 'MO',
          id: 'test-guid',
          country: 'US',
          cityCode: 'LA',
          ownership: '12234',
          status: 'confirmed',
        },
        percentageNetGrossReceipt: 1.0,
        feeItem: {
          formula: '% of Net Gross',
          min: null,
          max: 300,
          note: '',
        },
        budget: 0,
        budgetPercentage: 0,
        internal: 900,
        internalPercentage: 0.05,
        updatedDate: '2023-10-09T23:07:47.650Z',
      },
      {
        id: '1a09f260-9d4d-4fc3-b4c3-4276a9f41185',
        eventId: '6e12426e-99a3-45e8-8481-6c611bfd61ce',
        offerId: 'e9e6a7cb-f1e9-4b4c-b523-37e9448e56f1',
        settlementId: '6e12426e-99a3-45e8-8481-6c611bfd61ce',
        venue: {
          name: 'Agora Theatre',
          city: 'Cleveland',
          stateCode: 'OH',
          id: 'test-guid',
          country: 'US',
          cityCode: 'LA',
          ownership: '12234',
          status: 'confirmed',
        },
        percentageNetGrossReceipt: 1.0,
        feeItem: {
          formula: '% of Net Gross',
          min: null,
          max: 300,
          note: '',
        },
        budget: 0,
        budgetPercentage: 0,
        internal: 900,
        internalPercentage: 0.05,
        updatedDate: '2023-10-09T23:07:47.650Z',
      },
      {
        id: '1a09f260-9d4d-4fc3-b4c3-4276a9f41186',
        eventId: '6e12426e-99a3-45e8-8481-6c611bfd61ce',
        offerId: 'e9e6a7cb-f1e9-4b4c-b523-37e9448e56f1',
        settlementId: '6e12426e-99a3-45e8-8481-6c611bfd61ce',
        venue: {
          name: 'The Senate',
          city: 'Columbia',
          stateCode: 'SC',
          id: 'test-guid',
          country: 'US',
          cityCode: 'LA',
          ownership: '12234',
          status: 'confirmed',
        },
        percentageNetGrossReceipt: 1.0,
        feeItem: {
          formula: '% of Net Gross',
          min: null,
          max: 300,
          note: '',
        },
        budget: 0,
        budgetPercentage: 0,
        internal: 900,
        internalPercentage: 0.05,
        updatedDate: '2023-10-09T23:07:47.650Z',
      },
      {
        id: '1a09f260-9d4d-4fc3-b4c3-4276a9f41117',
        eventId: '6e12426e-99a3-45e8-8481-6c611bfd61ce',
        offerId: 'e9e6a7cb-f1e9-4b4c-b523-37e9448e56f1',
        settlementId: '6e12426e-99a3-45e8-8481-6c611bfd61ce',
        venue: {
          name: 'Newport Music Hall',
          city: 'Columbus',
          stateCode: 'OH',
          id: 'test-guid',
          country: 'US',
          cityCode: 'LA',
          ownership: '12234',
          status: 'confirmed',
        },
        percentageNetGrossReceipt: 1.0,
        feeItem: {
          formula: '% of Net Gross',
          min: null,
          max: 300,
          note: '',
        },
        budget: 0,
        budgetPercentage: 0,
        internal: 900,
        internalPercentage: 0.05,
        updatedDate: '2023-10-09T23:07:47.650Z',
      },
      {
        id: '1a09f260-9d4d-4fc3-b4c3-4276a9f41118',
        eventId: '6e12426e-99a3-45e8-8481-6c611bfd61ce',
        offerId: 'e9e6a7cb-f1e9-4b4c-b523-37e9448e56f1',
        settlementId: '6e12426e-99a3-45e8-8481-6c611bfd61ce',
        venue: {
          name: 'Trees',
          city: 'Dallas',
          stateCode: 'TX',
          id: 'test-guid',
          country: 'US',
          cityCode: 'LA',
          ownership: '12234',
          status: 'confirmed',
        },
        percentageNetGrossReceipt: 1.0,
        feeItem: {
          formula: '% of Net Gross',
          min: null,
          max: 300,
          note: '',
        },
        budget: 0,
        budgetPercentage: 0,
        internal: 900,
        internalPercentage: 0.05,
        updatedDate: '2023-10-09T23:07:47.650Z',
      },
      {
        id: '1a09f260-9d4d-4fc3-b4c3-4276a9f41119',
        eventId: '6e12426e-99a3-45e8-8481-6c611bfd61ce',
        offerId: 'e9e6a7cb-f1e9-4b4c-b523-37e9448e56f1',
        settlementId: '6e12426e-99a3-45e8-8481-6c611bfd61ce',
        venue: {
          name: 'The Majestic',
          city: 'Detroit',
          stateCode: 'MI',
          id: 'test-guid',
          country: 'US',
          cityCode: 'LA',
          ownership: '12234',
          status: 'confirmed',
        },
        percentageNetGrossReceipt: 1.0,
        feeItem: {
          formula: '% of Net Gross',
          min: null,
          max: 300,
          note: '',
        },
        budget: 0,
        budgetPercentage: 0,
        internal: 900,
        internalPercentage: 0.05,
        updatedDate: '2023-10-09T23:07:47.650Z',
      },
    ],
  },
  {
    id: '1a09f260-9d4d-4fc3-b4c3-4276a9f41188',
    showsCount: 10,
    name: 'ASCAP',
    budget: {
      average: 0,
      total: 0,
    },
    internal: {
      average: 103,
      total: 2000,
    },
    eventOffers: [
      {
        id: '1a09f260-9d4d-4fc3-b4c3-4276a9f41187',
        venue: {
          name: 'Georgia Theatre',
          city: 'Athens',
          stateCode: 'GA',
          id: 'test-guid',
          country: 'US',
          cityCode: 'LA',
          ownership: '12234',
          status: 'confirmed',
        },
        eventId: '6e12426e-99a3-45e8-8481-6c611bfd61ce',
        offerId: 'e9e6a7cb-f1e9-4b4c-b523-37e9448e56f1',
        settlementId: '6e12426e-99a3-45e8-8481-6c611bfd61ce',
        percentageNetGrossReceipt: 1.0,
        feeItem: {
          formula: '% of Net Gross',
          min: null,
          max: 300,
          note: '',
        },
        budget: 0,
        budgetPercentage: 0,
        internal: 900,
        internalPercentage: 0.05,
        updatedDate: '2023-10-09T23:07:47.650Z',
      },
      {
        id: '1a09f260-9d4d-4fc3-b4c3-4276a9f41182',
        eventId: '6e12426e-99a3-45e8-8481-6c611bfd61ce',
        offerId: 'e9e6a7cb-f1e9-4b4c-b523-37e9448e56f1',
        settlementId: '6e12426e-99a3-45e8-8481-6c611bfd61ce',
        venue: {
          name: 'Rams Head Live!',
          city: 'Baltimore',
          stateCode: 'MD',
          id: 'test-guid',
          country: 'US',
          cityCode: 'LA',
          ownership: '12234',
          status: 'confirmed',
        },
        percentageNetGrossReceipt: 1.0,
        feeItem: {
          formula: '% of Net Gross',
          min: null,
          max: 300,
          note: 'Lorem ipsum dolor sit amet',
        },
        budget: 0,
        budgetPercentage: 0,
        internal: 900,
        internalPercentage: 0.05,
        updatedDate: '2023-10-09T23:07:47.650Z',
      },
      {
        id: '1a09f260-9d4d-4fc3-b4c3-4276a9f41183',
        eventId: '6e12426e-99a3-45e8-8481-6c611bfd61ce',
        offerId: 'e9e6a7cb-f1e9-4b4c-b523-37e9448e56f1',
        settlementId: '6e12426e-99a3-45e8-8481-6c611bfd61ce',
        venue: {
          name: 'Royale',
          city: 'Boston',
          stateCode: 'MA',
          id: 'test-guid',
          country: 'US',
          cityCode: 'LA',
          ownership: '12234',
          status: 'confirmed',
        },
        percentageNetGrossReceipt: 1.0,
        feeItem: {
          formula: '% of Net Gross',
          min: null,
          max: 300,
          note: '',
        },
        budget: 0,
        budgetPercentage: 0,
        internal: 900,
        internalPercentage: 0.05,
        updatedDate: '2023-10-09T23:07:47.650Z',
      },
      {
        id: '1a09f260-9d4d-4fc3-b4c3-4276a9f41184',
        eventId: '6e12426e-99a3-45e8-8481-6c611bfd61ce',
        offerId: 'e9e6a7cb-f1e9-4b4c-b523-37e9448e56f1',
        settlementId: '6e12426e-99a3-45e8-8481-6c611bfd61ce',
        venue: {
          name: 'The Factory',
          city: 'Chesterfield',
          stateCode: 'MO',
          id: 'test-guid',
          country: 'US',
          cityCode: 'LA',
          ownership: '12234',
          status: 'confirmed',
        },
        percentageNetGrossReceipt: 1.0,
        feeItem: {
          formula: '% of Net Gross',
          min: null,
          max: 300,
          note: '',
        },
        budget: 0,
        budgetPercentage: 0,
        internal: 900,
        internalPercentage: 0.05,
        updatedDate: '2023-10-09T23:07:47.650Z',
      },
    ],
  },
  {
    id: '1a09f260-9d4d-4fc3-b4c3-4276a9f41189',
    showsCount: 10,
    name: 'BMI',
    budget: {
      average: 0,
      total: 0,
    },
    internal: {
      average: 103,
      total: 2000,
    },
    eventOffers: [
      {
        id: '1a09f260-9d4d-4fc3-b4c3-4276a9f41187',
        venue: {
          name: 'Georgia Theatre',
          city: 'Athens',
          stateCode: 'GA',
          id: 'test-guid',
          country: 'US',
          cityCode: 'LA',
          ownership: '12234',
          status: 'confirmed',
        },
        eventId: '6e12426e-99a3-45e8-8481-6c611bfd61ce',
        offerId: 'e9e6a7cb-f1e9-4b4c-b523-37e9448e56f1',
        settlementId: '6e12426e-99a3-45e8-8481-6c611bfd61ce',
        percentageNetGrossReceipt: 1.0,
        feeItem: {
          formula: '% of Net Gross',
          min: null,
          max: 300,
          note: '',
        },
        budget: 0,
        budgetPercentage: 0,
        internal: 900,
        internalPercentage: 0.05,
        updatedDate: '2023-10-09T23:07:47.650Z',
      },
      {
        id: '1a09f260-9d4d-4fc3-b4c3-4276a9f41182',
        eventId: '6e12426e-99a3-45e8-8481-6c611bfd61ce',
        offerId: 'e9e6a7cb-f1e9-4b4c-b523-37e9448e56f1',
        settlementId: '6e12426e-99a3-45e8-8481-6c611bfd61ce',
        venue: {
          name: 'Rams Head Live!',
          city: 'Baltimore',
          stateCode: 'MD',
          id: 'test-guid',
          country: 'US',
          cityCode: 'LA',
          ownership: '12234',
          status: 'confirmed',
        },
        percentageNetGrossReceipt: 1.0,
        feeItem: {
          formula: '% of Net Gross',
          min: null,
          max: 300,
          note: 'Lorem ipsum dolor sit amet',
        },
        budget: 0,
        budgetPercentage: 0,
        internal: 900,
        internalPercentage: 0.05,
        updatedDate: '2023-10-09T23:07:47.650Z',
      },
      {
        id: '1a09f260-9d4d-4fc3-b4c3-4276a9f41183',
        eventId: '6e12426e-99a3-45e8-8481-6c611bfd61ce',
        offerId: 'e9e6a7cb-f1e9-4b4c-b523-37e9448e56f1',
        settlementId: '6e12426e-99a3-45e8-8481-6c611bfd61ce',
        venue: {
          name: 'Royale',
          city: 'Boston',
          stateCode: 'MA',
          id: 'test-guid',
          country: 'US',
          cityCode: 'LA',
          ownership: '12234',
          status: 'confirmed',
        },
        percentageNetGrossReceipt: 1.0,
        feeItem: {
          formula: '% of Net Gross',
          min: null,
          max: 300,
          note: '',
        },
        budget: 0,
        budgetPercentage: 0,
        internal: 900,
        internalPercentage: 0.05,
        updatedDate: '2023-10-09T23:07:47.650Z',
      },
      {
        id: '1a09f260-9d4d-4fc3-b4c3-4276a9f41184',
        eventId: '6e12426e-99a3-45e8-8481-6c611bfd61ce',
        offerId: 'e9e6a7cb-f1e9-4b4c-b523-37e9448e56f1',
        settlementId: '6e12426e-99a3-45e8-8481-6c611bfd61ce',
        venue: {
          name: 'The Factory',
          city: 'Chesterfield',
          stateCode: 'MO',
          id: 'test-guid',
          country: 'US',
          cityCode: 'LA',
          ownership: '12234',
          status: 'confirmed',
        },
        percentageNetGrossReceipt: 1.0,
        feeItem: {
          formula: '% of Net Gross',
          min: null,
          max: 300,
          note: '',
        },
        budget: 0,
        budgetPercentage: 0,
        internal: 900,
        internalPercentage: 0.05,
        updatedDate: '2023-10-09T23:07:47.650Z',
      },
    ],
  },
  {
    id: '1a09f260-9d4d-4fc3-b4c3-4276a9f41110',
    showsCount: 10,
    name: 'Credit Card',
    budget: {
      average: 0,
      total: 0,
    },
    internal: {
      average: 103,
      total: 2000,
    },
    eventOffers: [
      {
        id: '1a09f260-9d4d-4fc3-b4c3-4276a9f41187',
        venue: {
          name: 'Georgia Theatre',
          city: 'Athens',
          stateCode: 'GA',
          id: 'test-guid',
          country: 'US',
          cityCode: 'LA',
          ownership: '12234',
          status: 'confirmed',
        },
        eventId: '6e12426e-99a3-45e8-8481-6c611bfd61ce',
        offerId: 'e9e6a7cb-f1e9-4b4c-b523-37e9448e56f1',
        settlementId: '6e12426e-99a3-45e8-8481-6c611bfd61ce',
        percentageNetGrossReceipt: 1.0,
        feeItem: {
          formula: '% of Net Gross',
          min: null,
          max: 300,
          note: '',
        },
        budget: 0,
        budgetPercentage: 0,
        internal: 900,
        internalPercentage: 0.05,
        updatedDate: '2023-10-09T23:07:47.650Z',
      },
      {
        id: '1a09f260-9d4d-4fc3-b4c3-4276a9f41182',
        eventId: '6e12426e-99a3-45e8-8481-6c611bfd61ce',
        offerId: 'e9e6a7cb-f1e9-4b4c-b523-37e9448e56f1',
        settlementId: '6e12426e-99a3-45e8-8481-6c611bfd61ce',
        venue: {
          name: 'Rams Head Live!',
          city: 'Baltimore',
          stateCode: 'MD',
          id: 'test-guid',
          country: 'US',
          cityCode: 'LA',
          ownership: '12234',
          status: 'confirmed',
        },
        percentageNetGrossReceipt: 1.0,
        feeItem: {
          formula: '% of Net Gross',
          min: null,
          max: 300,
          note: 'Lorem ipsum dolor sit amet',
        },
        budget: 0,
        budgetPercentage: 0,
        internal: 900,
        internalPercentage: 0.05,
        updatedDate: '2023-10-09T23:07:47.650Z',
      },
      {
        id: '1a09f260-9d4d-4fc3-b4c3-4276a9f41183',
        eventId: '6e12426e-99a3-45e8-8481-6c611bfd61ce',
        offerId: 'e9e6a7cb-f1e9-4b4c-b523-37e9448e56f1',
        settlementId: '6e12426e-99a3-45e8-8481-6c611bfd61ce',
        venue: {
          name: 'Royale',
          city: 'Boston',
          stateCode: 'MA',
          id: 'test-guid',
          country: 'US',
          cityCode: 'LA',
          ownership: '12234',
          status: 'confirmed',
        },
        percentageNetGrossReceipt: 1.0,
        feeItem: {
          formula: '% of Net Gross',
          min: null,
          max: 300,
          note: '',
        },
        budget: 0,
        budgetPercentage: 0,
        internal: 900,
        internalPercentage: 0.05,
        updatedDate: '2023-10-09T23:07:47.650Z',
      },
      {
        id: '1a09f260-9d4d-4fc3-b4c3-4276a9f41184',
        eventId: '6e12426e-99a3-45e8-8481-6c611bfd61ce',
        offerId: 'e9e6a7cb-f1e9-4b4c-b523-37e9448e56f1',
        settlementId: '6e12426e-99a3-45e8-8481-6c611bfd61ce',
        venue: {
          name: 'The Factory',
          city: 'Chesterfield',
          stateCode: 'MO',
          id: 'test-guid',
          country: 'US',
          cityCode: 'LA',
          ownership: '12234',
          status: 'confirmed',
        },
        percentageNetGrossReceipt: 1.0,
        feeItem: {
          formula: '% of Net Gross',
          min: null,
          max: 300,
          note: '',
        },
        budget: 0,
        budgetPercentage: 0,
        internal: 900,
        internalPercentage: 0.05,
        updatedDate: '2023-10-09T23:07:47.650Z',
      },
    ],
  },
  {
    id: '1a09f260-9d4d-4fc3-b4c3-4276a9f41111',
    showsCount: 10,
    name: 'GMR',
    budget: {
      average: 0,
      total: 0,
    },
    internal: {
      average: 103,
      total: 2000,
    },
    eventOffers: [
      {
        id: '1a09f260-9d4d-4fc3-b4c3-4276a9f41187',
        venue: {
          name: 'Georgia Theatre',
          city: 'Athens',
          stateCode: 'GA',
          id: 'test-guid',
          country: 'US',
          cityCode: 'LA',
          ownership: '12234',
          status: 'confirmed',
        },
        eventId: '6e12426e-99a3-45e8-8481-6c611bfd61ce',
        offerId: 'e9e6a7cb-f1e9-4b4c-b523-37e9448e56f1',
        settlementId: '6e12426e-99a3-45e8-8481-6c611bfd61ce',
        percentageNetGrossReceipt: 1.0,
        feeItem: {
          formula: '% of Net Gross',
          min: null,
          max: 300,
          note: '',
        },
        budget: 0,
        budgetPercentage: 0,
        internal: 900,
        internalPercentage: 0.05,
        updatedDate: '2023-10-09T23:07:47.650Z',
      },
      {
        id: '1a09f260-9d4d-4fc3-b4c3-4276a9f41182',
        eventId: '6e12426e-99a3-45e8-8481-6c611bfd61ce',
        offerId: 'e9e6a7cb-f1e9-4b4c-b523-37e9448e56f1',
        settlementId: '6e12426e-99a3-45e8-8481-6c611bfd61ce',
        venue: {
          name: 'Rams Head Live!',
          city: 'Baltimore',
          stateCode: 'MD',
          id: 'test-guid',
          country: 'US',
          cityCode: 'LA',
          ownership: '12234',
          status: 'confirmed',
        },
        percentageNetGrossReceipt: 1.0,
        feeItem: {
          formula: '% of Net Gross',
          min: null,
          max: 300,
          note: 'Lorem ipsum dolor sit amet',
        },
        budget: 0,
        budgetPercentage: 0,
        internal: 900,
        internalPercentage: 0.05,
        updatedDate: '2023-10-09T23:07:47.650Z',
      },
      {
        id: '1a09f260-9d4d-4fc3-b4c3-4276a9f41183',
        eventId: '6e12426e-99a3-45e8-8481-6c611bfd61ce',
        offerId: 'e9e6a7cb-f1e9-4b4c-b523-37e9448e56f1',
        settlementId: '6e12426e-99a3-45e8-8481-6c611bfd61ce',
        venue: {
          name: 'Royale',
          city: 'Boston',
          stateCode: 'MA',
          id: 'test-guid',
          country: 'US',
          cityCode: 'LA',
          ownership: '12234',
          status: 'confirmed',
        },
        percentageNetGrossReceipt: 1.0,
        feeItem: {
          formula: '% of Net Gross',
          min: null,
          max: 300,
          note: '',
        },
        budget: 0,
        budgetPercentage: 0,
        internal: 900,
        internalPercentage: 0.05,
        updatedDate: '2023-10-09T23:07:47.650Z',
      },
      {
        id: '1a09f260-9d4d-4fc3-b4c3-4276a9f41184',
        eventId: '6e12426e-99a3-45e8-8481-6c611bfd61ce',
        offerId: 'e9e6a7cb-f1e9-4b4c-b523-37e9448e56f1',
        settlementId: '6e12426e-99a3-45e8-8481-6c611bfd61ce',
        venue: {
          name: 'The Factory',
          city: 'Chesterfield',
          stateCode: 'MO',
          id: 'test-guid',
          country: 'US',
          cityCode: 'LA',
          ownership: '12234',
          status: 'confirmed',
        },
        percentageNetGrossReceipt: 1.0,
        feeItem: {
          formula: '% of Net Gross',
          min: null,
          max: 300,
          note: '',
        },
        budget: 0,
        budgetPercentage: 0,
        internal: 900,
        internalPercentage: 0.05,
        updatedDate: '2023-10-09T23:07:47.650Z',
      },
    ],
  },
  {
    id: '1a09f260-9d4d-4fc3-b4c3-4276a9f41112',
    showsCount: 10,
    name: 'Rent',
    budget: {
      average: 0,
      total: 0,
    },
    internal: {
      average: 103,
      total: 2000,
    },
    eventOffers: [
      {
        id: '1a09f260-9d4d-4fc3-b4c3-4276a9f41187',
        venue: {
          name: 'Georgia Theatre',
          city: 'Athens',
          stateCode: 'GA',
          id: 'test-guid',
          country: 'US',
          cityCode: 'LA',
          ownership: '12234',
          status: 'confirmed',
        },
        eventId: '6e12426e-99a3-45e8-8481-6c611bfd61ce',
        offerId: 'e9e6a7cb-f1e9-4b4c-b523-37e9448e56f1',
        settlementId: '6e12426e-99a3-45e8-8481-6c611bfd61ce',
        percentageNetGrossReceipt: 1.0,
        feeItem: {
          formula: '% of Net Gross',
          min: null,
          max: 300,
          note: '',
        },
        budget: 0,
        budgetPercentage: 0,
        internal: 900,
        internalPercentage: 0.05,
        updatedDate: '2023-10-09T23:07:47.650Z',
      },
      {
        id: '1a09f260-9d4d-4fc3-b4c3-4276a9f41182',
        eventId: '6e12426e-99a3-45e8-8481-6c611bfd61ce',
        offerId: 'e9e6a7cb-f1e9-4b4c-b523-37e9448e56f1',
        settlementId: '6e12426e-99a3-45e8-8481-6c611bfd61ce',
        venue: {
          name: 'Rams Head Live!',
          city: 'Baltimore',
          stateCode: 'MD',
          id: 'test-guid',
          country: 'US',
          cityCode: 'LA',
          ownership: '12234',
          status: 'confirmed',
        },
        percentageNetGrossReceipt: 1.0,
        feeItem: {
          formula: '% of Net Gross',
          min: null,
          max: 300,
          note: 'Lorem ipsum dolor sit amet',
        },
        budget: 0,
        budgetPercentage: 0,
        internal: 900,
        internalPercentage: 0.05,
        updatedDate: '2023-10-09T23:07:47.650Z',
      },
      {
        id: '1a09f260-9d4d-4fc3-b4c3-4276a9f41183',
        eventId: '6e12426e-99a3-45e8-8481-6c611bfd61ce',
        offerId: 'e9e6a7cb-f1e9-4b4c-b523-37e9448e56f1',
        settlementId: '6e12426e-99a3-45e8-8481-6c611bfd61ce',
        venue: {
          name: 'Royale',
          city: 'Boston',
          stateCode: 'MA',
          id: 'test-guid',
          country: 'US',
          cityCode: 'LA',
          ownership: '12234',
          status: 'confirmed',
        },
        percentageNetGrossReceipt: 1.0,
        feeItem: {
          formula: '% of Net Gross',
          min: null,
          max: 300,
          note: '',
        },
        budget: 0,
        budgetPercentage: 0,
        internal: 900,
        internalPercentage: 0.05,
        updatedDate: '2023-10-09T23:07:47.650Z',
      },
      {
        id: '1a09f260-9d4d-4fc3-b4c3-4276a9f41184',
        eventId: '6e12426e-99a3-45e8-8481-6c611bfd61ce',
        offerId: 'e9e6a7cb-f1e9-4b4c-b523-37e9448e56f1',
        settlementId: '6e12426e-99a3-45e8-8481-6c611bfd61ce',
        venue: {
          name: 'The Factory',
          city: 'Chesterfield',
          stateCode: 'MO',
          id: 'test-guid',
          country: 'US',
          cityCode: 'LA',
          ownership: '12234',
          status: 'confirmed',
        },
        percentageNetGrossReceipt: 1.0,
        feeItem: {
          formula: '% of Net Gross',
          min: null,
          max: 300,
          note: '',
        },
        budget: 0,
        budgetPercentage: 0,
        internal: 900,
        internalPercentage: 0.05,
        updatedDate: '2023-10-09T23:07:47.650Z',
      },
    ],
  },
  {
    id: '1a09f260-9d4d-4fc3-b4c3-4276a9f41113',
    showsCount: 10,
    name: 'SESAC',
    budget: {
      average: 0,
      total: 0,
    },
    internal: {
      average: 103,
      total: 2000,
    },
    eventOffers: [
      {
        id: '1a09f260-9d4d-4fc3-b4c3-4276a9f41187',
        venue: {
          name: 'Georgia Theatre',
          city: 'Athens',
          stateCode: 'GA',
          id: 'test-guid',
          country: 'US',
          cityCode: 'LA',
          ownership: '12234',
          status: 'confirmed',
        },
        eventId: '6e12426e-99a3-45e8-8481-6c611bfd61ce',
        offerId: 'e9e6a7cb-f1e9-4b4c-b523-37e9448e56f1',
        settlementId: '6e12426e-99a3-45e8-8481-6c611bfd61ce',
        percentageNetGrossReceipt: 1.0,
        feeItem: {
          formula: '% of Net Gross',
          min: null,
          max: 300,
          note: '',
        },
        budget: 0,
        budgetPercentage: 0,
        internal: 900,
        internalPercentage: 0.05,
        updatedDate: '2023-10-09T23:07:47.650Z',
      },
      {
        id: '1a09f260-9d4d-4fc3-b4c3-4276a9f41182',
        eventId: '6e12426e-99a3-45e8-8481-6c611bfd61ce',
        offerId: 'e9e6a7cb-f1e9-4b4c-b523-37e9448e56f1',
        settlementId: '6e12426e-99a3-45e8-8481-6c611bfd61ce',
        venue: {
          name: 'Rams Head Live!',
          city: 'Baltimore',
          stateCode: 'MD',
          id: 'test-guid',
          country: 'US',
          cityCode: 'LA',
          ownership: '12234',
          status: 'confirmed',
        },
        percentageNetGrossReceipt: 1.0,
        feeItem: {
          formula: '% of Net Gross',
          min: null,
          max: 300,
          note: 'Lorem ipsum dolor sit amet',
        },
        budget: 0,
        budgetPercentage: 0,
        internal: 900,
        internalPercentage: 0.05,
        updatedDate: '2023-10-09T23:07:47.650Z',
      },
      {
        id: '1a09f260-9d4d-4fc3-b4c3-4276a9f41183',
        eventId: '6e12426e-99a3-45e8-8481-6c611bfd61ce',
        offerId: 'e9e6a7cb-f1e9-4b4c-b523-37e9448e56f1',
        settlementId: '6e12426e-99a3-45e8-8481-6c611bfd61ce',
        venue: {
          name: 'Royale',
          city: 'Boston',
          stateCode: 'MA',
          id: 'test-guid',
          country: 'US',
          cityCode: 'LA',
          ownership: '12234',
          status: 'confirmed',
        },
        percentageNetGrossReceipt: 1.0,
        feeItem: {
          formula: '% of Net Gross',
          min: null,
          max: 300,
          note: '',
        },
        budget: 0,
        budgetPercentage: 0,
        internal: 900,
        internalPercentage: 0.05,
        updatedDate: '2023-10-09T23:07:47.650Z',
      },
      {
        id: '1a09f260-9d4d-4fc3-b4c3-4276a9f41184',
        eventId: '6e12426e-99a3-45e8-8481-6c611bfd61ce',
        offerId: 'e9e6a7cb-f1e9-4b4c-b523-37e9448e56f1',
        settlementId: '6e12426e-99a3-45e8-8481-6c611bfd61ce',
        venue: {
          name: 'The Factory',
          city: 'Chesterfield',
          stateCode: 'MO',
          id: 'test-guid',
          country: 'US',
          cityCode: 'LA',
          ownership: '12234',
          status: 'confirmed',
        },
        percentageNetGrossReceipt: 1.0,
        feeItem: {
          formula: '% of Net Gross',
          min: null,
          max: 300,
          note: '',
        },
        budget: 0,
        budgetPercentage: 0,
        internal: 900,
        internalPercentage: 0.05,
        updatedDate: '2023-10-09T23:07:47.650Z',
      },
    ],
  },
  {
    id: '1a09f260-9d4d-4fc3-b4c3-4276a9f41114',
    showsCount: 10,
    name: 'Show Insurance',
    budget: {
      average: 0,
      total: 0,
    },
    internal: {
      average: 103,
      total: 2000,
    },
    eventOffers: [
      {
        id: '1a09f260-9d4d-4fc3-b4c3-4276a9f41187',
        venue: {
          name: 'Georgia Theatre',
          city: 'Athens',
          stateCode: 'GA',
          id: 'test-guid',
          country: 'US',
          cityCode: 'LA',
          ownership: '12234',
          status: 'confirmed',
        },
        eventId: '6e12426e-99a3-45e8-8481-6c611bfd61ce',
        offerId: 'e9e6a7cb-f1e9-4b4c-b523-37e9448e56f1',
        settlementId: '6e12426e-99a3-45e8-8481-6c611bfd61ce',
        percentageNetGrossReceipt: 1.0,
        feeItem: {
          formula: '% of Net Gross',
          min: null,
          max: 300,
          note: '',
        },
        budget: 0,
        budgetPercentage: 0,
        internal: 900,
        internalPercentage: 0.05,
        updatedDate: '2023-10-09T23:07:47.650Z',
      },
      {
        id: '1a09f260-9d4d-4fc3-b4c3-4276a9f41182',
        eventId: '6e12426e-99a3-45e8-8481-6c611bfd61ce',
        offerId: 'e9e6a7cb-f1e9-4b4c-b523-37e9448e56f1',
        settlementId: '6e12426e-99a3-45e8-8481-6c611bfd61ce',
        venue: {
          name: 'Rams Head Live!',
          city: 'Baltimore',
          stateCode: 'MD',
          id: 'test-guid',
          country: 'US',
          cityCode: 'LA',
          ownership: '12234',
          status: 'confirmed',
        },
        percentageNetGrossReceipt: 1.0,
        feeItem: {
          formula: '% of Net Gross',
          min: null,
          max: 300,
          note: 'Lorem ipsum dolor sit amet',
        },
        budget: 0,
        budgetPercentage: 0,
        internal: 900,
        internalPercentage: 0.05,
        updatedDate: '2023-10-09T23:07:47.650Z',
      },
      {
        id: '1a09f260-9d4d-4fc3-b4c3-4276a9f41183',
        eventId: '6e12426e-99a3-45e8-8481-6c611bfd61ce',
        offerId: 'e9e6a7cb-f1e9-4b4c-b523-37e9448e56f1',
        settlementId: '6e12426e-99a3-45e8-8481-6c611bfd61ce',
        venue: {
          name: 'Royale',
          city: 'Boston',
          stateCode: 'MA',
          id: 'test-guid',
          country: 'US',
          cityCode: 'LA',
          ownership: '12234',
          status: 'confirmed',
        },
        percentageNetGrossReceipt: 1.0,
        feeItem: {
          formula: '% of Net Gross',
          min: null,
          max: 300,
          note: '',
        },
        budget: 0,
        budgetPercentage: 0,
        internal: 900,
        internalPercentage: 0.05,
        updatedDate: '2023-10-09T23:07:47.650Z',
      },
      {
        id: '1a09f260-9d4d-4fc3-b4c3-4276a9f41184',
        eventId: '6e12426e-99a3-45e8-8481-6c611bfd61ce',
        offerId: 'e9e6a7cb-f1e9-4b4c-b523-37e9448e56f1',
        settlementId: '6e12426e-99a3-45e8-8481-6c611bfd61ce',
        venue: {
          name: 'The Factory',
          city: 'Chesterfield',
          stateCode: 'MO',
          id: 'test-guid',
          country: 'US',
          cityCode: 'LA',
          ownership: '12234',
          status: 'confirmed',
        },
        percentageNetGrossReceipt: 1.0,
        feeItem: {
          formula: '% of Net Gross',
          min: null,
          max: 300,
          note: '',
        },
        budget: 0,
        budgetPercentage: 0,
        internal: 900,
        internalPercentage: 0.05,
        updatedDate: '2023-10-09T23:07:47.650Z',
      },
    ],
  },
];
