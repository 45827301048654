/* eslint-disable max-len */
import { CustomTheme, styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { DataGridPro } from '@mui/x-data-grid-pro';

export const AltRowColorGrid = styled(DataGridPro)(({ theme }: { theme?: CustomTheme }) => ({
  width: '100%',
  borderWidth: '0 !important',
  borderStyle: 'none !important',
  borderColor: '#fff !important',
  '& .MuiDataGrid-row:not(.MuiDataGrid-row--detailPanelExpanded), & .MuiDataGrid-pinnedColumns, & .MuiDataGrid-detailPanel':
    {
      borderBottom: `solid 1px ${theme?.palette.action.disabledBackground as string}`,
    },
  '& .MuiDataGrid-row:hover, & .MuiDataGrid-row.Mui-hovered': {
    backgroundColor: 'transparent',
  },
  '& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus, & .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-columnHeader:focus':
    {
      outline: 'none',
    },
  '& .MuiDataGrid-columnHeaders, & .MuiDataGrid-pinnedColumnHeaders': {
    backgroundColor: theme?.palette.primary.states?.hover,
    boxShadow: 'none',
    borderColor: 'transparent',
  },
  '& .MuiDataGrid-pinnedColumnHeaders--right': {
    padding: '0!important',
  },
  "& .MuiDataGrid-cell[data-field='expandEvent'], & .MuiDataGrid-cell[data-field='addEvent']": {
    padding: 0,
  },
  '& .MuiDataGrid-pinnedColumns': {
    boxShadow: 'none',
    borderBottom: 'none !important',
  },
  '& .MuiDataGrid-pinnedColumnHeaders': {
    backgroundColor: theme?.palette.primary.states?.hover,
  },
  '& .MuiDataGrid-columnHeader, & .MuiDataGrid-cell': {
    border: 'none',
  },
  '& .MuiDataGrid-row': {
    borderBottom: 'none !important',
    backgroundColor: '#fff !important',
  },
  '& .MuiDataGrid-cell': {
    padding: '0',
  },
  '& .MuiTypography-root': {
    padding: '0 10px',
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  '& .MuiDataGrid-actionsCell': {
    width: '100%',
    height: '100%',
  },
  '& .MuiDataGrid-actionsCell .MuiButtonBase-root': {
    borderTop: '1px solid #B4C0CC',
    width: '100%',
    height: '100%',
    borderRadius: 0,
  },
})) as typeof DataGridPro;

export const StyledTypography = styled(Typography)(({ theme }) => ({
  borderTop: '1px solid #B4C0CC',
}));
