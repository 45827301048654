import { styled } from '@mui/material/styles';

// Based on the design this should be a styled Button, not a styled 'div'
// But for this specific implementation where it's being used inside an icon={} prop,
// Using a Button type returns a React warning about nested buttons
export const AddTextStyled = styled('div')(({ theme }) => ({
  color: theme.palette.info.main,
  fontSize: theme.spacing(1.875),
  fontWeight: 500,
  paddingRight: theme.spacing(1.75),
  '&.pinned-left': {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
}));
