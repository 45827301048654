import { Controller, useFormContext } from 'react-hook-form';
import { AutocompleteGetTagProps } from '@mui/material/useAutocomplete';
import { kebabCase } from '@utils/stringHelpers';
import { AutocompleteOption, FormAutocomplete } from '@components';
import {
  InputLabel, InputLabelContainer,
} from './Form.styled';

type FormAutocompleteWrapperProps<
  Entity = any,
> = {
  fieldName: string;
  label?: string;
  options: Array<any>;
  isRequired?: boolean;
  testId?: string;
  placeholder?: string;
  getOptionLabel: (entity: Entity) => string;
  onInputChange: React.Dispatch<React.SetStateAction<string>>;
  renderTags?: (value: Entity[], getTagProps: AutocompleteGetTagProps) => JSX.Element[];
  renderOption?: (
    props: React.HTMLAttributes<HTMLLIElement>,
    option: AutocompleteOption,
  ) => React.ReactNode;
  dataLoading?: boolean;
  filterOut?: Array<string>;
  persistentOption?: any;
  freeSolo?: boolean;
  forceEmailValidation?: boolean;
};

export const FormAutocompleteWrapper = ({
  fieldName,
  label,
  options,
  isRequired,
  testId,
  placeholder,
  getOptionLabel,
  onInputChange,
  renderTags,
  renderOption,
  dataLoading,
  filterOut,
  persistentOption,
  freeSolo,
  forceEmailValidation,
}: FormAutocompleteWrapperProps) => {
  const { control, trigger } = useFormContext();
  return <>
    {label && (
      <InputLabelContainer>
        <InputLabel
          required={isRequired}
          data-testid={`${kebabCase(label)}-label`}
        >
          {label}
        </InputLabel>
      </InputLabelContainer>
    )}
    <Controller
      name={fieldName}
      control={control}
      render={({ field: { onChange, value }, fieldState: { error } }) => <FormAutocomplete
        getOptionLabel={getOptionLabel}
        options={options}
        placeholder={placeholder}
        onInputChange={onInputChange}
        onSelect={(e) => {
          onChange(e);
          if (forceEmailValidation) {
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            trigger();
          }
        }}
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        value={value ?? []}
        error={error !== undefined}
        helperText={error?.message}
        testId={testId}
        renderTags={renderTags}
        renderOption={renderOption}
        dataLoading={dataLoading}
        freeSolo={freeSolo}
        filterOut={filterOut}
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        persistentOption={persistentOption}
        forceEmailValidation={forceEmailValidation}
        dataFilterField={'id'}
      />}
    />
  </>;
};
