/* eslint-disable max-len */
import {
  Tours,
  TourDetails,
  SignIn,
  Artists,
  Companies,
  Festivals,
  Notifications,
  OneOffs,
  Reports,
  Settings,
  Users,
  ErrorPage,
  TaskPane,
  AdminPortal,
  Unauthorized,
  ParentTourDetails,
  WarningModal,
  VenueManagement,
  Venues,
  OldElvisNosSettlements,
  UnauthorizedFile,
  Bundles,
  BundleDetails,
  OneOffDetails,
} from '@views';
import { Navigate, Route } from 'react-router-dom';
import {
  ExcelContextLayout,
  ModernElvisLayout,
  ProtectedLayout,
  WebContextLayout,
  ExcelTaskPaneLayout,
} from '@layouts';
import FeatureFlags from '@utils/featureFlags';
import { BundleProvider, VenueManagementProvider } from '@providers';
import { FeatureFlagLayout } from './layouts/FeatureFlagLayout';

export function ModernElvisRoutes() {
  return (
    <>
      <Route element={<WebContextLayout />}>
        <Route element={<ProtectedLayout />}>
          <Route element={<ModernElvisLayout />}>
            <Route path="/" element={<Navigate replace to="tours" />} />
            <Route path="tours" element={<Tours />} />
            <Route path="tours/:id" element={<TourDetails />} />
            <Route path="bundles" element={<Bundles />} />
            <Route
              path="bundles/:id"
              element={
                <BundleProvider>
                  <BundleDetails />
                </BundleProvider>
              }
            />
            <Route path="multi-leg-tour/:id" element={<ParentTourDetails />} />
            <Route path="artists" element={<Artists />} />
            <Route path="companies" element={<Companies />} />
            <Route path="festivals" element={<Festivals />} />
            <Route path="old-elvis/companies/:companyId/nos-settlements" element={<OldElvisNosSettlements />} />
            <Route path="notifications" element={<Notifications />} />
            <Route element={<FeatureFlagLayout featureFlag={FeatureFlags.ONE_OFFS_EVENTS_LIST} redirectTo="https://elvis.apps.aegpresents.com" />}>
              <Route path="one-offs" element={<OneOffs />} />
            </Route>
            <Route path="one-offs/:id" element={<OneOffDetails />} />
            <Route path="reports" element={<Reports />} />
            <Route path="settings" element={<Settings />} />
            <Route path="users" element={<Users />} />
            <Route path="venues" element={<Venues />} />
            <Route
              path="venues/:venueId/spaces/:spaceId"
              element={
                <VenueManagementProvider>
                  <VenueManagement />
                </VenueManagementProvider>
              }
            />
            <Route path="admin" element={<AdminPortal />} />
          </Route>
        </Route>
        <Route path="auth/signin" element={<SignIn />} />
      </Route>
      <Route element={<ExcelContextLayout />}>
        <Route path="task-pane" element={<ExcelTaskPaneLayout />}>
          <Route path="" element={<TaskPane />} />
        </Route>
        <Route path="warning" element={<WarningModal />} />
      </Route>
      <Route path="unauthorized" element={<Unauthorized />} />
      <Route path='unauthorized-file' element={<UnauthorizedFile />} />
      <Route path="*" element={<ErrorPage />} />
    </>
  );
}
