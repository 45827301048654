import MailOutline from '@mui/icons-material/MailOutline';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';

export const SearchContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  flexGrow: 1,
  justifyContent: 'space-between',
  alignItems: 'center',
}));

export const NewVenueButton = styled(Button)(({ theme }) => ({
  height: 'fit-content',
  marginRight: theme.spacing(2),
}));

export const MailIcon = styled(MailOutline)(({ theme }) => ({
  marginRight: theme.spacing(1),
}));

export const TextContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  borderTop: `1px solid ${theme.palette.divider}`,
  paddingTop: theme.spacing(6),
  color: theme.palette.primary.main,
  opacity: 0.5,
}));

export const FlexContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  height: '100%',
}));
