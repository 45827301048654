import React, { useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import AddIcon from '@mui/icons-material/Add';
import Typography from '@mui/material/Typography';
import { ListViewLayout } from '@layouts/ListViewLayout';
import {
  getGridSingleSelectOperators,
  getGridStringOperators,
  GridActionsCellItem,
  GridColDef,
  GridFilterItem,
  GridValidRowModel,
} from '@mui/x-data-grid-pro';
import {
  CreateEventDrawer,
  FilteringListView,
  ListViewFilter,
  ListViewFilterColumn,
  SearchAutocomplete,
  StartDateFilterInput,
  StatusChip,
} from '@components';

import { getTimezoneDate } from '@utils/stringHelpers';

import { OfferStatusFilters } from '@types';
import { SEARCH_TOURS } from '@gql/queries/tours';
import { CreateButton } from './OneOffsEventsListView.styled';

interface OneOffsEventsListViewProps {
  listViewFilterModel: ListViewFilter | undefined;
  setListViewFilterModel: React.Dispatch<React.SetStateAction<ListViewFilter | undefined>>;
  disableVirtualization?: boolean;
  onEventsCountChange?: (count: number) => void;
}

export type OneOffsEventsListPageFields = {
  id: string;
  event: { id?: string | null; name?: string | null } | null;
  buyer?: Array<string> | null;
  eventStatus?: string | null;
  offer?: string | null;
  date?: string | null;
  offerStatus?: string | null;
  venue?: { id?: string | null; name?: string | null; timezone?: string | null } | null;
  cityState?: string | null;
};

const oneOffsMockDataList: Array<OneOffsEventsListPageFields> = [
  {
    id: '123',
    event: { id: '123', name: 'The Mars Volta' },
    buyer: ['Josh Moore'],
    eventStatus: 'NOS Settled',
    offer: 'The Mars Volta @ Terminal 5 - 1',
    date: '2024-07-22T19:36:18.300Z',
    offerStatus: 'NOS Settled',
    venue: { id: '123', name: 'Terminal 5', timezone: 'America/Los_Angeles' },
    cityState: 'Seattle, WA',
  },
  {
    id: '456',
    event: { id: '456', name: 'Fun fun fun' },
    buyer: ['Jane Doe'],
    eventStatus: 'Canceled',
    offer: 'The Mars Volta @ Terminal 5 - 1',
    date: '2024-07-22T19:36:18.300Z',
    offerStatus: 'Canceled',
    venue: { id: '456', name: 'Terminal 5', timezone: 'America/Los_Angeles' },
    cityState: 'Miami, FL',
  },
  {
    id: '789',
    event: { id: '789', name: 'The Mars Volta' },
    buyer: ['Josh Moore'],
    eventStatus: 'Confirmed',
    offer: 'JJ Grey & Mofro @ Patchchogue Theatre',
    date: '2024-07-22T19:36:18.300Z',
    offerStatus: 'Confirmed',
    venue: { id: '789', name: 'Patchogue Theatre', timezone: 'America/Los_Angeles' },
    cityState: 'Seattle, WA',
  },
];

type OneOffsEventsListGridRow = GridValidRowModel & OneOffsEventsListPageFields;

// eslint-disable-next-line max-lines-per-function
export const OneOffsEventsListView = ({
  disableVirtualization,
  listViewFilterModel,
  setListViewFilterModel,
}: OneOffsEventsListViewProps) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  // eslint-disable-next-line max-lines-per-function
  const getOneOffEventsListColumns = <T extends OneOffsEventsListGridRow>(): GridColDef<T>[] => [
    {
      field: 'event',
      headerName: 'Event',
      flex: 0.17,
      renderCell: (params) => (
        <Typography data-testid="child-one-off-link" variant="body2" color="info.main">
          {params.row.event?.name}
        </Typography>
      ),
    },
    {
      field: 'buyer',
      headerName: 'Buyer',
      flex: 0.12,
      renderCell: (params) => params.row.buyer?.[0],
    },
    {
      field: 'eventStatus',
      headerName: 'Event Status',
      flex: 0.12,
      renderCell: (params) => {
        const status = params.row.eventStatus as string;
        return status ? <StatusChip status={status} /> : <></>;
      },
      type: 'singleSelect',
      valueOptions: Object.values(OfferStatusFilters),
    },
    {
      field: 'offer',
      headerName: 'Offer',
      flex: 0.17,
      renderCell: (params) => (
        <Typography data-testid="child-one-off-offer-link" variant="body2" color="info.main">
          {params.row.offer}
        </Typography>
      ),
    },
    {
      field: 'date',
      headerName: 'Date',
      flex: 0.12,
      renderCell: (params) => {
        if (params.row.date === 'TBD') {
          return 'TBD';
        }
        const [formattedDate] = getTimezoneDate(params.row.date as string, params.row.venue?.timezone, 'll');
        return formattedDate;
      },
    },
    {
      field: 'offerStatus',
      headerName: 'Offer Status',
      flex: 0.12,
      renderCell: (params) => {
        const status = params.row.offerStatus as string;
        return status ? <StatusChip status={status} /> : <></>;
      },
      type: 'singleSelect',
      valueOptions: Object.values(OfferStatusFilters),
    },
    {
      field: 'venue',
      headerName: 'Venue',
      flex: 0.12,
      renderCell: (params) => params.row.venue?.name,
    },
    {
      field: 'cityState',
      headerName: 'City/State',
      flex: 0.12,
      renderCell: (params) => params.row?.cityState,
    },
    {
      field: 'moreActions',
      type: 'actions',
      resizable: false,
      width: 10,
      getActions: () => [
        <GridActionsCellItem data-testid="one-offs-event-list-action" showInMenu label=" " onClick={() => {}} />,
      ],
    },
  ];

  const columns: GridColDef<OneOffsEventsListPageFields>[] = getOneOffEventsListColumns<OneOffsEventsListPageFields>();

  const memoCols = React.useMemo<ListViewFilterColumn[]>(
    () =>
      columns.map((col) => {
        switch (col.field) {
          case 'venue':
          case 'event':
            return {
              ...col,
              filterOperators: getGridStringOperators().filter((operator) => operator.value === 'contains'),
              singleFilterAllowed: true,
            };
          case 'offerStatus':
          case 'eventStatus':
            return {
              ...col,
              sortable: true,
              filterOperators: getGridSingleSelectOperators().filter((operator) => operator.value === 'is'),
            };
          case 'date':
            return {
              ...col,
              sortable: true,
              filterOperators: [
                {
                  label: 'range',
                  value: 'range',
                  getApplyFilterFn: () => (): boolean => true,
                  InputComponent: StartDateFilterInput,
                },
              ],
            };
          case 'buyer':
          case 'offer':
          case 'cityState':
            return {
              ...col,
              filterOperators: getGridStringOperators().filter((operator) => operator.value === 'contains'),
              singleFilterAllowed: true,
            };
          default:
            return {
              ...col,
              sortable: false,
              filterable: false,
            };
        }
      }),
    [],
  );

  const mapFilterToSearchParams = (gridFilter: GridFilterItem[]): URLSearchParams => {
    const newSearchParams = new URLSearchParams();

    gridFilter.forEach((filterItem) => {
      // eslint-disable-next-line prefer-const
      let { field, value } = filterItem as { field: string; value: unknown };

      let fromDate: Dayjs | undefined;
      let toDate: Dayjs | undefined;
      let from: string;
      let to: string;
      switch (filterItem.field) {
        case 'date':
          fromDate = (value as Dayjs[])?.at(0);
          toDate = (value as Dayjs[])?.at(1);
          from = fromDate?.isValid() ? fromDate.format('YYYY-MM-DD') : '';
          to = toDate?.isValid() ? toDate.format('YYYY-MM-DD') : '';
          value = from.length && to.length ? `${from},${to}` : '';
          break;
        case 'buyer':
        case 'cityState':
        case 'event':
        case 'eventStatus':
        case 'offer':
        case 'offerStatus':
        case 'venue':
        default:
      }
      newSearchParams.append(field, (value ?? '') as string);
    });

    return newSearchParams;
  };

  const mapSearchParamsToFilter = (newSearchParams: URLSearchParams): GridFilterItem[] =>
    Array.from(newSearchParams.entries()).map(([field, value], index) => {
      const filterItem: GridFilterItem = {
        id: `${field}-${index}`,
        field,
        value: value ?? '',
        operator: 'is',
      };
      switch (field) {
        case 'date':
          filterItem.value = value.split(',').map((date) => dayjs.utc(date));
          filterItem.operator = 'range';
          break;
        default:
          filterItem.operator = 'contains';
      }
      return filterItem;
    });

  return (
    <ListViewLayout headerText="Events">
      <FilteringListView
        filterModel={listViewFilterModel}
        columns={memoCols}
        disableVirtualization={disableVirtualization}
        isLoadingData={false}
        isLoadingFilters={false}
        onFilterChange={setListViewFilterModel}
        mapFilterToSearchParams={mapFilterToSearchParams}
        mapSearchParamsToFilter={mapSearchParamsToFilter}
        rows={oneOffsMockDataList}
        searchComponent={
          <SearchAutocomplete
            defaultValue={''}
            query={SEARCH_TOURS} // TODO: wire up using oneOffs query
            getOptions={() => oneOffsMockDataList ?? []}
            getOptionLabel={(oneOffs) => oneOffs?.event?.name ?? ''}
            placeholder={'Search Events'}
            onSubmit={() => {}}
            onSelect={() => {}}
            testId={'one-offs-search-autocomplete'}
            inputTestId={'search-autocomplete-input'}
          />
        }
        actions={
          <CreateButton
            variant="contained"
            data-testid="one-offs-events-list-create-button"
            startIcon={<AddIcon />}
            onClick={() => setIsDrawerOpen(true)}
          >
            Create
          </CreateButton>
        }
      />
    <CreateEventDrawer
      isDrawerOpen={isDrawerOpen}
      closeDrawer={() => setIsDrawerOpen(false)}
    />
    </ListViewLayout>
  );
};
