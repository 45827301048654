import { createBrowserRouter, createRoutesFromElements, RouterProvider } from 'react-router-dom';

import { ElvisTheme } from '@services/elvis-theme';
import CssBaseline from '@mui/material/CssBaseline';
import ThemeProvider from '@mui/material/styles/ThemeProvider';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { EnvConfigProvider } from '@providers';
import { EnvConfig } from '@types';
import { ModernElvisRoutes } from './routes';

type AppProps = {
  envConfig: EnvConfig;
};

const routes = createRoutesFromElements(ModernElvisRoutes());

const router = createBrowserRouter(routes);

export function App({ envConfig }: AppProps) {
  return (
    <ThemeProvider theme={ElvisTheme}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <EnvConfigProvider envConfig={envConfig}>
          <CssBaseline />
          <RouterProvider router={router} />
        </EnvConfigProvider>
      </LocalizationProvider>
    </ThemeProvider>
  );
}
