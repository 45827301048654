import InputLabel from '@mui/material/InputLabel';
import TextField, { TextFieldProps } from '@mui/material/TextField';

import { FormControlStyled } from './SearchInputComponent.styled';

export const SearchInputComponent = (props: TextFieldProps) => (
    <FormControlStyled variant="standard" fullWidth>
      <InputLabel shrink variant='standard' id={props.InputLabelProps?.id ?? ''}>
        {props.InputLabelProps?.children || 'Value'}
      </InputLabel>
      <TextField {...props} variant='standard' />
    </ FormControlStyled>
);
