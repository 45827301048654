/* eslint-disable @typescript-eslint/restrict-template-expressions */
import Box from '@mui/material/Box';
import { CustomTheme, styled } from '@mui/material/styles';

export const ConfirmBundleContainer = styled('div')(({ theme }: { theme?: CustomTheme }) => ({
  padding: `${theme?.spacing(0)} ${theme?.spacing(3)}`,
}));

export const ButtonContainer = styled(Box)(({ theme }: { theme?: CustomTheme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  gap: theme?.spacing(2),
  '& .MuiButtonBase-root': {
    marginTop: theme?.spacing(1),
  },
}));
