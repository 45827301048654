import React from 'react';
import { EnvConfig } from '@types';
import featureFlags from '@utils/featureFlags';

const EnvConfigContext = React.createContext<EnvConfig>({
  AZURE_AD_CLIENT_ID: '',
  AZURE_AD_TENANT_ID: '',
  GLOBAL_TOURS_ENDPOINT: '',
  ONE_OFFS_ENDPOINT: '',
  ENABLE_APOLLO_DEV_TOOLS: false,
  INTERCOM_APP_ID: '',
  INTERCOM_ENABLED: false,
  APP_INSIGHTS_INSTRUMENTATION_KEY: '',
  LAUNCH_DARKLY_CLIENT_ID: '',
  flags: {
    [featureFlags.NOS_SETTLEMENT_VERSIONING]: false,
    [featureFlags.NOS_SETTLEMENT_EMAIL]: false,
    [featureFlags.CAN_UPDATE_VENUE]: false,
    [featureFlags.BUNDLES]: false,
    [featureFlags.ONE_OFFS_EVENTS_LIST]: false,
    [featureFlags.GLOBAL_SEARCH]: false,
    [featureFlags.CAN_REFRESH_WORKBOOK]: false,
    [featureFlags.DISPLAY_CLONE_OFFER_DETAILS]: false,
    [featureFlags.DISPLAY_CLONE_EXPENSES_TABLE]: false,
    [featureFlags.CAN_DELETE_TOUR]: false,
    [featureFlags.CAN_KILL_EVENT]: false,
    [featureFlags.CAN_UPDATE_VENUE_ROUTING_CONFIRMED_AND_CONFIRMED]: false,
    [featureFlags.SAVE_PRIMARY_NOS_WORKBOOK]: false,
  },
  flagOverrides: {},
  intercomArticles: {},
});

type ProviderProps = {
  envConfig: EnvConfig;
  children: React.ReactNode;
};
function EnvConfigProvider({ children, envConfig }: ProviderProps) {
  return (
    <EnvConfigContext.Provider value={envConfig}>
      {children}
    </EnvConfigContext.Provider>
  );
}

export { EnvConfigProvider, EnvConfigContext };
