import Stars from '@mui/icons-material/Stars';
import Chip from '@mui/material/Chip';
import TextField from '@mui/material/TextField';
import { CustomTheme, styled } from '@mui/material/styles';
import { DataGridPro, GridFilterPanel } from '@mui/x-data-grid-pro';

interface StyledEventCountChipProps {
  selectedEventCount: number;
}

export const GridFilterPanelStyled = styled(GridFilterPanel)(({ theme }) => ({
  '& .MuiDataGrid-filterFormValueInput': {
    minWidth: theme.spacing(43.75),
    position: 'unset',
  },
}));

export const StyledDataGrid = styled(DataGridPro)(({ theme }: { theme?: CustomTheme }) => ({
  width: '100%',
  border: 'none',
  paddingLeft: theme?.spacing(7),
  '& .MuiDataGrid-pinnedColumns': {
    boxShadow: 'none',
  },
  [`
    & .MuiDataGrid-cell:focus-within,
    & .MuiDataGrid-cell:focus,
  `]:
    {
      outline: 'none',
    },
  '& .MuiDataGrid-cell': {
    border: 'none',
  },
})) as typeof DataGridPro;

export const SaveAndCloseButtonContainer = styled('div')(({ theme }: { theme?: CustomTheme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  padding: theme?.spacing(3),
}));

export const SearchTextField = styled(TextField)(({ theme }: { theme?: CustomTheme }) => ({
  margin: theme?.spacing(3),
  width: '100%',
  maxWidth: theme?.spacing(80),
}));

export const PrimaryStar = styled(Stars)(({ theme }: { theme?: CustomTheme }) => ({
  color: theme?.palette.primary.main,
}));

export const EventNameContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: theme.spacing(1),
  width: '100%',
}));

export const OfferNameContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
}));

export const DateContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
}));

export const StyledEventCountChip = styled(Chip, {
  shouldForwardProp: (prop) => prop !== 'selectedEventCount',
})<StyledEventCountChipProps>(({ theme, selectedEventCount }) => ({
  backgroundColor: selectedEventCount > 0
    ? theme?.palette?.action?.active
    : theme?.palette?.action?.disabled,
}));

export const SwitchContainer = styled('div')(({ theme }) => ({
  marginLeft: theme?.spacing(-1.25),
}));
