import Box from '@mui/material/Box';
import Button, { ButtonProps } from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import FormLabel from '@mui/material/FormLabel';
import { CustomTheme, styled } from '@mui/material/styles';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

export enum FormStylingMode {
  MINI = 'mini',
  COMPACT = 'compact',
  DEFAULT = 'default',
  UNSET = 'unset',
}

interface InputLabelContainerProps {
  formStylingMode?: FormStylingMode;
}

interface FieldsContainerProps {
  formStylingMode?: FormStylingMode;
}

interface InlineFormFieldsContainerProps {
  numberOfFields: number;
}

interface ButtonStyledProps extends ButtonProps {
  minWidth?: number; // to pass into theme.spacing
}

export const InputLabel = styled(FormLabel)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  paddingRight: theme.spacing(1),
  fontWeight: 500,
  color: theme.palette.primary.main,
})) as typeof FormLabel;

export const InputContainer = styled(TextField)<TextFieldProps>(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  color: theme.palette.primary.main,
  '& .MuiInputBase-root.Mui-disabled': {
    backgroundColor: theme.palette.action.hoverOpacity,
    color: theme.palette.primary.main,
    WebkitTextFillColor: `${theme.palette.primary.main}!important`,
  },
  '& .MuiOutlinedInput-input.Mui-disabled': {
    color: theme.palette.primary.main,
    WebkitTextFillColor: `${theme.palette.primary.main}!important`,
  },
}));

export const FormTextInputContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
}));

export const DropdownContainer = styled('div')(({ theme }: { theme?: CustomTheme }) => ({
  display: 'flex',
  flexDirection: 'column',
  '& .Mui-disabled': {
    backgroundColor: theme?.palette.action.hoverOpacity,
    color: theme?.palette.primary.main,
  },
}));

export const PlaceholderContainer = styled('div')(() => ({
  color: 'grey',
}));

export const CheckboxContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginTop: theme.spacing(1),
}));

export const DisabledTypography = styled(Typography)(({ theme }: { theme?: CustomTheme }) => ({
  color: theme?.palette.action.disabled,
}));

export const AddElementLinkContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  marginTop: theme.spacing(2),
  gap: theme.spacing(1),
  '& .MuiSvgIcon-root': {
    color: theme.palette.action.active,
  },
  '& .MuiTypography-root:hover': {
    cursor: 'pointer',
  },
}));

export const VerticalSpacer = styled('div')(({ theme }) => ({
  backgroundColor: '#e1e6ea',
  position: 'absolute',
  top: 0,
  left: 0,
  width: theme.spacing(0.5),
  height: '100%',
  marginRight: theme.spacing(3),
}));

export const FieldsContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'formStylingMode',
})<FieldsContainerProps>(({ theme, formStylingMode }) => ({
  position: 'relative',
  marginTop: theme.spacing(2.5),
  padding: formStylingMode === FormStylingMode.COMPACT
    ? `${theme.spacing(0.5)} ${theme.spacing(0)} ${theme.spacing(1)} ${theme.spacing(2)}`
    : theme.spacing(1.5),
}));

export const InlineFormFieldsContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'numberOfFields',
})<InlineFormFieldsContainerProps>(({ theme, numberOfFields }) => ({
  display: 'grid',
  gridTemplateColumns: `repeat(${numberOfFields}, 1fr)`,
  gap: theme.spacing(2),
}));

export const HeaderContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1.5),
  color: theme.palette.primary.main,
}));

export const AutocompleteInputContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1.75),
  marginTop: theme.spacing(1.5),
  '& .MuiSvgIcon-root': {
    color: theme.palette.action.active,
    cursor: 'pointer',
  },
  '& .MuiFormControl-root': {
    padding: 0,
  },
  '& .MuiAutocomplete-root': {
    width: '94%',
  },
}));

export const ButtonStyled = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'minWidth',
})<ButtonStyledProps>(({ theme, minWidth }) => ({
  display: 'flex',
  flexDirection: 'column',
  textTransform: 'none',
  marginTop: theme.spacing(3),
  marginBottom: theme.spacing(3),
  minWidth: minWidth ? theme.spacing(minWidth) : undefined,
}));

export const InputLabelContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'formStylingMode',
})<InputLabelContainerProps>(({ theme, formStylingMode }) => ({
  paddingTop: formStylingMode === FormStylingMode.COMPACT ? theme.spacing(0) : theme.spacing(3),
  paddingBottom: theme.spacing(1.5),
  display: 'flex',
}));

export const AddSubjectTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.info.main,
}));

export const TextInputContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1.75),
  marginTop: theme.spacing(1.5),
  '& .MuiSvgIcon-root': {
    color: theme.palette.action.active,
    cursor: 'pointer',
  },
  '& .MuiFormControl-root': {
    padding: 0,
  },
  '& .MuiTextField-root': {
    width: '94%',
  },
}));

export const StyledChip = styled(Chip, {
  shouldForwardProp: (prop) => prop !== 'error',
})(({ theme, error }: { theme?: CustomTheme, error?: boolean }) => ({
  backgroundColor: error ? theme?.palette?.error?.light : theme?.palette?.action?.selected,
  color: theme?.palette?.text?.primary,
  '& .MuiSvgIcon-root': {
    color: error ? theme?.palette?.error?.main : undefined,
  },
}));

export const StyledChipChild = styled('div')(({ theme }: { theme?: CustomTheme }) => ({
  color: theme?.palette?.text?.base,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: theme?.spacing(4),
  gap: theme?.spacing(1),
}));
