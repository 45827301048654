import { useState, useEffect } from 'react';
import { GridSortModel } from '@mui/x-data-grid-pro';

export enum PersistedSortGrids {
  BUNDLE_DETAILS = 'BUNDLE_DETAILS',
}

const GRID_SORT_STORAGE_KEYS = {
  BUNDLE_DETAILS: 'bundleOffersSort',
};

const defaultRoutingSort = {
  field: 'market',
  sort: 'asc',
} as GridSortModel[0];

export const useGridState = (grid?: PersistedSortGrids): {
  sortModel: GridSortModel;
  setSortModel: (newSortModel: GridSortModel) => void;
} => {
  const [sortModel, setSortModel] = useState<GridSortModel>(() => {
    if (grid && GRID_SORT_STORAGE_KEYS[grid]) {
      const savedSortModel = localStorage.getItem(GRID_SORT_STORAGE_KEYS[grid]);
      return savedSortModel ? (JSON.parse(savedSortModel) as GridSortModel) : [defaultRoutingSort];
    }
    return [];
  });

  useEffect(() => {
    if (grid && GRID_SORT_STORAGE_KEYS[grid]) {
      localStorage.setItem(GRID_SORT_STORAGE_KEYS[grid], JSON.stringify(sortModel));
    }
  }, [sortModel]);

  return {
    sortModel,
    setSortModel,
  };
};
