import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';

export const ContentContainer = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(1),
  padding: theme.spacing(2),
  paddingBottom: theme.spacing(1),
}));

export const EyebrowLabel = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
}));

export const OptionSwitch = styled(Switch)(({ theme }) => ({
  margin: theme.spacing(1),
}));

export const StyledDivider = styled(Divider)(({ theme }) => ({
  marginLeft: theme.spacing(2),
  marginRight: theme.spacing(2),
  marginTop: theme.spacing(1),
}));
