import Button, { ButtonProps } from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

export const CreateButtonContainer = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  width: '100%',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
}));

export const CreateText = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.light[300],
  margin: theme.spacing(2, 0),
  fontSize: theme.spacing(2),
}));

export const ButtonStyled = styled(Button)(({ theme }) => ({
  textTransform: 'none',
  fontSize: theme.spacing(1.875),
  width: 'fit-content',
}));

export const CreateButton = styled(ButtonStyled)<ButtonProps>(({ theme }) => ({
  margin: theme.spacing(3, 2),
  padding: theme.spacing(1, 2.75),
}));

export const ActionButtonStyled = styled(ButtonStyled)(({ theme }) => ({
  fontSize: theme.spacing(1.75),
}));
