import Select from '@mui/material/Select';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

export const SubHeader = styled(Typography)(({ theme }) => ({
  paddingTop: theme.spacing(3),
})) as typeof Typography;

export const SelectStyled = styled(Select)(({ theme }) => ({
  '&.Mui-disabled, & .MuiOutlinedInput-input.Mui-disabled': {
    backgroundColor: theme.palette.action.hoverOpacity,
    color: theme.palette.primary.main,
    WebkitTextFillColor: `${theme.palette.primary.main}!important`,
  },
}));
