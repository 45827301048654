import { styled } from '@mui/material/styles';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

export const ColoredTableRow = styled(TableRow)({
  backgroundColor: '#f5f7f8',
});

export const InputCell = styled(TableCell)(({ theme }) => ({
  padding: theme.spacing(1),
  borderBottom: '0',
}));
