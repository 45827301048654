import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

export const ColoredTableRow = styled(TableRow)({
  backgroundColor: '#f5f7f8',
});

export const InputCell = styled(TableCell)(({ theme }) => ({
  padding: theme.spacing(1),
  borderBottom: '0',
}));

export const RemoveButtonCell = styled(TableCell)({
  width: '56px',
  '&.borderless': {
    borderBottom: '0',
  },
});

export const RadioContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

export const CloseIconWithDisable = styled(CloseIcon)({
  cursor: 'pointer',
  '&.disabled': {
    opacity: 0.6,
    pointerEvents: 'none',
  },
});
