/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable max-lines-per-function */
import React, {
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import dayjs from 'dayjs';
import tz from 'dayjs/plugin/timezone';
import Box from '@mui/material/Box';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';
import {
  DataGridProProps,
  GridCellParams,
  GridColDef,
  GridRowId,
  GridRowModel,
  GridSortModel,
  GRID_DETAIL_PANEL_TOGGLE_FIELD,
} from '@mui/x-data-grid-pro';
import {
  AltRowColorGrid,
  ShowTable,
  StatusChip,
  JDEEventSubledgerStatus,
  FeatureGate,
  NOSVersionsTable,
  CloseIconGrey,
  ProtectedComponent,
} from '@components';
import { Event, Show, User } from '@gql/types/graphql';
import { EventStatus, PermissionCheck } from '@types';
import { useGridState, PersistedSortGrids } from '@hooks';
import { BundleDispatchContext } from '@providers';
import { expandColumn, manageExpandedRows } from '@utils/datagridHelpers';
import { getTimezoneDate } from '@utils/stringHelpers';
import { sortEventsByDateActive, sortEventsByDate } from '@utils/eventHelpers';
import { getMarketDisplayName } from '@utils/venueHelpers';
import featureFlags from '@utils/featureFlags';
import {
  EventsContainer,
  StyledNoEventsTypography,
  StyledButton,
  SwitchContainer,
  PrimaryStar,
  PrimaryStarDisabled,
} from './EventsList.styled';
import { ShowsGridData } from '../shared/ShowTable/ShowTable.types';
import { EventListTableActions } from './EventListTableActions';

dayjs.extend(tz);

type EventListProps = {
  events?: Event[];
  columns?: string[];
  columnHeaderOverrides?: Record<string, string>;
  sortByDate?: boolean;
  noEventsFoundText: string;
  removeEventPermission?: PermissionCheck;
  onActivateEvent?: (eventId: string, isActive: boolean) => void;
  onRemoveEvent?: (eventId: string) => void;
  persistSort?: boolean;
};

export function EventsList({
  events,
  columns,
  columnHeaderOverrides,
  sortByDate = false,
  noEventsFoundText,
  removeEventPermission,
  onActivateEvent,
  onRemoveEvent,
  persistSort,
}: EventListProps) {
  const [expandedRows, setExpandedRows] = useState<GridRowId[]>([]);
  const setSortedEventIds = useContext(BundleDispatchContext);
  const { sortModel, setSortModel } = useGridState(
    persistSort ? PersistedSortGrids.BUNDLE_DETAILS : undefined,
  );

  const expandRowClick = (gridRowId: GridRowId) => {
    manageExpandedRows(gridRowId, expandedRows, setExpandedRows);
  };

  const getDetailPanelContent = React.useCallback<NonNullable<DataGridProProps['getDetailPanelContent']>>(
    ({ row }: { row: ShowsGridData }) => (
      <Box>
        <ShowTable row={row} />
        <FeatureGate configFlag={featureFlags.NOS_SETTLEMENT_VERSIONING}>
          <NOSVersionsTable eventId={row.id}/>
        </FeatureGate>
      </Box>
    ),
    [],
  );

  const eventData = useMemo(() => {
    if (!events) {
      return [];
    }

    const sortedEvents = () => {
      if (persistSort) {
        return events;
      }
      return sortByDate
        ? sortEventsByDate(events)
        : sortEventsByDateActive(events);
    };

    // TODO: Add subledger and capacity data when available
    const mappedEventsData = sortedEvents().map((event: Event) => {
      const {
        id, date, status, shows, venue, space, subledgerId, subledgerStatus, subledgerPayload,
        isSettledInOldElvis, workbookFileId, isActive, primaryBuyer, isOneOffPrimary,
      } = event;

      const venueName = space?.name || '';

      const market = getMarketDisplayName(venue);

      return {
        id: id ?? '',
        capacity: event.sellableCapacity || event.space?.maxCapacity || 'Not Available',
        market,
        firstEventDate: date as string ?? '',
        shows: shows as Show[] ?? [],
        status: status as EventStatus ?? '',
        subledgerId,
        subledgerStatus,
        subledgerPayload,
        venueName,
        timezone: event.venue?.timezone ?? null,
        isSettledInOldElvis,
        workbookFileId,
        active: isActive,
        primaryBuyer: primaryBuyer as User ?? [],
        isOneOffPrimary,
      };
    });

    return mappedEventsData;
  }, [events, sortModel]);

  useEffect(() => {
    if (persistSort) {
      setSortedEventIds({ sortedEventIds: eventData.map((event) => event.id) });
    }
  }, [eventData, persistSort]);

  const getExpandColumn = () => expandColumn(expandedRows, expandRowClick);

  const getVenueColumn = (): GridColDef<ShowsGridData> => ({
    field: 'venueName',
    headerName: 'Venue',
    flex: 1,
    renderCell: (params) => (
      <Typography>
        {params.row.venueName}
      </Typography>
    ),
  });

  const getMarketColumn = (): GridColDef<ShowsGridData> => ({
    field: 'market',
    headerName: 'Market',
    flex: 1,
    renderCell: (params) => (
      <Typography>
        {params.row.market}
      </Typography>
    ),
  });

  const getShowCountColumn = (): GridColDef<ShowsGridData> => ({
    field: 'showsCount',
    headerName: 'Show(s)',
    flex: 0.6,
    renderCell: (params) => (
      <Typography> {params.row.shows.length} </Typography>
    ),
  });

  const getFirstEventDateColumn = (): GridColDef<ShowsGridData> => ({
    field: 'firstEventDate',
    headerName: 'First Event Date',
    flex: 0.8,
    renderCell: (params) => (
      <Typography>
        {params.row.firstEventDate
          ? getTimezoneDate(params.row.firstEventDate, params.row.timezone)[0]
          : 'TBD'
        }
      </Typography>
    ),
  });

  const getLastUpdatedColumn = (): GridColDef<ShowsGridData> => ({
    field: 'updatedDate',
    headerName: 'Last Updated',
    flex: 0.8,
    renderCell: (params) => params.row.updatedDate ?? 'TBD',
  });

  const getCapacityColumn = (): GridColDef<ShowsGridData> => ({
    field: 'capacity',
    headerName: 'Capacity',
    flex: 0.6,
    renderCell: (params) => (
      <Typography>
        {params.row.capacity}
      </Typography>
    ),
  });

  const getSubledgerColumn = (): GridColDef<ShowsGridData> => ({
    field: 'subledger',
    headerName: 'Subledger',
    flex: 0.8,
    renderCell: (params: GridCellParams<GridRowModel>) => (
      <JDEEventSubledgerStatus
        eventId={params.row.id as string}
        subledgerId={params.row.subledgerId as string}
        subledgerStatus={params.row.subledgerStatus as string}
      />
    ),
  });

  const getBuyerColumn = (): GridColDef<ShowsGridData> => ({
    field: 'buyerIds',
    headerName: 'Buyer',
    flex: 0.8,
    renderCell: (params) => params.row.primaryBuyer?.name ?? '',
  });

  const getStatusColumn = (): GridColDef<ShowsGridData> => ({
    field: 'status',
    headerName: columnHeaderOverrides?.status || 'Event Status',
    flex: 0.8,
    renderCell: (params) => (
      <StatusChip status={params.row.status as string} />
    ),
  });

  const getActiveColumn = (): GridColDef<ShowsGridData> => ({
    field: 'active',
    headerName: 'Active',
    flex: 0.5,
    renderCell: (params) => (
      <SwitchContainer>
        <Switch
          data-testid={`toggle-active-event-${params.row.id}`}
          checked={params.row.active}
          onChange={onActivateEvent ? (e) => onActivateEvent(params.row.id, e.target.checked) : undefined}
        />
      </SwitchContainer>
    ),
  });

  const getRemoveActionColumn = (): GridColDef<ShowsGridData> => ({
    field: 'removeAction',
    type: 'actions',
    width: 10,
    resizable: false,
    sortable: false,
    filterable: false,
    getActions: (params) => [
      <ProtectedComponent
        /* Without a key, DataGridPro throws an `Each child in a list should have a unique "key" prop.` warning */
        key='remove-event'
        checkPermission={removeEventPermission as PermissionCheck}
      >
        <StyledButton
          data-testid={`remove-event-${params.row.id}`}
          onClick={onRemoveEvent ? () => onRemoveEvent(params.row.id) : undefined}
        >
          <CloseIconGrey />
        </StyledButton>
      </ProtectedComponent>,
    ],
  });

  const getMoreActionsColumn = (): GridColDef<ShowsGridData> => ({
    field: 'moreActions',
    type: 'actions',
    resizable: false,
    width: 10,
    getActions: EventListTableActions,
  });

  const getPrimaryColumn = (): GridColDef<ShowsGridData> => ({
    field: 'isPrimary',
    headerName: '',
    width: 10,
    resizable: false,
    sortable: false,
    filterable: false,
    renderCell: (params) => (
      params.row.isOneOffPrimary ? <PrimaryStar /> : <PrimaryStarDisabled />
    ),
  });

  const columnsList: GridColDef<ShowsGridData>[] = [
    getPrimaryColumn(),
    getVenueColumn(),
    getMarketColumn(),
    getShowCountColumn(),
    getFirstEventDateColumn(),
    getLastUpdatedColumn(),
    getCapacityColumn(),
    getSubledgerColumn(),
    getBuyerColumn(),
    getStatusColumn(),
    getActiveColumn(),
    getMoreActionsColumn(),
    getRemoveActionColumn(),
  ].filter((column) => !columns || columns.includes(column.field));

  const filteredColumns: GridColDef<ShowsGridData>[] = [
    ...getExpandColumn(), // expand column (must be first)
    ...columnsList,
  ];

  const handleSortModelChange = (model: GridSortModel) => {
    if (persistSort) {
      setSortModel(model);

      const sortedEvents = model.length
        ? [...eventData].sort((a, b) => {
          const { sort } = model[0];
          const field = model[0].field as keyof typeof a & keyof typeof b;
          const aValue = a[field] ?? '';
          const bValue = b[field] ?? '';

          if (aValue < bValue) return sort === 'asc' ? -1 : 1;
          if (aValue > bValue) return sort === 'asc' ? 1 : -1;
          return 0;
        })
        : eventData;

      setSortedEventIds({ sortedEventIds: sortedEvents.map((event) => event.id) });
    }
  };

  useEffect(() => {
    // Trigger sorting logic on initial load
    if (eventData.length && persistSort) {
      handleSortModelChange(sortModel);
    }
  }, [eventData, sortModel]);

  return (
    <EventsContainer data-testid="events-container">
      {events && events.length ? (
        <AltRowColorGrid
          hideFooter
          rows={eventData}
          columns={filteredColumns}
          data-testid="events-data-grid"
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          getRowId={(row) => row.id as string}
          getDetailPanelContent={getDetailPanelContent}
          getDetailPanelHeight={() => 'auto'}
          initialState={{
            pinnedColumns: {
              right: [GRID_DETAIL_PANEL_TOGGLE_FIELD, 'moreActions', 'removeAction'],
            },
          }}
          onSortModelChange={handleSortModelChange}
          sortModel={persistSort ? sortModel : undefined}
        />
      ) : (
        <StyledNoEventsTypography>
          {noEventsFoundText}
        </StyledNoEventsTypography>
      )}
    </EventsContainer>
  );
}
