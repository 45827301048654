import Box from '@mui/material/Box';
import Button, { ButtonProps } from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Tab, { TabProps } from '@mui/material/Tab';
import { GridFilterPanel } from '@mui/x-data-grid-pro';

export const StyledTab = styled(Tab)<TabProps>(() => ({
  textTransform: 'none',
  fontWeight: 700,
}));

export const CreateButton = styled(Button)<ButtonProps>(({ theme }) => ({
  textTransform: 'none',
  padding: theme.spacing(1, 2.75),
  display: 'inline-flex',
  marginRight: theme.spacing(2),
}));

export const AutocompleteContainer = styled(Box)(({ theme }) => ({
  display: 'inline-flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  height: theme.spacing(6),
  '& .MuiAutocomplete-root': {
    width: '100%',
    height: '100%',
  },
  '& .MuiAutocomplete-popper': {
    minWidth: '350px',
  },
}));

export const GridFilterPanelStyled = styled(GridFilterPanel)({
  '& .MuiDataGrid-filterFormValueInput': {
    minWidth: '350px',
    position: 'unset',
  },
});
