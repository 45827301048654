/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { Control, FieldValues, useFieldArray } from 'react-hook-form';
import { AutocompleteOption } from '@components';
import { useTheme, CustomTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import AddIcon from '@mui/icons-material/Add';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CloseIcon from '@mui/icons-material/Close';
import { AutocompleteInput } from './AutocompleteInput';
import {
  AddElementLinkContainer,
  AddSubjectTypography,
  AutocompleteInputContainer,
  FieldsContainer,
  HeaderContainer,
  VerticalSpacer,
} from './Form.styled';

type AutocompleteFieldArrayProps<
  TFieldValues extends FieldValues = any,
  TContext = any,
> = {
  subjectName: string;
  fieldName: string;
  placeholderText: string;
  control: Control<TFieldValues, TContext>;
  options: Array<any>;
  filterOut?: Array<string>;
  areOptionsLoading?: boolean;
  onInputChange: React.Dispatch<React.SetStateAction<string>>;
  defaultValue: any;
  showHeader?: boolean;
  showVerticalSpacer?: boolean;
  customHeader?: React.ReactNode;
};

// eslint-disable-next-line max-lines-per-function
export const AutocompleteFieldArray = ({
  subjectName,
  fieldName,
  placeholderText,
  control,
  options,
  filterOut,
  areOptionsLoading,
  onInputChange,
  defaultValue,
  showHeader = true,
  showVerticalSpacer = true,
  customHeader = null,
}: AutocompleteFieldArrayProps) => {
  const theme: CustomTheme = useTheme();

  const { fields, append, remove } = useFieldArray({
    name: fieldName,
    control,
  });

  const header = customHeader ?? (
    <HeaderContainer>
      <Typography variant="subtitle2">{subjectName}</Typography>
      <InfoOutlinedIcon />
    </HeaderContainer>
  );

  return (
    <div>
      {fields.length > 0 && (
        <FieldsContainer
          style={!showVerticalSpacer ? ({ padding: 0 }) : {}}
          data-testid={`autocomplete-array-fields-container-${fieldName}`}
        >
          {showVerticalSpacer ? <VerticalSpacer /> : null}
          {showHeader ? header : null}
          {fields.map((field, idx) => (
            <AutocompleteInputContainer key={field.id}>
              <AutocompleteInput
                fieldName={`${fieldName}[${idx}]`}
                control={control}
                label={''}
                options={options}
                dataDisplayField={{ primary: 'name' }}
                dataFilterField={'id'}
                filterOut={filterOut}
                areOptionsLoading={areOptionsLoading}
                onInputChange={onInputChange}
                placeholderText={placeholderText}
                isRequired={false}
                defaultValue={
                  defaultValue
                    ? (defaultValue[idx] as AutocompleteOption)
                    : null
                }
                removeClearIcon={true}
              />
              <CloseIcon
                onClick={() => remove(idx)}
                data-testid={`close-autocomplete-${fieldName}${idx}`}
              />
            </AutocompleteInputContainer>
          ))}
        </FieldsContainer>
      )}
      <AddElementLinkContainer
        data-testid={`autocomplete-array-link-container-${fieldName}`}
      >
        <AddIcon />
        <AddSubjectTypography
          variant="body2"
          data-testid={`autocomplete-array-link-${fieldName}`}
          onClick={() => append(null)}
        >
          Add {subjectName}
        </AddSubjectTypography>
        <Typography
          variant="caption"
          color={theme?.palette.input?.standard.enabledBorder}
        >
          (optional)
        </Typography>
      </AddElementLinkContainer>
    </div>
  );
};
