import dayjs from 'dayjs';
import { DetailsMetadataItem, StatusChip, StarsFilledIcon } from '@components';
import { BoxOfficeItemFieldsFragment, SearchEventFieldsFragment } from '@gql/types/graphql';
import { Phases } from '@utils/excel/shared/constants';
import { getFragmentData } from '@gql/types';
import { BOX_OFFICE_FRAGMENT } from '@gql/queries/events';
import { formatNumberToCurrency } from '@utils/numberHelpers';
import Box from '@mui/material/Box';
import { StarContainer } from './CloneTab.styled';

export const EXPENSES_COLUMNS = [
  {
    field: 'expenseName',
    headerName: 'Expense Type',
    minWidth: 150,
    flex: 1,
  },
  {
    field: 'amount',
    headerName: 'Amount',
    minWidth: 100,
    headerAlign: 'right',
    align: 'right',
  },
];

export const BOX_OFFICE_COLUMNS = [
  {
    field: 'ticketTier',
    headerName: 'Ticket Tier',
    flex: 2,
  },
  {
    field: 'availableCap',
    headerName: 'Available Cap',
    flex: 1,
  },
  {
    field: 'price',
    headerName: 'Price',
    flex: 1,
    headerAlign: 'right',
    align: 'right',
  },
  {
    field: 'grossPotential',
    headerName: 'Gross',
    flex: 1,
    headerAlign: 'right',
    align: 'right',
  },
];

export const generateOfferMetadataRowItems = (selectedSearchedEvent?: SearchEventFieldsFragment) => {
  if (selectedSearchedEvent) {
    const eventDate = dayjs(selectedSearchedEvent?.date);
    const lastUpdatedDate = dayjs(selectedSearchedEvent?.tourInformation?.offerUpdatedDate);

    const singleOfferMetadata = [
      {
        title: 'Offer Name',
        element: <Box sx={{ display: 'flex' }}>
          {selectedSearchedEvent?.tourInformation?.offerIsPrimary ? <StarContainer>
            <StarsFilledIcon fontSize="small" />
          </StarContainer> : null}
          {selectedSearchedEvent?.tourInformation?.offerName ?? '-'}
        </Box>,
      },
      {
        title: 'Last Updated',
        value: lastUpdatedDate.isValid() ? lastUpdatedDate.format('MM/DD/YYYY') : '-',
      },
      {
        title: 'Status',
        element: <StatusChip status={selectedSearchedEvent?.status ?? '-'}/>,
      },
      {
        title: 'Shows',
        value: `${selectedSearchedEvent?.shows?.length ?? '-'}`,
      },
      {
        title: 'Headliner',
        value: selectedSearchedEvent?.headliner?.name ?? '-',
      },
      {
        title: 'Event Date',
        value: eventDate.isValid() ? eventDate.format('MM/DD/YYYY') : '-',
      },
      {
        title: 'Available Cap',
        value: `${selectedSearchedEvent?.projectedBoxOfficeOverview?.totalAvailableCapacity ?? '-'}`,
      },
      {
        title: 'Configuration',
        value: selectedSearchedEvent?.configuration ?? '-',
      },
      {
        title: 'Buyer',
        value: selectedSearchedEvent?.primaryBuyer?.name ?? '-',
      },
      {
        title: 'Trucks',
        value: `${selectedSearchedEvent?.tourInformation?.numberOfTrucks ?? '-'}`,
      },
    ];

    return singleOfferMetadata.map((singleOfferItem, index) => (
      <DetailsMetadataItem
        key={`single-offer-${index}`}
        metadata={singleOfferItem}
        first={index === 0}
      />
    ));
  }
  return [];
};

interface ExpensesRowItem {
  id: string;
  key: string;
  expenseName: string;
  amount: string;
  bold?: boolean;
}
const DISPLAYED_EXPENSES_TOTALS: { [key: string]: boolean } = {
  Stagehands: true,
  Staffing: true,
  Rent: true,
  Venue: true,
  Production: true,
};
export const generateExpensesRowItems = (selectedSearchedEvent?: SearchEventFieldsFragment) => {
  const result : ExpensesRowItem[] = [];
  if (selectedSearchedEvent) {
    // Fixed category items
    const expensePhases = selectedSearchedEvent?.expense?.phases ?? [];
    const modelingExpensePhase = expensePhases.find((phase) => phase.id === Phases.MODELING);
    modelingExpensePhase?.fixedCategoryItems?.forEach((fixedCategoryItem, index) => {
      const id = `fixed-category-item-${index}`;
      const { categoryId, totalBudget } = fixedCategoryItem;
      if (categoryId && DISPLAYED_EXPENSES_TOTALS[categoryId]) {
        result.push({
          id,
          key: id,
          expenseName: categoryId,
          amount: formatNumberToCurrency(totalBudget) ?? '-',
        });
      }
    });

    // Fixed Costs Total
    result.push({
      id: 'fixed-costs-total',
      key: 'fixed-costs-total',
      expenseName: 'Fixed Costs Total',
      amount: formatNumberToCurrency(modelingExpensePhase?.totalFixedCosts) ?? '-',
      bold: true,
    });
  }
  return result;
};

const isPhaseItemAllNull = (phaseItem: BoxOfficeItemFieldsFragment) => {
  const {
    name, capacity, price, grossPotential,
  } = phaseItem;
  return name === null && capacity === null && price === null && (grossPotential === null || grossPotential === 0);
};

interface BoxOfficeRowItem {
  id: string;
  key: string;
  ticketTier: string;
  availableCap: string | number;
  price: string | number;
  grossPotential: string | number;
  bold?: boolean;
}
export const generateBoxOfficeRowItems = (selectedSearchedEvent?: SearchEventFieldsFragment) => {
  let result : BoxOfficeRowItem[] = [];
  if (selectedSearchedEvent) {
    const boxOfficePhases = selectedSearchedEvent?.defaultBoxOffice?.phases ?? [];
    const modelingPhase = boxOfficePhases.find((phase) => phase.id === Phases.MODELING);
    result = modelingPhase?.items?.reduce((accum: BoxOfficeRowItem[], item, index) => {
      const id = `box-office-row-${index}`;
      const phaseItem = getFragmentData(BOX_OFFICE_FRAGMENT, item) as unknown as BoxOfficeItemFieldsFragment;

      const {
        name, capacity, price, grossPotential,
      } = phaseItem;
      if (isPhaseItemAllNull(phaseItem)) {
        return accum;
      }

      const boxOfficeRow: BoxOfficeRowItem = {
        id,
        key: id,
        ticketTier: name ?? '-',
        availableCap: capacity?.toLocaleString() ?? '-',
        price: formatNumberToCurrency(price) ?? '-',
        grossPotential: formatNumberToCurrency(grossPotential) ?? '-',
      };
      accum.push(boxOfficeRow);
      return accum;
    }, []) ?? [];
  }
  return result;
};

export const generatePinnedBoxOfficeRowItems = (selectedSearchedEvent?: SearchEventFieldsFragment) => {
  const result : BoxOfficeRowItem[] = [];
  if (selectedSearchedEvent) {
    result.push({
      id: 'total-row-1',
      key: 'total-row-1',
      ticketTier: 'Total',
      availableCap: selectedSearchedEvent?.projectedBoxOfficeOverview?.totalAvailableCapacity ?? '-',
      price: '-',
      grossPotential: formatNumberToCurrency(selectedSearchedEvent?.projectedBoxOfficeOverview?.totalGross) ?? '-',
      bold: true,
    });
    result.push({
      id: 'total-row-2',
      key: 'total-row-2',
      ticketTier: 'Net Show Receipts',
      availableCap: '-',
      price: '-',
      grossPotential:
        formatNumberToCurrency(selectedSearchedEvent?.projectedNetShowReceipts?.netGrossReceiptsTotal) ?? '-',
      bold: true,
    });
  }
  return {
    bottom: result,
  };
};
