import Edit from '@mui/icons-material/Edit';
import { StyledChip, StyledChipChild } from './AgentChip.styled';

interface AgentChipProps {
  name: string;
  onClick: () => void;
}

export function AgentChip({ name, onClick, ...props }: AgentChipProps) {
  return (
    <StyledChip
      {...props}
      label={(
        <StyledChipChild>
          {name}
          <Edit
            fontSize='small'
            color='inherit'
            opacity={0.26}
            onClick={onClick}
            data-testid='edit-agent-icon-btn'
          />
        </StyledChipChild>
      )}
    />
  );
}
