/* eslint-disable max-lines-per-function */
import { useState, useEffect, useContext } from 'react';
import { NotificationDispatchContext } from '@providers';
import Tooltip from '@mui/material/Tooltip';
import { logError } from '@services/telemetry-service';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { SnackbarType } from '../../Snackbar/Snackbar';
import {
  StyledIconButton,
  StyledFailedIcon,
  StyledPendingIcon,
} from './StatusIcon.styled';

enum IconStatus {
  Failed = 'Failed',
  Pending = 'Pending',
  Other = 'Other', // Status is Success or undefined - does not render the icon
}

type MessageProps = {
  pendingTooltip: string;
  failedTooltip: string;
  errorToast: string;
};

type StatusIconProps = {
  status: string;
  messages: MessageProps;
  clickFn: () => Promise<void>;
};

export function StatusIcon({
  status,
  messages,
  clickFn,
}: StatusIconProps) {
  const appInsights = useAppInsightsContext();
  const setNotification = useContext(NotificationDispatchContext);
  const [iconStatus, setIconStatus] = useState<IconStatus>(IconStatus.Other);

  useEffect(() => {
    if (status) {
      if (status.includes(IconStatus.Failed)) {
        setIconStatus(IconStatus.Failed);
      } else if (status.includes(IconStatus.Pending)) {
        setIconStatus(IconStatus.Pending);
      } else {
        setIconStatus(IconStatus.Other);
      }
    }
  }, []);

  const handleRetry = () => {
    void (async () => {
      setIconStatus(IconStatus.Pending);
      try {
        await clickFn();
        setIconStatus(IconStatus.Other);
      } catch (err) {
        logError(appInsights, 'retry error', err);
        setTimeout(() => {
          setIconStatus(IconStatus.Failed);
          setNotification({
            type: SnackbarType.ERROR,
            text: messages.errorToast,
            duration: 6000,
          });
        }, 1000);
      }
    })();
  };

  return (
    <>
      {iconStatus === IconStatus.Failed && (
        <Tooltip title={messages.failedTooltip} arrow>
          <StyledIconButton onClick={handleRetry}>
            <StyledFailedIcon></StyledFailedIcon>
          </StyledIconButton>
        </Tooltip>
      )}
      {iconStatus === IconStatus.Pending && (
        <Tooltip title={messages.pendingTooltip} arrow>
          <StyledPendingIcon size={24} data-testid='PendingIcon'></StyledPendingIcon>
        </Tooltip>
      )}
    </>
  );
}
