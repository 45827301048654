import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';

export const GlobalSearchContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  paddingTop: theme.spacing(6),
  gap: theme.spacing(1),
}));

export const NoResultsContainer = styled(Box)({
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

export const NoResultsText = styled(Typography)({
  color: '#00000061',
});

export const DocumentRow = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(1),
}));

export const NameContainer = styled(Link)({
  display: 'flex',
  alignContent: 'end',
  alignItems: 'end',
});

export const DocumentTypeLabel = styled(Typography)({
  textTransform: 'capitalize',
});
