import Chip from '@mui/material/Chip';
import { CustomTheme, styled } from '@mui/material/styles';

export const StyledChip = styled(Chip)(({ theme }: { theme?: CustomTheme }) => ({
  backgroundColor: theme?.palette?.action?.selected,
  color: theme?.palette?.text?.primary,
}));

export const StyledChipChild = styled('div')(({ theme }: { theme?: CustomTheme }) => ({
  color: theme?.palette?.text?.base,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: theme?.spacing(4),
  gap: theme?.spacing(1),
}));
