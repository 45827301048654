import React, { useState } from 'react';
import MenuItem from '@mui/material/MenuItem';
import { capitalize } from '@mui/material/utils';

import { ValueTypeCellContainer } from './ValueTypeCell.styled';
import { SelectCell, TextFieldCell } from '../Cells.styled';

interface ValueTypeCellProps {
  value?: string;
  onChangeValue?: (value: string) => void;
  onSelectType?: (type: string) => void;
  formatValue?: (value: string) => string;
  type?: string;
  options: {
    label: string;
    value: string;
  }[];
  disabled?: boolean;
}

// eslint-disable-next-line max-lines-per-function
export function ValueTypeCell({
  value,
  type,
  options,
  disabled,
  formatValue,
  onChangeValue,
  onSelectType,
}: ValueTypeCellProps) {
  const typeNotInOptions = !options.some(
    (option) => option.value === type,
  ); // i.e. 'Varies' or 'Manual'
  const [inputValue, setInputValue] = useState<string>();
  const [valueFocused, setValueFocused] = useState(false);
  const onFocus = () => {
    const safeValue = Number.isNaN(Number(value)) ? '' : value;
    setInputValue(safeValue);
    setValueFocused(true);
  };
  const onBlur = () => setValueFocused(false);

  const handleChange = (targetValue: string) => {
    const cleanTargetValue = targetValue.replace('%', '');
    const numberValue = Number(cleanTargetValue);

    if (Number.isNaN(numberValue) || numberValue < 0 || numberValue > 100) {
      return;
    }

    setInputValue(targetValue);
    onChangeValue?.(cleanTargetValue);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    e.stopPropagation(); // Prevent MUI datagrid arrow keys from triggering
    if (e.key === 'Enter') {
      const target = e.target as HTMLInputElement;
      handleChange(target.value);
      target.blur();
    }
  };

  return (
    <ValueTypeCellContainer>
      <TextFieldCell
        variant="standard"
        placeholder='Enter value'
        value={valueFocused
          ? inputValue // Use input value if focused
          : formatValue?.(value ?? '') // Otherwise use the formatted value passed in
        }
        onChange={(e) => handleChange(e.target.value)}
        disabled={disabled}
        onFocus={onFocus}
        onBlur={onBlur}
        onKeyDown={handleKeyDown}
      />
      <SelectCell
        variant="standard"
        displayEmpty
        value={type}
        onChange={({ target }) => onSelectType?.(target.value as string)}
        disabled={disabled || !onSelectType}
      >
        <MenuItem disabled value={typeNotInOptions ? type : ''}>
          {typeNotInOptions ? capitalize(type ?? '') : 'Enter amount'}
        </MenuItem>
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </SelectCell>
    </ValueTypeCellContainer>
  );
}
