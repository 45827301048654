import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

export const GridRowName = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.body2.fontSize,
  textWrap: 'pretty',
}));

export const GridRowPinned = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
  justifyContent: 'space-between',
  width: '100%',
  height: '100%',
}));

export const ShowSelectContainer = styled('div')(({ theme }) => ({
  marginBottom: theme.spacing(2),
  width: theme.spacing(14),
}));

export const FlexRow = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1),
}));

export const NestedGridContainer = styled('div')(({ theme }) => ({
  maxHeight: theme.spacing(70),
}));
