import React, { useContext, useEffect, useState } from 'react';
import CheckCircle from '@mui/icons-material/CheckCircle';
import ErrorOutline from '@mui/icons-material/ErrorOutline';
import WarningAmberOutlined from '@mui/icons-material/WarningAmberOutlined';
import CloseIcon from '@mui/icons-material/Close';
import { SnackbarCloseReason, SnackbarProps } from '@mui/material/Snackbar';
import { NotificationContext, NotificationDispatchContext } from '@providers';
import { AlertStyled, CloseSnackbarButton, SnackbarStyled } from './Snackbar.styled';

export enum SnackbarType {
  SUCCESS = 'success',
  WARNING = 'warning',
  ERROR = 'error',
}

export function AegSnackbar({
  type,
  message,
  duration,
  ...rest
}: {
  type?: SnackbarType;
  message: string;
  duration?: number | null;
} & SnackbarProps) {
  const [open, setOpen] = useState(false);
  const setNotification = useContext(NotificationDispatchContext);
  const notification = useContext(NotificationContext);

  useEffect(() => {
    if (notification) {
      setOpen(true);
    }
  }, [notification]);

  const onClose = (
    event?:Event | React.SyntheticEvent<any, Event>,
    reason?: SnackbarCloseReason,
  ) => {
    if (reason === 'clickaway' && !duration) {
      return;
    }

    setOpen(false);
    setTimeout(() => {
      setNotification(null);
    }, 500);
  };

  let icon;
  if (type === SnackbarType.SUCCESS) {
    icon = <CheckCircle />;
  } else if (type === SnackbarType.WARNING) {
    icon = <WarningAmberOutlined fontSize='small' />;
  } else if (type === SnackbarType.ERROR) {
    icon = <ErrorOutline/>;
  }

  return (
    <SnackbarStyled
      open={open}
      anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
      autoHideDuration={duration}
      onClose={onClose}
      {...rest}
    >
      <AlertStyled
        data-testid="toast-snackbar-message"
        icon={icon}
        severity={type}
        variant="filled"
        action={!duration && (
          <CloseSnackbarButton onClick={() => onClose()}>
            <CloseIcon fontSize='small'/>
          </CloseSnackbarButton>
        )}
      >
        {message}
      </AlertStyled>
    </SnackbarStyled>
  );
}
