import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import { kebabCase } from '@utils/stringHelpers';
import ListItemIcon from '@mui/material/ListItemIcon';

export type DropdownOption<T> = {
  label: string;
  value: T;
  divider?: boolean;
  disabled?: boolean;
  icon?: React.ReactElement;
};

type DropdownButtonProps<T> = {
  label: string;
  dropdownOptions: Array<DropdownOption<T>>;
  variant?: 'text' | 'contained';
  onSelect: (value: T) => void;
  disabled?: boolean;
};

export function DropdownButton<T>(props: DropdownButtonProps<T>) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const {
    label,
    dropdownOptions,
    onSelect,
    variant = 'outlined',
    disabled,
    ...rest
  } = props;

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleAction = (value: T) => {
    setAnchorEl(null);
    onSelect(value);
  };

  return (
    <Box {...rest}>
      <Button
        data-testid="dropdown-button"
        variant={variant}
        onClick={handleClick}
        endIcon={<KeyboardArrowDown />}
        disabled={disabled}
      >
        {label}
      </Button>
      <Menu
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        data-testid="dropdown-menu"
      >
        {dropdownOptions.map((option, index) => (
          <MenuItem
            data-testid={`${kebabCase(String(option.value))}-option`}
            onClick={() => handleAction(option.value)}
            key={`menu-item-${index}`}
            divider={option.divider}
            disabled={option.disabled}
          >
            {option.icon && <ListItemIcon>{option.icon}</ListItemIcon> }
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
}
