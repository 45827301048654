import {
  ChangeEvent,
  KeyboardEvent,
  useContext,
  useState,
} from 'react';
import { useQuery } from '@apollo/client';
import { InputContainer, Loading, StatusChip } from '@components';
import { GLOBAL_SEARCH } from '@gql/queries/globalSearch';
import InputAdornment from '@mui/material/InputAdornment';
import Typography from '@mui/material/Typography';
import SearchIcon from '@mui/icons-material/Search';
import { GlobalSearchDocument, GlobalSearchResourceType } from '@gql/types/graphql';
import { SideNavFlyoutDispatchContext } from '@providers';
import {
  DocumentRow,
  DocumentTypeLabel,
  GlobalSearchContainer,
  NameContainer,
  NoResultsContainer,
  NoResultsText,
} from './GlobalSearch.styled';

// eslint-disable-next-line max-lines-per-function
export function GlobalSearch() {
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [searchInputValue, setSearchInputValue] = useState<string>('');
  const isDirty = searchInputValue.length > 0 && searchTerm !== searchInputValue;
  const { setSideNavOpenFlyoutId } = useContext(SideNavFlyoutDispatchContext);

  const { loading, data } = useQuery(GLOBAL_SEARCH, {
    variables: {
      searchTerm,
    },
    skip: searchTerm.length < 1,
  });

  const handleKeyUp = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      const input = event.target as HTMLInputElement;
      setSearchTerm(input.value);
    }
  };

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchInputValue(event.target.value);
  };

  const handleSubmit = () => {
    setSearchTerm('');
    setSideNavOpenFlyoutId(null);
    setSearchInputValue('');
  };

  const documents = data?.globalSearch ?? [];

  const getLink = (document: GlobalSearchDocument): string => {
    switch (document.type) {
      case GlobalSearchResourceType.Bundle:
        return `/bundles/${document.id}`;
      case GlobalSearchResourceType.Tour:
        return `/tours/${document.id}`;
      default: // Should never happen
        return '/';
    }
  };

  return (
    <GlobalSearchContainer>
      <InputContainer
        data-testid='global-search-input'
        placeholder="Search venues, artists, and events..."
        onKeyUp={handleKeyUp}
        onChange={handleOnChange}
        helperText={isDirty ? 'Hit "Enter" to search' : ''}
        FormHelperTextProps={{
          sx: {
            color: 'initial',
            opacity: 0.6,
            pl: 1.5,
            mt: 0.5,
          },
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />
      {loading && (
        <NoResultsContainer>
          <Loading />
        </NoResultsContainer>
      )}
      {!loading && documents.length === 0 && searchTerm.length === 0 && (
        <NoResultsContainer>
          <NoResultsText>Search results will appear here.</NoResultsText>
        </NoResultsContainer>
      )}
      {!loading && documents.length === 0 && searchTerm.length > 0 && (
        <NoResultsContainer>
          <NoResultsText>No Results Found. Try search again.</NoResultsText>
        </NoResultsContainer>
      )}
      {!loading && documents.length > 0 && documents.map((searchDocument) => (
        <DocumentRow key={searchDocument.id}>
          <NameContainer to={getLink(searchDocument)} onClick={handleSubmit}>
            <Typography>{searchDocument.name}</Typography>
            <DocumentTypeLabel variant='caption'>&nbsp;- {searchDocument.type.toLowerCase()}</DocumentTypeLabel>
          </NameContainer>
          <StatusChip status={searchDocument.status}/>
        </DocumentRow>
      ))}
    </GlobalSearchContainer>
  );
}
