import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import { styled } from '@mui/material/styles';

export const ActionButton = styled(Button)(({ theme }) => ({
  marginLeft: theme.spacing(2),
}));

export const DialogStyled = styled(Dialog)(() => ({
  zIndex: 1,
}));
