import { useRef, useState } from 'react';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import { IconPropsColorOverrides } from '@mui/material/Icon';
import IconButton from '@mui/material/IconButton';
import MenuList from '@mui/material/MenuList';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { OverridableStringUnion } from '@mui/types';

type IconColor = OverridableStringUnion<
| 'inherit'
| 'action'
| 'disabled'
| 'primary'
| 'secondary'
| 'error'
| 'info'
| 'success'
| 'warning',
IconPropsColorOverrides
> | undefined;

type KebabPopperMenuProps = {
  kebabIconColor?: IconColor;
  isDisabled?: boolean;
  children: React.ReactNode;
  kebabIconSize?: 'small' | 'medium' | 'large';
  kebabIcon?: React.ReactNode;
};

export const KebabPopperMenu = ({
  kebabIconColor,
  isDisabled,
  children,
  kebabIconSize,
  kebabIcon,
}: KebabPopperMenuProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const anchorRef = useRef<HTMLButtonElement>(null);

  return (
    <>
      <IconButton
        ref={anchorRef}
        onClick={() => setIsOpen(!isOpen)}
        disabled={isDisabled ?? false}
        data-testid='actions-kebab'
      >
        {kebabIcon ?? <MoreVertIcon color={kebabIconColor} fontSize={kebabIconSize} />}
      </IconButton>
      <Popper
        open={isOpen}
        anchorEl={anchorRef.current}
        role={undefined}
        placement="bottom-start"
        transition
      >
        {({ TransitionProps }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: 'right top',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={() => setIsOpen(false)}>
                <MenuList
                  autoFocusItem={isOpen}
                  id="composition-menu"
                  aria-labelledby="composition-button"
                >
                  {children}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};
