import { styled } from '@mui/material/styles';

export const TabHeaderContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  paddingBottom: theme.spacing(3),
  marginBottom: theme.spacing(3),
  borderBottomColor: theme.palette.divider,
  borderBottomWidth: '1px',
  borderBottomStyle: 'solid',
}));

export const SortLabel = styled('div')(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontSize: theme.spacing(1.5),
  fontWeight: 400,
  lineHeight: theme.spacing(1.5),
  letterSpacing: '0.15px',
}));

export const SortText = styled('span')(() => ({
  fontWeight: 700,
}));
