import Typography from '@mui/material/Typography';
import { Footer } from './LastSyncedFooter.styled';

type LastSyncedFooterProps = {
  lastSynced?: string;
};

export const LastSyncedFooter = (props: LastSyncedFooterProps) => {
  const { lastSynced } = props;

  const generateLastSyncedMessage = () => {
    if (!lastSynced) {
      return 'never.';
    }
    const date = new Date(lastSynced);
    if (date.toString() === 'Invalid Date') {
      return 'N/A';
    }

    return date.toLocaleString();
  };

  return (
    <Footer>
      <Typography variant="caption-small" data-testid="last-sync">
          Last synced {generateLastSyncedMessage()}
      </Typography>
    </Footer>
  );
};
