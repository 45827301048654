import Box from '@mui/material/Box';
import Button, { ButtonProps } from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { CustomTheme, styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

export const NotesInput = styled(TextField)(({ theme }) => ({
  width: '100%',
  marginTop: theme.spacing(1),
}));

export const NotesContainer = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  padding: theme.spacing(2),
}));

export const HeaderGrid = styled(Grid)(({ theme }) => ({
  marginTop: theme.spacing(4),
  marginBottom: theme.spacing(6),
  maxWidth: '100%',
}));

export const Title = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
}));

export const VenueName = styled(Typography)(({ theme }) => ({
  marginRight: theme.spacing(5),
}));

export const MetadataGrid = styled(Grid)(({ theme }) => ({
  display: 'grid',
  gap: theme.spacing(0),
  gridTemplateColumns: `repeat(auto-fill, max(${theme.spacing(20)}))`,
})) as typeof Grid;

export const StickyHeader = styled(Box)(({ theme }: { theme?:CustomTheme }) => ({
  position: 'sticky',
  top: 0,
  backgroundColor: theme?.palette.primary.contrast,
  zIndex: 10,
  borderRadius: theme?.spacing(0.5),
}));

export const FlexContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: theme.spacing(2),
}));

export const ButtonContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1),
}));

export const VenueDataContainer = styled(Paper)(({ theme }: { theme?: CustomTheme }) => ({
  marginBottom: theme?.spacing(2),
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: theme?.palette.primary.contrast,
  borderRadius: theme?.spacing(0.5),
}));

export const VenueGridsContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
}));

export const FlexGridItem = styled(Grid)(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'end',
}));

export const VenueEditRequestButton = styled(Button)<ButtonProps>(() => ({
  minWidth: 'max-content',
  height: 'fit-content',
}));

export const LastUpdated = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
}));
