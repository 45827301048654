/* eslint-disable max-lines-per-function */
import { useContext } from 'react';
import Grid from '@mui/material/Grid';
import { BundleEvent } from '@gql/types/graphql';
import { EventsList, REMOVE_BUNDLE_EVENT_DIALOG, SnackbarType } from '@components';
import { AegResourceTypes, UserPermissions } from '@types';
import { DialogDispatchContext, NotificationDispatchContext } from '@providers';
import { useMutation } from '@apollo/client';
import { REMOVE_BUNDLE_EVENT, TOGGLE_ACTIVE_EVENT } from '@gql/mutations/bundles';

interface BundleRoutingTabProps {
  bundleEvents: BundleEvent[];
  bundleId: string;
  bundleOfferId: string;
}

export const BUNDLE_ROUTING_COLUMNS = [
  'isPrimary',
  'venueName',
  'market',
  'showsCount',
  'firstEventDate',
  'updatedDate',
  'capacity',
  'buyerIds',
  'status',
  'active',
  'removeAction',
];

export function BundleRoutingTab({ bundleEvents, bundleId, bundleOfferId }: BundleRoutingTabProps) {
  const setDialog = useContext(DialogDispatchContext);
  const setNotification = useContext(NotificationDispatchContext);

  const [toggleActiveEvent] = useMutation(TOGGLE_ACTIVE_EVENT, {
    errorPolicy: 'all',
    onError() {
      setNotification({
        text: 'Error toggling event active status. Please try again.',
        type: SnackbarType.ERROR,
        duration: 6000,
      });
      setDialog(null);
    },
  });

  const [removeBundleEvent] = useMutation(REMOVE_BUNDLE_EVENT, {
    errorPolicy: 'all',
    onError() {
      setNotification({
        text: 'Error removing event from bundle. Please try again.',
        type: SnackbarType.ERROR,
        duration: 6000,
      });
      setDialog(null);
    },
  });

  const handleCancelDialog = () => setDialog(null);

  const handleActivateEvent = (eventId: string, isActive: boolean) => {
    void toggleActiveEvent({
      variables: {
        bundleId,
        bundleOfferId,
        eventId,
        isActive,
      },
    });
  };

  const handleRemoveConfirmed = async (eventId: string) => {
    setDialog((prevDialog) => ({
      ...(prevDialog ?? {}),
      isLoading: true,
    }));
    await removeBundleEvent({
      variables: {
        bundleId,
        bundleOfferId,
        eventId,
      },
    });
    setDialog(null);
  };

  const handleConfirmRemoveEvent = (eventId: string) => {
    setDialog({
      titles: REMOVE_BUNDLE_EVENT_DIALOG.TITLES,
      description: REMOVE_BUNDLE_EVENT_DIALOG.DESCRIPTION,
      submit: {
        text: REMOVE_BUNDLE_EVENT_DIALOG.SUBMIT,
        action: () => {
          void handleRemoveConfirmed(eventId);
        },
      },
      cancel: {
        text: REMOVE_BUNDLE_EVENT_DIALOG.CANCEL,
        action: handleCancelDialog,
      },
    });
  };

  const events = bundleEvents?.map((bundleEvent) => ({
    ...bundleEvent.event,
    isActive: bundleEvent.isActive,
  }));

  return (
    <Grid container>
      <Grid item xs={12}>
        <EventsList
          events={events}
          columns={BUNDLE_ROUTING_COLUMNS}
          columnHeaderOverrides={{ status: 'Offer Status' }}
          noEventsFoundText='Your Offer is empty. Click the "Add / Remove One-Offs" button to add your first event.'
          removeEventPermission={{
            permission: UserPermissions.UpdateBundle,
            resourceType: AegResourceTypes.Bundle,
            resourceId: bundleId,
          }}
          onActivateEvent={handleActivateEvent}
          onRemoveEvent={handleConfirmRemoveEvent}
          persistSort
        />
      </Grid>
    </Grid>
  );
}
