import * as React from 'react';
import { Link } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import { BreadcrumbContext } from '@providers';
import { kebabCase } from '@utils/stringHelpers';
import { StyledNavBreadcrumbs } from './NavBreadcrumbs.styled';

interface CrumbParams {
  text: string;
  href: string;
  last: boolean;
}

function Crumb({ text, href, last = false }: CrumbParams) {
  const dataIdText: string = kebabCase(text);
  if (last) {
    return (
      <Typography
        variant="body2"
        color="text.primary"
        data-testid={`${dataIdText}-breadcrumb`}
      >
        {text}
      </Typography>
    );
  }

  return (
    <Link to={href} data-testid={`${dataIdText}-breadcrumb`}>
      <Typography variant="body2" color="info.main">
        {text}
      </Typography>
    </Link>
  );
}

export function NavBreadcrumbs() {
  const breadcrumbs = React.useContext(BreadcrumbContext);

  return (
    <StyledNavBreadcrumbs aria-label="breadcrumb" data-testid="routeBreadcrumbs">
      {breadcrumbs.map((crumb, idx) => (
        <Crumb {...crumb} key={idx} last={idx === breadcrumbs.length - 1} />
      ))}
    </StyledNavBreadcrumbs>
  );
}
