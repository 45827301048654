import React from 'react';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import {
  MenuItemStyled,
  SelectContainer,
  SelectItemContainer,
  SelectLabelContainer,
  SelectControlContainer,
} from './SelectProjectionType.styled';

export interface SelectOption {
  label: string;
  value: string;
}

interface SelectProjectionTypeProps {
  value?: string;
  items: SelectOption[];
  onChange: (change: string) => void;
}

export const SelectProjectionType: React.FC<SelectProjectionTypeProps> = ({
  value,
  items,
  onChange,
}) => {
  const selectLabel = (
    <SelectLabelContainer data-testid="select-projection-type-label-container">
      Select Projection Type
    </SelectLabelContainer>
  );

  const handleChange = (change: SelectChangeEvent<string>) => {
    if (change.target.value !== undefined) {
      onChange(change.target.value);
    }
  };

  return (
    <SelectContainer data-testid="select-projection-type-container">
      {selectLabel}
      <SelectControlContainer>
        <Select
          variant="standard"
          onChange={handleChange}
          value={value}
          fullWidth
          aria-label="Select Projection Type"
          data-testid="select-projection-type-select-container"
          inputProps={{
            'data-testid': 'select-projection-type-input',
          }}
        >
          {items.map((item, index) => (
            <MenuItemStyled
              key={`select-projection-type-option-${index}`}
              value={item.value}
              data-testid={`item-select-projection-type-option-${index}`}
              showDivider={items.length === index + 1}
            >
              <SelectItemContainer
                className='alternate'
                data-testid="select-item-container"
              >
                {item.label}
              </SelectItemContainer>
            </MenuItemStyled>
          ))}
        </Select>
      </SelectControlContainer>
    </SelectContainer>
  );
};
