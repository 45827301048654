import Button, { ButtonProps } from '@mui/material/Button';
import { styled } from '@mui/material/styles';

export const CloseButton = styled(Button)<ButtonProps>(({ theme }) => ({
  display: 'flex',
  alignItems: 'end',
  justifyContent: 'end',
  top: theme.spacing(5),
  marginLeft: 'auto',
}));
